import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import { Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import BackspaceSharpIcon from '@mui/icons-material/BackspaceSharp';
import { COLORS } from '../../constants';

const NumberButton = styled(Button)(() => ({
  width: '100%',
  minWidth: '50px !important',
  minHeight: '50px !important',
  height: '73px',
  border: '0.386901px solid #575657',
  borderRadius: '9.80442px',
  color: COLORS.BLACK,
  fontSize: '26px',
}));

interface ButtonProps {
  onChange: (amount: string) => void;
}

export const NumberKeyboardButtons = memo(({ onChange }: ButtonProps) => {
  const [amount, setAmount] = useState<string>('');
  const divRef = useRef<HTMLDivElement>(null);
  const [onKeyDown, setOnKeyDown] = useState('');
  const [flag, setFlag] = useState(0);
  const onClickNumber = (number: number) => {
    let currentAmount = '';
    if (amount) {
      currentAmount = amount + number.toString();
    } else {
      currentAmount = number.toString();
    }
    setAmount(currentAmount);
    onChange(currentAmount);
  };
  const onClickOption = (option: string) => {
    switch (option) {
      case '0':
        if (amount) {
          const total = amount.toString() + option.toString();
          setAmount(total);
          onChange(total);
        } else {
          setAmount(option.toString());
          onChange(option.toString());
        }
        break;
      case '.':
        if (amount) {
          setAmount(`${amount.toString()}.`);
          onChange(`${amount.toString()}.`);
        } else {
          setAmount('0.');
          onChange('0.');
        }
        break;
      case 'x':
        setAmount(amount.slice(0, -1));
        onChange(amount.slice(0, -1));
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (onKeyDown) {
      if ((Number(onKeyDown) >= 0 && Number(onKeyDown) <= 9)) {
        onClickNumber(Number(onKeyDown));
      } else if (onKeyDown === 'Backspace') {
        onClickOption('x');
      } else if (onKeyDown === '.') {
        onClickOption('.');
      }
    }
  }, [onKeyDown, flag]);
  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (divRef.current) {
        const { key, code } = event;
        if (((Number(key) >= 0 && Number(key) <= 9) || key === 'Backspace' || key === '.') && code !== 'Space') {
          setOnKeyDown(key);
          setFlag((cont) => cont + 1);
        }
      }
    });
  }, []);

  return (
    <Grid container spacing={1} justifyContent="center" flexDirection="row" ref={divRef}>
      <Grid container item xs={12} justifyContent="center" spacing={1}>
        {[7, 8, 9].map((number) => (
          <Grid item xs={4} key={number}>
            <NumberButton id={`number-${number}-Btn`} onClick={() => onClickNumber(number)}>
              {number}
            </NumberButton>
          </Grid>
        ))}
      </Grid>
      <Grid container item spacing={1} xs={12} justifyContent="center">
        {[4, 5, 6].map((number) => (
          <Grid item xs={4} key={number}>
            <NumberButton id={`number-${number}-Btn`} onClick={() => onClickNumber(number)}>
              {number}
            </NumberButton>
          </Grid>
        ))}
      </Grid>
      <Grid container item spacing={1} xs={12} justifyContent="center">
        {[1, 2, 3].map((number) => (
          <Grid item xs={4} key={number}>
            <NumberButton id={`number-${number}-Btn`} onClick={() => onClickNumber(number)}>
              {number}
            </NumberButton>
          </Grid>
        ))}
      </Grid>
      <Grid container item spacing={1} xs={12} justifyContent="center">
        {[0].map((option) => (
          <Grid item xs={4} key={option}>
            <NumberButton id={`number-${option}-Btn`} onClick={() => onClickNumber(option)}>
              {option}
            </NumberButton>
          </Grid>
        ))}
        <Grid item xs={4} key="dot">
          <NumberButton id="number-dot-Btn" onClick={() => onClickOption('.')}>
            .
          </NumberButton>
        </Grid>
        <Grid item xs={4} key="remove">
          <NumberButton id="number-remove-Btn" onClick={() => onClickOption('x')}>
            <BackspaceSharpIcon />
          </NumberButton>
        </Grid>
      </Grid>
    </Grid>
  );
});
