/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import {
  Container,
  Grid,
  styled,
  Box,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Divider,
} from '@mui/material';
import { COLORS } from '../../constants';
import Header from './Header';
import ProductForm, { ProductFormValues } from './ProductForm';
import {
  Addition, MenuAddition, Product, MenuSection, AdditionOption, Gallery,
} from '../../core';
import { Nullable } from '../../types';
import AdditionsPicker from './addition';
import { useAppSelector } from '../../appHooks/redux';
import {
  AdditionFormValues, AdditionOptionFormValues,
} from './addition/additionEditView/AdditionEditForm';
import { UploadImgGalleryProps } from '../galleryScreen/types';

const ContainerMain = styled(Container)(() => ({
  width: '100%',
  minHeight: '93.8vh',
  backgroundColor: COLORS.BG_GRAY,
}));

type ProductDetailsScreenProps = {
  isSharedMenu: boolean,
  menuSection: Nullable<MenuSection>,
  currentProduct: Nullable<Product>,
  onDeleteProduct?: (productId: string) => void,
  onSubmitSave:(values: ProductFormValues, productId: string) =>void | Promise<void>,
  restaurantAdditions?: Nullable<MenuAddition[]>,
  productAdditions: Nullable<Addition[]>,
  onCreateMenuAdditionsRestaurant?: (
    values: AdditionFormValues, availability: boolean
  ) => void | Promise<void>,
  onDeleteMenuAdditionRestaurant?: (menuAdditionId: string) => void | Promise<void>,
  onAddAdditionProduct?: (
    productId: string, menuAddition: MenuAddition, order: number,
  ) => void | Promise<void>,
  onDeleteAdditionProduct?: (productId: string, additionId:string) => void | Promise<void>,
  onDragEndProductsAdditions: (prodId:string, additions: Addition[]) => void,
  onSharedWithOtherProducts: (menuAdditionsId: string, prodId:string) => Promise<number>,
  onEditAdditionRestaurantAndProduct?: (
    values: AdditionFormValues,
    availability:boolean,
    menuAdditionId: string,
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
  onEditAdditionOptionsRestaurantAndProduct?: (
    swaped: AdditionOption[],
    menuAdditionsId: string,
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
  onEditAdditionOptionsProduct?: (
    swaped: AdditionOptionFormValues[],
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
  onUploadImg: ({ file, docId }: UploadImgGalleryProps) => void | Promise<boolean>,
  onAddGallery: (gallery: Gallery) => Promise<Gallery | null>,
};

export const ProductDetailsScreen = ({
  isSharedMenu,
  menuSection,
  currentProduct,
  onDeleteProduct,
  onSubmitSave,
  restaurantAdditions,
  productAdditions,
  onCreateMenuAdditionsRestaurant,
  onDeleteMenuAdditionRestaurant,
  onAddAdditionProduct,
  onDeleteAdditionProduct,
  onDragEndProductsAdditions,
  onSharedWithOtherProducts,
  onEditAdditionRestaurantAndProduct,
  onEditAdditionOptionsRestaurantAndProduct,
  onEditAdditionOptionsProduct,
  onUploadImg,
  onAddGallery,
}: ProductDetailsScreenProps) => {
  const currentRestaurant = useAppSelector((state) => state.restaurant.restaurant);

  return (
    <ContainerMain maxWidth="xl">
      {menuSection ? (
        <>
          <Header
            isSharedMenu={isSharedMenu}
            sectionName={menuSection?.name || ''}
          />
          {!currentProduct && (
          <Card>
            <CardHeader title="Cargando Producto" />
            <Divider />
            <CardContent>
              <Box display="flex" justifyContent="center" my={5}>
                <CircularProgress />
              </Box>
            </CardContent>
          </Card>
          )}
          {currentProduct && (
          <Grid container flexDirection="row" justifyContent="space-between">
            <Grid item xs={8} md={8}>
              <Box mt={3} mr={3}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <ProductForm
                      currentProduct={currentProduct}
                      isSharedMenu={isSharedMenu}
                      onDeleteProduct={onDeleteProduct}
                      onSubmitSave={onSubmitSave}
                      onUploadImg={onUploadImg}
                      onAddGallery={onAddGallery}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box mt={3}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    {!_.isEmpty(currentRestaurant) && (
                      <AdditionsPicker
                        isSharedMenu={isSharedMenu}
                        product={currentProduct}
                        restaurantAdditions={restaurantAdditions}
                        productAdditions={productAdditions}
                        onCreateMenuAdditionsRestaurant={onCreateMenuAdditionsRestaurant}
                        onDeleteMenuAdditionRestaurant={onDeleteMenuAdditionRestaurant}
                        onAddAdditionProduct={onAddAdditionProduct}
                        onDeleteAdditionProduct={onDeleteAdditionProduct}
                        onDragEndProductsAdditions={onDragEndProductsAdditions}
                        onSharedWithOtherProducts={onSharedWithOtherProducts}
                        onEditAdditionRestaurantAndProduct={onEditAdditionRestaurantAndProduct}
                        onEditAdditionOptionsRestaurantAndProduct={onEditAdditionOptionsRestaurantAndProduct}
                        onEditAdditionOptionsProduct={onEditAdditionOptionsProduct}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          )}
        </>
      ) : (
        <Card>
          <CardHeader title="Cargando Producto" />
          <Divider />
          <CardContent>
            <Box display="flex" justifyContent="center" my={5}>
              <CircularProgress />
            </Box>
          </CardContent>
        </Card>
      )}
    </ContainerMain>
  );
};
