/* eslint-disable max-len */
import React from 'react';

import nProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useNavigate } from 'react-router-dom';
import { HeadTitle } from '../../components';
import { MenuSectionScreen } from '../../screens';
import { useAppSelector } from '../../appHooks/redux';
import {
  editSubsidiaryMenuSection,
  MenuSection,
  useIsTemplateMenu,
} from '../../core';
import { enableSectionProps } from '../../screens/menuSectionScreen/types';

const SubsidiaryMenu = () => {
  const router = useNavigate();
  const isSharedMenu = useIsTemplateMenu();
  const currentRestaurant = useAppSelector((state) => state.restaurant.restaurant);
  const currentSubsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  const menuListData = currentSubsidiary?.menu_section || [];

  nProgress.configure({ showSpinner: false });

  const onEnableSection = async ({
    section, index, enable,
  }: enableSectionProps) => {
    const editMenuSection = {
      ...section,
      enable,
    };
    if (currentRestaurant?.id && currentSubsidiary?.id && menuListData) {
      const newMenuSections = menuListData.slice();
      newMenuSections[index] = new MenuSection(editMenuSection);
      await editSubsidiaryMenuSection(newMenuSections, currentRestaurant?.id, currentSubsidiary.id);
    }
  };

  const onDragEnd = async (menuSections: MenuSection[]) => {
    if (currentRestaurant?.id && currentSubsidiary?.id) {
      await editSubsidiaryMenuSection(menuSections, currentRestaurant?.id, currentSubsidiary.id);
    }
  };

  const onClickSection = async (sectionId: string) => {
    await router(`/subsidiaryMenu/${sectionId}`);
  };

  return (
    <>
      <HeadTitle title="Menu Sucursal" />
      <MenuSectionScreen
        menuSectionsDB={menuListData}
        isSharedMenu={isSharedMenu}
        onEnableSection={onEnableSection}
        onDragEnd={onDragEnd}
        onClickSection={onClickSection}
      />
    </>
  );
};

export default SubsidiaryMenu;
