import React, { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { CheckCircleOutlinedIcon, Ghost } from '../../assets';
import {
  HeadTitle,
  AlertModal,
} from '../../components';
import {
  Order, ORDER_TYPE, PAYMENT_METHOD, PAYMENT_STATUS, STATUS,
} from '../../core';
import { PaymentScreen } from '../../screens/paymentScreen';
import { useAppDispatch, useAppSelector } from '../../appHooks/redux';
import {
  cleanAllOrder,
  cleanTransactionFailedProducts,
  setCurrentOrder,
  setTransactionFailedProducts,
} from '../../redux/reducers/shoppingCart';
import { setOrder } from '../../core/order/order.service';
import { ROUTES } from '../../constants';
import {
  parseOrderProduct, addOrderProducts, updateOrderProducts, OrderProduct, orderTransactions, deleteProductTransaction,
} from '../../core/orderProduct';
import {
  TicketGenerator, Order
  as OrderTicket,
} from '../../components/pdf';
import { useValidateEnableInventory } from '../../core/restaurant/restaurant.service';

const ContainerMain = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Payment = () => {
  const {
    currentOrder,
    currentProducts,
    currentAdditions,
    orderProduct,
    isOrderEdit,
  } = useAppSelector((state) => state.shoppingCart);
  const [paymentSucces, setPaymentSucces] = useState(false);
  const enableInventory = useValidateEnableInventory();
  const [modalMessage, setModalMessage] = useState<string |null>(null);
  const [eventType, setEventTye] = useState<string | null>(null);
  const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  const restaurant = useAppSelector((state) => state.restaurant.restaurant);
  const { beforeProduct, afterProduct } = useAppSelector((state) => state.inventory);
  const journeyProductionProductsSet = useAppSelector((state) => state.inventory.journeyProductionProductsSet);
  const { value: lastOpenjourneyProduction } = useAppSelector((state) => state.inventory.journeyProduction);
  const products = useAppSelector((state) => state.inventory.journeyProductionProducts);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState<string>('');
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const onPrintClick = async () => {
    if (currentProducts.length && restaurant?.enable_comanda) {
      const ticketPdf = await pdf(
        TicketGenerator({
          ticket: {
            clientName: currentOrder?.client_name || '',
            orderNumber: currentOrder?.order_code || '',
            orderType: currentOrder?.order_type || ORDER_TYPE.LOCAL,
            createAt: new Date(),
            orders: currentProducts.map((product) => {
              const additions = currentAdditions.filter(
                (addition) => addition.product_id === product.id,
              );
              const order:OrderTicket = {
                amount: product.quantity,
                productName: product.name,
                additions: additions.map((add) => ({ amount: add.quantity, name: add.name })),
              };
              return order;
            }),
          },
        }),
      ).toBlob();

      window.open(
        URL.createObjectURL(ticketPdf),
        '_blank',
        'width=600,height=700',
      );
    }
  };

  const onBack = () => {
    router(-1);
  };

  const handleOrderPayment = async (newOrderProducts: OrderProduct[], restaurantId: string, subsidiaryId: string, orderId: string) => {
    if (orderProduct.length > 0) {
      await updateOrderProducts({
        newOrderProducts,
        orderProduct,
        restaurantId,
        subsidiaryId,
        orderId,
        journeyProductionId: lastOpenjourneyProduction?.id,
        products,
        isNotPayed: Boolean(currentOrder?.payment_status === PAYMENT_STATUS.NOT_PAYED),
      });
    } else {
      addOrderProducts({
        newOrderProducts,
        restaurantId,
        subsidiaryId,
        orderId,
        journeyProductionId: lastOpenjourneyProduction?.id,
        products,
        isNotPayed: Boolean(currentOrder?.payment_status === PAYMENT_STATUS.NOT_PAYED),
      });
    }
    dispatch(cleanTransactionFailedProducts());
    enqueueSnackbar('Orden Actualizada', {
      variant: 'success',
    });
  };

  const onCloseAlert = () => {
    dispatch(cleanAllOrder());
    router(ROUTES.COMMAND_PANEL);
  };

  const handleSubmit = async (action: boolean | undefined, payment_method?: PAYMENT_METHOD, orderNotPayed?: Order) => {
    setPaymentSucces(false);
    if (action) {
      if (!restaurant?.id || !subsidiary?.id || !currentOrder) return;
      let orderId:string = currentOrder.id;
      const order = new Order({
        ...currentOrder,
        count_of_products: currentProducts?.length || 0,
        status: STATUS.COMPLETED,
        payment_status: PAYMENT_STATUS.PAID,
        payment_method,
      });
      if (isOrderEdit) {
        orderId = await setOrder({
          restaurantId: restaurant?.id,
          order,
          subsidiaryId: subsidiary?.id,
        });
      }
      const newOrderProducts = parseOrderProduct({
        products: currentProducts,
        additions: currentAdditions,
        orderId,
      });
      if (!enableInventory && restaurant.enable_inventory && journeyProductionProductsSet && lastOpenjourneyProduction) {
        const result = await orderTransactions(restaurant.id, subsidiary.id, beforeProduct, afterProduct, lastOpenjourneyProduction.id, journeyProductionProductsSet);
        setModal(!result.isSuccess);
        if (result.isSuccess) {
          await handleOrderPayment(newOrderProducts, restaurant?.id, subsidiary?.id, orderId);
        } else if (result?.failedProduct) {
          dispatch(setTransactionFailedProducts(result?.failedProduct));
        }
      } else {
        await handleOrderPayment(newOrderProducts, restaurant?.id, subsidiary?.id, orderId);
      }
    }
    if (eventType === PAYMENT_STATUS.NOT_PAYED || orderNotPayed) {
      if (isOrderEdit && orderNotPayed) {
        if (!restaurant?.id || !subsidiary?.id || !currentOrder) return;
        let orderId:string = currentOrder.id;
        orderId = await setOrder({
          restaurantId: restaurant?.id,
          subsidiaryId: subsidiary?.id,
          order: orderNotPayed,
        });
        const newOrderProducts = parseOrderProduct({
          products: currentProducts,
          additions: currentAdditions,
          orderId,
        });
        await handleOrderPayment(newOrderProducts, restaurant?.id, subsidiary?.id, orderId);
      }
      if (lastOpenjourneyProduction && orderProduct.length > 0 && !enableInventory && restaurant?.enable_inventory && subsidiary && restaurant && journeyProductionProductsSet) {
        const deleteProducts = orderProduct.map((product) => [product.product_id, product.quantity]);
        await deleteProductTransaction(restaurant.id, subsidiary.id, lastOpenjourneyProduction.id, journeyProductionProductsSet, deleteProducts);
      }
      setModalMessage(null);
      setEventTye(null);
    }
  };

  const handleSelect = async (value: PAYMENT_METHOD | PAYMENT_STATUS) => {
    setLoading(value);
    const total = (currentOrder?.sub_total || 0) - (currentOrder?.discount || 0);
    switch (value) {
      case PAYMENT_METHOD.CASH: {
        dispatch(setCurrentOrder(new Order({
          ...currentOrder,
          payment_method: value,
          total,
          payment_status: PAYMENT_STATUS.PAID,
        })));
        router('cash');
        break;
      }
      case PAYMENT_METHOD.CARD: {
        await onPrintClick();
        handleSubmit(true, PAYMENT_METHOD.CARD);
        dispatch(setCurrentOrder(new Order({
          ...currentOrder,
          payment_method: value,
          status: STATUS.COMPLETED,
          total,
          amount_paid: total,
          balance: 0,
          payment_status: PAYMENT_STATUS.PAID,
        })));
        break;
      }
      case PAYMENT_METHOD.ONLINE: {
        await onPrintClick();
        handleSubmit(true, PAYMENT_METHOD.ONLINE);
        dispatch(setCurrentOrder(new Order({
          ...currentOrder,
          payment_method: value,
          status: STATUS.COMPLETED,
          total,
          amount_paid: total,
          balance: 0,
          payment_status: PAYMENT_STATUS.PAID,
        })));
        break;
      }
      case PAYMENT_METHOD.QR: {
        await onPrintClick();
        handleSubmit(true, PAYMENT_METHOD.QR);
        dispatch(setCurrentOrder(new Order({
          ...currentOrder,
          payment_method: value,
          status: STATUS.COMPLETED,
          total,
          amount_paid: total,
          balance: 0,
          payment_status: PAYMENT_STATUS.PAID,
        })));
        break;
      }
      case PAYMENT_METHOD.TRANSFER:
        await onPrintClick();
        handleSubmit(true, PAYMENT_METHOD.TRANSFER);
        dispatch(setCurrentOrder(new Order({
          ...currentOrder,
          payment_method: value,
          status: STATUS.COMPLETED,
          total,
          amount_paid: total,
          balance: 0,
          payment_status: PAYMENT_STATUS.PAID,
        })));
        break;
      case PAYMENT_STATUS.NOT_PAYED:
        handleSubmit(false, PAYMENT_METHOD.CASH, new Order({
          ...currentOrder,
          payment_status: PAYMENT_STATUS.NOT_PAYED,
          status: STATUS.CANCELED,
          count_of_products: currentProducts?.length || 0,
          total,
        }));
        dispatch(setCurrentOrder(new Order({
          ...currentOrder,
          payment_status: PAYMENT_STATUS.NOT_PAYED,
          status: STATUS.CANCELED,
          total,
        })));
        setEventTye(value);
        setModalMessage('Los cambios se guardaron correctamente');
        break;
      case PAYMENT_STATUS.PENDING:
        await onPrintClick();
        dispatch(setCurrentOrder(new Order({
          ...currentOrder,
          payment_status: value,
          status: STATUS.ON_HOLD,
          total,
        })));
        break;
      default:
        break;
    }
    setPaymentSucces(true);
    setLoading('');
  };

  return (
    <>
      <HeadTitle title="Tipo de Pago" />
      <ContainerMain>
        <PaymentScreen loading={loading} onSelectPaymentType={handleSelect} onBack={onBack} />
        <AlertModal
          icon={CheckCircleOutlinedIcon}
          open={paymentSucces}
          title={modalMessage || 'Se realizó el pago correctamente'}
          okText="Aceptar"
          onClose={onCloseAlert}
        />
        <AlertModal
          icon={Ghost}
          open={modal}
          title="Ups! El stock disminuyo y no se puede agregar los productos requeridos"
          okText="Aceptar"
          onClose={() => setModal(false)}
        />
      </ContainerMain>
    </>
  );
};

export default Payment;
