import _ from 'lodash';
import {
  doc,
  getFirestore,
  setDoc,
  writeBatch,
} from 'firebase/firestore';
import {
  getDownloadURL, getStorage, ref, uploadBytesResumable,
} from 'firebase/storage';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { COLLECTIONS } from '../../constants';
import { Product } from '../product';
import { Restaurant } from './restaurant.dto';
import { admin } from '../../redux/store';
import { MenuSection } from '../menuSection';
import { useAppSelector } from '../../appHooks/redux';
import { toTimestamp } from '../../utils/firestoreUtils';

const db = getFirestore();

export const restaurantRef = (restaurantId:string) => doc(db, `${COLLECTIONS.RESTAURANT}/${restaurantId}`);

export type EditRestaurantParams = {
      restaurant: Partial<Restaurant>,
      restaurantId:string;
  }

type DataMenuProps = {
  description: string,
  price: number | string,
  product: string,
  section: string,
}

export const editRestaurant = async (
  {
    restaurantId, restaurant,
  }:EditRestaurantParams,
): Promise<void> => {
  const orderRef = doc(
    db,
    `${COLLECTIONS.RESTAURANT}/${restaurantId}`,
  );
  await setDoc(orderRef, restaurant, { merge: true });
};

export const uploadBannerLogo = async (file:File, type:string, restaurantId:string):Promise<string> => {
  const storage = getStorage();
  const storageRef = ref(
    storage,
    `restaurant/${restaurantId}/${type === 'banner' ? 'banner' : 'logo'}/${restaurantId}`,
  );

  const upload = await uploadBytesResumable(storageRef, file).then((snapshot) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.warn(`Upload is ${progress}% done`);
    return getDownloadURL(snapshot.ref).then((downloadURL) => downloadURL);
  }).catch((error) => console.error('error', error));
  return upload as string;
};

const createNewMenuSection = (value:DataMenuProps) => {
  const sectionId = admin.firestore().collection('menu_section').doc().id;
  const newMenuSection = new MenuSection({
    id: sectionId,
    name: value.section,
  });
  return { ...newMenuSection };
};

const createNewProduct = (products:DataMenuProps[], restaurantId:string, sectionId:string) => {
  const productsTemplate = products.map((product, index) => {
    const idProduct = admin.firestore().collection(COLLECTIONS.SHARED_PRODUCTS).doc().id;
    const newProduct = new Product({
      id: idProduct,
      restaurant_id: restaurantId,
      menu_section_id: sectionId,
      template: true,
      menu_order: index,
      name: product.product,
      unit_price: +product.price,
      description: product.description,
      cooking_time: 10,
    });
    return newProduct;
  });
  return productsTemplate;
};

export const importMenuConbrixFromXlsx = async (dataMenu: DataMenuProps[], restaurantId: string, menuListData:MenuSection[] = []) => {
  try {
    const productSharedRef = admin
      .firestore()
      .collection(COLLECTIONS.RESTAURANT)
      .doc(restaurantId)
      .collection(COLLECTIONS.SHARED_PRODUCTS);

    const newMenuSections:MenuSection[] = [];
    const newProductsTemplates:Product[] = [];
    const menu_section = menuListData;
    const dataMenuTrim = dataMenu.map((data:DataMenuProps) => ({
      ...data,
      section: data.section.trim(),
      product: data.product.trim(),
    }));
    const contentMenuBySections = _.chain(dataMenuTrim)
      .groupBy('section')
      .map((value) => {
        const newMenuSection = createNewMenuSection(value[0]);
        const newProductsTemplate = createNewProduct(
          value,
          restaurantId,
          newMenuSection.id,
        );
        return {
          menuSection: newMenuSection,
          productsTemplate: newProductsTemplate,
        };
      })
      .value();

    await Promise.all(
      contentMenuBySections.map(async (newMenu) => {
        const currentMenuSection = menu_section.filter(
          (menuSection) => menuSection.name.trim() === newMenu.menuSection.name.trim(),
        )[0];
        if (currentMenuSection) {
          await Promise.all(
            newMenu.productsTemplate.map(async (newProduct) => {
              const currentProduct = await productSharedRef
                .where('name', '==', newProduct.name)
                .where('enable', '==', true)
                .get()
                .then((querySnapshot:any) => {
                  if (querySnapshot.empty) return [];
                  return querySnapshot.docs.map(
                    (docSnap:any) => new Product({ ...docSnap.data(), id: docSnap.id }),
                  );
                });

              if (currentProduct.length === 0) {
                const newProductTemplate:Product = new Product({
                  ...newProduct,
                  menu_section_id: currentMenuSection.id,
                });
                newProductsTemplates.push(newProductTemplate);
              }
            }),
          );
        } else {
          newMenuSections.push(new MenuSection(newMenu.menuSection));
          newProductsTemplates.push(...newMenu.productsTemplate);
        }
      }),
    );

    const batchRestaurant = writeBatch(db);
    // create menu section
    batchRestaurant.update(restaurantRef(restaurantId), {
      menu_section: [...newMenuSections.map((menuSection) => menuSection.toPlainObject()), ...menu_section.map((menuSection) => menuSection.toPlainObject())],
    });

    await batchRestaurant.commit();

    // create products templates
    const arrayOfArrayNewProductsTemplates = _.chunk(newProductsTemplates, 500);
    await Promise.all(
      arrayOfArrayNewProductsTemplates.map(
        async (arrayOfNewProductsTemplates) => {
          try {
            const batch = admin.firestore().batch();
            arrayOfNewProductsTemplates.forEach((prodTemplate) => {
              batch.set(productSharedRef.doc(prodTemplate.id), {
                ...prodTemplate.toPlainObject(),
              });
            });
            await batch.commit();
          } catch (error) {
            console.error(
              'error product template',
              arrayOfNewProductsTemplates.map((item) => item.id),
            );
          }
        },
      ),
    );

    return { isSucceeded: true, message: 'save success' };
  } catch (error) {
    console.error('error', JSON.stringify(error));
    return { isSucceeded: false, message: 'Error, on save menu import' };
  }
};

export const useValidateEnableInventory = () => {
  const restaurant = useAppSelector((store) => store.restaurant.restaurant);
  const { value: allJourneyProduction, isLoading } = useAppSelector((store) => store.inventory.journeyProduction);

  return useMemo(() => {
    if (isLoading) {
      return false;
    }
    if (restaurant && restaurant.enable_inventory) {
      const currentDate = moment();
      if (!allJourneyProduction) {
        return true;
      }
      if (allJourneyProduction.date_end && allJourneyProduction.date_start) {
        const date = allJourneyProduction?.date_start ? toTimestamp(allJourneyProduction.date_start).toDate() : null;
        const startDate = moment(date);
        if (startDate > currentDate || allJourneyProduction?.date_end || !allJourneyProduction?.date_start) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  }, [isLoading, restaurant, allJourneyProduction]);
};
