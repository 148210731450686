import { PlainObject } from '../../types';

export class Photo {
  original: string;

  x40: string;

  x400: string;

  x80: string;

  constructor({
    original = '',
    x40 = '',
    x400 = '',
    x80 = '',
  }) {
    this.original = original;
    this.x40 = x40;
    this.x400 = x400;
    this.x80 = x80;
  }

  toPlainObject(): PlainObject<Photo> {
    return { ...this };
  }
}
