import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Typography,
  Stack,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../appHooks/redux';
import { Profile } from '../../core';
import { setSelectedProfile } from '../../redux/reducers/profile';
import { ROUTES } from '../../constants';
import { Nullable } from '../../types';

const ContainerMain = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomButton = styled(Button)`
  width: 122px;
  height: 97px;
  border-radius: 0;
  border: 1px solid #575657 !important;
  background-color: #EFF2FB;
  color: black;

  &:hover {
    background-color: #EFF2FB;
  }
`;

const codeTable = [
  [
    { value: '7', id: 7, key: 7 },
    { value: '8', id: 8, key: 8 },
    { value: '9', id: 8, key: 9 },
  ],
  [
    { value: '4', id: 4, key: 4 },
    { value: '5', id: 5, key: 5 },
    { value: '6', id: 6, key: 6 },
  ],
  [
    { value: '1', id: 1, key: 1 },
    { value: '2', id: 2, key: 2 },
    { value: '3', id: 3, key: 3 },
  ],
  [
    { value: 'Delete', id: 'Delete', key: 'Delete' },
    { value: '0', id: 0, key: 0 },
    { value: 'Go', id: 'Go', key: 'Go' },
  ],
];

interface CodeTableProps {
  value: string,
}

const validateCode = (code:string|null, profiles: Profile[] | null) => profiles?.find(
  (prof: Profile) => prof?.code === code,
);

export const SelectProfileScreen = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const router = useNavigate();
  const buttonConfirmRef = useRef<HTMLButtonElement>(null);
  const [code, setCode] = useState('');
  const [encryptedCode, setEncryptedCode] = useState('');
  const { profiles } = useAppSelector((state) => state.profiles);
  const [onKeyDown, setOnKeyDown] = useState<Nullable<string>>(null);
  const [flag, setFlag] = useState(0);

  const handleClick = ({ value }: CodeTableProps) => {
    let currentCode = code;
    let encrypted = encryptedCode;
    if (value === 'Go') {
      if (code.length < 4) {
        enqueueSnackbar('Pin incorrecto, debe tener 4 digitos', { variant: 'error' });
        return;
      }
      const selectProfile = validateCode(code, profiles);
      if (selectProfile) {
        dispatch(setSelectedProfile(selectProfile));
        enqueueSnackbar('Pin correcto', { variant: 'success' });
        router(ROUTES.COMMAND_PANEL);
      } else {
        enqueueSnackbar('Pin incorrecto, intentelo otra vez', { variant: 'error' });
      }
    } else if (value === 'Delete') {
      currentCode = currentCode.slice(0, -1);
      encrypted = encrypted.slice(0, -1);
    } else if (currentCode.length < 5) {
      currentCode += value;
      encrypted += 'x';
    }
    setCode(currentCode);
    setEncryptedCode(encrypted);
  };

  useEffect(() => {
    if (onKeyDown) {
      handleClick({ value: onKeyDown });
    }
  }, [flag]);

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (buttonConfirmRef.current) {
        const { key } = event;
        if (key === 'Backspace') {
          setFlag((cont) => cont + 1);
          setOnKeyDown('Delete');
        } else if (key === 'Enter') {
          setFlag((cont) => cont + 1);
          setOnKeyDown('Go');
        } else if (Number(key) >= 0 && Number(key) <= 9 && event.code !== 'Space') {
          setFlag((cont) => cont + 1);
          setOnKeyDown(key);
        }
      }
    });
  }, []);

  return (
    <ContainerMain>
      <Stack flexDirection="column" alignItems="center" spacing={2} p={2}>
        <Typography variant="h2">Ingrese su codigo para cambiar de usuario</Typography>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" mb={3} height="37.5px">
            <Typography fontWeight="bold" fontSize="25px">{encryptedCode}</Typography>
          </Box>
          {codeTable.map((row) => (
            <ButtonGroup fullWidth variant="contained" key={Math.random()}>
              {row.map(({ value, key }) => (
                <CustomButton ref={buttonConfirmRef} key={key} onClick={() => handleClick({ value })}>
                  {value === 'Delete' && (
                    <BackspaceIcon style={{ width: 48, height: 28 }} />
                  )}
                  {value !== 'Delete' && (
                    <Typography
                      fontSize="38px"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      {value}
                    </Typography>
                  )}
                </CustomButton>
              ))}
            </ButtonGroup>
          ))}
        </Box>
      </Stack>
    </ContainerMain>
  );
};
