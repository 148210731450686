import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { Loader } from '../loader';

const AuthGuard = ({ children }:{children:any}) => {
  const router = useNavigate();
  const [showChildren, setShowChildren] = useState(false);
  useEffect(() => {
    const user = getAuth().currentUser;
    if (!user) {
      router('/login');
    } else {
      setShowChildren(true);
    }
  }, [router]);
  if (showChildren) {
    return children;
  }
  return <Loader />;
};

export default AuthGuard;
