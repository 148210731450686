/* eslint-disable no-plusplus */
import React, { useState, useMemo } from 'react';
import moment from 'moment-timezone';
import {
  Container, SelectChangeEvent, Typography, Stack, Grid,
} from '@mui/material';
import Header from './header';
import { useAppSelector } from '../../appHooks/redux';
import { useAllOrdersBySubsidiary, Order } from '../../core';
import { getDate } from '../../utils/date';
import { STATUS as ORDER_STATUS } from '../../core/order/order.enum';
import OrdersRealTime from './OrdersRealTime';
import AverageOrder from './AverageOrder';
import NetSales from './NetSales';
import TotalCustomers from './TotalCustomers';
import NewCustomers from './NewCustomers';
import RepeatCustomers from './RepeatCustomers';

const timeRanges = [
  {
    value: 'today',
    text: 'Hoy',
  },
  {
    value: 'yesterday',
    text: 'Ayer',
  },
  {
    value: 'last_7_days',
    text: 'Ultimos 7 días',
  },
  {
    value: 'last_30_days',
    text: 'Ultimos 30 días',
  },
  {
    value: 'last_year',
    text: 'Año Pasado',
  },
];

function get30DayLabels() {
  const labels: string[] = [];
  const last30 = moment().subtract(30, 'days');
  for (let i = 0; i < 30; i++) {
    const date = last30.add(1, 'days').format('DD/MM/YY');
    if (i % 5 === 0) {
      labels.push(date);
    } else {
      labels.push(date);
    }
  }
  return labels;
}

function get7DayLabels() {
  const labels: string[] = [];
  const last7 = moment().subtract(7, 'days');
  for (let i = 0; i < 7; i++) {
    const date = last7.add(1, 'days').format('DD/MM/YY');
    if (i % 5 === 0) {
      labels.push(date);
    } else {
      labels.push(date);
    }
  }
  return labels;
}

function getDayLabels() {
  const labels: string[] = [];
  for (let i = 0; i < 24; i++) {
    labels.push(`${i}:00`);
  }
  return labels;
}

function getYearLabels() {
  const labels: string[] = [];
  for (let i = 0; i < 12; i++) {
    labels.push(
      moment()
        .month(i)
        .format('MMMM'),
    );
  }
  return labels;
}

function getChange(newAmount:number, oldAmount:number) {
  const change = newAmount - oldAmount;
  if (oldAmount === 0 || Number.isNaN(oldAmount)) {
    if (newAmount === 0 || Number.isNaN(newAmount)) {
      return 0;
    }
    return 100;
  }
  if (Number.isNaN(newAmount)) {
    return 0;
  }
  const percentageChange = change / (oldAmount * 100);
  return percentageChange;
}

type TimerangeType = {
  value: string,
  text: string
}

export const DashboardScreen = () => {
  const restaurant = useAppSelector((store) => store.restaurant.restaurant);
  const subsidiary = useAppSelector((store) => store.subsidiary.subsidiary);
  const orders = useAllOrdersBySubsidiary(restaurant?.id, subsidiary?.id);
  const [timeRange, setTimeRange] = useState<string>(timeRanges[2].text);

  const labelTitle = useMemo(() => timeRanges.find((tr:TimerangeType) => tr.text === timeRange), [timeRange]);

  const { dates, labels } = useMemo(() => {
    const today = moment();
    switch (timeRange) {
      case 'Ultimos 30 días':
        return {
          dates: {
            start: moment(today)
              .subtract(30, 'days')
              .toDate(),
            compare: moment(today)
              .subtract(60, 'days')
              .toDate(),
            end: today.toDate(),
          },
          labels: get30DayLabels(),
        };
      case 'Ultimos 7 días':
        return {
          dates: {
            start: moment(today)
              .subtract(7, 'days')
              .toDate(),
            compare: moment(today)
              .subtract(14, 'days')
              .toDate(),
            end: today.toDate(),
          },
          labels: get7DayLabels(),
        };
      case 'Hoy':
        return {
          dates: {
            start: moment(today)
              .subtract(1, 'days')
              .endOf('day')
              .toDate(),
            compare: moment(today)
              .subtract(2, 'days')
              .toDate(),
            end: today.toDate(),
          },
          labels: getDayLabels(),
        };
      case 'Ayer':
        return {
          dates: {
            start: moment(today)
              .subtract(2, 'days')
              .endOf('day')
              .toDate(),
            compare: moment(today)
              .subtract(3, 'days')
              .toDate(),
            end: moment(today)
              .subtract(1, 'days')
              .endOf('day')
              .toDate(),
          },
          labels: getDayLabels(),
        };
      case 'Año Pasado':
        return {
          dates: {
            start: moment(today)
              .subtract(1, 'year')
              .toDate(),
            compare: moment(today)
              .subtract(2, 'years')
              .toDate(),
            end: moment(today).toDate(),
          },
          labels: getYearLabels(),
        };
      default:
        return {
          dates: {
            start: moment(today)
              .subtract(7, 'days')
              .toDate(),
            compare: moment(today)
              .subtract(14, 'days')
              .toDate(),
            end: today.toDate(),
          },
          labels: [],
        };
    }
  }, [timeRange]);

  const data = useMemo(() => {
    let currentOrders:Order[] = [];
    if (orders) {
      currentOrders = orders.filter((order) => moment(getDate(order.created_at)).isBetween(
        dates.start,
        dates.end,
      ));
    }
    const dataLabels:any = {};
    let values = [];
    switch (timeRange) {
      case 'Ultimos 30 días':
        currentOrders.forEach((order) => {
          const date = moment(getDate(order.created_at)).format('DD/MM/YY');
          if (!dataLabels[date]) {
            dataLabels[date] = 1;
          } else {
            dataLabels[date] += 1;
          }
        });
        values = labels.map((value:string) => (dataLabels[value] ? dataLabels[value] : 0));
        return values;
      case 'Ultimos 7 días':
        currentOrders.forEach((order) => {
          const date = moment(getDate(order.created_at)).format('DD/MM/YY');
          if (!dataLabels[date]) {
            dataLabels[date] = 1;
          } else {
            dataLabels[date] += 1;
          }
        });
        values = labels.map((value:string) => (dataLabels[value] ? dataLabels[value] : 0));
        return values;
      case 'Hoy':
        currentOrders.forEach((order) => {
          const orderHour = moment(getDate(order.created_at)).hour();
          if (!dataLabels[orderHour]) {
            dataLabels[orderHour] = 1;
          } else {
            dataLabels[orderHour] += 1;
          }
        });
        return Object.values(dataLabels);
      case 'Ayer':
        currentOrders.forEach((order) => {
          const orderHour = moment(getDate(order.created_at)).hour();
          if (!dataLabels[orderHour]) {
            dataLabels[orderHour] = 1;
          } else {
            dataLabels[orderHour] += 1;
          }
        });
        return Object.values(dataLabels);
      case 'Año Pasado':
        currentOrders.forEach((order) => {
          const date = moment(getDate(order.created_at)).format('MMMM');
          if (!dataLabels[date]) {
            dataLabels[date] = 1;
          } else {
            dataLabels[date] += 1;
          }
        });
        values = labels.map((value:string) => (dataLabels[value] ? dataLabels[value] : 0));
        return values;
      default:
        return [];
    }
  }, [dates, labels, timeRange]);

  const averageOrder = useMemo(() => {
    const currentOrders = orders?.filter((order) => moment(getDate(order.created_at)).isBetween(dates.start, dates.end)) || [];
    const latestOrders = orders?.filter((order) => moment(getDate(order.created_at)).isBetween(dates.compare, dates.start)) || [];
    let value = (
      currentOrders
        .filter((order) => order.status === ORDER_STATUS.COMPLETED)
        .reduce(
          (map, next) => (next.total && !Number.isNaN(next.total) ? map + next.total : map),
          0,
        ) / currentOrders.length
    );
    const oldValue = latestOrders
      .filter((order) => order.status === ORDER_STATUS.COMPLETED)
      .reduce(
        (map, next) => (next.total && !Number.isNaN(next.total) ? map + next.total : map),
        0,
      ) / latestOrders.length;
    value = Number.isNaN(value) ? 0 : value;
    const difference = getChange(value, Number.isNaN(oldValue) ? 0 : oldValue);
    return { value, difference, currency: 'Bs' };
  }, [dates]);

  const netSales = useMemo(() => {
    const currentOrders = orders?.filter((order) => moment(getDate(order.created_at)).isBetween(dates.start, dates.end)) || [];
    const latestOrders = orders?.filter((order) => moment(getDate(order.created_at)).isBetween(dates.compare, dates.start)) || [];
    let value = (
      currentOrders
        .filter((order) => order.status === ORDER_STATUS.COMPLETED)
        .reduce(
          (map, next) => (next.total && !Number.isNaN(next.total) ? map + next.total : map),
          0,
        )
    );
    const oldValue = latestOrders
      .filter((order) => order.status === ORDER_STATUS.COMPLETED)
      .reduce(
        (map, next) => (next.total && !Number.isNaN(next.total) ? map + next.total : map),
        0,
      ) / latestOrders.length;
    value = Number.isNaN(value) ? 0 : value;
    const difference = getChange(value, Number.isNaN(oldValue) ? 0 : oldValue);
    return { value, difference, currency: 'Bs' };
  }, [dates]);

  function getCustomersProps(type = 'normal') {
    let currentOrders: Order[] = [];
    let latestOrders: Order[] = [];
    if (type === 'normal') {
      currentOrders = orders?.filter((order: Order) => moment(getDate(order.created_at))) || [];
    }
    if (type === 'new' || type === 'repeat') {
      currentOrders = orders?.filter((order: Order) => moment(getDate(order.created_at)).isBetween(dates.start, dates.end)) || [];
      latestOrders = orders?.filter((order: Order) => moment(getDate(order.created_at)).isBetween(dates.compare, dates.start)) || [];
    }
    const currentCustomers: any = {};
    currentOrders.forEach((order) => {
      if (currentCustomers[order.invoice_nit]) {
        currentCustomers[order.invoice_nit] += 1;
      } else {
        currentCustomers[order.invoice_nit] = 1;
      }
    });
    const currentCustomersValue = Object.keys(currentCustomers).length;
    const latestCustomers: any = {};
    if (type === 'new' || type === 'repeat') {
      latestOrders.forEach((order) => {
        if (latestCustomers[order.invoice_nit]) {
          latestCustomers[order.invoice_nit] += 1;
        } else {
          latestCustomers[order.invoice_nit] = 1;
        }
      });
    }
    if (type === 'new') {
      let newCustomers = 0;
      Object.keys(currentCustomers).forEach((customerId) => {
        if (!latestCustomers[customerId]) {
          newCustomers++;
        }
      });
      const value = newCustomers;
      const oldValue = Object.values(latestCustomers).filter((item) => item === 1)
        .length;
      const difference = getChange(value, oldValue);
      return { value, difference };
    }
    if (type === 'repeat') {
      let repeatCustomers = 0;
      const customers = currentCustomers
        ? Object.keys(currentCustomers).map((key) => ({
          id: key,
          amount: currentCustomers[key],
        }))
        : [];
      customers.forEach((customer) => {
        if (customer.amount > 1) repeatCustomers++;
      });
      const value = repeatCustomers;
      const difference = customers.length > 0 ? (repeatCustomers * 100) / customers.length : 0;
      return { value, difference };
    }
    const difference = getChange(currentCustomersValue, 0);
    return { value: currentCustomersValue, difference };
  }

  const handleTimeRange = (event:SelectChangeEvent) => {
    setTimeRange(event.target.value);
  };

  return (
    <Container>
      <Stack direction="column" spacing={2}>
        <Header timeRange={timeRange} timeRanges={timeRanges} handleTimeRange={handleTimeRange} dates={dates} />
        <Typography variant="h3" color="textPrimary">
          Resumen
        </Typography>
        <OrdersRealTime
          dates={dates}
          labels={labels}
          data={data}
          orders={orders}
        />
        <Typography variant="h3" color="textPrimary">
          {`Ventas - ${labelTitle?.text || ''}`}
        </Typography>
        <Grid container direction="row" spacing={1}>
          <Grid item lg={4} sm={6} xs={12}>
            <NetSales
              currency={netSales.currency}
              value={netSales.value}
            />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <AverageOrder
              currency={averageOrder.currency}
              value={averageOrder.value}
            />
          </Grid>
        </Grid>
        <Typography variant="h3" color="textPrimary">
          {`Clientes - ${labelTitle?.text || ''}`}
        </Typography>
        <Grid container direction="row" spacing={1}>
          <Grid item lg={4} sm={6} xs={12}>
            <TotalCustomers {...getCustomersProps()} />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <NewCustomers {...getCustomersProps('new')} />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <RepeatCustomers {...getCustomersProps('repeat')} />
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};
