import React, {
  useLayoutEffect, useRef, useState, useMemo, useEffect,
} from 'react';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { pdf } from '@react-pdf/renderer';
import { useNavigate } from 'react-router-dom';
import { doc } from 'firebase/firestore';
import { Header } from './header';
import { OptionSection } from './optionSection';
import { OrderList } from './orderList';
import { useAppDispatch, useAppSelector } from '../../../appHooks/redux';
import {
  cleanAllOrder,
  setCurrentOrder,
  cleanTransactionFailedProducts,
  setTransactionFailedProducts, cleanSelect, deleteProduct,
} from '../../../redux/reducers/shoppingCart';
import {

  Order, ORDER_TYPE, STATUS, useGetProductsOfJourneyProduction,
  useValidateEnableInventory,
  deleteOrder, setOrder, orderRef, getAdditionByProduct, Addition,
} from '../../../core';
import {
  TicketGenerator, Order
  as OrderTicket,
} from '../../pdf';
import { AlertModal, Discount } from '../../modal';
import { ROUTES } from '../../../constants';
import {
  parseOrderProduct, addOrderProducts, updateOrderProducts, OrderProduct, orderTransactions, deleteProductTransaction,
} from '../../../core/orderProduct';
import { Ghost, Warning } from '../../../assets';
import { useLoadingBackdrop } from '../../../providers/loadingBackdropProvider/hooks';
import { VerifyAdditionsModal } from '../../modal/verifyAdditionsModal';
import { verifyAdditions } from '../../../utils/orderUtils';

const ContainerMain = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  border-right: 5px solid black;
`;

const ContainerListOrder = styled(Box)`
  overflow: scroll;
  max-height: -webkit-fill-available;
`;

export const SideBar = () => {
  const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  const restaurant = useAppSelector((state) => state.restaurant.restaurant);
  const { showBackdrop, hideBackdrop } = useLoadingBackdrop();
  const containerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const productsStock = useAppSelector((store) => store.stock.productsStock);
  const [sectioHeight, setSectioHeight] = useState<number>(0);
  const currentOrder = useAppSelector((state) => state.shoppingCart.currentOrder);
  const currentAdditions = useAppSelector((state) => state.shoppingCart.currentAdditions);
  const currentProducts = useAppSelector((state) => state.shoppingCart.currentProducts);
  const orderProduct = useAppSelector((state) => state.shoppingCart.orderProduct);
  const selectProduct = useAppSelector((store) => store.shoppingCart.selectProduct);
  const selectAdditions = useAppSelector((store) => store.shoppingCart.selectAdditions);
  const { value: lastOpenjourneyProduction } = useAppSelector((state) => state.inventory.journeyProduction);
  const journeyProductionProductsSet = useAppSelector((state) => state.inventory.journeyProductionProductsSet);
  const { beforeProduct, afterProduct } = useAppSelector((state) => state.inventory);
  const [modal, setModal] = useState(false);
  const realtimeRestaurant = useAppSelector((store) => store.restaurant.restaurant);
  const journeyProducts = useGetProductsOfJourneyProduction(restaurant?.id, subsidiary?.id, lastOpenjourneyProduction?.id);
  const enableInventory = useValidateEnableInventory();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [verifyAdditionsModal, setVerifyAdditionsModal] = useState(false);
  const [missingAdditionsList, setMissingAdditionsList] = useState<string[]>([]);
  const [allAdditionsByProduct, setAllAdditionsByProduct] = useState<Addition[]>([]);
  const [actionVerifyAddtions, setActionVerifyAddtions] = useState('');
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const router = useNavigate();
  useLayoutEffect(() => {
    if (containerRef.current) {
      // @ts-ignore
      setContainerHeight(containerRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if (selectProduct) {
      const fetchAdditions = async () => {
        const additions = await getAdditionByProduct({
          subsidiaryId: selectProduct.subsidiary_id,
          productId: selectProduct.id,
          restaurantId: selectProduct.restaurant_id,
        });
        setAllAdditionsByProduct(additions);
      };
      fetchAdditions();
    }
  }, [selectProduct]);
  const orderSectionHeight = useMemo(
    () => containerHeight - (headerHeight + sectioHeight),
    [headerHeight, containerHeight, sectioHeight],
  );
  const cleanProductDelete = (isCleanProduct:boolean = false) => {
    let newCurrentProducts = currentProducts;
    let newCurrentAdditions = currentAdditions;
    if (selectProduct && isCleanProduct) {
      newCurrentProducts = currentProducts.filter((product) => product.id !== selectProduct.id);
      newCurrentAdditions = currentAdditions.filter((addition) => addition.product_index !== selectProduct.index);
    }
    return { newCurrentProducts, newCurrentAdditions };
  };

  const onPrint = async (isCleanProduct:boolean = false) => {
    if (currentProducts.length && restaurant?.enable_comanda) {
      const { newCurrentProducts, newCurrentAdditions } = cleanProductDelete(isCleanProduct);
      const ticketPdf = await pdf(
        TicketGenerator({
          ticket: {
            clientName: currentOrder?.client_name || '',
            orderNumber: currentOrder?.order_code || '',
            orderType: currentOrder?.order_type || ORDER_TYPE.LOCAL,
            createAt: new Date(),
            orders: newCurrentProducts.map((product) => {
              const additions = newCurrentAdditions.filter(
                (addition) => addition.product_id === product.id,
              );
              const order:OrderTicket = {
                amount: product.quantity,
                productName: product.name,
                additions: additions.map((add) => ({ amount: add.quantity, name: add.name })),
              };
              return order;
            }),
          },
        }),
      ).toBlob();

      window.open(
        URL.createObjectURL(ticketPdf),
        '_blank',
        'width=600,height=700',
      );
    }
  };

  const onPrintClick = async () => {
    const listMissingAdditions = verifyAdditions(allAdditionsByProduct, selectAdditions);
    if (listMissingAdditions.length > 0) {
      setMissingAdditionsList(listMissingAdditions);
      setActionVerifyAddtions('onPrint');
      setVerifyAdditionsModal(true);
    } else {
      await onPrint();
    }
  };

  const onResetHandler = async () => {
    if (lastOpenjourneyProduction && orderProduct.length > 0 && !enableInventory && realtimeRestaurant?.enable_inventory && subsidiary && restaurant && journeyProductionProductsSet) {
      const deleteProducts = orderProduct.map((product) => [product.product_id, product.quantity]);
      await deleteProductTransaction(restaurant.id, subsidiary.id, lastOpenjourneyProduction.id, journeyProductionProductsSet, deleteProducts);
    }
    dispatch(cleanAllOrder());
    if (currentOrder?.id) {
      await deleteOrder(currentOrder?.id, restaurant?.id || '', subsidiary?.id || '');
    }

    router(ROUTES.COMMAND_PANEL);
    enqueueSnackbar('Se vació correctamente.', {
      variant: 'success',
    });
  };

  const handleOrderSend = async (newOrderProducts: OrderProduct[], restaurantId: string, subsidiaryId: string, orderId: string) => {
    if (orderProduct.length > 0) {
      await updateOrderProducts({
        newOrderProducts,
        orderProduct,
        restaurantId,
        subsidiaryId,
        orderId,
        journeyProductionId: lastOpenjourneyProduction?.id,
        products: journeyProducts,
      });
    } else {
      addOrderProducts({
        newOrderProducts,
        restaurantId,
        subsidiaryId,
        orderId,
      });
    }
    dispatch(cleanTransactionFailedProducts());
    dispatch(cleanAllOrder());
    enqueueSnackbar('Orden Enviada', {
      variant: 'success',
    });
    await onPrintClick();
    router(ROUTES.COMMAND_PANEL);
    setLoading(false);
  };

  const handleOrderChangeStatus = async (newOrderProducts: OrderProduct[], restaurantId: string, subsidiaryId: string, orderId: string) => {
    if (orderProduct.length > 0) {
      await updateOrderProducts({
        newOrderProducts,
        orderProduct,
        restaurantId,
        subsidiaryId,
        orderId,
        journeyProductionId: lastOpenjourneyProduction?.id,
        products: journeyProducts,
      });
    } else {
      addOrderProducts({
        newOrderProducts,
        restaurantId,
        subsidiaryId,
        orderId,
      });
    }
    dispatch(cleanTransactionFailedProducts());
    dispatch(cleanAllOrder());
    enqueueSnackbar('Se cambio de estado correctamente.', {
      variant: 'success',
    });
    router(ROUTES.COMMAND_PANEL);
    setLoading(false);
  };

  const onHold = async (isCleanProduct:boolean = false) => {
    showBackdrop('Guardando Órden');
    try {
      if (!restaurant?.id || !subsidiary?.id || !currentOrder) return;
      const { newCurrentProducts, newCurrentAdditions } = cleanProductDelete(isCleanProduct);
      const orderId: string = currentOrder.id || doc(orderRef(restaurant?.id, subsidiary.id)).id;
      const order = new Order({
        ...currentOrder,
        id: orderId,
        count_of_products: newCurrentProducts?.length || 0,
        status: STATUS.ON_HOLD,
      });
      const newOrderProducts = parseOrderProduct({
        products: newCurrentProducts,
        additions: newCurrentAdditions,
        orderId,
        enableInventory,
      });
      if (!enableInventory && realtimeRestaurant?.enable_inventory && lastOpenjourneyProduction && journeyProductionProductsSet) { // if enableInventory is false allows to update the sale
        const result = await orderTransactions(restaurant.id, subsidiary.id, beforeProduct, afterProduct, lastOpenjourneyProduction.id, journeyProductionProductsSet);
        setModal(!result.isSuccess);
        if (result.isSuccess) {
          await setOrder({
            restaurantId: restaurant?.id,
            order,
            subsidiaryId: subsidiary?.id,
          });
          await handleOrderChangeStatus(newOrderProducts, restaurant?.id, subsidiary?.id, orderId);
        } else if (result?.failedProduct) {
          dispatch(setTransactionFailedProducts(result?.failedProduct));
          enqueueSnackbar('No se ha podido cambiar de estado', {
            variant: 'error',
          });
          setLoading(false);
        }
      } else {
        await setOrder({
          restaurantId: restaurant?.id,
          order,
          subsidiaryId: subsidiary?.id,
        });
        await handleOrderChangeStatus(newOrderProducts, restaurant?.id, subsidiary?.id, orderId);
      }
    } finally {
      hideBackdrop();
    }
  };

  const onHoldHandler = async () => {
    const listMissingAdditions = verifyAdditions(allAdditionsByProduct, selectAdditions);
    if (listMissingAdditions.length > 0) {
      setMissingAdditionsList(listMissingAdditions);
      setActionVerifyAddtions('onHold');
      setVerifyAdditionsModal(true);
    } else {
      await onHold();
    }
  };

  const onSend = async (isCleanProduct = false) => {
    setLoading(true);
    showBackdrop('Guardando Órden');
    try {
      if (!restaurant?.id || !subsidiary?.id || !currentOrder) return;
      const { newCurrentProducts, newCurrentAdditions } = cleanProductDelete(isCleanProduct);
      const orderId: string = currentOrder.id || doc(orderRef(restaurant?.id, subsidiary.id)).id;
      const order = new Order({
        ...currentOrder,
        id: orderId,
        count_of_products: newCurrentProducts?.length || 0,
        status: STATUS.IN_PROCESS,
      });
      const newOrderProducts = parseOrderProduct({
        products: newCurrentProducts,
        additions: newCurrentAdditions,
        orderId,
      });
      if (!enableInventory && realtimeRestaurant?.enable_inventory && lastOpenjourneyProduction && journeyProductionProductsSet) { // if enableInventory is false allows to update the sale
        const result = await orderTransactions(restaurant.id, subsidiary.id, beforeProduct, afterProduct, lastOpenjourneyProduction.id, journeyProductionProductsSet);
        setModal(!result.isSuccess);
        if (result.isSuccess) {
          await setOrder({
            restaurantId: restaurant?.id,
            order,
            subsidiaryId: subsidiary?.id,
          });
          await handleOrderSend(newOrderProducts, restaurant?.id, subsidiary?.id, orderId);
        } else if (result?.failedProduct) {
          dispatch(setTransactionFailedProducts(result.failedProduct));
          enqueueSnackbar('No se ha podido enviar la orden', {
            variant: 'error',
          });
          setLoading(false);
        }
      } else {
        await setOrder({
          restaurantId: restaurant?.id,
          order,
          subsidiaryId: subsidiary?.id,
        });
        await handleOrderSend(newOrderProducts, restaurant?.id, subsidiary?.id, orderId);
      }
    } finally {
      hideBackdrop();
    }
  };

  const onSendHandler = async () => {
    const listMissingAdditions = verifyAdditions(allAdditionsByProduct, selectAdditions);
    if (listMissingAdditions.length > 0) {
      setMissingAdditionsList(listMissingAdditions);
      setActionVerifyAddtions('onSend');
      setVerifyAdditionsModal(true);
    } else {
      await onSend();
    }
  };

  const onDiscount = (discount: Discount) => {
    dispatch(
      setCurrentOrder(
        new Order({
          ...currentOrder,
          discount: discount.discount,
          discount_reason: discount.reason,
        }),
      ),
    );
    if (discount.discount > 0) {
      enqueueSnackbar('Se ha aplicado el descuento', {
        variant: 'success',
      });
    }
  };
  const onConvert = () => {
    router(`${ROUTES.COMMAND_PANEL}/?action=change`);
  };

  const onNewItem = () => {
    dispatch(cleanTransactionFailedProducts());
    router(ROUTES.MENU);
  };

  const handleHeight = (site: string, value: number) => {
    if (site === 'header') setHeaderHeight(value);
    if (site === 'section') setSectioHeight(value);
  };

  const onPayment = (isCleanProduct:boolean = false) => {
    const { newCurrentProducts } = cleanProductDelete(isCleanProduct);
    if (newCurrentProducts.length > 0) {
      router(ROUTES.PAYMENT);
    } else {
      setModalPayment(true);
    }
  };

  const onPaymentHandler = async () => {
    const listMissingAdditions = verifyAdditions(allAdditionsByProduct, selectAdditions);
    if (listMissingAdditions.length > 0) {
      setMissingAdditionsList(listMissingAdditions);
      setActionVerifyAddtions('onPayment');
      setVerifyAdditionsModal(true);
    } else {
      await onPayment();
    }
  };
  const onChangeClientName = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (currentOrder) {
      dispatch(
        setCurrentOrder(
          new Order({
            ...currentOrder,
            client_name: event.target.value,
          }),
        ),
      );
    }
  };

  const onChangePhoneNumber = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (currentOrder) {
      dispatch(
        setCurrentOrder(
          new Order({
            ...currentOrder,
            client_phone_number: event.target.value,
          }),
        ),
      );
    }
  };

  const deleteProductAndSaveHoldOrder = async () => {
    if (selectProduct) dispatch(deleteProduct(selectProduct));
    dispatch(cleanSelect());
    if (actionVerifyAddtions === 'onHold') onHold(true);
    if (actionVerifyAddtions === 'onSend') onSend(true);
    if (actionVerifyAddtions === 'onPayment') onPayment(true);
    if (actionVerifyAddtions === 'onPrint') onPrint(true);
    setVerifyAdditionsModal(false);
  };

  return (
    <ContainerMain ref={containerRef}>
      <Header
        onHeaderHeight={handleHeight}
        onChangeClientName={onChangeClientName}
        onChangePhoneNumber={onChangePhoneNumber}
        phoneNumber={currentOrder?.client_phone_number}
        clientName={currentOrder?.client_name}

      />
      <ContainerListOrder height={orderSectionHeight}>
        <OrderList
          additionsForProduct={currentAdditions}
          allAdditionsByProduct={allAdditionsByProduct}
          products={currentProducts}
          productsStock={productsStock}
          selectedProduct={selectProduct}
          realtimeRestaurant={realtimeRestaurant}
          restaurantId={restaurant?.id || ''}
          subsidiaryId={subsidiary?.id || ''}
        />
      </ContainerListOrder>
      <OptionSection
        order={currentOrder}
        onResetHandler={onResetHandler}
        onHoldHandler={onHoldHandler}
        onSendHandler={onSendHandler}
        onDiscount={onDiscount}
        onPrint={onPrintClick}
        onConvert={onConvert}
        onNewItem={onNewItem}
        onPayment={onPaymentHandler}
        loading={loading}
        onHeaderHeight={handleHeight}
        currentAdditions={currentAdditions}
        currentProducts={currentProducts}
        currentOrder={currentOrder}
      />
      <AlertModal
        icon={Ghost}
        open={modal}
        title="Ups! El stock disminuyo y no se puede agregar los productos requeridos"
        okText="Aceptar"
        onClose={() => setModal(false)}
      />
      <AlertModal
        icon={Warning}
        open={modalPayment}
        title="No puede acceder a esta opción si no tiene productos seleccionados en la orden."
        okText="Aceptar"
        onClose={() => setModalPayment(false)}
      />
      <VerifyAdditionsModal
        open={verifyAdditionsModal}
        title={`Si continúa perderá el producto ${selectProduct ? selectProduct.name : ''}`}
        okText="Continuar"
        onConfirm={() => {
          deleteProductAndSaveHoldOrder();
          setVerifyAdditionsModal(false);
        }}
        cancelText="Cancelar"
        onClose={() => { setVerifyAdditionsModal(false); }}
        missingAdditions={missingAdditionsList}
      />
    </ContainerMain>
  );
};
