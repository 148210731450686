/* eslint-disable max-len */
import {
  collection, addDoc, getFirestore, setDoc, doc, getDocs, deleteDoc, writeBatch, updateDoc,
} from 'firebase/firestore';
import { COLLECTIONS } from '../../constants';
// eslint-disable-next-line import/no-cycle
import { getSharedProductsPerRestaurant } from '../product';
import { Addition } from './addition.dto';

const db = getFirestore();
 type queryParams={
  restaurantId:string;
  subsidiaryId:string;
  productId:String;
 }

export const editAddition = async (
  productUpdated: Partial<Addition>,
  productId: string,
  path: string,
): Promise<void> => {
  const productRef = doc(db, path, productId);
  await setDoc(productRef, productUpdated, { merge: true });
};

export const addAddition = async (product: Addition, path: string): Promise<void> => {
  const result = await addDoc(collection(db, path), product.toPlainObject());
  await editAddition({ id: result.id }, result.id, path);
};

export const getAdditionByProduct = async (params:queryParams): Promise<Addition[]> => {
  const docRef = collection(
    db,
    // eslint-disable-next-line max-len
    `${COLLECTIONS.RESTAURANT}/${params.restaurantId}/${COLLECTIONS.SUBSIDIARY}/${params.subsidiaryId}/${COLLECTIONS.LOCAL_PRODUCTS}/${params.productId}/${COLLECTIONS.ADDITION}/`,
  );
  const querySnapshot = await getDocs(docRef);

  const additions = querySnapshot.docs
    .map((snap) => new Addition({ ...snap.data(), id: snap.id }))
    .sort((a:Addition, b:Addition) => (a.order > b.order ? 1 : -1));
  return additions;
};

export const deleteAdditionSharedProduct = async (restaurantId: string, productId: string, additionId: string): Promise<void> => {
  const additionProductRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SHARED_PRODUCTS, productId, COLLECTIONS.ADDITION, additionId);
  await deleteDoc(additionProductRef);
};

export const addAdditionSharedProduct = async (restaurantId: string, productId: string, addition: Addition, additionId: string): Promise<void> => {
  const additionProductRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SHARED_PRODUCTS, productId, COLLECTIONS.ADDITION, additionId);
  await setDoc(additionProductRef, addition.toPlainObject());
};

export const saveSharedProductsAdditions = async (restaurantId: string, productId: string, additions: Addition[]) => {
  const batch = writeBatch(db);
  additions.forEach((addition, index) => {
    const additionProductRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SHARED_PRODUCTS, productId, COLLECTIONS.ADDITION, addition.id);
    batch.update(additionProductRef, { order: index });
  });
  await batch.commit();
};

export const saveLocalProductsAdditions = async (restaurantId: string, subsidiaryId:string, productId: string, additions: Addition[]) => {
  const batch = writeBatch(db);
  additions.forEach((addition, index) => {
    const additionProductRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SUBSIDIARY, subsidiaryId, COLLECTIONS.LOCAL_PRODUCTS, productId, COLLECTIONS.ADDITION, addition.id);
    batch.update(additionProductRef, { order: index });
  });
  await batch.commit();
};

export const getAdditionsPerProduct = async (restaurantId:string, productId:string) => {
  const querySnapshot = await getDocs(collection(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SHARED_PRODUCTS, productId, COLLECTIONS.ADDITION));
  const additions = querySnapshot.docs.map((snap:any) => {
    const addition = new Addition({ ...snap.data(), id: snap.id });
    return addition;
  });
  return additions;
};

export const sharedWithOtherProducts = async (restaurantId: string, menuAdditionsId: string, productId:string) => {
  let products = await getSharedProductsPerRestaurant(restaurantId);
  products = products.filter((product) => product.id !== productId);
  let counter:number = 0;

  await Promise.all(products.map(async (product) => {
    const additions = await getAdditionsPerProduct(restaurantId, product.id);
    additions.forEach((addition) => {
      if (addition.menu_additions_id === menuAdditionsId) {
        counter += 1;
      }
    });
  }));
  return counter;
};

export const editSharedProductAdditions = async (
  restaurantId: string,
  productId: string,
  additionId: string,
  additionUpdated: Partial<Addition>,
): Promise<void> => {
  const additionsRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SHARED_PRODUCTS, productId, COLLECTIONS.ADDITION, additionId);
  await updateDoc(additionsRef, { ...additionUpdated });
};

export const editLocalProductAdditions = async (
  restaurantId: string,
  subsidiaryId:string,
  productId: string,
  additionId: string,
  additionUpdated: Partial<Addition>,
): Promise<void> => {
  const additionsRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SUBSIDIARY, subsidiaryId, COLLECTIONS.LOCAL_PRODUCTS, productId, COLLECTIONS.ADDITION, additionId);
  await updateDoc(additionsRef, { ...additionUpdated });
};
