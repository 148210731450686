/* eslint-disable no-unsafe-optional-chaining */
import React, { useMemo } from 'react';
import { Product, Restaurant } from '../../core';
import { CarouselWithDots, MenuButton } from '../../components';
import { ProductsStock } from '../../redux/reducers/stock';

type MenuScreenProps = {
  products?: Product[];
  index?: number;
  onSelectProduct:(product:Product)=>void;
  selectProduct:Product | null;
  sideBarWidth: number;
  restaurant?: Restaurant | null;
  productsStock:ProductsStock
};

const ColorsButton = [
  {
    color: '#A7B8E7',
    hoverColor: '#3F65CB',
    textColor: '#000000',
    hoverTextColor: '#ffffff',
  },
  {
    color: '#FCBDB1',
    hoverColor: '#e74626',
    textColor: '#000000',
    hoverTextColor: '#ffffff',
  },
  {
    color: '#F9DCDF',
    hoverColor: '#c7535f',
    textColor: '#000000',
    hoverTextColor: '#ffffff',
  },
  {
    color: '#EF9FA8',
    hoverColor: '#DC2D40',
    textColor: '#000000',
    hoverTextColor: '#ffffff',
  },
];

export const ProductsSection = ({
  products,
  index,
  onSelectProduct,
  selectProduct,
  sideBarWidth,
  restaurant,
  productsStock,
}: MenuScreenProps) => {
  const position = useMemo(() => {
    if (!index) return 0;
    if (index < 4) return index;
    return index % 4;
  }, [index]);

  const productsList = useMemo(() => {
    if (selectProduct?.id) return [selectProduct];
    return products;
  }, [products, selectProduct]);

  return (
    <CarouselWithDots sideBarWidth={sideBarWidth} slideWidth={160}>
      {productsList?.map((product: Product) => {
        const isSelected = selectProduct?.id === product.id;
        return (
          <MenuButton
            key={`key-${product.id}`}
            label={product?.name}
            bgColor={ColorsButton[position].color}
            hoverColor={ColorsButton[position].hoverColor}
            textColor={ColorsButton[position].textColor}
            hoverTextColor={ColorsButton[position].hoverTextColor}
            onClick={() => {
              onSelectProduct(product);
            }}
            stock={productsStock[product.id] || 0}
            isSelected={isSelected}
            restaurant={restaurant}
          />
        );
      })}
    </CarouselWithDots>
  );
};
