import React, { useMemo, useState } from 'react';
import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  styled,
  Popover,
} from '@mui/material';

import { useSnackbar } from 'notistack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { es } from 'date-fns/esm/locale';

import moment from 'moment-timezone';
import { useAppSelector } from '../../appHooks/redux';
import { useSubsidiaries } from '../../core/subsidiary/subsidiary.hook';
import { getDefaultRanges } from '../../core/journey/journey.utils';
import { DateRange, DateRangePicker } from '../../components/rangeSelectorDatePicker';

const TextFieldCustom = styled(TextField)(() => ({
  '.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
    background: 'white',
  },
}));

type HeaderProps = {
  onReset:()=>void;
  onFilter:(restaurantId:string, subsidiaryId:string, range:DateRange)=>void;
  setDateRange:React.Dispatch<React.SetStateAction<DateRange>>;
  dateRange:DateRange;
  onExportExcelInventoryHistory: (dateRange:DateRange) => void;
  productsBySectionsLength: number
}
const Header = ({
  onReset, onFilter, setDateRange, dateRange, onExportExcelInventoryHistory, productsBySectionsLength,

}:HeaderProps) => {
  const [subsidiary, setSubsidiary] = useState('');
  const restaurant = useAppSelector((state) => state.restaurant.restaurant);
  const subsidiaries = useSubsidiaries(restaurant?.id);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const id = open ? 'simple-popover' : undefined;

  const allsubsidiaries = useMemo(() => {
    if (subsidiaries) {
      return [...subsidiaries].filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i,
      );
    }
    return [];
  }, [subsidiaries]);

  const toggle = () => setOpen(!open);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };
  const resetClick = () => {
    setSubsidiary('');
    setDateRange({});
    onReset();
  };
  const filterClick = () => {
    if (subsidiary && dateRange.endDate) {
      onFilter(restaurant?.id || '', subsidiary || '', dateRange);
      setDateRange(dateRange);
    } else {
      enqueueSnackbar('Debe seleccionar ambas opciones de búsqueda', {
        variant: 'warning',
      });
    }
  };
  return (
    <Grid container pt={5} spacing={1}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DateRangePicker
          open={open}
          locale={es}
          maxDate={new Date()}
          wrapperClassName="range-picker"
          definedRanges={getDefaultRanges(new Date(), es)}
          toggle={toggle}
          onChange={(range) => {
            setDateRange(range);
          }}
        />
      </Popover>
      <Grid item>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => resetClick()}
          >
            Resetear
          </Button>
        </Stack>
      </Grid>
      <Grid item sm={5} md={5} xs={6}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <TextFieldCustom
            fullWidth
            sx={{ backgroundColor: 'white' }}
            label="Fecha"
            name="journey"
            onClick={(e) => handleClick(e as any)}
            variant="outlined"
            size="small"
            value={
              dateRange.startDate
                ? `${moment(dateRange.startDate).format('DD/MM/YYYY')} - ${moment(dateRange.endDate).format('DD/MM/YYYY')}`
                : ''
            }
            color="secondary"
            InputProps={{
              endAdornment: <ArrowDropDownIcon />,
            }}
          />
          <TextFieldCustom
            fullWidth
            label="Sucursal"
            select
            name="subsidiary"
            onChange={(e: any) => {
              setSubsidiary(e.target.value);
            }}
            value={subsidiary}
            variant="outlined"
            size="small"
            color="secondary"
          >
            {allsubsidiaries.map((sub) => (
              <MenuItem id={sub.id} key={sub.id} value={sub.id}>
                {sub.name}
              </MenuItem>
            ))}
          </TextFieldCustom>
        </Stack>
      </Grid>
      <Grid item>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Button
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => filterClick()}
          >
            Filtrar
          </Button>
        </Stack>
      </Grid>
      <Grid item sm={4} md={4} xs={3}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Button
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => onExportExcelInventoryHistory(dateRange)}
            disabled={!(productsBySectionsLength > 0)}
          >
            Exportar a Excel
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Header;
