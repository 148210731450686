import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  styled,
  TextField,
} from '@mui/material';
import { City, Subsidiary, User } from '../../core';
import { Nullable } from '../../types';

const Container = styled(Card)(() => ({
  boxShadow: 'unset',

  width: '100%',

}));
const CustomDividir = styled(Divider)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.12)', border: 'none', height: '1px',
}));

export type SubsidiaryFormProps = {
  subsidiary:Nullable<Subsidiary>,
  cities:Nullable<City[]>,
  user: Nullable<User>
};
const SubsidiaryForm = ({
  subsidiary,
  cities,
  user,
}: SubsidiaryFormProps) => {
  if (!subsidiary) {
    return (
      <Box m="auto" pt={2}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Grid>
      <Formik
        enableReinitialize
        initialValues={{
          name: subsidiary.name ? subsidiary.name : '',
          email: user ? user.email : '',
          city: subsidiary.city.name ? subsidiary.city.name : '',
          address: subsidiary.address ? subsidiary.address : '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Requerido'),
          email: Yup.string(),
        })}
        onSubmit={async () => {
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off" noValidate>
            <Grid item container>
              <Grid item container>
                <Container>
                  <CardHeader title="Datos de subsidiaria:" />
                  <CustomDividir />
                  <CardContent sx={{ alignContent: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Grid item container md={12} padding={2}>
                      <Grid
                        item
                        container
                        direction="column"
                        md={4}
                        padding={3}
                        justifyContent="flex-end"
                      >
                        <Box mb={3}>
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            label="Nombre del Restaurante"
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            inputProps={{ readOnly: true }}
                            variant="outlined"
                          />
                        </Box>

                      </Grid>
                      <Grid
                        item
                        container
                        direction="column"
                        md={4}
                        padding={3}
                        justifyContent="flex-end"
                      >
                        <Box mb={3}>
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label="Correo electrónico"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{ readOnly: true }}
                            value={values.email}
                            variant="outlined"
                          />
                        </Box>

                      </Grid>
                      <Grid
                        item
                        padding={3}
                        container
                        direction="column"
                        md={4}
                        justifyContent="flex-end"
                      >
                        <Box mb={3}>
                          <TextField
                            error={Boolean(touched.city && errors.city)}
                            fullWidth
                            helperText={touched.city && errors.city}
                            label="Ciudad"
                            select
                            name="city"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{ readOnly: true }}
                            sx={{ textAlign: 'left' }}
                            value={values.city}
                            variant="outlined"
                          >
                            {cities?.map((option) => (
                              <MenuItem key={option.id} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>

                      </Grid>
                      <Grid
                        item
                        padding={3}
                        container
                        direction="column"
                        md={4}
                        justifyContent="flex-end"
                      >

                        <Box mb={3}>
                          <TextField
                            inputProps={{ readOnly: true }}
                            error={Boolean(
                              touched.address
                                && errors.address,
                            )}
                            fullWidth
                            helperText={
                              touched.address
                              && errors.address
                            }
                            label="Dirección"
                            name="address"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.address}
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                    </Grid>

                  </CardContent>
                </Container>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Grid>
  );
};

export default SubsidiaryForm;
