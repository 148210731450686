import React, { useState } from 'react';
import {
  Stack, TextField, Typography, styled, IconButton, FormControlLabel, ListItem,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { COLORS } from '../../constants';
import {
  PosSwitch, ConfirmModal,
} from '../../components';
import { MenuSection } from '../../core/menuSection';
import { saveNameProps, enableSectionProps } from './types';
import { CrossIcon, EditIcon } from '../../components/imageIcons';

const FormControlLabelCustom = styled(FormControlLabel)(() => ({
  alignItems: 'center',
  width: 50,
  '.mui-style-ce5obw-MuiTypography-root': {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 0,
  },
}));

type SectionCardProps = {
  section: MenuSection;
  index: number;
  isSharedMenu: boolean;
  onSaveName?: ({
    name, section, index, enable,
  }: saveNameProps) => void;
  onRemoveSection?: (index: number) => void;
  onEnableSection: ({
    section, index, enable,
  }: enableSectionProps) => void;
  onClickSection: (sectionId: string) => void;
};

const SectionCard = ({
  section,
  index,
  isSharedMenu,
  onSaveName = () => {},
  onRemoveSection = () => {},
  onEnableSection = () => {},
  onClickSection = () => {},
}: SectionCardProps) => {
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(section.name);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const confirmationAction = () => {
    if (!section.enable) {
      setShowModalConfirm(true);
    } else {
      onSaveName({ name, section, index });
      setEditing(false);
    }
  };

  const handleKeyUp = (event:any) => {
    if (event.keyCode === 13) {
      confirmationAction();
    }
  };

  const handleSection = (isActive: boolean) => {
    onEnableSection({ section, index, enable: isActive });
  };

  return (
    <>
      <ListItem
        sx={{ paddingTop: 0, paddingBottom: 0 }}
        key={section.id}
        onClick={(event:any) => {
          if (event.target.id !== section.id) {
            event?.preventDefault();
            onClickSection(section.id);
          }
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <IconButton>
            <DragIndicatorIcon sx={{ fontSize: 45, color: COLORS.BLACK }} />
          </IconButton>
          {!editing && (
          <div>
            <Typography sx={{ fontSize: 12, fontWeight: 400 }}>Sección</Typography>
            <Typography sx={{ fontSize: 18, fontWeight: 300 }}>
              {`${index + 1}. ${section.name}`}
            </Typography>
          </div>
          )}
          {editing && (
          <TextField
            id={section.id}
            value={name}
            variant="standard"
            onChange={(e) => {
              e?.preventDefault();
              setName(e.target.value);
            }}
            onKeyUp={handleKeyUp}
            color="secondary"
          />
          )}
        </Stack>
      </ListItem>
      <Stack
        direction="row"
        spacing={1}
        pr={2}
        alignItems="center"
        justifyContent="center"
      >
        {isSharedMenu && (
        <Stack direction="row" pr={3}>
          {!editing && (
          <IconButton id="editSection" onClick={() => { setEditing(!editing); }}>
            <EditIcon />
          </IconButton>
          )}
          {editing && (
          <IconButton id="saveName" onClick={() => { confirmationAction(); }}>
            <SaveIcon />
          </IconButton>
          )}
          <IconButton
            id="deleteSection"
            onClick={() => {
              if (editing) {
                setEditing(false);
              } else {
                setShowModalDelete(true);
              }
            }}
          >
            <CrossIcon />
          </IconButton>
        </Stack>
        )}
        <FormControlLabelCustom
          value="top"
          control={(
            <PosSwitch
              value={section.enable}
              onChange={(e:any) => handleSection(e.target.checked)}
            />
                        )}
          label={section.enable ? 'Deshabilitar' : 'Habilitar'}
          labelPlacement="top"
        />
      </Stack>
      <ConfirmModal
        open={showModalConfirm}
        onClose={() => {
          onSaveName({ name, section, index });
          setEditing(false);
          setShowModalConfirm(!showModalConfirm);
        }}
        onConfirm={(e) => {
          e?.preventDefault();
          onSaveName({
            name, section, index, enable: true,
          });
          setEditing(false);
          setShowModalConfirm(!showModalConfirm);
        }}
        title="Quieres habilitar la sección de menú?"
      />
      <ConfirmModal
        open={showModalDelete}
        okText="BORRAR"
        cancelText="CANCELAR"
        onClose={() => {
          setShowModalDelete(!showModalDelete);
        }}
        onConfirm={(e:any) => {
          e?.preventDefault();
          onRemoveSection(index);
          setShowModalDelete(!showModalDelete);
        }}
        title="Seguro que deseas borrar este ítem?"
      />
    </>
  );
};

export default SectionCard;
