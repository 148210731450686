/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import nProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Product,
  useSharedProductsBySectionId,
  addSharedProduct,
  deleteSharedProduct,
  editSharedProduct,
  saveSharedProductsOrder,
  MenuSection,
  useIsTemplateMenu,
  updateProductsProductionId,
  JourneyProductionProduct,
} from '../../../core';
import { useAppSelector } from '../../../appHooks/redux';
import { enableProductProps } from '../../../screens/productSectionScreen/types';
import { ProductSectionScreen } from '../../../screens';
import { COLLECTIONS } from '../../../constants';
import { admin } from '../../../redux/store';

const SharedSectionId = () => {
  const router = useNavigate();
  const { sectionId } = useParams();
  const sectionIdParams = typeof sectionId === 'string' ? sectionId : '';
  const { enqueueSnackbar } = useSnackbar();
  const currentRestaurant = useAppSelector((state) => state.restaurant.restaurant);
  const isSharedMenu = useIsTemplateMenu();
  const productsListData = useSharedProductsBySectionId(currentRestaurant?.id || '', sectionIdParams);
  const menuListData = currentRestaurant?.menu_section || [];
  const restaurant = useAppSelector((state) => state.restaurant.restaurant);
  const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  const { value: lastOpenjourneyProduction } = useAppSelector((state) => state.inventory.journeyProduction);
  const journeyProductionProduct = useAppSelector((state) => state.inventory.journeyProductionProducts);
  /*
  Router.events.on('routeChangeStart', nProgress.start);
  Router.events.on('routeChangeError', nProgress.done);
  Router.events.on('routeChangeComplete', nProgress.done); */
  nProgress.configure({ showSpinner: false });

  const menuSection = useMemo(() => {
    if (sectionId && menuListData) {
      return menuListData?.filter((item: MenuSection) => item.id === sectionId)[0];
    }
    return null;
  }, [sectionId, menuListData]);

  const onCreateProduct = async (IdSection:string) => {
    if (currentRestaurant) {
      const productId = admin.firestore().collection(COLLECTIONS.SHARED_PRODUCTS).doc().id;
      const newProduct = new Product({});
      newProduct.id = productId;
      newProduct.restaurant_id = currentRestaurant.id || '';
      newProduct.menu_section_id = IdSection;
      newProduct.template = true;
      newProduct.menu_order = productsListData?.length || 0;
      await addSharedProduct(
        currentRestaurant.id,
        productId,
        newProduct,
      );
      await router(`/sharedMenu/${sectionId}/product/${productId}`);
    }
  };

  const onRemoveProduct = async (productId: string) => {
    if (currentRestaurant) {
      await deleteSharedProduct(currentRestaurant.id, productId);
    }
    enqueueSnackbar('El producto se elimino correctamente.', { variant: 'success' });
  };

  const onEnableProduct = async ({ productId, checked }: enableProductProps) => {
    if (currentRestaurant) {
      await editSharedProduct({ availability: checked }, currentRestaurant.id, productId);
      const journeyProductionProductFinded = journeyProductionProduct?.find((prod:JourneyProductionProduct) => prod.product.id === productId);
      if (journeyProductionProductFinded && restaurant && subsidiary && lastOpenjourneyProduction) {
        await updateProductsProductionId(
          restaurant?.id,
          subsidiary?.id,
          lastOpenjourneyProduction?.id,
          journeyProductionProductFinded?.id,
          {
            product: {
              ...journeyProductionProductFinded?.product.toPlainObject(),
              availability: checked,
            },
          },
        );
      }
    }
  };

  const onDragEnd = async (products: Product[]) => {
    if (currentRestaurant?.id) {
      await saveSharedProductsOrder(products, currentRestaurant.id);
    }
  };

  const onClickProduct = async (productId: string) => {
    router(`/sharedMenu/${sectionId}/product/${productId}`);
  };

  return (
    <>
      <ProductSectionScreen
        productsDB={productsListData}
        isSharedMenu={isSharedMenu}
        onDragEnd={onDragEnd}
        onCreateProduct={onCreateProduct}
        onRemoveProduct={onRemoveProduct}
        onEnableProduct={onEnableProduct}
        onClickProduct={onClickProduct}
        menuSection={menuSection}
      />
    </>
  );
};

export default SharedSectionId;
