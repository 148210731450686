import React, { useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../appHooks/redux';
import { SideBar } from '../../components/layouts';
import { useOrdersOnHoldBySubsidiary, Order } from '../../core';
import { Header } from './header';
import { TableOrders } from './tableOrders';
import { cleanTransactionFailedProducts } from '../../redux/reducers/shoppingCart';

const ContainerMain = styled(Box)`
  width: 100%;
  height: 89vh;
  display: flex;
`;

const ContainerSideBar = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '30%',
  [theme.breakpoints.down('md')]: {
    width: '40%',
  },
  marginRight: '6px',
}));

const CotainerOrders = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '70%',
  [theme.breakpoints.down('md')]: {
    width: '60%',
  },
}));

const HEADERS = [
  '#Orden',
  '#Items',
  'Tipo',
  'Nombre del cliente',
  'Hora de pedido',
  'Estado',
];

type OrderOnHoldProps = {
  onRowClick: (order: Order) => void;
};

export const OrderOnHold = ({ onRowClick }: OrderOnHoldProps) => {
  const dispatch = useAppDispatch();
  const restaurant = useAppSelector((store) => store.restaurant.restaurant);
  const subsidiary = useAppSelector((store) => store.subsidiary.subsidiary);
  const orders = useOrdersOnHoldBySubsidiary(restaurant?.id, subsidiary?.id);

  const [search, setSearch] = useState<string>('');

  const filterOrders = useMemo(() => orders?.filter((order:any) => {
    let matches = true;
    const properties = ['order_code', 'client_name'];
    let containsQuery = false;
    properties.forEach((property) => {
      if (
        order[property]
              && order[property].toLowerCase().includes(search.toLowerCase())
      ) {
        containsQuery = true;
      }
    });
    if (!containsQuery) {
      matches = false;
    }
    return matches;
  }), [orders, search]);

  const handleSearchChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    dispatch(cleanTransactionFailedProducts());
    setSearch(e.target.value);
  };

  return (
    <ContainerMain>
      <ContainerSideBar>
        <SideBar />
      </ContainerSideBar>
      <CotainerOrders>
        <Header
          onChange={handleSearchChange}
          search={search}
        />
        {orders && (
          <TableOrders rows={filterOrders} headerData={HEADERS} onRowClick={onRowClick} />
        )}
      </CotainerOrders>
    </ContainerMain>
  );
};
