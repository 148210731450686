import { styled, Box } from '@mui/material';
import { COLORS } from '../../constants';

export const BoxWrapper = styled(Box)(() => ({
  position: 'relative',
}));

export const BoxLoadingWrapper = styled(Box)(() => ({
  background: COLORS.DEFAULT_TRANSPARENT,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 10,
}));
