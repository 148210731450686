import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SideBar, CashKeyboard } from '../../components';

const ContainerMain = styled(Box)`
width: 100%;
height: 89vh;
display: flex;
`;

const ContainerSideBar = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '30%',
  [theme.breakpoints.down('md')]: {
    width: '40%',
  },
  marginRight: '6px',
}));

const ContainerPage = styled(Box)(({ theme }) => ({
  height: 'auto',
  display: 'flex',
  width: '70%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '60%',
  },
}));

type PaymentScreenProps = {
  onBack:()=>void;
  loading?:boolean
  onCancel:()=>void,
  onConfirm:(value:string)=>void
};

export const CashPaymentScreen = ({
  onBack,
  loading,
  onCancel,
  onConfirm,
}: PaymentScreenProps) => (
  <ContainerMain>
    <ContainerSideBar>
      <SideBar />
    </ContainerSideBar>
    <ContainerPage>
      <CashKeyboard loading={loading} onBack={onBack} onCancel={onCancel} onConfirm={onConfirm} />
    </ContainerPage>
  </ContainerMain>
);
