export enum ORDER_TYPE {
  DELIVERY_ENTREGAS = 'DELIVERY_ENTREGAS',
  DELIVERY_OTHER = 'DELIVERY_OTHER',
  PICK_UP = 'PICK_UP',
  LOCAL = 'LOCAL',
}

export enum PAYMENT_METHOD {
  CASH = 'CASH',
  ONLINE = 'ONLINE',
  CARD= 'CARD',
  QR = 'QR',
  TRANSFER = 'TRANSFER'
}

export enum STATUS {
  ON_CART = 'ON_CART',
  IN_PROCESS = 'IN_PROCESS',
  ACCEPTED = 'ACCEPTED',
  READY_TO_PICK = 'READY_TO_PICK',
  READY_TO_CASHIER = 'READY_TO_CASHIER',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  ON_HOLD = 'ON_HOLD',
  SENDED = 'SENDED'
}

export enum PAYMENT_STATUS {
  PENDING='PENDING',
  NOT_PAYED='NOT_PAYED',
  PAID ='PAID'
}
