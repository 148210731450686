import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  styled,
  Button,
  Box,
} from '@mui/material';
import { Journey } from '../../core';
import { COLORS } from '../../constants';

type TableOrdersProps = {
  rows?: Journey[];
  headerData:string[];
  deleteUserHandler:(user:Journey)=>void;
  editModalHandler:(user:Journey)=> void;
};

const OrderRow = styled(TableRow)`
  &:last-child td, &:last-child th {
    border: 0;
  }
`;

export const TableTurns = ({
  rows, headerData, deleteUserHandler, editModalHandler,
}:TableOrdersProps) => (
  <TableContainer
    component={Paper}
  >
    <Table aria-label="oreder-table">
      <TableHead>
        <TableRow key="header-row">
          {headerData?.map((head:string) => (
            <TableCell align="center" key={head}>
              <Typography variant="h4">{head}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows?.map((row: Journey, index) => (
          <OrderRow
            key={`key-order-${row.id}`}
          >
            <TableCell align="center">{index + 1 || '-'}</TableCell>
            <TableCell align="center">{row.name || '-'}</TableCell>

            <TableCell align="center">
              <Box sx={{ textAlign: 'center' }}>

                <Button
                  variant="contained"
                  style={{ backgroundColor: COLORS.BLUE_LIGHT_52, textAlign: 'center' }}
                  onClick={() => editModalHandler(row)}
                >
                  <Typography
                    textTransform="initial"
                    color="black"
                    fontWeight="bold"
                  >
                    Editar
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  style={{ backgroundColor: '#E25060', marginLeft: 3 }}
                  onClick={() => deleteUserHandler(row)}
                >
                  <Typography
                    textTransform="initial"
                    color="black"
                    fontWeight="bold"
                  >
                    Borrar
                  </Typography>
                </Button>

              </Box>

            </TableCell>
          </OrderRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
