import React from 'react';
import { Helmet } from 'react-helmet';

type HeadProps = {
  title: string;
}
export const HeadTitle = ({ title }: HeadProps) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
);
