import React from 'react';
import {
  Container, Stack, Typography, Paper, Breadcrumbs, Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import { COLORS } from '../../constants';
import { City, Subsidiary, User } from '../../core';
import { Nullable } from '../../types';
import SubsidiaryForm from './subsidiaryForms';

const ContainerMain = styled(Container)(() => ({
  width: '100%',
  minHeight: '93.8vh',
  backgroundColor: COLORS.BG_GRAY,
}));

export type SubsidiaryProfileProps = {
  subsidiary:Nullable<Subsidiary>,
  cities:Nullable<City[]>,
  user: Nullable<User>
};

export const SubsidiaryProfileScreen = ({ subsidiary, cities, user }:SubsidiaryProfileProps) => (
  <ContainerMain maxWidth="xl">
    <Stack>
      <Stack mt={4} mb={5} justifyContent="center">
        <Typography sx={{ fontWeight: 'bold', fontSize: 23, textAlign: 'center' }}>
          Información de Subsidiaria
        </Typography>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{
            color: COLORS.BLACK, fontSize: '18px', textAlign: 'left', paddingTop: '6px',
          }}
        >
          <Link component={RouterLink} underline="hover" color="inherit" to="/restaurantProfile">
            <Typography
              sx={{
                fontWeight: 600,
                '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
              }}
            >
              Perfil de restaurante
            </Typography>
          </Link>
          <Typography sx={{ fontWeight: 600 }}>
            Informacion de Sucursal
          </Typography>
        </Breadcrumbs>
      </Stack>

      <Paper
        square
        sx={{
          padding: '32px 16px 16px 16px', marginBottom: '24px',
        }}
      >
        <Stack>

          <SubsidiaryForm subsidiary={subsidiary} cities={cities} user={user} />
        </Stack>
      </Paper>
    </Stack>
  </ContainerMain>
);
