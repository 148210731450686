// eslint-disable-next-line max-classes-per-file
import { FirebaseBase } from '../firebaseBase';
import {
  Constructor,
  PlainObject,
} from '../../types';

export class AdditionOption {
  id:string;

  enable:boolean;

  name :string;

  price : number;

  availability :boolean;

  constructor({
    id = '',
    enable = true,
    name = '',
    price = 0,
    availability = true,
  }:Constructor<AdditionOption>) {
    this.id = id;
    this.enable = enable;
    this.name = name;
    this.price = price;
    this.availability = availability;
  }
}

export class Addition extends FirebaseBase {
  availability?: boolean;

  has_max_amount_addition: boolean;

  is_quantifiable?:boolean;

  name: string;

  order: number;

  mandatory?: boolean;

  mandatory_amount:boolean;

  menu_additions_id:string;

  multi_selection?: boolean;

  max_selection: number;

  options?: AdditionOption[];

  product_id:string;

  subsidiary_id:String;

  single_addition:boolean;

  restaurant_id: string;

  constructor({
    availability = false,
    has_max_amount_addition = false,
    is_quantifiable = false,
    menu_additions_id = '',
    mandatory_amount = false,
    single_addition = false,
    name = 'Nuevo',
    subsidiary_id = '',
    product_id = '',
    order = 0,
    mandatory = false,
    multi_selection = false,
    max_selection = 1,
    options = [],
    restaurant_id = '',

    ...data
  }:Constructor<Addition>) {
    super(data);
    this.availability = availability;
    this.name = name;
    this.order = order;
    this.mandatory = mandatory;
    this.multi_selection = multi_selection;
    this.max_selection = max_selection;
    this.options = options;
    this.single_addition = single_addition;
    this.has_max_amount_addition = has_max_amount_addition;
    this.is_quantifiable = is_quantifiable;
    this.mandatory_amount = mandatory_amount;
    this.product_id = product_id;
    this.subsidiary_id = subsidiary_id;
    this.menu_additions_id = menu_additions_id;
    this.restaurant_id = restaurant_id;
  }

  toPlainObject(): PlainObject<Addition> {
    return {
      ...this,
    };
  }
}

export class OrderAdditionSelected extends AdditionOption {
  addition_id:string;

  addition_name:string;

  product_id:string;

  quantity:number;

  product_index:string;

  constructor({
    addition_id = '',
    addition_name = '',
    product_id = '',
    quantity = 0,
    product_index = '',
    ...data
  }:Constructor<OrderAdditionSelected>) {
    super(data);
    this.addition_id = addition_id;
    this.addition_name = addition_name;
    this.product_id = product_id;
    this.quantity = quantity;
    this.product_index = product_index;
  }

  toPlainObject(): PlainObject<OrderAdditionSelected> {
    return { ...this };
  }
}
