// import { useSelector } from 'react-redux';
// import { useFirestoreConnect } from 'react-redux-firebase';
import { useLocation } from 'react-router-dom';
// import { COLLECTIONS } from '../../constants';
// import { MenuSection } from './menuSection.dto';
// import { Nullable } from '../../types';

export const useIsTemplateMenu = (): boolean => {
  const router = useLocation();
  const path = router.pathname;
  return path.includes('sharedMenu');
};

/* export const useMenuSectionByRestaurant = (restId: string): Nullable<MenuSection[]> => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANT,
      doc: restId,
      storeAs: 'restaurant_menu',
    },
  ]);
  const restaurant = useSelector(
    (state: any) => state.firestore.ordered.restaurant_menu,
  );

  if (restaurant) {
    return restaurant[0].menu_section.map((menuSection:MenuSection) => new MenuSection(menuSection));
  }
  return null;
}; */

/* export const useMenuSectionBySubsidiary = (restId: string, subId: string): Nullable<MenuSection[]> => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANT,
      doc: restId,
      subcollections: [{ collection: COLLECTIONS.SUBSIDIARY, doc: subId }],
      storeAs: 'subsidiary_menu',
    },
  ]);
  const subsidiary = useSelector(
    (state: any) => state.firestore.ordered.subsidiary_menu,
  );

  if (subsidiary) {
    return subsidiary[0].menu_section;
  }
  return null;
}; */
