import {
  Box, Card, CardContent, Grid, InputAdornment, styled, SvgIcon, TextField,
} from '@mui/material';
import React, { useState, useMemo } from 'react';
import {
  Search as SearchIcon,
} from 'react-feather';
import { Subsidiary } from '../../../core';
import { REGISTER_STATUS } from '../../../core/subsidiary/subsidiary.enum';
import { Nullable } from '../../../types';
import { TableSubsidiaries } from '../SubsidiariesTable';

const Container = styled(Card)(() => ({
  width: '100%',
  boxShadow: 'unset',
}));

interface TabPanelProps {
    index: number;
    value: number;
    subsidiaries?:Nullable<Subsidiary[]>
  }

type HeaderData = {
  id: string;
  label: string;
  filterName?: keyof Subsidiary;
}

const columns: HeaderData[] = [
  { id: 'id', filterName: 'id', label: 'Nro' },
  { id: 'name', filterName: 'name', label: 'Sucursal' },
  { id: 'city', filterName: 'city', label: 'Ciudad' },
  { id: 'address', filterName: 'address', label: 'Dirección' },
  { id: 'status', filterName: 'register_status', label: 'Estado' },
  { id: 'actions', label: 'Acciones' },
];
const getState = (item:REGISTER_STATUS) => {
  if (item === REGISTER_STATUS.PENDING) { return 'Pendiente'; }
  if (item === REGISTER_STATUS.ACTIVE) { return 'Activo'; }
  if (item === REGISTER_STATUS.ARCHIVED) { return 'Archivado'; }
  if (item === REGISTER_STATUS.SUBSCRIPTION_EXPIRED) { return 'Suscripción expirado'; }
  if (item === REGISTER_STATUS.SUSPENDED) { return 'Suspendido'; }
  if (item === REGISTER_STATUS.REJECTED) { return 'Rechazado'; }
  return 'Sin rol';
};

export const SubsidiariesTab = ({ value, index, subsidiaries }: TabPanelProps) => {
  const [query, setQuery] = useState('');

  const tableRows = useMemo(() => {
    if (subsidiaries) {
      if (query) {
        const result:Subsidiary[] = [];
        subsidiaries.forEach((element) => {
          const local = {
            name: element.name, city: element.city.name, address: element.address, status: getState(element.register_status), index: element.index,
          };
          const values = JSON.stringify(Object.values({ ...local })).toLocaleLowerCase();
          if (values.includes(query.toLocaleLowerCase())) {
            result.push(element);
          }
        });

        return result;
      }
      return subsidiaries;
    }
    return [];
  }, [query, subsidiaries]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}

    >
      {value === index && (
      <Box sx={{ p: 3 }}>
        <Grid>

          <Grid item container>
            <Grid item container>
              <Container>
                <Box m={3}>
                  <TextField
                    sx={{ width: 500 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',

                            borderRadius: 100,
                            height: 35,
                            width: 35,
                            fontSize: 10,
                          }}
                          >
                            <SvgIcon fontSize="small">
                              <SearchIcon />
                            </SvgIcon>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => setQuery(event.currentTarget.value)}
                    placeholder="Buscar"
                    variant="outlined"
                    value={query}
                  />
                </Box>

                <CardContent>
                  <TableSubsidiaries headerData={columns} rows={tableRows} />
                </CardContent>
              </Container>
            </Grid>
          </Grid>

        </Grid>
      </Box>
      )}
    </div>
  );
};
