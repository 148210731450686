import {
  doc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import { COLLECTIONS } from '../../constants';
import { axiosInstance } from '../../utils/axios';
import { ENDPOINT } from '../report/report.enum';
import JourneyProduction from './journeyProduction.dto';

const db = getFirestore();

export const editJourneyProduction = async (
  restaurantId:string,
  subsidiaryId:string,
  journeyProductId: string,
  data: Partial<JourneyProduction>,
) => {
  const journeyProductionRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SUBSIDIARY, subsidiaryId, COLLECTIONS.JOURNEY_PRODUCTION, journeyProductId);
  updateDoc(journeyProductionRef, { ...data });
};

export const createJourneyProductionWithProducts = async (
  restaurant_id:string,
  subsidiary_id:string,
  profile_id:string,
  journey_id:string,
  journey_production_id: string,
) => {
  const result = await axiosInstance
    .post(
      ENDPOINT.CREATE_JOURNEY_PRODUCTION,
      {
        restaurant_id, subsidiary_id, profile_id, journey_id, journey_production_id,
      },
    )
    .then(({ data }) => data);
  return result;
};

export const closeJourneyProductionWithProducts = async (
  restaurant_id:string,
  subsidiary_id:string,
  journey_production_id: string,
) => {
  const result = await axiosInstance
    .post(
      ENDPOINT.CLOSE_JOURNEY_PRODUCTION,
      {
        restaurant_id, subsidiary_id, journey_production_id,
      },
    )
    .then(({ data }) => data);
  return result;
};
