import React, { useState } from 'react';
import { LoadingBackdropContext } from './context';
import { SimpleBackdrop } from '../../components/backdrop';

export const LoadingBackdropProvider = ({ children }:{children:any}) => {
  const [value, setValue] = useState({ show: false, text: '' });
  const showBackdrop = (text:string) => {
    setValue({ show: true, text });
  };
  const hideBackdrop = () => {
    setValue({ show: false, text: '' });
  };
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <LoadingBackdropContext.Provider value={{ showBackdrop, hideBackdrop }}>
      {children}
      <SimpleBackdrop show={value.show} text={value.text} />
    </LoadingBackdropContext.Provider>
  );
};
