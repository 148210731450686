import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';
import { Subsidiary } from '../../../core';
import { Nullable } from '../../../types';

type SubsidiaryState = {
  subsidiary:Nullable<Subsidiary>
}

const initialState: SubsidiaryState = { subsidiary: null };

export const subsidiarySlice = createSlice({
  name: 'subsidiary',
  initialState,
  reducers: {
    setSubsidiary: (state: Draft<SubsidiaryState>, action: PayloadAction<Nullable<Subsidiary>>) => {
      state.subsidiary = action.payload;
    },
    cleanSubsidiary: (state: Draft<SubsidiaryState>) => {
      state.subsidiary = null;
    },
  },
});

export const { setSubsidiary, cleanSubsidiary } = subsidiarySlice.actions;
export default subsidiarySlice.reducer;
export const subsidiaryReducerTransform = createTransform(
  (toSave:any) => ({
    ...toSave,
  }),
  (toRehydrated) => ({
    ...toRehydrated,
  }),
  { whitelist: ['subsidiary'] },
);
