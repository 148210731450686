import storage from 'localforage';
import { authReducerTransform } from '../reducers/auth';
import { profileReducerTransform } from '../reducers/profile';
import { restaurantReducerTransform } from '../reducers/restaurant';
import { shoppingCartReducerTransform } from '../reducers/shoppingCart';
import { subsidiaryReducerTransform } from '../reducers/subsidiary';
import { userReducerTransform } from '../reducers/user';
import { InventoryReducerTransform } from '../reducers/inventory';
import { stockReducerTransform } from '../reducers/stock';
import { productReducerTransform } from '../reducers/product';

const persistConfig = {
  key: 'root',
  storage,
  // Only add if you need persist a reducer
  whitelist: [
    'users', 'profiles', 'shoppingCart', 'inventory', 'stock', 'product',
  ],
  transforms: [
    userReducerTransform,
    authReducerTransform,
    profileReducerTransform,
    restaurantReducerTransform,
    shoppingCartReducerTransform,
    subsidiaryReducerTransform,
    InventoryReducerTransform,
    stockReducerTransform,
    productReducerTransform,
  ],
};

export default persistConfig;
