/* eslint-disable max-len */
import { Box, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { swap } from 'react-grid-dnd';
import { COLORS } from '../../../constants';
import { Addition, AdditionOption } from '../../../core';
import { AdditionFormValues, AdditionOptionFormValues } from './additionEditView/AdditionEditForm';
import AdditionPickerItem from './AdditionPickerItem';

const BoxCustom = styled(Box)(() => ({
  marginTop: '24px',
  '&:hover': {
    background: COLORS.BLUE_LIGHT_92,
    cursor: 'pointer',
  },
}));

type DragDropAdditionsProps = {
  productAdditions: Addition[],
  prodId: string,
  onLoading: (value:boolean) => void,
  isSharedMenu: boolean,
  onDragEndProductsAdditions: (prodId:string, additions: Addition[]) => void,
  onSharedWithOtherProducts: (menuAdditionsId: string, prodId:string) => Promise<number>,
  onDeleteAdditionProduct?: (productId: string, additionId:string) => void | Promise<void>,
  onEditAdditionRestaurantAndProduct?: (
    values: AdditionFormValues,
    availability:boolean,
    menuAdditionId: string,
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
  onEditAdditionOptionsRestaurantAndProduct?: (
    swaped: AdditionOption[],
    menuAdditionsId: string,
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
  onEditAdditionOptionsProduct?: (
    swaped: AdditionOptionFormValues[],
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
}
const DragDropAdditions = ({
  productAdditions,
  prodId,
  onLoading,
  isSharedMenu,
  onDragEndProductsAdditions,
  onSharedWithOtherProducts,
  onDeleteAdditionProduct,
  onEditAdditionRestaurantAndProduct,
  onEditAdditionOptionsRestaurantAndProduct,
  onEditAdditionOptionsProduct,
}: DragDropAdditionsProps) => {
  const [additions, setAdditions] = useState<Addition[]>([]);

  useEffect(() => {
    if (productAdditions && productAdditions.length > 0) {
      setAdditions(productAdditions);
    }
  }, [productAdditions]);

  const dragEnd = async ({ destination, source }: DropResult) => {
    if (!destination) return;
    onLoading(true);
    const swaped = swap(
      additions,
      source.index,
      destination.index,
    );
    setAdditions(swaped);
    await onDragEndProductsAdditions(prodId, swaped);
    setTimeout(() => {
      onLoading(false);
    }, 1500);
  };
  return (
    <DragDropContext onDragEnd={dragEnd}>
      <Droppable droppableId="droppable-additions">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {additions.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(prov, snapshot) => (
                  <BoxCustom
                    ref={prov.innerRef}
                    {...prov.draggableProps}
                    {...prov.dragHandleProps}
                    sx={{
                      backgroundColor: snapshot.isDragging ? COLORS.BLUE_LIGHT_92 : '',
                      opacity: snapshot.isDragging ? 0.5 : 1,
                    }}
                  >
                    <AdditionPickerItem
                      addition={item}
                      prodId={prodId}
                      isSharedMenu={isSharedMenu}
                      onSharedWithOtherProducts={onSharedWithOtherProducts}
                      onDeleteAdditionProduct={onDeleteAdditionProduct}
                      onEditAdditionRestaurantAndProduct={onEditAdditionRestaurantAndProduct}
                      onEditAdditionOptionsRestaurantAndProduct={onEditAdditionOptionsRestaurantAndProduct}
                      onEditAdditionOptionsProduct={onEditAdditionOptionsProduct}
                    />
                  </BoxCustom>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropAdditions;
