import React, { useEffect, useState } from 'react';
import { swap } from 'react-grid-dnd';
import {
  DragDropContext, Draggable, Droppable, DropResult,
} from 'react-beautiful-dnd';
import { Paper, styled } from '@mui/material';
import { COLORS } from '../../constants';
import { Product } from '../../core/product';
import ProductCard from './ProductCard';
import { enableProductProps } from './types';

const PaperCustom = styled(Paper)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: 8,
  marginBottom: 16,
  padding: '8px 24px 8px 8px',
  boxShadow: '0px 4px 5px 2px rgba(0, 0, 0, 0.25)',
  '&:hover': {
    background: COLORS.BLUE_LIGHT_92,
    cursor: 'pointer',
  },
}));

type ProductSectionListProps = {
  productsDB: Product[];
  isSharedMenu: boolean,
  onRemoveProduct?: (productId: string) => void;
  onEnableProduct: ({
    productId, checked,
  }: enableProductProps) => void;
  onDragEnd: (products: Product[]) => void;
  onClickProduct: (productId: string) => void;
}
const ProductSectionList = ({
  productsDB,
  isSharedMenu,
  onRemoveProduct,
  onEnableProduct,
  onDragEnd,
  onClickProduct,
}:ProductSectionListProps) => {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    if (productsDB && productsDB.length >= 0) {
      setProducts(productsDB);
    }
  }, [productsDB]);

  const dragEnd = async ({ destination, source }: DropResult) => {
    if (!destination) return;
    const swaped = swap(
      products,
      source.index,
      destination.index,
    );
    setProducts(swaped);
    await onDragEnd(swaped);
  };
  return (
    <DragDropContext onDragEnd={dragEnd}>
      <Droppable droppableId="droppable-list-menu-section">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {products.map((product, index) => (
              <Draggable key={product.id} draggableId={product.id} index={index}>
                {(prov, snapshot) => (
                  <PaperCustom
                    ref={prov.innerRef}
                    {...prov.draggableProps}
                    {...prov.dragHandleProps}
                    sx={{
                      backgroundColor: snapshot.isDragging ? COLORS.BLUE_LIGHT_92 : '',
                      opacity: snapshot.isDragging ? 0.5 : 1,
                    }}
                  >
                    <ProductCard
                      product={product}
                      index={index}
                      isSharedMenu={isSharedMenu}
                      onRemoveProduct={onRemoveProduct}
                      onEnableProduct={onEnableProduct}
                      onClickProduct={onClickProduct}
                    />
                  </PaperCustom>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default React.memo(
  ProductSectionList,
  // eslint-disable-next-line max-len
  (prevProps, nextProps) => JSON.stringify(prevProps.productsDB) === JSON.stringify(nextProps.productsDB),
);
