import React from 'react';
import {
  CompletedOrder,
  Gallery,
  InProcessOrder,
  LocalMenu,
  Menu,
  NewOrder,
  NoSaleOrder,
  OnHoldOrder,
  OrderPanel,
  RestaurantProfile,
  SaleReport,
  Settings,
  XpressMenu,
  ActualInventory,
  HistorialInventory,
} from '../../../assets';

export const CompleteOrderIcon = () => (
  <img
    src={CompletedOrder}
    alt="completed-order"
    width={20}
    height={30}
  />
);

export const GalleryIcon = () => (
  <img
    src={Gallery}
    alt="gallery"
    width={20}
    height={30}
  />
);

export const InProcessOrderIcon = () => (
  <img
    src={InProcessOrder}
    alt="in-process-order"
    width={20}
    height={30}
  />
);

export const LocalMenuIcon = () => (
  <img
    src={LocalMenu}
    alt="local-Menu"
    width={20}
    height={30}
  />
);

export const MenuIcon = () => (
  <img
    src={Menu}
    alt="menu"
    width={20}
    height={30}
  />
);

export const NewOrderIcon = () => (
  <img
    src={NewOrder}
    alt="new-order"
    width={20}
    height={30}
  />
);

export const NoSaleOrderIcon = () => (
  <img
    src={NoSaleOrder}
    alt="no-sale-order"
    width={20}
    height={30}
  />
);

export const OnHoldOrderIcon = () => (
  <img
    src={OnHoldOrder}
    alt="on-hold-order"
    width={20}
    height={30}
  />
);

export const OrderPanelIcon = () => (
  <img
    src={OrderPanel}
    alt="order-panel"
    width={20}
    height={30}
  />
);

export const RestaurantProfileIcon = () => (
  <img
    src={RestaurantProfile}
    alt="restaurant-profile"
    width={20}
    height={30}
  />
);

export const SaleReportIcon = () => (
  <img
    src={SaleReport}
    alt="sale-report"
    width={20}
    height={30}
  />
);

export const SettingsIcon = () => (
  <img
    src={Settings}
    alt="settings"
    width={20}
    height={30}
  />
);

export const XpressMenuIcon = () => (
  <img
    src={XpressMenu}
    alt="xpress-menu"
    width={20}
    height={30}
  />
);

export const ActualInventoryIcon = () => (
  <img
    src={ActualInventory}
    alt="actual-inventory"
    width={20}
    height={30}
  />
);

export const HistorialInventoryIcon = () => (
  <img
    src={HistorialInventory}
    alt="historial-inventory"
    width={20}
    height={30}
  />
);
