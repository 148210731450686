/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useAppSelector } from '../../appHooks/redux';
import { ROLES } from '../../core';
import { NotFoundScreen } from '../../screens';

const RoleGuard = ({ roles, children }:{children:any, roles:string[]}) => {
  const user = useAppSelector((state) => state.profiles.selectProfile);
  const found = roles.includes(user?.roles[0] || '');

  if (found) {
    return children;
  }
  return <NotFoundScreen />;
};

export default RoleGuard;
