import {
  uploadBytesResumable, getDownloadURL, StorageReference, getStorage, ref, deleteObject,
} from 'firebase/storage';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { COLLECTIONS } from '../../constants';
import { Gallery } from './gallery.dto';
import { CreateUrlResizeProps } from '../../screens/galleryScreen/types';
import { Product } from '../product';

type deleteGalleryFromProductsProps = {
  galleryId: string,
  pathProducts: string,
}

const db = getFirestore();

export const updateGallery = async (
  restId: string,
  gallery: Gallery,
): Promise<void> => {
  const galleryRef = doc(
    db,
    COLLECTIONS.RESTAURANT,
    restId,
    COLLECTIONS.GALLERY,
    gallery.id,
  );
  await setDoc(galleryRef, gallery.toPlainObject(), { merge: true });
};

export const updateGalleryImage = async (
  restId: string,
  gallery: Gallery,
): Promise<void> => {
  const galleryRef = doc(
    db,
    COLLECTIONS.RESTAURANT,
    restId,
    COLLECTIONS.GALLERY,
    gallery.id,
  );
  await setDoc(galleryRef, {
    image: gallery.image.toPlainObject(),
    original_image: gallery.original_image,
  }, { merge: true });
};

export const addGallery = async (
  restId: string,
  gallery: Gallery,
): Promise<void> => {
  const galleryRef = doc(
    db,
    COLLECTIONS.RESTAURANT,
    restId,
    COLLECTIONS.GALLERY,
    gallery.id,
  );
  await setDoc(galleryRef, {
    ...gallery,
    image: gallery.image.toPlainObject(),
    restaurant_id: restId,
  }, { merge: true });
};

export const uploadImgGallery = async (
  file: Blob | File | null,
  galleryRef: StorageReference,
): Promise<any> => {
  if (file && galleryRef) {
    const upload = await uploadBytesResumable(galleryRef, file).then((snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.warn(`Upload is ${progress}% done`);
      return getDownloadURL(snapshot.ref).then((downloadURL) => downloadURL);
    }).catch((error) => console.error('error', error));
    return upload;
  }
  return '';
};

export const deleteGalleryFromProducts = async ({ galleryId, pathProducts }: deleteGalleryFromProductsProps) => {
  const docRef = collection(
    db,
    pathProducts,
  );
  const queyRef = query(docRef, where('gallery_id', '==', galleryId));
  const querySnapshot = await getDocs(queyRef);
  let products: Product[] = [];
  if (querySnapshot.size) {
    products = querySnapshot.docs.map((snap) => new Product({
      ...snap.data(),
      id: snap.id,
    }));
  }
  const batch = writeBatch(db);
  products.forEach((product) => {
    const productRef = doc(
      db,
      `${pathProducts}/${product.id}`,
    );
    batch.update(
      productRef,
      {
        photo: {
          original: '',
          x40: '',
          x400: '',
          x80: '',
        },
      },
    );
  });
  await batch.commit();
};

export const deleteDocument = async (restId: string, docId: string) => {
  const docRef = doc(
    db,
    COLLECTIONS.RESTAURANT,
    restId,
    COLLECTIONS.GALLERY,
    docId,
  );
  await deleteDoc(docRef);
};

export const deleteStorage = async (urlImage: string[]): Promise<void> => {
  const storage = getStorage();
  urlImage.map(async (url) => {
    const deleteRef = ref(
      storage,
      url,
    );
    await deleteObject(deleteRef);
  });
};

export const createUrlResize = ({
  bucket, restId, docId, resize,
// eslint-disable-next-line max-len
}: CreateUrlResizeProps) => `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/restaurant_gallery%2F${restId}%2F${docId}%2Fresize%2F${docId}_${resize}?alt=media`;

export const urlResizeOriginal = ({
  bucket, restId, docId, resize,
// eslint-disable-next-line max-len
}: CreateUrlResizeProps) => `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/restaurant_gallery%2F${restId}%2Fresize%2F${docId}_${resize}?alt=media`;
