import { Environment } from './type';

const TestEnv:Environment = {
  firebaseConfig: {
    apiKey: 'AIzaSyB_IScrTXcvBuYQw4yI72BkmAQTM0Mfv-Y',
    authDomain: 'platform-of-sales-test.firebaseapp.com',
    projectId: 'platform-of-sales-test',
    storageBucket: 'platform-of-sales-test.appspot.com',
    messagingSenderId: '668726552322',
    appId: '1:668726552322:web:21cce3b46476265b2f841b',
    measurementId: 'G-FN2W01G93N',
  },
  emailDomain: 'conbrix.com',
  REACT_APP_CLOUD_FUNCTIONS_API: 'https://us-central1-platform-of-sales-test.cloudfunctions.net/api',
};

export default TestEnv;
