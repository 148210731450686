/* eslint-disable max-len */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { COLLECTIONS } from '../../constants';
import { Nullable } from '../../types';
import { Addition } from './addition.dto';

export const useSharedAdditionsByProduct = (restaurantId: string, productId: string): Nullable<Addition[]> => {
  useFirestoreConnect(
    [{
      collection: COLLECTIONS.RESTAURANT,
      doc: restaurantId,
      subcollections: [
        {
          collection: COLLECTIONS.SHARED_PRODUCTS,
          doc: productId,
        },
        { collection: COLLECTIONS.ADDITION },
      ],
      storeAs: 'all_shared_additions',
    }],
  );

  const allAdditions = useSelector(
    (state:any) => state.firestore.ordered.all_shared_additions,
  );

  return useMemo(() => (allAdditions
    ? allAdditions.slice()
      .sort((a:Addition, b:Addition) => (a.order > b.order ? 1 : -1))
    : null), [allAdditions]);
};

export const useLocalAdditionsByProduct = (restaurantId: string, subsidiaryId: string, productId: string): Nullable<Addition[]> => {
  useFirestoreConnect(
    [{
      collection: COLLECTIONS.RESTAURANT,
      doc: restaurantId,
      subcollections: [
        {
          collection: COLLECTIONS.SUBSIDIARY,
          doc: subsidiaryId,
        },
        {
          collection: COLLECTIONS.LOCAL_PRODUCTS,
          doc: productId,
        },
        { collection: COLLECTIONS.ADDITION },
      ],
      storeAs: 'all_local_additions',
    }],
  );

  const allAdditions = useSelector(
    (state:any) => state.firestore.ordered.all_local_additions,
  );

  return useMemo(() => (allAdditions
    ? allAdditions.slice()
      .sort((a:Addition, b:Addition) => (a.order > b.order ? 1 : -1))
    : null), [allAdditions]);
};
