import React, { useMemo } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import useWindowDimensions from '../../appHooks/useWindowDimensions';

type HorizontalCarouselProps = {
  children:React.ReactNode,
  sideBarWidth: number,
  slideWidth: number
}

export const CarouselWithArrows = ({
  children, sideBarWidth = 550, slideWidth = 155,
}: HorizontalCarouselProps) => {
  const window = useWindowDimensions();

  const responsive = useMemo(() => {
    let countOfItems = 5;
    if (window) {
      const dots = (window.width - sideBarWidth) / slideWidth;
      dots.toFixed();
      countOfItems = dots > 1 ? dots : 1;
    }
    return {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 500 },
        items: countOfItems,
        slidesToSlide: countOfItems,

      },
    };
  }, [window, sideBarWidth, slideWidth]);

  return (
    <Carousel
      responsive={responsive}
      infinite={false}
      partialVisible
      itemClass="carousel-item-section"
    >
      {children}
    </Carousel>
  );
};
