import React, { useEffect, useState } from 'react';
import {
  Button, Grid, MenuItem, Stack, TextField, styled, Typography, Box,
} from '@mui/material';
import {
  Journey, JourneyProduction, Profile, useValidateEnableInventory,
} from '../../core';
import { Nullable } from '../../types';
import { useAppSelector } from '../../appHooks/redux';
import { COLORS } from '../../constants';

const TextFieldCustom = styled(TextField)(() => ({
  '.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
    background: 'white',
  },
}));

type HeaderInventoryProps = {
  profiles: Profile[];
  journeys: Journey[];
  journeyProduction: Nullable<JourneyProduction>;
  onOpenJourney: (roleId: string, journeyId: string) => void;
  onCloseJourney: () => void;
  onExportToExcel: () => void;
};

const Header = ({
  profiles, journeys, onOpenJourney, onCloseJourney, journeyProduction, onExportToExcel,
}: HeaderInventoryProps) => {
  const [roleId, setRoleId] = useState('');
  const [journeyId, setJourneyId] = useState('');
  const enableInventory = useValidateEnableInventory();
  const productsMenu = useAppSelector((state) => state.product.products);

  useEffect(() => {
    if (journeyProduction) {
      if (!journeyProduction.date_end) {
        setRoleId(journeyProduction.data_manager.id);
        setJourneyId(journeyProduction.journey.id);
      } else {
        setRoleId('');
        setJourneyId('');
      }
    }
  }, [journeyProduction]);

  const existDateEnd = () => {
    if (journeyProduction && !journeyProduction.date_end) {
      return true;
    }
    return false;
  };

  return (
    <>
      {!productsMenu ? (
        <Box mt={3} display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: COLORS.RED_LIGHT_60 }}>
          <Typography>
            Debe crear secciones y productos para crear turnos
          </Typography>
        </Box>
      ) : (
        <Grid container pt={5} spacing={1}>
          <Grid item sm={4} md={4} xs={6}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <TextFieldCustom
                fullWidth
                label="Encargado"
                select
                name="manager"
                value={roleId}
                onChange={(e:any) => {
                  setRoleId(e.target.value);
                }}
                variant="outlined"
                size="small"
                color="secondary"
                disabled={!enableInventory}
              >
                {profiles.map((profile) => (
                  <MenuItem id={profile.id} key={profile.id} value={profile.id}>
                    {profile.full_name}
                  </MenuItem>
                ))}
              </TextFieldCustom>
              <TextFieldCustom
                fullWidth
                label="Turno"
                select
                name="journey"
                value={journeyId}
                onChange={(e:any) => setJourneyId(e.target.value)}
                variant="outlined"
                size="small"
                color="secondary"
                disabled={!enableInventory}
              >
                {journeys.map((journeySub) => (
                  <MenuItem id={journeySub.id} key={journeySub.id} value={journeySub.id}>
                    {journeySub.name}
                  </MenuItem>
                ))}
              </TextFieldCustom>
            </Stack>
          </Grid>
          <Grid item sm={4} md={4} xs={3}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => onOpenJourney(roleId, journeyId)}
                disabled={!roleId || !journeyId || existDateEnd()}
              >
                Abrir Turno
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => onCloseJourney()}
                disabled={!existDateEnd()}
              >
                Cerrar Turno
              </Button>
            </Stack>
          </Grid>
          <Grid item sm={4} md={4} xs={3}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={onExportToExcel}
              >
                Exportar a Excel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Header;
