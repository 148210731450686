import React from 'react';
import { useParams } from 'react-router-dom';
import {
  OrderInProcess, OrderOnHold, OrderCompleted, NotFoundScreen, OrderNotPayed,
} from '../../screens';
import { ORDER_STATUS } from '../../constants';
import { HeadTitle } from '../../components';
import { useAppSelector, useAppDispatch } from '../../appHooks/redux';
import { Order as OrderObj } from '../../core';
import { getOrderProductsByOrderId, orderProductDestructuring } from '../../core/orderProduct';
import {
  loadCurrentOrder, setProducts, setOrderProduct, setAdditions,
} from '../../redux/reducers/shoppingCart';

const Order = () => {
  const { orderStatus } = useParams();
  const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  const currentOrder = useAppSelector((state) => state.shoppingCart.currentOrder);
  const restaurant = useAppSelector((state) => state.restaurant.restaurant);
  const dispatch = useAppDispatch();

  const handleRowClick = async (order: OrderObj) => {
    if (currentOrder?.id !== order.id) {
      const orderProducts = await getOrderProductsByOrderId({
        orderId: order?.id || '',
        restaurantId: restaurant?.id || '',
        subsidiaryId: subsidiary?.id || '',
      });
      const { additions, products } = orderProductDestructuring(orderProducts);
      dispatch(loadCurrentOrder(order));
      dispatch(setProducts(products));
      dispatch(setAdditions(additions));
      dispatch(setOrderProduct(orderProducts));
    }
  };
  return (
    <>
      <HeadTitle title="Listado de Ordenes" />
      {orderStatus === ORDER_STATUS.IN_PROCESS && <OrderInProcess onRowClick={handleRowClick} />}
      {orderStatus === ORDER_STATUS.ON_HOLD && <OrderOnHold onRowClick={handleRowClick} />}
      {orderStatus === ORDER_STATUS.COMPLETED && <OrderCompleted onRowClick={handleRowClick} />}
      {orderStatus === ORDER_STATUS.NO_SALES && <OrderNotPayed onRowClick={handleRowClick} />}
      {orderStatus !== ORDER_STATUS.ON_HOLD
      && orderStatus !== ORDER_STATUS.IN_PROCESS
      && orderStatus !== ORDER_STATUS.COMPLETED
      && orderStatus !== ORDER_STATUS.NO_SALES
      && <NotFoundScreen />}
    </>
  );
};

export default Order;
