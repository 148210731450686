import React from 'react';
import { styled } from '@mui/material/styles';
import ImageIcon from '@mui/icons-material/Image';
import { Box, Typography } from '@mui/material';
import Dropzone from 'react-dropzone';
import { CustomMainBox, CustomIconBox } from './addImage';
import { COLORS } from '../../constants';

const CustomImageIcon = styled(ImageIcon)`
  color: ${COLORS.GREEN};
  width: 100%;
  height: 100%;
`;

const CustomUploadTypography = styled(Typography)`
  font-size: 10px;
  text-decoration: underline;
`;

const CustomDetailBox = styled(Box)`
  text-align: center;
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 16px;
`;

type DragImageProps = {
  handleDropGallery: (files: FileList | File[] | null) => void | Promise<boolean>,
};

export const DragImage = ({
  handleDropGallery,
}: DragImageProps) => (
  <CustomMainBox>
    <Dropzone
      onDrop={handleDropGallery}
      accept={{
        'image/jpeg': [],
        'image/png': [],
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <CustomDetailBox {...getRootProps({
          style: {
            borderRadius: '6px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          },
        })}
        >
          <input {...getInputProps()} accept="image/png, image/jpeg" />
          <CustomIconBox>
            <CustomImageIcon />
          </CustomIconBox>
          <Box mb={3}>
            <Typography>Arrastra la imagen</Typography>
            <Typography>aquí</Typography>
          </Box>
          <CustomUploadTypography>Sube imagenes desde tu dispositivo</CustomUploadTypography>
        </CustomDetailBox>
      )}
    </Dropzone>
  </CustomMainBox>
);
