import { PlainObject } from '../../types';

export class AdditionOptionItem {
  id:string;

  enable:boolean;

  name :string;

  price : number;

  availability :boolean;

  constructor({
    id = '',
    enable = true,
    name = 'Nuevo',
    price = 0,
    availability = true,
  } = {}) {
    this.id = id;
    this.enable = enable;
    this.name = name;
    this.price = price;
    this.availability = availability;
  }

  toPlainObject(): PlainObject<AdditionOptionItem> {
    return {
      ...this,
    };
  }
}
