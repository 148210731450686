import React from 'react';
import { Stack, CircularProgress } from '@mui/material';
import { LogoWithText } from '../../assets';

export const Loader = () => (
  <Stack
    direction="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    height="100vh"
    spacing={4}
  >
    <img
      src={LogoWithText}
      alt="logo-with-text"
      width="250"
      height="80"
    />
    <CircularProgress />
  </Stack>
);
