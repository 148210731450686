import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SideBar } from '../../components/layouts';
import { Warning } from '../../assets';
import { AlertModal } from '../../components';
import { ROUTES } from '../../constants';
import { useValidateEnableInventory } from '../../core/restaurant/restaurant.service';

const ContainerSideBar = styled(Box)(({ theme }) => ({
  height: '89vh',
  width: '30%',
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    width: '40%',
  },
  marginRight: '6px',
}));

export const CommandPanel = () => {
  const enableInventory = useValidateEnableInventory();
  const router = useNavigate();

  const onCloseAlert = () => {
    router(ROUTES.INVENTORY);
  };

  return (
    <ContainerSideBar>
      <SideBar />
      <AlertModal
        icon={Warning}
        open={enableInventory}
        title="Debe abrir turno para continuar con la orden"
        okText="Ir a la vista del turno"
        onClose={onCloseAlert}
        closeIcon={false}
        isCloseModal
      />
    </ContainerSideBar>
  );
};
