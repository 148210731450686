import React from 'react';
import { useAppSelector } from '../../appHooks/redux';
import { SelectProfileScreen } from '../../screens';

const ProfileGuard = ({ children }:{children:any}) => {
  const selectProfile = useAppSelector((state) => state.profiles.selectProfile);
  if (selectProfile) {
    return children;
  }
  return <SelectProfileScreen />;
};

export default ProfileGuard;
