// eslint-disable-next-line max-classes-per-file
import { Photo } from '../photo';
import { FirebaseBase } from '../firebaseBase';
import {
  Constructor, PlainObject,
  // PlainObject,
} from '../../types';

type Category = {
  label: string;
  value: string;
};

export class Product extends FirebaseBase {
  availability: boolean;

  category: Category;

  cooking_time: number;

  description: string;

  menu_order: number;

  menu_section_id: string;

  name: string;

  photo: Photo;

  pre_packed: boolean;

  restaurant_id: string;

  subsidiary_id: string;

  unit_price: number;

  template: boolean;

  enable: boolean;

  gallery_id: string;

  stock: number;

  barcode: string;

  constructor({
    availability = false,
    category = {
      label: '',
      value: '',
    },
    cooking_time = 10,
    description = '',
    menu_order = 0,
    menu_section_id = '',
    name = 'Nuevo Producto',
    photo = new Photo({}),
    pre_packed = false,
    restaurant_id = '',
    subsidiary_id = '',
    unit_price = 0,
    template = false,
    enable = true,
    gallery_id = '',
    stock = 0,
    barcode = '',
    ...data
  }:Constructor<Product>) {
    super(data);
    this.availability = availability;
    this.category = category;
    this.cooking_time = cooking_time;
    this.description = description;
    this.menu_order = menu_order;
    this.menu_section_id = menu_section_id;
    this.name = name;
    this.photo = photo;
    this.pre_packed = pre_packed;
    this.restaurant_id = restaurant_id;
    this.subsidiary_id = subsidiary_id;
    this.unit_price = unit_price;
    this.template = template;
    this.enable = enable;
    this.gallery_id = gallery_id;
    this.stock = stock;
    this.barcode = barcode;
  }

  toPlainObject(): PlainObject<Product> {
    return { ...this, photo: this.photo.toPlainObject() };
  }
}

export class SelectedProduct extends Product {
  quantity:number;

  order_product_id:string;

  failed: string;

  index:string;

  additionFlag: boolean;

  isEditing: boolean;

  constructor({
    quantity = 0,
    order_product_id = '',
    index = '',
    failed = '',
    additionFlag = false,
    isEditing = false,
    ...data
  }:Constructor<SelectedProduct>) {
    super(data);
    this.quantity = quantity;
    this.order_product_id = order_product_id;
    this.failed = failed;
    this.index = index;
    this.additionFlag = additionFlag;
    this.isEditing = isEditing;
  }
}
// export type SelectedProduct= Product & {quantity:number};
