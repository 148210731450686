import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';

type BackdropProps = {
  show: boolean,
  text?: string
}
export const SimpleBackdrop = ({ show, text }:BackdropProps) => (
  <div>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress color="inherit" />
        <Typography style={{
          marginTop: 30,
          fontSize: 24,
          paddingRight: 20,
          paddingLeft: 20,
          textAlign: 'center',
        }}
        >
          {text}
        </Typography>
      </Box>
    </Backdrop>
  </div>
);
