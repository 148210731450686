import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { COLLECTIONS } from '../../constants';
import Journey from './journey.dto';

export const useAllJourneySubsidiary = (restaurantId: string, subsidiaryId: string): Journey [] => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANT,
      doc: restaurantId,
      subcollections: [{
        collection: COLLECTIONS.SUBSIDIARY,
        doc: subsidiaryId,
      },
      {
        collection: COLLECTIONS.JOURNEY,
      }],
      storeAs: 'all_journey_subsidiary',
    },
  ]);
  const allJourneySubsidiary = useSelector((state:any) => state.firestore.ordered.all_journey_subsidiary);
  return allJourneySubsidiary || [];
};
