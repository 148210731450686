import { Environment } from './type';

const StagingEnv:Environment = {
  firebaseConfig: {
    apiKey: 'AIzaSyAnOsw1qJdBjBJpbsEkNHGdCM-mXdXMr50',
    authDomain: 'platform-of-sales-production.firebaseapp.com',
    projectId: 'platform-of-sales-production',
    storageBucket: 'platform-of-sales-production.appspot.com',
    messagingSenderId: '1002100357201',
    appId: '1:1002100357201:web:7f4d7eb1132cdec2cd6070',
    measurementId: 'G-FSDWFTKB91',
  },
  emailDomain: 'conbrix.com',
  REACT_APP_CLOUD_FUNCTIONS_API: 'https://us-central1-platform-of-sales-production.cloudfunctions.net/api',
};

export default StagingEnv;
