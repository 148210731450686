import React, {
  useState, useMemo, useEffect, useRef,
} from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../../../constants';
import { Discount, DiscountModal } from '../../../modal/discountModal';
import { Order, STATUS, PAYMENT_STATUS } from '../../../../core';
import { useAppSelector } from '../../../../appHooks/redux';

const ContainerBottom = styled(Grid)`
  padding-inline: 13px;
  padding-block: 6px;
`;

const CustomTypography = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  text-transform: initial;
  color: black;
`;

const CustomButton = styled(Button)`
  background-color: ${COLORS.WHITE};
  border-color: ${COLORS.BLACK};
  height: 45px;
  &:hover {
    background-color: ${COLORS.WHITE};
    border-color: ${COLORS.BLACK};
  }
`;
export type BottomSectionProps = {
  onDiscount: (discount: Discount) => void;
  onPrint: () => void;
  onConvert: () => void;
  order: Order | null;
  onPayment:()=>void;
  subTotal :number
};

export const BottomSection = ({
  onDiscount,
  order,
  onPrint,
  onConvert,
  subTotal,
  onPayment,
}: BottomSectionProps) => {
  const currentOrder = useAppSelector((state) => state.shoppingCart.currentOrder);
  const buttonDiscountRef = useRef<HTMLButtonElement>(null);
  const buttonPrintRef = useRef<HTMLButtonElement>(null);
  const buttonChangeRef = useRef<HTMLButtonElement>(null);
  const buttonPayRef = useRef<HTMLButtonElement>(null);
  const [discountModal, setDiscountModal] = useState(false);
  const isDisabled = useMemo(() => (order?.status === STATUS.COMPLETED), [order]);

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      const { key } = event;
      if (buttonDiscountRef.current && key === 'F1') {
        event.preventDefault();
        buttonDiscountRef.current.click();
      }
      if (buttonPrintRef.current && key === 'F2') {
        event.preventDefault();
        buttonPrintRef.current.click();
      }
      if (buttonChangeRef.current && key === 'F3') {
        event.preventDefault();
        buttonChangeRef.current.click();
      }
      if (buttonPayRef.current && key === 'F4') {
        event.preventDefault();
        buttonPayRef.current.click();
      }
    });
  }, []);

  return (
    <ContainerBottom
      container
      direction="row"
      spacing={2}
      justifyContent="center"
    >
      <Grid item sm={6} md={3} xs={12}>
        <CustomButton
          id="discountBtn"
          variant="outlined"
          fullWidth
          disabled={isDisabled}
          onClick={() => setDiscountModal(true)}
          ref={buttonDiscountRef}
        >
          <CustomTypography>Descontar</CustomTypography>
        </CustomButton>
      </Grid>
      <Grid item sm={6} md={3} xs={12}>
        <CustomButton id="printBtn" variant="outlined" fullWidth onClick={onPrint} disabled={isDisabled} ref={buttonPrintRef}>
          <CustomTypography>Imprimir</CustomTypography>
        </CustomButton>
      </Grid>
      <Grid item sm={6} md={3} xs={12}>
        <CustomButton
          id="convertBtn"
          variant="outlined"
          fullWidth
          disabled={isDisabled}
          onClick={onConvert}
          ref={buttonChangeRef}
        >
          <CustomTypography>Convertir Servicio</CustomTypography>
        </CustomButton>
      </Grid>
      <Grid item sm={6} md={3} xs={12}>
        <CustomButton ref={buttonPayRef} id="paymentBtn" variant="outlined" fullWidth onClick={() => { onPayment(); }} disabled={currentOrder?.payment_status !== PAYMENT_STATUS.PENDING || isDisabled}>
          <CustomTypography>Pagar</CustomTypography>
        </CustomButton>
      </Grid>
      {discountModal && (
        <DiscountModal
          totalPrice={subTotal || 0}
          open={discountModal}
          onAcept={(value) => {
            onDiscount(value);
            setDiscountModal(false);
          }}
          onClose={() => {
            setDiscountModal(false);
          }}
        />
      )}
    </ContainerBottom>
  );
};
