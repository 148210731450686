import React, { useState } from 'react';
import {
  Box,
  TableHead,
  TableCell,
  Checkbox,
  TableRow,
  TableContainer,
  TableBody,
  Table,
  Button,
  Typography,
  styled,
  CircularProgress,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Addition, MenuAddition, Product } from '../../../../core';
import { Nullable } from '../../../../types';
import { roundTwoDecimals } from '../../../../utils/commonUtils';
import ModalCreateAddition from './ModalCreateAddition';
import { ConfirmModal, TitleHeaderModal } from '../../../../components';
import { COLORS } from '../../../../constants';
import { AdditionFormValues } from './AdditionEditForm';
import { BoxLoadingWrapper, BoxWrapper } from '../../../../components/box';

const CheckboxCustom = styled(Checkbox)(() => ({
  color: COLORS.GRAY_LIGHT_2,
}));

type ModalPickAdditionProps = {
  show: boolean,
  onClose: () => void,
  product: Product,
  restaurantAdditions: Nullable<MenuAddition[]>,
  productAdditions: Nullable<Addition[]>,
  onCreateMenuAdditionsRestaurant?: (
    values: AdditionFormValues, availability: boolean
  ) => void | Promise<void>,
  onDeleteMenuAdditionRestaurant?: (menuAdditionId: string) => void | Promise<void>,
  onAddAdditionProduct?: (
    productId: string, menuAddition: MenuAddition, order: number,
  ) => void | Promise<void>,
  onDeleteAdditionProduct?: (productId: string, additionId:string) => void | Promise<void>,
}
const ModalPickAddition = ({
  show,
  onClose,
  product,
  restaurantAdditions,
  productAdditions,
  onCreateMenuAdditionsRestaurant,
  onDeleteMenuAdditionRestaurant,
  onAddAdditionProduct,
  onDeleteAdditionProduct,
}: ModalPickAdditionProps) => {
  const [showModal, setShowModal] = useState(false);
  const [deleteMenuAdditionId, setDeleteMenuAdditionId] = useState('');
  const [loading, setLoading] = useState(false);

  const deleteAdditionFromRestaurant = async () => {
    setLoading(true);
    if (onDeleteMenuAdditionRestaurant) {
      await onDeleteMenuAdditionRestaurant(deleteMenuAdditionId);
    }
    setLoading(false);
    setDeleteMenuAdditionId('');
  };

  const handleChange = (event: any, menuAddition: MenuAddition, additionId: string) => {
    const { checked } = event.target;
    if (checked) {
      if (onAddAdditionProduct && productAdditions) {
        onAddAdditionProduct(product.id, menuAddition, productAdditions.length);
      }
    } else if (onDeleteAdditionProduct) {
      onDeleteAdditionProduct(product.id, additionId);
    }
  };

  return (
    <>
      <TitleHeaderModal
        show={show}
        onClose={onClose}
        size="md"
        label="AÑADIR ADICIONES"
      >
        <BoxWrapper>
          {loading && (
            <BoxLoadingWrapper>
              <CircularProgress />
            </BoxLoadingWrapper>
          )}
          <Button
            id="addAddition"
            color="secondary"
            onClick={() => setShowModal(true)}
            startIcon={<AddCircleOutlineIcon />}
          >
            Crear Adición
          </Button>
          <Box mt={2}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" />
                    <TableCell>
                      <Typography variant="h4">
                        <strong>
                          Tipo de adición
                        </strong>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4">
                        <strong>
                          Obligatorio
                        </strong>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4">
                        <strong>
                          Adiciones
                        </strong>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4">
                        <strong>
                          Selección maxima
                        </strong>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4">
                        <strong>
                          Acciones
                        </strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {restaurantAdditions?.map((menuAddition: MenuAddition) => {
                    const productAddition = productAdditions?.find(
                      (a: Addition) => a.menu_additions_id === menuAddition.id,
                    );
                    const sizeOptions = menuAddition?.options?.length || 0;
                    return (
                      <TableRow key={menuAddition.id}>
                        <TableCell padding="checkbox">
                          <CheckboxCustom
                            checked={!!productAddition}
                            onChange={(e:any) => {
                              handleChange(e, menuAddition, productAddition?.id || '');
                            }}
                          />
                        </TableCell>
                        <TableCell>{menuAddition.name}</TableCell>
                        <TableCell>
                          {menuAddition.mandatory ? 'SI' : 'NO'}
                        </TableCell>

                        <TableCell>
                          {menuAddition?.options?.map(
                            (o, i) => `${o.name} (Bs. ${roundTwoDecimals(o.price)})${
                              i === sizeOptions - 1 ? '' : ', '
                            }`,
                          )}
                        </TableCell>
                        <TableCell>{menuAddition.max_selection}</TableCell>
                        <TableCell>
                          <Button
                            id="deleteMenuAddition"
                            color="secondary"
                            onClick={() => setDeleteMenuAdditionId(menuAddition.id)}
                          >
                            Eliminar del restaurant
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </BoxWrapper>
      </TitleHeaderModal>
      <ModalCreateAddition
        show={showModal}
        onClose={() => setShowModal(false)}
        onCreateMenuAdditionsRestaurant={onCreateMenuAdditionsRestaurant}
      />
      <ConfirmModal
        open={!!deleteMenuAdditionId}
        okText="ELIMINAR"
        cancelText="CANCELAR"
        onClose={() => {
          setDeleteMenuAdditionId('');
        }}
        onConfirm={(e:any) => {
          e?.preventDefault();
          deleteAdditionFromRestaurant();
          setDeleteMenuAdditionId('');
        }}
        // eslint-disable-next-line max-len
        title="Esta seguro de eliminar la adición del restaurant? Esta acción eliminara la adición en todos los productos que la usen (Esto puede demorar unos segundos)."
      />
    </>
  );
};

export default ModalPickAddition;
