import { Navigate, Route } from 'react-router-dom';
import React from 'react';
import { LoggedLayout } from '../../components/layouts/loggedLayout';
import Dashboard from '../../pages/dashboard';
import CommandPanel from '../../pages/commandPanel';
import Login from '../../pages/login';
import { LoginLayout } from '../../components/layouts/loginLayout';
import Gallery from '../../pages/gallery';
import Menu from '../../pages/menu';
import Payment from '../../pages/payment';
import Orders from '../../pages/order/[orderStatus]';
import SharedMenu from '../../pages/sharedMenu';
import SharedSectionId from '../../pages/sharedMenu/[sectionId]';
import SharedProductId from '../../pages/sharedMenu/[sectionId]/product/[productId]';
import SubsidiaryMenu from '../../pages/subsidiaryMenu';
import SubsidiarySectionId from '../../pages/subsidiaryMenu/[sectionId]';
import SubsidiaryProductId from '../../pages/subsidiaryMenu/[sectionId]/product/[productId]';
import CashPayment from '../../pages/cashPayment';
import Account from '../../pages/account';
import RoleGuard from '../../components/guards/roleGuard';
import SalesPage from '../../pages/report/sales';
import { ROLES } from '../../core';
import RestaurantProfile from '../../pages/restaurantProfile';
import SubsidiaryInfo from '../../pages/restaurantProfile/subsidiaryInfo';
import Inventory from '../../pages/inventory';
import TurnPage from '../../pages/journey';
import InventoryHistory from '../../pages/inventoryHistory';

const RootRoutes = (
  <Route path="/">
    <Route index element={<Navigate to="/commandPanel" replace />} />
    <Route element={<LoggedLayout />}>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="inventory" element={<Inventory />} />
      <Route path="inventoryHistory" element={<InventoryHistory />} />
      <Route path="commandPanel" element={<CommandPanel />} />
      <Route path="gallery" element={<Gallery />} />
      <Route path="menu" element={<Menu />} />
      <Route path="order/:orderStatus" element={<Orders />} />
      <Route path="performance/salesReport" element={<SalesPage />} />
      <Route path="payment">
        <Route index element={<Payment />} />
        <Route path="cash" element={<CashPayment />} />
      </Route>
      <Route path="sharedMenu">
        <Route index element={<SharedMenu />} />
        <Route path=":sectionId">
          <Route index element={<SharedSectionId />} />
          <Route path="product/:productId">
            <Route index element={<SharedProductId />} />
          </Route>
        </Route>
      </Route>
      <Route path="subsidiaryMenu">
        <Route index element={<SubsidiaryMenu />} />
        <Route path=":sectionId">
          <Route index element={<SubsidiarySectionId />} />
          <Route path="product/:productId">
            <Route index element={<SubsidiaryProductId />} />
          </Route>
        </Route>
      </Route>
      <Route
        path="administrationAccounts"
        element={(
          <RoleGuard roles={[ROLES.OWNER, ROLES.SUPERVISOR]}>
            <Account />
          </RoleGuard>
        )}
      />
      <Route path="restaurantProfile">
        <Route index element={<RestaurantProfile />} />
        <Route path="subsidiary/:subsidiaryId">
          <Route index element={<SubsidiaryInfo />} />
        </Route>
      </Route>
      <Route path="turns">
        <Route index element={<TurnPage />} />

      </Route>
    </Route>
    <Route path="login" element={<LoginLayout />}>
      <Route index element={<Login />} />
    </Route>

  </Route>
);

export default RootRoutes;
