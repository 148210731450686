import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  writeBatch,
  runTransaction,
  DocumentReference,
  DocumentData,
  deleteDoc,
  updateDoc,
  increment,
  Transaction,
} from 'firebase/firestore';
import { getDocId } from '../../config/firebaseService';
import { COLLECTIONS } from '../../constants';
import { Nullable } from '../../types';
import { OrderAdditionSelected } from '../additions';
import { JourneyProduction } from '../journeyProduction';
import {
  JourneyProductionProduct, updateProductsProductionId, verifyProductTransaction,
} from '../journeyProductionProduct';
import { Order } from '../order/order.dto';
import { SelectedProduct } from '../product';
import { OrderProduct } from './orderProduct.dto';
import { getDifference, getDeletes } from './orderProduct.utils';

const db = getFirestore();

export type EditOrderParams = {
    product: Partial<OrderProduct>,
    restaurantId:string;
    subsidiaryId:string;
    orderId:string;
}

export const editOrderProduct = async (
  {
    restaurantId, product, subsidiaryId, orderId,
  }:EditOrderParams,
): Promise<void> => {
  const orderRef = doc(
    db,
    // eslint-disable-next-line max-len
    `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.ORDER}/${orderId}/${COLLECTIONS.ORDER_PRODUCT}/${product.id}`,
  );
  await setDoc(orderRef, product, { merge: true });
};

type setOrderByPathParams = {
  orderProduct: Partial<OrderProduct>,
  path:string;
}

export const setOrderProductByPath = async ({
  orderProduct, path,
}:setOrderByPathParams): Promise<void> => {
  const ref = doc(db, path);
  await setDoc(ref, { ...orderProduct }, { merge: true });
};

export type addOrderParams = {
    orderId: string,
    orderProduct :OrderProduct,
    restaurantId:string;
    subsidiaryId:string;
}
export const addOrderProduct = async ({
  restaurantId, orderProduct, subsidiaryId, orderId,
}:addOrderParams): Promise<string> => {
  // eslint-disable-next-line max-len
  const path = `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.ORDER}/${orderId}/${COLLECTIONS.ORDER_PRODUCT}`;
  const id = getDocId(path);
  const ref = doc(
    db,
    `${path}/${id}`,
  );
  await setDoc(ref, { ...orderProduct.toPlainObject(), id }, { merge: true });
  return id;
};

type AddOrderByPathParams = {
  orderProduct :OrderProduct,
  path:string;
}

export const addOrderProductByPath = async ({
  orderProduct, path,
}:AddOrderByPathParams): Promise<void> => {
  const id = getDocId(path);
  const ref = doc(
    db,
    `${path}/${id}`,
  );
  await setDoc(ref, { ...orderProduct.toPlainObject(), id }, { merge: true });
};

export type getOrderParams = {
    orderId: string,
    restaurantId:string;
    subsidiaryId:string;
    orderProductId: string;
}
export const getOrderProduct = async ({
  orderId, restaurantId, subsidiaryId, orderProductId,
}:getOrderParams):Promise<OrderProduct> => {
  const docRef = doc(
    db,
    // eslint-disable-next-line max-len
    `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.ORDER}/${orderId}/${COLLECTIONS.ORDER_PRODUCT}/${orderProductId}`,
  );
  const querySnapshot = await getDoc(docRef);
  const order = new OrderProduct({ ...querySnapshot.data(), id: querySnapshot.id });
  return order;
};

export const saveProductOrder = async (
  products:SelectedProduct[],
  order: Order,
  restaurantId:string,
  subsidiaryId:string,
  additions:OrderAdditionSelected[],
):Promise<void> => {
  const promises: any[] = [];
  products.forEach((element) => {
    promises.push(addOrderProduct({
      orderId: order.id,
      restaurantId,
      subsidiaryId,
      orderProduct: new OrderProduct({
        product_id: element.id,
        quantity: element.quantity,
        unit_price: element.unit_price,
        index: element.index,
        order_id: order.id,
        name: element.name,
        cooking_time: element.cooking_time,
        selected_options: additions.filter((addition) => addition.product_id === element.id),
        availability: element.availability,
      }),
    }));
  });
  await Promise.all(promises);
};

export type EditManyOrderParams = {
  additions: OrderAdditionSelected[],
  orderProduct:SelectedProduct[],
  oldOrderProduct:OrderProduct[],
  restaurantId:string;
  subsidiaryId:string;
  orderId:string;
}

export const editManyOrderProduct = async (
  {
    restaurantId, subsidiaryId, orderId, additions, orderProduct, oldOrderProduct,
  }:EditManyOrderParams,
): Promise<void> => {
  const newOrderProducts:OrderProduct[] = [];
  const oldOrderProducts: OrderProduct[] = [];
  orderProduct.forEach((element) => {
    const exist = oldOrderProduct.find((old) => old.product_id === element.id);
    if (exist) {
      oldOrderProducts.push(new OrderProduct({
        ...exist,
        quantity: element.quantity,
        selected_options: additions.filter((addition) => addition.product_id === element.id),
      }));
    }
  });
  orderProduct.forEach((element) => {
    const exist = oldOrderProducts.find((old) => old.product_id === element.id);
    if (!exist) {
      newOrderProducts.push(new OrderProduct({
        product_id: element.id,
        quantity: element.quantity,
        unit_price: element.unit_price,
        order_id: orderId,
        index: element.index,
        name: element.name,
        cooking_time: element.cooking_time,
        selected_options: additions.filter((addition) => addition.product_id === element.id),
        availability: element.availability,
      }));
    }
  });

  const batch = writeBatch(db);
  oldOrderProducts.forEach((element) => {
    const orderRef = doc(
      db,
      // eslint-disable-next-line max-len
      `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.ORDER}/${orderId}/${COLLECTIONS.ORDER_PRODUCT}/${element.id}`,
    );
    batch.update(
      orderRef,
      {
        quantity: element.quantity,
        selected_options: element.selected_options,
      },
    );
  });

  newOrderProducts.forEach((element) => {
    // eslint-disable-next-line max-len
    const path = `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.ORDER}/${orderId}/${COLLECTIONS.ORDER_PRODUCT}`;
    const id = getDocId(path);
    const orderRef = doc(
      db,
      // eslint-disable-next-line max-len
      `${path}/${id}`,
    );
    batch.set(
      orderRef,
      { ...element, id },
    );
  });

  await batch.commit();
};

export type getOrderProductsByOrderIdParams = {
  orderId: string,
  restaurantId:string;
  subsidiaryId:string;
}
export const getOrderProductsByOrderId = async ({
  orderId, restaurantId, subsidiaryId,
}:getOrderProductsByOrderIdParams):Promise<OrderProduct[]> => {
  const docRef = collection(
    db,
    // eslint-disable-next-line max-len
    `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.ORDER}/${orderId}/${COLLECTIONS.ORDER_PRODUCT}/`,
  );
  let orders :OrderProduct [] = [];
  const querySnapshot = await getDocs(docRef);
  if (querySnapshot.size) {
    orders = querySnapshot.docs.map((snap) => new OrderProduct({
      ...snap.data(),
      id: snap.id,
    }));
  }
  return orders;
};

type UpdateOrderProductsParams = {
  newOrderProducts: OrderProduct[];
  orderProduct: OrderProduct[];
  restaurantId: string;
  subsidiaryId: string;
  orderId: string;
  journeyProductionId?: string;
  products?: JourneyProductionProduct[] | null;
  isNotPayed?: boolean
};

export const updateOrderProducts = async ({
  newOrderProducts, orderProduct, restaurantId, subsidiaryId, orderId, journeyProductionId, products, isNotPayed = false,
}:UpdateOrderProductsParams):Promise<void> => {
  const path = `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.ORDER}/${orderId}/${COLLECTIONS.ORDER_PRODUCT}`;
  const idKeeps: string[] = [];
  const deletesProducts = getDeletes({ oldOrderProducts: orderProduct, newOrderProducts });
  newOrderProducts.forEach(async (product:OrderProduct) => {
    if (product.id === '') {
      await addOrderProductByPath({ orderProduct: product, path });
      return;
    }
    const oldProduct = orderProduct.find((findProduct:OrderProduct) => findProduct.id === product.id);
    if (!oldProduct) return;
    const updates = getDifference({ oldOrderProduct: oldProduct, newOrderProduct: product });
    if (updates) {
      setOrderProductByPath({
        orderProduct: updates,
        path: `${path}/${product.id}`,
      });
    }
    if (product.id !== '') idKeeps.push(product.id);
    if (isNotPayed) {
      const journeyProductionProductFinded = products?.find((prod:JourneyProductionProduct) => prod.product.id === product.product_id);
      if (journeyProductionProductFinded && journeyProductionId && product.is_discounted_inventory) {
        // eslint-disable-next-line max-len
        const pathIncrement = `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.JOURNEY_PRODUCTION}/${journeyProductionId}/${COLLECTIONS.JOURNEY_PRODUCTION_PRODUCTS}/${journeyProductionProductFinded.id}`;
        const journeyProductionProductRef = doc(db, pathIncrement);
        await updateDoc(journeyProductionProductRef, {
          sales: increment(-product.quantity),
        });
      }
    }
  });
  await Promise.all(deletesProducts.map(async (deleteProduct: OrderProduct) => {
    const orderProductRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SUBSIDIARY, subsidiaryId, COLLECTIONS.ORDER, orderId, COLLECTIONS.ORDER_PRODUCT, deleteProduct.id);
    const journeyProductionProductFinded = products?.find((prod:JourneyProductionProduct) => prod.product.id === deleteProduct.product_id);
    if (journeyProductionProductFinded && journeyProductionId && deleteProduct.is_discounted_inventory) {
      // eslint-disable-next-line max-len
      const pathIncrement = `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.JOURNEY_PRODUCTION}/${journeyProductionId}/${COLLECTIONS.JOURNEY_PRODUCTION_PRODUCTS}/${journeyProductionProductFinded.id}`;
      const journeyProductionProductRef = doc(db, pathIncrement);
      await updateDoc(journeyProductionProductRef, {
        sales: increment(-deleteProduct.quantity),
      });
    }
    await deleteDoc(orderProductRef);
  }));
};

type AddOrderProductsParams = {
  newOrderProducts: OrderProduct[];
  restaurantId: string;
  subsidiaryId: string;
  orderId: string;
  journeyProductionId?: string;
  products?: JourneyProductionProduct[] | null;
  isNotPayed?: boolean;
}

export const addOrderProducts = ({
  newOrderProducts, restaurantId, subsidiaryId, orderId, journeyProductionId, products, isNotPayed = false,
}:AddOrderProductsParams) => {
  const path = `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.ORDER}/${orderId}/${COLLECTIONS.ORDER_PRODUCT}`;
  newOrderProducts.forEach(async (orderProduct:OrderProduct) => {
    await addOrderProductByPath({ orderProduct, path });
    if (isNotPayed) {
      const journeyProductionProductFinded = products?.find((prod:JourneyProductionProduct) => prod.product.id === orderProduct.product_id);
      if (journeyProductionProductFinded && journeyProductionId && orderProduct.is_discounted_inventory) {
        // eslint-disable-next-line max-len
        const pathIncrement = `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.JOURNEY_PRODUCTION}/${journeyProductionId}/${COLLECTIONS.JOURNEY_PRODUCTION_PRODUCTS}/${journeyProductionProductFinded.id}`;
        const journeyProductionProductRef = doc(db, pathIncrement);
        await updateDoc(journeyProductionProductRef, {
          sales: increment(-orderProduct.quantity),
        });
      }
    }
  });
};

export const updateSalesProduct = async (
  productId: string,
  quantity: number,
  subId: string,
  restId: string,
  lastOpenjourneyProduction: Nullable<JourneyProduction>,
  journeyProductionProduct: Record<string, JourneyProductionProduct>,
) => {
  if (subId && restId) {
    const productionProductSub = journeyProductionProduct[productId];
    if (productionProductSub && lastOpenjourneyProduction) {
      updateProductsProductionId(restId, subId, lastOpenjourneyProduction.id, productionProductSub.id, { sales: Number(productionProductSub.sales) });
    }
  }
};

type DataProps = {
  journeyProductionRef: DocumentReference<DocumentData>,
  newSales: number,
  productId?: string,
  status?: boolean,
}

const updateDocumentTransaction = async (isSuccess: boolean[], data: DataProps[], transaction: Transaction) => {
  const object: Record<string, string> = {};
  data?.forEach((item) => {
    if (!item.status && item.productId) {
      object[item.productId] = item.productId;
    }
  });
  if (isSuccess.length > 0 && !isSuccess.includes(false)) {
    await Promise.all(data.map(async (productUpdate) => {
      if (productUpdate.journeyProductionRef) {
        transaction.update(productUpdate.journeyProductionRef, { sales: productUpdate.newSales });
      }
    }));
    return { isSuccess: true };
  }
  return { isSuccess: false, failedProduct: object };
};

export const deleteProductTransaction = async (
  restId: string,
  subId: string,
  journeyProductId: string,
  journeyProductionProduct: Record<string, JourneyProductionProduct>,
  productsProduction: Nullable<(string | number)[][]>,
) => {
  if (restId && subId && productsProduction) {
    const resultTransaction = await runTransaction(db, async (transaction):Promise<boolean> => {
      const results = await Promise.all(productsProduction?.map(async (product) => {
        const productData = journeyProductionProduct[product[0]];
        const journeyProductionRef = doc(
          db,
          COLLECTIONS.RESTAURANT,
          restId,
          COLLECTIONS.SUBSIDIARY,
          subId,
          COLLECTIONS.JOURNEY_PRODUCTION,
          journeyProductId,
          COLLECTIONS.JOURNEY_PRODUCTION_PRODUCTS,
          productData.id,
        );

        const productDB = await transaction.get(journeyProductionRef);
        const productItem = new JourneyProductionProduct({ ...productDB.data() });
        const newSales = productItem.sales - Number(product[1]);
        if (newSales >= 0) {
          transaction.update(journeyProductionRef, { sales: newSales });
          return true;
        }
        return false;
      }));
      return results.every((item) => item);
    });
    return resultTransaction;
  }
  return false;
};

export const orderTransactions = async (
  restId: string,
  subId: string,
  before: Nullable<(string | number)[][]>,
  after: Nullable<(string | number)[][]>,
  lastOpenjourneyProductionId: string,
  journeyProductionProduct: Record<string, JourneyProductionProduct>,
) => {
  const resultTransaction = await runTransaction(db, async (transaction) => {
    const isSuccess: boolean[] = [];
    const data: DataProps[] = [];
    if (after && after?.length > 0) {
      await Promise.all(after.map(async (product) => {
        const productExists = before?.filter((productAfter) => productAfter[0] === product[0]);
        if (productExists && productExists?.length > 0) {
          const productionProduct = journeyProductionProduct[product[0]];
          const newStock = Number(product[1]) - Number(productExists[0][1]);
          const result = await verifyProductTransaction(restId, subId, lastOpenjourneyProductionId, productionProduct.id, transaction, newStock);
          isSuccess.push(result.isSuccess);
          data.push(result.data);
        } else {
          const productionProduct = journeyProductionProduct[product[0]];
          const result = await verifyProductTransaction(restId, subId, lastOpenjourneyProductionId, productionProduct.id, transaction, Number(product[1]));
          isSuccess.push(result.isSuccess);
          data.push(result.data);
        }
      }));
    }
    const resultUpdate = await updateDocumentTransaction(isSuccess, data, transaction);
    return resultUpdate;
  });
  return resultTransaction;
};

type DeleteOrderProduct = {restaurantId:string, subsidiaryId:string, orderId:string, orderProductId:string}
export const deleteOrderProduct = async (params:DeleteOrderProduct) => {
  const orderProductRef = doc(
    db,
    COLLECTIONS.RESTAURANT,
    params.restaurantId,
    COLLECTIONS.SUBSIDIARY,
    params.subsidiaryId,
    COLLECTIONS.ORDER,
    params.orderId,
    COLLECTIONS.ORDER_PRODUCT,
    params.orderProductId,
  );
  await deleteDoc(orderProductRef);
};
