import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from 'firebase/firestore';
import { getDocId } from '../../config/firebaseService';
import { COLLECTIONS } from '../../constants';
import { Profile } from '../profile';
import { editSubsidiary, Subsidiary } from '../subsidiary';
import { Order } from './order.dto';

const db = getFirestore();

type queryParams= {
  restaurantId:string;
  subsidiaryId:string;
}

export const getOrderSubsidiaryCodeBase = (
  subsidiaryIid:string,
  ordersLength:number,
) => `${subsidiaryIid.substring(0, 2).toUpperCase()}-${ordersLength}`;

export const createOrderSubsidiaryCode = async (params:queryParams) => {
  const docRef = doc(
    db,
    // eslint-disable-next-line max-len
    `${COLLECTIONS.RESTAURANT}/${params.restaurantId}/${COLLECTIONS.SUBSIDIARY}/${params.subsidiaryId}/`,
  );
  const querySnapshot = await getDoc(docRef);
  const subsidiary = new Subsidiary({ ...querySnapshot.data(), id: querySnapshot.id });

  const ordersLength = subsidiary.orders_number + 1;

  await editSubsidiary({
    restaurantId: params.restaurantId,
    subsidiaryId: params.subsidiaryId,
    subsidiary: { orders_number: ordersLength },
  });
  return getOrderSubsidiaryCodeBase(subsidiary.id, ordersLength);
};

type EditOrderParams = {
    order: Partial<Order>,
    restaurantId:string;
    subsidiaryId:string;
    orderId:string;
}

export const editOrder = async (
  {
    restaurantId, order, subsidiaryId, orderId,
  }:EditOrderParams,
): Promise<void> => {
  const orderRef = doc(
    db,
    // eslint-disable-next-line max-len
    `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.ORDER}/${orderId}`,
  );
  if (order.canceled_by instanceof Profile) {
    await setDoc(
      orderRef,
      {
        ...order,
        canceled_by: order.canceled_by?.toPlainObject(),
      },

      { merge: true },
    );
  } else {
    await setDoc(
      orderRef,
      order,

      { merge: true },
    );
  }
};

type addOrderParams = {
  order: Order,
  restaurantId:string;
  subsidiaryId:string;
}

export const orderRef = (restId: string, subId: string) => collection(db, `${COLLECTIONS.RESTAURANT}/${restId}/${COLLECTIONS.SUBSIDIARY}/${subId}/${COLLECTIONS.ORDER}`);

export const addOrder = async ({
  restaurantId,
  order,
  subsidiaryId,
}:addOrderParams): Promise<string> => {
  const path = `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.ORDER}`;
  const id = getDocId(path);
  const ref = doc(
    db,
    `${path}/${id}`,
  );
  await setDoc(ref, { ...order.toPlainObject(), id }, { merge: true });
  return id;
};

type setOrderParams = {
  order: Order,
  restaurantId:string;
  subsidiaryId:string;
}

export const setOrder = async ({
  restaurantId,
  order,
  subsidiaryId,
}:setOrderParams): Promise<string> => {
  const path = `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.ORDER}`;
  const id = order?.id === '' ? getDocId(path) : order?.id;
  const ref = doc(db, `${path}/${id}`);
  await setDoc(ref, { ...order.toPlainObject(), id }, { merge: true });
  return id;
};

export const deleteOrder = async (
  id:string,
  restaurantId:string,
  subsidiaryId:string,
) => {
  if (id) {
    const pathCollectionOrderProduct = `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.ORDER}/${id}/${COLLECTIONS.ORDER_PRODUCT}`;
    const docsRef = collection(db, pathCollectionOrderProduct);
    (await getDocs(docsRef)).docs.map((async (orderProduct) => {
      await deleteDoc(doc(db, pathCollectionOrderProduct, orderProduct.data().id));
    }));
    await deleteDoc(doc(db, `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.ORDER}`, id));
  }
};
