import { collection, getFirestore, doc } from 'firebase/firestore';
import { Nullable } from '../../types';

export const getCollection = async (collectionRef:any, type:any = null) => {
  const collectionDocs = await collectionRef.get();
  if (collectionDocs.empty) {
    return [];
  }
  return collectionDocs.docs.map((document:any) => {
    const data = { ...document.data(), id: document.id };
    if (type) {
      // eslint-disable-next-line new-cap
      return new type(data);
    }
    return data;
  });
};

export const getDocument = async <T>(documentRef:any, type:any = null):Promise<Nullable<T>> => {
  const document = await documentRef.get();
  if (document.exists) {
    const data = { ...document.data(), id: document.id };
    if (type) {
      // eslint-disable-next-line new-cap
      return new type(data);
    }
    return data;
  }
  return null;
};

export function getDocId(nameCollection:string) {
  const db = getFirestore();
  const ref = collection(db, nameCollection);
  return doc(ref).id;
}
