import { Constructor } from '../../types';
import { FirebaseBase } from '../firebaseBase';
import { Photo } from '../photo';

export class Gallery extends FirebaseBase {
  title: string;

  restaurant_id: string;

  extension: string;

  image: Photo;

  original_image: string;

  constructor({
    title = '',
    restaurant_id = '',
    extension = '',
    original_image = '',
    image = new Photo({}),
    ...data
  }:Constructor<Gallery>) {
    super(data);
    this.title = title;
    this.restaurant_id = restaurant_id;
    this.extension = extension;
    this.original_image = original_image;
    this.image = image;
  }
}
