import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getSharedProductsPerRestaurant, MenuSection, Restaurant } from '../core';
import { getDate } from './date';
import { LogoYellowXpress } from '../assets';

const imageToBase64 = require('image-to-base64/browser');

type dataReportProps = {
  section: string, product: string, price: number, description: string
};

const getDataMenu = async (restaurant:Restaurant) => {
  const menuSections = _.orderBy(restaurant.menu_section, 'name', 'asc');
  const products = await getSharedProductsPerRestaurant(restaurant.id);
  const dataReport:dataReportProps[] = [];
  menuSections.forEach((menuSection:MenuSection) => {
    const menuSectionProducts = products.filter(
      (product) => product.menu_section_id === menuSection.id,
    );
    menuSectionProducts.forEach((product) => {
      dataReport.push({
        section: menuSection.name,
        product: product.name,
        price: product.unit_price,
        description: product.description.replace(/\n/g, ' '),
      });
    });
  });
  return dataReport;
};
export const exportToXlsxConbrix = async (restaurant: Restaurant, columnsValues: string[]) => {
  const menuData = await getDataMenu(restaurant);
  const logoBase64 = await imageToBase64(LogoYellowXpress);
  const columnsTypes = [
    { key: 'section', width: 30 },
    { key: 'product', width: 30 },
    { key: 'price', width: 10 },
    { key: 'description', width: 50 },
    { width: 50 },
    { width: 30 },
  ];
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('TABLA GENERAL');

  worksheet.mergeCells('A1', 'A9');
  const logo = workbook.addImage({
    base64: logoBase64,
    extension: 'png',
  });
  worksheet.addImage(logo, 'A1:A9');

  worksheet.mergeCells('B1', 'D1');
  worksheet.getCell('B1').value = 'XPRESS TECHNOLOGIES';
  worksheet.getCell('B1').font = { bold: true, size: 14 };

  worksheet.mergeCells('B2', 'D2');
  worksheet.getCell('B2').value = 'MENÚ GENERAL';
  worksheet.getCell('B2').font = { bold: true, size: 13 };

  worksheet.mergeCells('B4', 'D4');
  worksheet.getCell(
    'B4',
  ).value = `Fecha de registro: ${moment(getDate(restaurant.created_at)).format('DD/MM/YYYY')}`;
  worksheet.getCell('B4').font = { bold: true };

  worksheet.mergeCells('B6', 'D6');
  worksheet.getCell('B6').value = `Nombre del comercio: ${restaurant.name}`;
  worksheet.getCell('B6').font = { bold: true };

  worksheet.mergeCells('E2', 'F2');
  worksheet.getCell('F2').style = {
    border: {
      top: { style: 'thick', color: { argb: '6aa84f' } },
      right: { style: 'thick', color: { argb: '6aa84f' } },
    },
  };
  worksheet.getCell('E2').value = 'IMÁGENES DE MENÚ';
  worksheet.getCell('E2').style = {
    font: { bold: true, size: 14 },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '6aa84f' } },
    border: {
      top: { style: 'thick', color: { argb: '6aa84f' } },
      right: { style: 'thick', color: { argb: '6aa84f' } },
      left: { style: 'thick', color: { argb: '6aa84f' } },
    },
  };

  worksheet.mergeCells('E3', 'F3');
  worksheet.getCell('E3').value = '❌ NO ADJUNTAR IMÁGENES A ESTE EXCEL';
  worksheet.getCell('E3').style = {
    font: { bold: true },
    border: {
      left: { style: 'thick', color: { argb: '6aa84f' } },
    },
  };
  worksheet.getCell('F3').style = {
    border: {
      right: { style: 'thick', color: { argb: '6aa84f' } },
    },
  };

  worksheet.mergeCells('E4', 'F4');
  worksheet.getCell('E4').value = '✅ Adjuntar imágenes al correo electrónico';
  worksheet.getCell('E4').style = {
    font: { bold: true },
    border: {
      left: { style: 'thick', color: { argb: '6aa84f' } },
    },
  };
  worksheet.getCell('F4').style = {
    border: {
      right: { style: 'thick', color: { argb: '6aa84f' } },
    },
  };

  worksheet.mergeCells('E5', 'F5');
  worksheet.getCell('E5').value = '✅ Imágenes son muy necesarias para la visibilidad y tráfico de clientes en la app';
  worksheet.getCell('E5').style = {
    font: { bold: true },
    border: {
      left: { style: 'thick', color: { argb: '6aa84f' } },
    },
  };
  worksheet.getCell('F5').style = {
    border: {
      right: { style: 'thick', color: { argb: '6aa84f' } },
    },
  };

  worksheet.mergeCells('E6', 'F6');
  worksheet.getCell('E6').value = '✅ Colocar los nombres de productos a sus respectivas imágenes';
  worksheet.getCell('E6').style = {
    font: { bold: true },
    border: {
      left: { style: 'thick', color: { argb: '6aa84f' } },
    },
  };
  worksheet.getCell('F6').style = {
    border: {
      right: { style: 'thick', color: { argb: '6aa84f' } },
    },
  };

  worksheet.mergeCells('E7', 'F7');
  worksheet.getCell('E7').value = '✅ Incluir imágenes en formato .jpg, .jpeg ó .png';
  worksheet.getCell('E7').style = {
    font: { bold: true },
    border: {
      left: { style: 'thick', color: { argb: '6aa84f' } },
      bottom: { style: 'thick', color: { argb: '6aa84f' } },
    },
  };
  worksheet.getCell('F7').style = {
    border: {
      right: { style: 'thick', color: { argb: '6aa84f' } },
      bottom: { style: 'thick', color: { argb: '6aa84f' } },
    },
  };

  worksheet.getRow(10).values = columnsValues;
  worksheet.columns = columnsTypes;
  worksheet.getCell('A10').style = {
    font: { bold: true, size: 14 },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '6aa84f' },
    },
    border: {
      top: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
    },
  };
  worksheet.getCell('B10').style = {
    font: { bold: true, size: 14 },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '6aa84f' },
    },
    border: {
      top: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
    },
  };
  worksheet.getCell('C10').style = {
    font: { bold: true, size: 14 },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '6aa84f' },
    },
    border: {
      top: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
    },
  };
  worksheet.getCell('D10').style = {
    font: { bold: true, size: 14 },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '6aa84f' },
    },
    border: {
      top: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
    },
  };

  worksheet.addRows(menuData);

  const buf = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buf]), `menu_${restaurant.name}.xlsx`);
};
