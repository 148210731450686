import 'react-phone-input-2/lib/material.css';
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  Grid,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { useMemo } from 'react';
import { Journey } from '../../core';
import { BaseModal } from './baseModal';

type DialogProps = {
  open: boolean;
  onClose: () => void;
  journey: Journey;
  onEdit: (user: Journey) => void;
  onSave: (user: Journey) => void;
  submitting: boolean;
};

const CustomContainer = styled(Box)(() => ({
  paddingTop: '2px',
}));

const CustomTitle = styled(Typography)(() => ({
  paddingTop: '2px',
}));

export const NewJourneyModal = ({
  open,
  onClose,
  journey,

  onEdit,
  onSave,
  submitting,
}: DialogProps) => {
  const content = useMemo(() => {
    if (submitting && journey.id) {
      return (
        <Grid item container justifyContent="center">
          <CircularProgress color="success" />
        </Grid>
      );
    }
    return (
      <Grid item container justifyContent="center">
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          disabled={submitting}
        >
          {submitting ? (
            <CircularProgress color="success" />
          ) : (
            'Guardar'
          )}
        </Button>
        {journey.id && (
          <Box ml={5}>
            <Button
              variant="contained"
              color="primary"
              type="button"
              disabled={submitting}
              onClick={onClose}
            >
              Cancelar
            </Button>
          </Box>
        )}
      </Grid>
    );
  }, [submitting, journey]);

  return (
    <BaseModal open={open} size="xs" onClose={onClose} fullWidth>
      <CustomContainer>
        <Grid sx={{ textAlign: 'center' }}>

          <CustomTitle variant="h5">
            {journey.id ? 'Actualizar turno' : 'Crear turno'}
          </CustomTitle>
          <CardContent>

            <Box my={3} width="100%">
              <Grid item>
                <Formik
                  initialValues={{
                    name: journey.name || '',
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required('Nombre es requerido'),
                  })}
                  onSubmit={async (values) => {
                    if (journey.id) {
                      const userData = new Journey({
                        id: journey.id,

                        name: values.name,
                      });
                      onEdit(userData);
                    } else {
                      const userData = new Journey({
                        name: values.name,
                      });

                      onSave(userData);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>

                      <Grid item>

                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label="Nombre de turno"
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          required
                          variant="outlined"
                        />

                      </Grid>

                      <Box mt={2}>
                        {content}
                      </Box>

                    </form>
                  )}
                </Formik>
              </Grid>
            </Box>

          </CardContent>

        </Grid>
      </CustomContainer>
    </BaseModal>
  );
};
