import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';
import { Product } from '../../../core';
import { Nullable } from '../../../types';

type ProductState = {
  products:Nullable<Product[]>
}

const initialState: ProductState = { products: null };

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProducts: (state: Draft<ProductState>, action: PayloadAction<Nullable<Product[]>>) => {
      state.products = action.payload;
    },
  },
});

export const { setProducts } = productSlice.actions;
export default productSlice.reducer;
export const productReducerTransform = createTransform(
  (toSave:ProductState) => ({
    ...toSave,
  }),
  (toRehydrated:ProductState) => ({
    ...initialState,
    ...toRehydrated,
    products: toRehydrated.products ? toRehydrated.products.map((item) => new Product(item)) : null,
  }),
  { whitelist: ['product'] },
);
