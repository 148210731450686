import React from 'react';
import { styled, Box } from '@mui/material';
import { HeadTitle } from '../../components';
import { DashboardScreen } from '../../screens';

const ContainerMain = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Dashboard = () => (
  <>
    <HeadTitle title="Dashboard" />
    <ContainerMain>
      <DashboardScreen />
    </ContainerMain>
  </>
);

export default Dashboard;
