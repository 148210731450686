import { Environment } from './type';
import DevEnv from './dev';
import TestEnv from './test';
import ProductionEnv from './production';
import StagingEnv from './staging';

const Environments: Record<'dev' | 'test' | 'staging'|'production', Environment> = {
  dev: DevEnv,
  test: TestEnv,
  staging: StagingEnv,
  production: ProductionEnv,
};

export default Environments.production;
