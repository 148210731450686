import { ORDER_TYPE, STATUS } from './order.enum';

const TRANSLATE_ORDER_TYPE = {
  DELIVERY_ENTREGAS: 'Delivery Entregas',
  DELIVERY_OTHER: 'Otro Delivery',
  PICK_UP: 'Recojo',
  LOCAL: 'Local',
};

const TRANSLATE_ORDER_STATUS = {
  ON_CART: 'En Carrito',
  IN_PROCESS: 'En Proceso',
  ACCEPTED: 'Aceptado',
  READY_TO_PICK: 'Listo Para Recoger',
  READY_TO_CASHIER: 'Listo Para Cajero',
  COMPLETED: 'Completado',
  CANCELED: 'Cancelado',
  ON_HOLD: 'En Espera',
  SENDED: 'Enviado',
};

export const getOrderType = (orderType: ORDER_TYPE) => TRANSLATE_ORDER_TYPE[orderType];
export const getOrderStatus = (orderStatus: STATUS) => TRANSLATE_ORDER_STATUS[orderStatus];
