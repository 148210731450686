import React, { useMemo } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  Box, styled,
} from '@mui/material';
import useWindowDimensions from '../../appHooks/useWindowDimensions';

const Dot = styled(Box)`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  cursor: pointer;
`;
type CustomDotProps = { onClick: any; active: any; };

const CustomDot = ({ onClick, active }: CustomDotProps) => (
  <Dot
    sx={active ? { backgroundColor: '#3D3834' } : { backgroundColor: '#7a7a7a' }}
    onClick={() => onClick()}
    style={{ margin: '8px 0px' }}
  />
);

type HorizontalCarouselProps = {
  children:React.ReactNode,
  sideBarWidth: number,
  slideWidth: number
}

export const CarouselWithDots = ({
  children, sideBarWidth = 550, slideWidth = 165,
}:HorizontalCarouselProps) => {
  const window = useWindowDimensions();

  const responsive = useMemo(() => {
    let countOfItems = 5;
    let partialVisibilityGutter = 0;
    if (window) {
      let dots = (window.width - sideBarWidth) / slideWidth;
      dots = Number(Math.floor(dots));
      partialVisibilityGutter = ((window.width - sideBarWidth) - (slideWidth * dots)) / 8;
      countOfItems = dots > 1 ? dots : 1;
    }
    return {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 0 },
        items: countOfItems,
        slidesToSlide: countOfItems,
        partialVisibilityGutter,
      },
    };
  }, [window, sideBarWidth, slideWidth]);

  return (
    <Carousel
      responsive={responsive}
      showDots
      arrows={false}
      partialVisible
      // @ts-ignore
      customDot={<CustomDot />}
      dotListClass="dot-list"
    >
      {children}
    </Carousel>
  );
};
