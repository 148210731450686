import 'react-phone-input-2/lib/material.css';
import {
  Box, Button, CardContent, CircularProgress, Grid, styled, TextField, Typography,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Profile, ROLES, Subsidiary } from '../../core';
import { BaseModal } from './baseModal';

   type DialogProps = {
    open: boolean;
    onClose: () => void;
    user:Profile;
    subsidiaries : Subsidiary[]
    onEdit:(user:Profile)=>void;
    onSave:(user:Profile)=>void;
    submitting:boolean;
    restaurantId:string;
  };

const CustomContainer = styled(Box)(() => ({
  paddingTop: '2px',
}));

const CustomTitle = styled(Typography)(() => ({
  paddingTop: '2px',
}));

const roles = [
  {
    value: ROLES.CASHIER,
    label: 'Cajero',
  },
  {
    value: ROLES.SUPERVISOR,
    label: 'Supervisor',
  },
];

export const NewUserModal = ({
  open,
  onClose,
  user,
  subsidiaries,
  onEdit,
  onSave,
  submitting,
  restaurantId,
}: DialogProps) => {
  const [limit, setLimit] = useState(4);
  const [currentRole, setCurrentRole] = useState(
    user.roles ? user.roles[0] : ROLES.CASHIER,
  );
  const [phoneNumber, setPhoneNumber] = useState(user.phone_number);

  useEffect(() => {
    if (user.roles) {
      if (typeof user.roles === 'string') {
        setCurrentRole(ROLES.CASHIER);
        setLimit(4);
      } else if (user.roles[0] === ROLES.CASHIER) {
        setLimit(4);
      } else {
        setLimit(5);
      }
    }
  }, [user]);
  return (
    <BaseModal open={open} size="sm" onClose={onClose} fullWidth>
      <CustomContainer>
        <Grid container sx={{ textAlign: 'center' }}>
          <Grid item xs={12}>
            <CustomTitle variant="h5">{user.id ? 'Actualizar Perfil' : 'Crear nuevo perfil'}</CustomTitle>
            <CardContent>
              <Grid container>
                <Box my={3} width="100%">
                  <Grid item container>
                    <Formik
                      initialValues={{
                        full_name: user.full_name || '',
                        phone_number: user.phone_number || '',
                        email: user.email || '',
                        roles: currentRole || ROLES.CASHIER,
                        ci: user.ci || '',
                        subsidiary_id: user.subsidiary_id || subsidiaries[0].id,
                        code: user.code || '',
                      }}
                      validationSchema={Yup.object().shape({
                        full_name: Yup.string()
                          .max(455)
                          .required(
                            'Nombre es requerido',
                          ),

                        phone_number: Yup.string().max(255),
                        email: Yup.string()
                          .email('Email no valido')
                          .max(255),
                        roles: currentRole === ROLES.OWNER ? Yup.mixed()
                          : Yup.mixed()
                            .oneOf([ROLES.CASHIER, ROLES.SUPERVISOR])
                            .required('Rol requerido'),
                        subsidiary_id: currentRole === ROLES.CASHIER
                          ? Yup.string().required(
                            'Seleccione una sucursal',
                          )
                          : Yup.string(),

                        code: Yup.string()
                          .length(
                            limit,
                            `Código debe tener exactamente ${limit} caracteres`,
                          )
                          .required('Código de acceso es requerido'),
                        ci: Yup.string().max(255),
                      })}
                      onSubmit={async (values) => {
                        if (user.id) {
                          const userData = new Profile({
                            id: user.id,
                            phone_number: phoneNumber || '',
                            full_name: values.full_name,
                            roles:
                            values.roles === ROLES.CASHIER
                              ? [ROLES.CASHIER]
                              : [ROLES.SUPERVISOR],
                            subsidiary_id: values.subsidiary_id,
                            email: values.email,
                            code: `${values.code}`,
                            ci: values.ci,
                            created_at: user.created_at,
                            restaurant_id: restaurantId,
                          });
                          onEdit(userData);
                        } else {
                          const userData = new Profile({
                            phone_number: phoneNumber || '',
                            full_name: values.full_name,
                            roles: [values.roles],
                            subsidiary_id: values.subsidiary_id,
                            email: values.email,
                            code: `${values.code}`,
                            ci: values.ci,
                            restaurant_id: restaurantId,
                          });

                          onSave(userData);
                        }
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        touched,
                        values,
                      }) => (
                        <form
                          noValidate
                          onSubmit={handleSubmit}
                        >
                          <div>
                            <div>
                              <Grid container>
                                <Grid item md={9} xs={12}>
                                  <Grid container spacing={3}>
                                    <Grid item md={11} xs={12}>
                                      <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                          <TextField
                                            error={Boolean(
                                              touched.full_name && errors.full_name,
                                            )}
                                            fullWidth
                                            helperText={
                                  touched.full_name && errors.full_name
                                }
                                            label="Nombre completo"
                                            name="full_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.full_name}
                                            required
                                            variant="outlined"
                                          />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                          <TextField
                                            error={Boolean(touched.email && errors.email)}
                                            fullWidth
                                            helperText={touched.email && errors.email}
                                            label="Email"
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            required
                                            value={values.email}
                                            variant="outlined"
                                            disabled={
                                                currentRole === ROLES.OWNER
                                              }
                                          />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                          <TextField
                                            error={Boolean(touched.ci && errors.ci)}
                                            fullWidth
                                            helperText={touched.ci && errors.ci}
                                            label="Carnet"
                                            name="ci"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            required
                                            value={values.ci}
                                            variant="outlined"
                                          />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                          <PhoneInput
                                            specialLabel="Numero de teléfono"
                                            inputProps={{
                                              placeholder: 'Numero de teléfono',
                                              required: true,
                                              name: 'phone_number',
                                              autoFocus: true,
                                              label: 'Numero de teléfono',
                                            }}
                                            country="bo"
                                            inputStyle={{ width: '100%', height: '55px' }}
                                            regions={['america', 'europe']}
                                            value={
                                  values.phone_number.includes('+')
                                    ? values.phone_number
                                    : `+${values.phone_number}`
                                }
                                            onChange={setPhoneNumber}
                                          />
                                        </Grid>

                                        <Grid sx={{ paddingTop: '20px', paddingBottom: '20px' }} item md={6} xs={12}>
                                          <TextField
                                            error={Boolean(
                                              touched.code
                                    && errors.code,
                                            )}
                                            fullWidth
                                            helperText={
                                  touched.code
                                  && errors.code
                                }
                                            type="number"
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            label="Código de acceso"
                                            name="code"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.code}
                                            variant="outlined"
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                  {currentRole !== ROLES.OWNER && (
                                    <Grid container spacing={3}>
                                      <Grid item md={12} xs={12}>
                                        <TextField
                                          fullWidth
                                          name="roles"
                                          onChange={(e) => {
                                            if (e.target.value === ROLES.CASHIER) {
                                              setLimit(4);
                                            } else {
                                              setLimit(5);
                                            }
                                            setCurrentRole(e.target.value as ROLES);

                                            handleChange(e);
                                          }}
                                          select
                                          label="Perfil"
                                          SelectProps={{ native: true }}
                                          value={values.roles}
                                          onBlur={handleBlur}
                                          variant="outlined"
                                          error={Boolean(touched.roles && errors.roles)}
                                          helperText={touched.roles && errors.roles}
                                        >
                                          {roles.map(({ value, label }) => (
                                            <option key={value} value={value}>
                                              {label}
                                            </option>
                                          ))}
                                        </TextField>
                                      </Grid>
                                      {currentRole !== ROLES.SUPERVISOR && (
                                      <Grid item md={12} xs={12}>
                                        <TextField
                                          fullWidth
                                          name="subsidiary_id"
                                          select
                                          SelectProps={{ native: true }}
                                          label="Subsidiaria"
                                          value={values.subsidiary_id}
                                          onChange={(e) => {
                                            handleChange(e);
                                            if (e.target.value === '') {
                                              setFieldValue('subsidiary_id', '');
                                            }
                                          }}
                                          variant="outlined"
                                          error={Boolean(
                                            touched.subsidiary_id && errors.subsidiary_id,
                                          )}
                                          helperText={
                                  touched.subsidiary_id && errors.subsidiary_id
                                }
                                        >
                                          {subsidiaries.map(({ id, name }) => (
                                            <option key={id} value={id}>
                                              {name }
                                            </option>
                                          ))}
                                        </TextField>

                                      </Grid>
                                      )}
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              <Box mt={2}>
                                <Grid item container justifyContent="center">
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {submitting ? <CircularProgress color="success" /> : 'Guardar' }
                                  </Button>
                                  {user.id && (
                                  <Box ml={5}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      type="button"
                                      disabled={isSubmitting}
                                      onClick={onClose}
                                    >
                                      Cancelar
                                    </Button>
                                  </Box>
                                  )}
                                </Grid>
                              </Box>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </Grid>
                </Box>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </CustomContainer>
    </BaseModal>
  );
};
