import React, { useState } from 'react';
import {
  Box, Typography, TextField, Stack, IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';

const EditOutlinedIconCustom = styled(EditOutlinedIcon)`
  cursor: pointer;
  height: 20px;
`;

const CheckOutlinedIconCustom = styled(CheckOutlinedIcon)`
  cursor: pointer;
  height: 20px;
`;

const TextFieldCustom = styled(TextField)`
  & .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input: {
    height: 20px;
    font-size: 14px;
  }
`;

const DeleteOutlineIconCustom = styled(DeleteOutlineIcon)`
  margin-left: 2px;
  cursor: pointer;
  height: 20px;
`;

const CloseIconCustom = styled(CloseIcon)`
  margin-left: 2px;
  cursor: pointer;
  height: 20px;
`;

type TextHandleEdit = {
  param: string,
  value: string,
  id: string,
}

type TextFieldEditIconProps = {
  text: string,
  type: string,
  disabled?: boolean,
  helperText?: string,
  textFieldWidth?: number,
  handleEdit: ({ param, value, id }: TextHandleEdit) => void,
  param: string,
  id: string,
  textDefault: string,
  sales?: number,
  discount?: number,
  initial?: number,
  maxValue?: number,
};

export const TextFieldEditIcon = ({
  text, type, param, disabled, helperText, textFieldWidth, handleEdit, id, textDefault, sales, discount, initial, maxValue = 100000,
}: TextFieldEditIconProps) => {
  const [flag, setFlag] = useState(false);
  const [value, setValue] = useState(text);
  const [error, setError] = useState<null | string>(null);
  const currentValue = text;

  const handleEditValue = () => {
    handleEdit({ param, value, id });
    setFlag(!flag);
  };

  const handleReset = () => {
    setFlag(true);
    if (sales && discount && initial) {
      const ingresoReset = (sales + discount) - initial;
      if (ingresoReset <= 0) {
        setValue(textDefault);
      } else {
        setValue(ingresoReset.toString());
      }
    }
    setValue(textDefault);
  };

  const handleCanceled = () => {
    setFlag(!flag);
    setValue(currentValue);
  };

  return (
    <Box width="auto" display="flex" justifyContent="flex-end">
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {flag && (
          <Stack direction="column" spacing={0.5}>
            <TextFieldCustom
              style={{ width: `${textFieldWidth}px` }}
              size="small"
              value={value}
              type={type}
              disabled={disabled}
              helperText={helperText}
              error={Boolean(error)}
              onChange={(e) => {
                if (Number(e.target.value) < 0) {
                  setError('El valor debe ser positivo');
                } else if (Number(e.target.value) >= maxValue) {
                  setError('El valor debe ser menor al stock');
                } else {
                  setError(null);
                  setValue(e.target.value);
                }
              }}
            />
            {error && <Typography fontSize="8px" sx={{ color: 'red' }}>{error}</Typography>}
          </Stack>
        )}
        {!flag && <Typography fontSize="14px">{value}</Typography>}
        <Box
          // ml={1}
          display="flex"
          alignItems="center"
        >
          {!flag && <EditOutlinedIconCustom onClick={() => setFlag(!flag)} color="secondary" />}
          {flag && (
            <>
              <IconButton onClick={handleEditValue} disabled={Boolean(error)}>
                <CheckOutlinedIconCustom color="secondary" />
              </IconButton>
              <IconButton onClick={handleCanceled}>
                <CloseIconCustom color="primary" />
              </IconButton>
            </>
          )}
          <DeleteOutlineIconCustom onClick={handleReset} color="primary" />
        </Box>
      </Box>
    </Box>
  );
};
