import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { COLOR, VARIANT, SIZE } from './enum';

const CustomizedButton = styled(Button)`
  padding-inline: 45px;
  padding-block: 10px;
  border-radius: 10px;
  font-weight: bold;
  @media (max-width: 450px) {
    padding-inline: 31px;
    padding-block: 6px;
  }
`;

interface ButtonProps {
  color?: COLOR;
  label: string;
  variant?: VARIANT;
  disabled?: boolean;
  className?: string;
  type?:'button' | 'submit' | 'reset' | undefined;
  fullWidth?: boolean;
  id?: string;
  size?: SIZE;
  onClick: () => void;
  style?: React.CSSProperties;
  ref?: React.RefObject<HTMLButtonElement>;
}

export const RoundedButton = ({
  color,
  label,
  variant,
  disabled,
  className,
  fullWidth,
  id,
  size,
  type,
  onClick,
  style,
  ref,
}: ButtonProps) => (
  <CustomizedButton
    ref={ref}
    id={id}
    className={className}
    color={color}
    size={size}
    onClick={onClick}
    variant={variant}
    disabled={disabled}
    type={type}
    fullWidth={fullWidth}
    style={style}
  >
    {label}
  </CustomizedButton>
);
