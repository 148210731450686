import { getMetadata, getStorage, ref } from 'firebase/storage';
import { Photo } from '../core';

type ImageSizes= {
  minWidth: number;
  minHeight: number;
}

type ValidateOptions = {
  maxWidth: boolean;
  minWidth: boolean;
  maxHeight: boolean;
  minHeight: boolean;
  errorMessage: boolean;
  error: boolean;
  message: string;
};

export const validateImageSizes = (
  blobFile:string,
  {
    minWidth, minHeight,
  }:ImageSizes,
  setCallback:(value:ValidateOptions)=>void,
) => {
  const img = new Image();
  let isValid:ValidateOptions = {
    maxWidth: true,
    minWidth: true,
    maxHeight: true,
    minHeight: true,
    errorMessage: false,
    message: '',
    error: false,
  };
  img.onload = () => {
    const { width } = img;
    const { height } = img;
    if (minWidth && width < minWidth) {
      isValid = {
        ...isValid,
        minWidth: false,
        errorMessage: true,
        error: true,
        message: 'La imagen es muy angosta',
      };
    }

    if (minHeight && height < minHeight) {
      isValid = {
        ...isValid,
        minHeight: false,
        errorMessage: true,
        error: true,
        message: 'La imagen es muy corta',
      };
    }

    setCallback(isValid);
  };
  img.src = blobFile;
};

type createUrlParams = {
  bucket: string;
    restId: string;
    resize: string;
    path: string;
    type: string;
}

export const createUrlResize = ({
  bucket,
  restId,
  resize,
  path,
  type,
  // eslint-disable-next-line max-len
}:createUrlParams) => {
  if (type === 'document') {
    return `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${'restaurant'}%2F${restId}%2F${path}%2Fresize%2F${restId}_${resize}?alt=media`;
  }
  if (type === 'profile') {
    return `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${'restaurant'}%2F${restId}%2F${'profile'}%2Fresize%2F${path}_${resize}?alt=media`;
  }
  return '';
};

export const getPhotoUrls = async (
  restId:string,
  url:string,
  original:string,
  path:string,
  type = 'document',
):Promise<Photo> => {
  const storage = getStorage();
  const refGallery = ref(
    storage,
    url,
  );
  const { bucket } = await getMetadata(refGallery);
  if (restId) {
    const image = {
      original,
      x400: createUrlResize({
        bucket,
        restId,
        resize: '400x400',
        path,
        type,
      }),
      x40: createUrlResize({
        bucket,
        restId,
        resize: '40x40',
        path,
        type,
      }),
      x80: createUrlResize({
        bucket,
        restId,
        resize: '80x80',
        path,
        type,
      }),
    };
    return image as Photo;
  }
  return {
    original,
    x400: '',
    x40: '',
    x80: '',
  } as Photo;
};
