import React from 'react';
import 'nprogress/nprogress.css';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { MenuSectionScreen } from '../../screens';
import { useAppSelector } from '../../appHooks/redux';
import { admin } from '../../redux/store';
import FileUtils from '../../utils/fileUtils';
import { readFileFromXlsx } from '../../utils/ReadFile';
import {
  deleteSharedProductBySectionId,
  editSharedMenuSection,
  MenuSection,
  useIsTemplateMenu,
} from '../../core';
import { enableSectionProps, saveNameProps } from '../../screens/menuSectionScreen/types';
import { exportToXlsxConbrix } from '../../utils/exportMenuExcelCombrix';
import { importMenuConbrixFromXlsx } from '../../core/restaurant/restaurant.service';
import { SimpleBackdrop } from '../../components/backdrop';

const SharedMenu = () => {
  const { enqueueSnackbar } = useSnackbar();
  const router = useNavigate();
  const isSharedMenu = useIsTemplateMenu();
  const currentRestaurant = useAppSelector((state) => state.restaurant.restaurant);
  const menuListData = currentRestaurant?.menu_section || [];
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const onCreateSection = async () => {
    const sectionId = admin.firestore().collection('menu_section').doc().id;
    const newSection = new MenuSection({});
    newSection.id = sectionId;
    if (currentRestaurant?.id && menuListData) {
      const newMenuSections = menuListData.slice();
      newMenuSections.push(newSection);
      await editSharedMenuSection(newMenuSections, currentRestaurant.id);
    }
  };

  const onSaveMenuSectionName = async ({
    name, section, index, enable = false,
  }: saveNameProps) => {
    const editMenuSection = {
      ...section,
      name,
      enable: enable || section.enable,
    };
    if (currentRestaurant?.id && menuListData) {
      const newMenuSections = menuListData.slice();
      newMenuSections[index] = new MenuSection(editMenuSection);
      await editSharedMenuSection(newMenuSections, currentRestaurant.id);
    }
  };

  const onRemoveSection = async (index: number) => {
    if (currentRestaurant?.id && menuListData) {
      const newMenuSections = menuListData.slice();
      const sectionId = newMenuSections[index].id;
      newMenuSections.splice(index, 1);
      await editSharedMenuSection(newMenuSections, currentRestaurant.id);
      await deleteSharedProductBySectionId(currentRestaurant.id, sectionId);
    }
  };

  const onEnableSection = async ({
    section, index, enable,
  }: enableSectionProps) => {
    const editMenuSection = {
      ...section,
      enable,
    };
    if (currentRestaurant?.id && menuListData) {
      const newMenuSections = menuListData.slice();
      newMenuSections[index] = new MenuSection(editMenuSection);
      await editSharedMenuSection(newMenuSections, currentRestaurant.id);
    }
  };

  const onDragEnd = async (menuSections: MenuSection[]) => {
    if (currentRestaurant?.id) {
      await editSharedMenuSection(menuSections, currentRestaurant.id);
    }
  };

  const onClickSection = async (sectionId: string) => {
    await router(`/sharedMenu/${sectionId}`);
  };

  const onExportToXlsx = async () => {
    if (currentRestaurant?.id) {
      const columnsValues = ['SECCIÓN', 'PRODUCTO', 'PRECIO', 'DESCRIPCIÓN'];
      await exportToXlsxConbrix(currentRestaurant, columnsValues);
    }
  };

  const onSaveMenuImport = async (fileData:any) => {
    if (currentRestaurant?.id) {
      if (!fileData) {
        enqueueSnackbar('Error, Seleccione un archivo Excel', {
          variant: 'error',
        });
        return;
      }
      const isExtensionXlsx = FileUtils.isXlsx(fileData);
      if (isExtensionXlsx) {
        try {
          setOpenBackdrop(true);
          const headers = ['section', 'product', 'price', 'description'];
          const responseReadFile:any = await readFileFromXlsx(fileData, headers, 10);
          const currentMenu = menuListData || [];
          if (responseReadFile.isSuccesRead) {
            const dataMenu = responseReadFile.dataMenu.filter((item: any) => _.has(item, 'section'));
            const response = await importMenuConbrixFromXlsx(
              dataMenu,
              currentRestaurant.id,
              currentMenu,
            );

            if (response.isSucceeded) {
              setOpenBackdrop(false);
            } else {
              setOpenBackdrop(false);
              enqueueSnackbar('Ocurrio un error al guardar el menu', {
                variant: 'error',
              });
            }
          } else {
            setOpenBackdrop(false);
            enqueueSnackbar('Error al leer el archivo', {
              variant: 'error',
            });
          }
        } catch (error) {
          console.error(error);
          setOpenBackdrop(false);
          enqueueSnackbar('Ocurrio un error al importar menu', {
            variant: 'error',
          });
        } finally {
          setOpenBackdrop(false);
        }
      } else {
        enqueueSnackbar('Error, Seleccione un archivo Excel', {
          variant: 'error',
        });
      }
    }
  };

  return (
    <>
      <SimpleBackdrop show={openBackdrop} text="Importando Menú" />
      <MenuSectionScreen
        menuSectionsDB={menuListData}
        onCreateSection={onCreateSection}
        isSharedMenu={isSharedMenu}
        onSaveName={onSaveMenuSectionName}
        onRemoveSection={onRemoveSection}
        onEnableSection={onEnableSection}
        onDragEnd={onDragEnd}
        onClickSection={onClickSection}
        onExportToXlsx={onExportToXlsx}
        onSaveMenuImport={onSaveMenuImport}
      />
    </>
  );
};

export default SharedMenu;
