import React, { useState, useMemo } from 'react';
import {
  Container, Stack, Typography, Paper, Box, Button, TextField, InputAdornment, SvgIcon,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Search as SearchIcon,
} from 'react-feather';
import { COLORS } from '../../constants';
import { Journey } from '../../core';
import { TableTurns } from './tableTurns';
import { getDate } from '../../utils/date';

const ContainerMain = styled(Container)(() => ({
  width: '100%',
  minHeight: '93.8vh',
  backgroundColor: COLORS.BG_GRAY,
}));

const CustomContainerButton = styled(Box)(() => ({
  marginLeft: '16px',
  right: '20px',
  textAlign: 'right',
  paddingLeft: '60px',
}));

export type TurnProps = {
  journeys:Journey[]|null,

  openModalHandler:()=>void,
  deleteUserHandler:(user:Journey)=>void,
  editModalHandler : (user:Journey)=>void,
};
const columns = ['Numero', 'Nombre', 'Acciones'];

export const TurnScreen = ({
  journeys, openModalHandler, deleteUserHandler, editModalHandler,
}:TurnProps) => {
  const [query, setQuery] = useState('');

  const tableRows = useMemo(() => {
    if (journeys) {
      const turns = [...journeys];
      if (query) {
        const result:Journey[] = [];
        journeys.forEach((element) => {
          if (element.name.toLowerCase().includes(query.toLocaleLowerCase())) {
            result.push(element);
          }
        });

        return result;
      }
      const sorted = turns.sort(
        (objA, objB) => Number(new Date(getDate(objB.created_at))) - Number(new Date(getDate(objA.created_at))),
      );
      return sorted;
    }
    return [];
  }, [query, journeys]);
  return (
    <ContainerMain maxWidth="xl">
      <Stack>
        <Stack mt={4} mb={5} justifyContent="center" alignItems="center">
          <Typography sx={{ fontWeight: 'bold', fontSize: 23 }}>
            Administración de Turnos
          </Typography>
        </Stack>
        <Paper
          square
          sx={{ padding: '32px 16px 16px 16px', minHeight: '80vh', marginBottom: '24px' }}
        >
          <Stack>

            <CustomContainerButton>
              <Button
                variant="contained"
                style={{ backgroundColor: '#E25060' }}
                onClick={openModalHandler}
              >
                <Typography
                  textTransform="initial"
                  color="black"
                  fontWeight="bold"
                >
                  Nuevo turno
                </Typography>
              </Button>
            </CustomContainerButton>
            <Box m={3}>
              <TextField
                sx={{ width: 500 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',

                        borderRadius: 100,
                        height: 35,
                        width: 35,
                        fontSize: 10,
                      }}
                      >
                        <SvgIcon fontSize="small">
                          <SearchIcon />
                        </SvgIcon>
                      </div>
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => setQuery(event.currentTarget.value)}
                placeholder="Buscar"
                variant="outlined"
                value={query}
              />
            </Box>
            <TableTurns rows={tableRows || []} headerData={columns} deleteUserHandler={deleteUserHandler} editModalHandler={editModalHandler} />

          </Stack>
        </Paper>
      </Stack>
    </ContainerMain>
  );
};
