import { useMemo } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Order } from './order.dto';
import { COLLECTIONS } from '../../constants';
import { Nullable } from '../../types';
import { STATUS, PAYMENT_STATUS } from './order.enum';

export const useOrdersInProcessBySubsidiary = (
  restaurant_id: string = '',
  subsidiary_id: string = '',
): Nullable<Order[]> => {
  const query = useMemo(() => {
    if (restaurant_id && subsidiary_id) {
      return [
        {
          collection: COLLECTIONS.RESTAURANT,
          doc: restaurant_id,
          subcollections: [{
            collection: COLLECTIONS.SUBSIDIARY,
            doc: subsidiary_id,
          },
          {
            collection: COLLECTIONS.ORDER,
            where: ['status', '==', STATUS.IN_PROCESS] as any,
            orderBy: ['created_at', 'desc'] as any,
          }],
          storeAs: 'orders_in_process',
        },
      ];
    }
    return [];
  }, [restaurant_id,
    subsidiary_id]);

  useFirestoreConnect(query);

  const orders = useSelector(
    (state:any) => state.firestore.ordered.orders_in_process,
  );

  if (orders) {
    return orders.map((order:Order) => new Order({ ...order }));
  }

  return null;
};

export const useOrdersOnHoldBySubsidiary = (
  restaurant_id: string = '',
  subsidiary_id: string = '',
): Nullable<Order[]> => {
  const query = useMemo(() => {
    if (restaurant_id && subsidiary_id) {
      return [{
        collection: COLLECTIONS.RESTAURANT,
        doc: restaurant_id,
        subcollections: [{
          collection: COLLECTIONS.SUBSIDIARY,
          doc: subsidiary_id,
        },
        {
          collection: COLLECTIONS.ORDER,
          where: ['status', '==', STATUS.ON_HOLD] as any,
          orderBy: ['created_at', 'desc'] as any,
        }],
        storeAs: 'orders_on_hold',
      }];
    }
    return [];
  }, [restaurant_id,
    subsidiary_id]);

  useFirestoreConnect(query);

  const orders = useSelector(
    (state:any) => state.firestore.ordered.orders_on_hold,
  );

  if (orders) {
    return orders.map((order:Order) => new Order({ ...order }));
  }

  return null;
};

export const useOrdersCompletedBySubsidiary = (
  restaurant_id: string = '',
  subsidiary_id: string = '',
): Nullable<Order[]> => {
  const query = useMemo(() => {
    if (restaurant_id && subsidiary_id) {
      return [{
        collection: COLLECTIONS.RESTAURANT,
        doc: restaurant_id,
        subcollections: [{
          collection: COLLECTIONS.SUBSIDIARY,
          doc: subsidiary_id,
        },
        {
          collection: COLLECTIONS.ORDER,
          where: ['status', '==', STATUS.COMPLETED] as any,
          orderBy: ['created_at', 'desc'] as any,
        }],
        storeAs: 'orders_completed',
      }];
    }
    return [];
  }, [restaurant_id,
    subsidiary_id]);

  useFirestoreConnect(query);
  const orders = useSelector(
    (state:any) => state.firestore.ordered.orders_completed,
  );

  if (orders) {
    return orders.map((order:Order) => new Order({ ...order }));
  }

  return null;
};

export const useOrdersNoPaidBySubsidiary = (
  restaurant_id: string = '',
  subsidiary_id: string = '',
): Nullable<Order[]> => {
  const query = useMemo(() => {
    if (restaurant_id && subsidiary_id) {
      return [{
        collection: COLLECTIONS.RESTAURANT,
        doc: restaurant_id,
        subcollections: [{
          collection: COLLECTIONS.SUBSIDIARY,
          doc: subsidiary_id,
        },
        {
          collection: COLLECTIONS.ORDER,
          where: ['payment_status', '==', PAYMENT_STATUS.NOT_PAYED] as any,
          orderBy: ['created_at', 'desc'] as any,
        }],
        storeAs: 'orders_not_payed',
      }];
    }
    return [];
  }, [restaurant_id,
    subsidiary_id]);

  useFirestoreConnect(query);
  const orders = useSelector(
    (state:any) => state.firestore.ordered.orders_not_payed,
  );

  if (orders) {
    return orders.map((order:Order) => new Order({ ...order }));
  }

  return null;
};

export const useAllOrdersBySubsidiary = (
  restaurant_id: string = '',
  subsidiary_id: string = '',
): Nullable<Order[]> => {
  const query = useMemo(() => {
    if (restaurant_id && subsidiary_id) {
      return [{
        collection: COLLECTIONS.RESTAURANT,
        doc: restaurant_id,
        subcollections: [{
          collection: COLLECTIONS.SUBSIDIARY,
          doc: subsidiary_id,
        },
        {
          collection: COLLECTIONS.ORDER,
          orderBy: ['created_at', 'desc'] as any,
        }],
        storeAs: 'all_orders',
      }];
    }
    return [];
  }, [restaurant_id,
    subsidiary_id]);

  useFirestoreConnect(query);
  const orders = useSelector(
    (state:any) => state.firestore.ordered.all_orders,
  );

  if (orders) {
    return orders.map((order:Order) => new Order({ ...order }));
  }

  return null;
};
