import React, { useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Typography,
  styled,
  TextField,
  MenuItem,
} from '@mui/material';
import { KeyboardArrowUp as KeyboardArrowUpIcon, KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
import { COLORS } from '../../../constants';
import { TextFieldEditIcon } from '../../../components/textField';
import { Metric, updateProductsProductionId, MenuSection } from '../../../core';
import { useAppSelector } from '../../../appHooks/redux';
import { Nullable } from '../../../types';

const TableBox = styled(Box)(() => ({
  border: `1px solid ${COLORS.BLUE_LIGHT_78}`,
  borderRadius: '10px',
}));

const DisableText = styled(Typography)(() => ({
  color: COLORS.SHIMMERING, paddingLeft: '20px', fontSize: '10px', paddingTop: '7px',
}));

type SimpleProduct = {
  id: string;
  availability: boolean;
  product_id:string;
  product_name:string;
  metric: Metric;
  initial: number;
  income: number;
  discount: number;
  sales: number;
  stock: number;
  menu_section: MenuSection;
};

type ProductBySection = {
  menu_section_id: string;
  menu_section_enable: boolean;
  menu_section_name: string;
  initial: number;
  income: number;
  discount: number;
  sales: number;
  stock: number;
  products: SimpleProduct[];
};

type RowProps = {
  metrics:Nullable<Metric[]>,
  onSaveMetric: ((metricId:string, productId:string) => void),
  productBySection: ProductBySection;
  editable: boolean;
};

type TextHandleEdit = {
  param: string,
  value: string,
  id: string,
}

export const TableRowCollapse = ({
  metrics = [], onSaveMetric, productBySection, editable,
}: RowProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  const restaurant = useAppSelector((state) => state.restaurant.restaurant);
  const { value: journeyProduction } = useAppSelector((state) => state.inventory.journeyProduction);

  const handleEdit = ({ param, value, id }: TextHandleEdit) => {
    if (restaurant && subsidiary && journeyProduction) {
      updateProductsProductionId(restaurant?.id, subsidiary?.id, journeyProduction?.id, id, { [param]: Number(value) });
    }
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell width="400px" align="left" sx={productBySection.menu_section_enable ? {} : { color: COLORS.SILVER_CHALICE }}>
          <Stack width="400px" direction="row" spacing={0.5} alignItems="center">
            <IconButton
              aria-label="expand row"
              size="small"
              color="secondary"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
            <Typography>
              {productBySection.menu_section_name}
            </Typography>
            {!productBySection.menu_section_enable && (
              <DisableText>
                Deshabilitado
              </DisableText>
            )}
          </Stack>
        </TableCell>
        <TableCell width="111px" component="th" scope="row" sx={{}} />
        <TableCell width="111px" sx={productBySection.menu_section_enable ? {} : { color: COLORS.SILVER_CHALICE }} align="center">{productBySection.initial}</TableCell>
        <TableCell width="111px" sx={productBySection.menu_section_enable ? {} : { color: COLORS.SILVER_CHALICE }} align="center">{productBySection.income}</TableCell>
        <TableCell width="111px" sx={productBySection.menu_section_enable ? {} : { color: COLORS.SILVER_CHALICE }} align="center">{productBySection.sales}</TableCell>
        <TableCell width="111px" sx={productBySection.menu_section_enable ? {} : { color: COLORS.SILVER_CHALICE }} align="center">{productBySection.discount}</TableCell>
        <TableCell width="111px" sx={productBySection.menu_section_enable ? {} : { color: COLORS.SILVER_CHALICE }} align="center">{productBySection.stock}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBlock: 0, paddingLeft: 40, paddingRight: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableBox>
              <Table aria-label="details-section" sx={{ minWidth: 1350 }}>
                <TableBody>
                  {productBySection?.products.map((simpleProduct:SimpleProduct) => {
                    const metricSelected = metrics?.filter((metric) => metric.id === simpleProduct.metric.id)[0];
                    const isDisabled = !simpleProduct.menu_section.enable || !simpleProduct.availability;
                    return (
                      <TableRow key={`${simpleProduct.product_id}-key`}>
                        <TableCell width="375px" align="left" sx={!isDisabled ? {} : { color: COLORS.SILVER_CHALICE }}>
                          <Box width="375px" display="flex">
                            <Typography>
                              {simpleProduct.product_name}
                            </Typography>
                            {isDisabled && (
                            <DisableText>
                              Deshabilitado
                            </DisableText>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell width="116px" align="left" sx={!isDisabled ? {} : { color: COLORS.SILVER_CHALICE }}>
                          <Box width="116px">
                            <TextField
                              select
                              fullWidth
                              name="Metric"
                              disabled={isDisabled}
                              value={metricSelected?.id || ''}
                              onChange={(e:any) => {
                                onSaveMetric(e.target.value, simpleProduct.id);
                              }}
                              variant="outlined"
                              size="small"
                              color="secondary"
                            >
                              {metrics && metrics.map((metric) => (
                                <MenuItem id={metric.id} key={metric.id} value={metric.id}>
                                  {metric.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                        </TableCell>
                        <TableCell sx={!isDisabled ? {} : { color: COLORS.SILVER_CHALICE }} width="116px" align="center">{simpleProduct.initial}</TableCell>
                        <TableCell sx={!isDisabled ? {} : { color: COLORS.SILVER_CHALICE }} width="116px" align="center">
                          <Box width="116px">
                            {!isDisabled && editable ? (
                              <>
                                <TextFieldEditIcon
                                  type="number"
                                  param="income"
                                  id={simpleProduct.id}
                                  handleEdit={({ param, value, id }) => handleEdit({ param, value, id })}
                                  textFieldWidth={70}
                                  text={simpleProduct.income.toString()}
                                  textDefault="0"
                                  sales={simpleProduct.sales}
                                  discount={simpleProduct.discount}
                                  initial={simpleProduct.initial}
                                />
                              </>
                            ) : (
                              <>
                                {simpleProduct.income.toString()}
                              </>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell sx={!isDisabled ? {} : { color: COLORS.SILVER_CHALICE }} width="116px" align="center">{simpleProduct.sales}</TableCell>
                        <TableCell sx={!isDisabled ? {} : { color: COLORS.SILVER_CHALICE }} width="116px" align="center" size="small">
                          <Box width="116px">
                            {!isDisabled && editable ? (
                              <>
                                <TextFieldEditIcon
                                  type="number"
                                  param="discount"
                                  id={simpleProduct.id}
                                  handleEdit={({ param, value, id }) => handleEdit({ param, value, id })}
                                  textFieldWidth={70}
                                  text={simpleProduct.discount.toString()}
                                  textDefault="0"
                                  maxValue={simpleProduct.stock}
                                />
                              </>
                            ) : (
                              <>
                                {simpleProduct.discount.toString()}
                              </>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell sx={!isDisabled ? {} : { color: COLORS.SILVER_CHALICE }} width="116px" align="center">{simpleProduct.stock}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableBox>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
