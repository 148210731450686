/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import {
  Stack, Typography, styled, IconButton, FormControlLabel, ListItemAvatar, ListItem, Grid, Box,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import { COLORS } from '../../constants';
import {
  PosSwitch, ConfirmModal,
} from '../../components';
import { enableProductProps } from './types';
import { Product } from '../../core';
import ProductDish from '../../assets/icons/ProductDish.png';

const FormControlLabelCustom = styled(FormControlLabel)(() => ({
  alignItems: 'center',
  width: 50,
  '.mui-style-ce5obw-MuiTypography-root': {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 0,
  },
  '.MuiFormControlLabel-label.Mui-disabled': {
    color: COLORS.BLACK,
  },
}));

type ProductCardProps = {
  product: Product;
  index: number;
  isSharedMenu: boolean;
  onRemoveProduct?: (productId: string) => void;
  onEnableProduct: ({
    productId, checked,
  }: enableProductProps) => void;
  onClickProduct: (productId: string) => void;
};

const ProductCard = ({
  product,
  index,
  isSharedMenu,
  onRemoveProduct = () => {},
  onEnableProduct = () => {},
  onClickProduct = () => {},
}: ProductCardProps) => {
  const [showModalDelete, setShowModalDelete] = useState(false);

  const handleSection = (isActive: boolean) => {
    onEnableProduct({ productId: product.id, checked: isActive });
  };

  return (
    <>
      <Grid container pr={2}>
        <Grid container item xs={10} sm={10} md={10}>
          <ListItem
            sx={{ paddingTop: 0, paddingBottom: 0 }}
            key={product.id}
            onClick={(event) => {
              event?.preventDefault();
              onClickProduct(product.id);
            }}
          >
            <Grid container item alignItems="center">
              <Grid item xs={4} sm={4} md={4} alignItems="center">
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <IconButton>
                    <DragIndicatorIcon sx={{ fontSize: 45, color: COLORS.BLACK }} />
                  </IconButton>
                  <ListItemAvatar>
                    <img
                      style={{ width: 40, height: 40, borderRadius: 4 }}
                      src={product.photo.x40 || product.photo.x80 || ProductDish}
                    // error="/icons/ProductImage.jpg"
                      onError={(event: any) => {
                      // eslint-disable-next-line no-param-reassign
                        event.target.src = ProductDish;
                        // eslint-disable-next-line no-param-reassign
                        event.onerror = null;
                      }}
                    />
                  </ListItemAvatar>
                  <div
                    style={{
                      width: '100%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      paddingRight: '16px',
                    }}
                  >
                    <Typography sx={{ fontSize: 12, fontWeight: 400 }}>Producto</Typography>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontWeight: 400,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {`${index + 1}. ${product.name}`}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <div
                  style={{
                    width: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    paddingRight: '16px',
                  }}
                >
                  <Typography sx={{ fontSize: 12, fontWeight: 400 }}>Descripción</Typography>
                  <Typography
                    sx={{
                      width: '100%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      fontSize: 18,
                      fontWeight: 400,
                    }}
                  >
                    {product.description === ''
                      ? 'Sin descripción'
                      : product.description}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <div>
                  <Typography sx={{ fontSize: 12, fontWeight: 400 }}>Precio</Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: 400 }}>
                    {`Bs.${product.unit_price.toFixed(2)}`}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </ListItem>
        </Grid>
        <Grid
          container
          item
          xs={2}
          sm={2}
          md={2}
          direction="row"
          justifyContent="flex-end"
        >
          <Stack
            direction="row"
            spacing={3}
            pt={1.5}
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControlLabelCustom
              value="top"
              control={(
                <PosSwitch
                  disabled={!product.unit_price}
                  value={product.availability}
                  onChange={(e:any) => handleSection(e.target.checked)}
                />
                              )}
              label={product.availability ? 'Deshabilitar' : 'Habilitar'}
              labelPlacement="top"
            />
            {isSharedMenu && (
            <FormControlLabelCustom
              value="top"
              control={(
                <IconButton
                  id="deleteProduct"
                  onClick={() => setShowModalDelete(true)}
                >
                  <DeleteIcon style={{ color: COLORS.RED }} />
                </IconButton>
                  )}
              label="Eliminar"
              labelPlacement="top"
            />
            )}
          </Stack>
        </Grid>
      </Grid>
      <ConfirmModal
        open={showModalDelete}
        onClose={() => {
          setShowModalDelete(!showModalDelete);
        }}
        onConfirm={(e:any) => {
          e?.preventDefault();
          onRemoveProduct(product.id);
          setShowModalDelete(!showModalDelete);
        }}
        title={`¿Estas seguro que deseas eliminar el producto ${product.name}?`}
      />
    </>
  );
};

export default ProductCard;
