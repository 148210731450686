import React, { useState, useEffect } from 'react';
import { HeadTitle } from '../../../components';
import SalesReportScreen from '../../../screens/reportScreen/salesReportScreen';
import { useCities } from '../../../core';
import { useAppSelector } from '../../../appHooks/redux';
import { useSubsidiaries } from '../../../core/subsidiary/subsidiary.hook';
import { exportToXlsxReport } from '../../../core/report/report.utils';
import { getSalesReport } from '../../../core/report';
import { MONTHS_FILTERED } from '../../../constants';

export type DataBodyReqProps = {
  city: string
  restaurant: string
  subsidiary: string
  year: string
  month: string
  promo: string
  statusOrder: string,
  statusPayment: string,
}

const SalesPage = () => {
  const currentRestaurant = useAppSelector((state) => state.restaurant.restaurant);
  const allSubsidiaries = useSubsidiaries(currentRestaurant?.id);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState<DataBodyReqProps>({
    city: 'all',
    restaurant: currentRestaurant?.id ? currentRestaurant?.id : '',
    subsidiary: 'all',
    year: `${new Date().getFullYear()}`,
    month: `${new Date().getMonth() + 1 <= 9 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}`,
    promo: 'all',
    statusOrder: 'COMPLETED',
    statusPayment: 'all',
  });
  const [allReport, setAllReport] = useState();
  const allCities = useCities();

  const onExportToExcel = async (totalGrossSale:number, totalDiscount:number, totalSales:number) => {
    exportToXlsxReport(
      await allReport || [],
      totalGrossSale,
      totalDiscount,
      totalSales,
      currentRestaurant?.name || '',
      `${MONTHS_FILTERED.find((current) => current.id === data.month)?.name} - ${data.year}`,
    );
  };
  const getData = async (value:DataBodyReqProps) => {
    setLoader(true);
    const report = await getSalesReport(value);
    setAllReport(report);
    setLoader(false);
  };
  useEffect(() => () => {
    getData(data);
  }, []);

  const handleSetData = (value: DataBodyReqProps) => {
    getData(value);
    setData(value);
  };
  return (
    <>
      <HeadTitle title="Reporte de ventas" />
      <SalesReportScreen
        loader={loader}
        allCities={allCities}
        allReport={allReport}
        allSubsidiaries={allSubsidiaries}
        handleSetData={handleSetData}
        onExportToExcel={onExportToExcel}
      />
    </>
  );
};

export default SalesPage;
