import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';
import { STATUS } from '../order';
import { ReportRow } from './report.type';

export const getDeliveryService = (service:string) => {
  if (service === 'local') {
    return 'Local';
  }
  if (service === 'pick up') {
    return 'Pick up';
  }
  if (service === STATUS.ACCEPTED) {
    return 'Aceptado';
  }
  if (service === 'delivery entregas') {
    return 'Delivery entregas';
  }
  if (service === 'delivery other') {
    return 'Otro delivery';
  }

  return service;
};
const getStatus = (state:string) => {
  if (state === STATUS.COMPLETED) {
    return 'Completado';
  }
  if (state === STATUS.IN_PROCESS) {
    return 'En Proceso';
  }
  if (state === STATUS.ACCEPTED) {
    return 'Aceptado';
  }
  if (state === STATUS.ON_CART) {
    return 'En el carro';
  }
  if (state === STATUS.ON_HOLD) {
    return 'En espera';
  }
  if (state === STATUS.CANCELED) {
    return 'Cancelado';
  }
  if (state === 'NO_SALES') {
    return 'Sin ventas';
  }
  if (state === 'NEW') {
    return 'Nuevo';
  }
  if (state === STATUS.SENDED) {
    return 'Enviado';
  }
  return '-';
};

export const exportToXlsxReport = async (reports:any[], totalGrossSale:number, totalDiscount:number, totalSales:number, restaurant:string, date:string) => {
  const columnsTypes = [
    { key: 'counter', width: 10 },
    { key: 'order_code', width: 25 },
    { key: 'client_name', width: 30 },
    { key: 'client_phone_number', width: 30 },
    { key: 'restaurant_name', width: 30 },
    { key: 'created_at', width: 30 },
    { key: 'status', width: 20 },
    { key: 'city', width: 30 },
    { key: 'service', width: 25 },
    { key: 'gross_sales', width: 15 },
    { key: 'discount', width: 15 },
    { key: 'total', width: 15 },
  ];

  const rows:ReportRow[] = [];
  reports.forEach((report) => {
    rows.push({
      counter: report.counter,
      order_code: report.order_code || '-',
      client_name: report.client_name || '-',
      client_phone_number: report.client_phone_number || '-',
      restaurant_name: report.restaurant_name || '-',
      created_at: report.date,
      status: getStatus(report.status),
      city: report.city || '-',
      service: getDeliveryService(report.service) || '-',
      gross_sales: report.gross_sales.toFixed(2) || 0,
      discount: report.discount.toFixed(2) || 0,
      total: report.total.toFixed(2) || 0,
    });
  });
  const columnsValues = ['Nro', 'No. de orden', 'Nombre', 'Número de teléfono', 'Restaurante', 'Fecha', 'Estado', 'Ciudad', 'Servicio', 'Ventas brutas', 'Descuento', 'Ventas netas'];
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Reporte de ventas');
  worksheet.getCell('E2').value = 'Combrix';
  worksheet.getCell('E2').font = { bold: true, size: 12 };

  worksheet.getCell('E3').value = date.toLocaleLowerCase();
  worksheet.getCell('E3').font = { bold: false, size: 10 };
  worksheet.getCell('E4').value = restaurant;
  worksheet.getCell('E4').font = { bold: false, size: 10 };

  worksheet.getCell('E9').value = 'REPORTE DE VENTAS';
  worksheet.getCell('E9').font = { bold: true, size: 10 };

  worksheet.getRow(10).values = columnsValues;
  worksheet.columns = columnsTypes;
  worksheet.getCell('A10').style = {
    font: { bold: true, size: 12 },

  };
  worksheet.getCell('B10').style = {
    font: { bold: true, size: 12 },

  };
  worksheet.getCell('C10').style = {
    font: { bold: true, size: 12 },

  };
  worksheet.getCell('D10').style = {
    font: { bold: true, size: 12 },

  };
  worksheet.getCell('E10').style = {
    font: { bold: true, size: 12 },

  };
  worksheet.getCell('F10').style = {
    font: { bold: true, size: 12 },

  };
  worksheet.getCell('G10').style = {
    font: { bold: true, size: 12 },

  };
  worksheet.getCell('H10').style = {
    font: { bold: true, size: 12 },

  };
  worksheet.getCell('I10').style = {
    font: { bold: true, size: 12 },

  };
  worksheet.getCell('J10').style = {
    font: { bold: true, size: 12 },

  };
  worksheet.getCell('K10').style = {
    font: { bold: true, size: 12 },

  };
  worksheet.getCell('L10').style = {
    font: { bold: true, size: 12 },

  };

  //  const columnsValues = ['Nro', 'No. de orden', 'Restaurant', 'Fecha', 'Estado', 'Ciudad', 'Servicio', 'Venta Bs.'];

  worksheet.addRows(rows);
  const lastRow = rows.length + 12;

  worksheet.getCell(`B${lastRow + 1}`).value = 'TOTALES';
  worksheet.getCell(`B${lastRow + 1}`).font = { bold: true, size: 12 };

  worksheet.getCell(`B${lastRow + 2}`).value = 'Totales';
  worksheet.getCell(`B${lastRow + 2}`).font = { bold: true, size: 12 };

  worksheet.getCell(`C${lastRow + 2}`).value = 'Cantidad Bs';
  worksheet.getCell(`C${lastRow + 2}`).font = { bold: true, size: 12 };

  worksheet.getCell(`B${lastRow + 3}`).value = 'Ventas Brutas';
  worksheet.getCell(`B${lastRow + 3}`).font = { bold: true, size: 12 };
  worksheet.getCell(`C${lastRow + 3}`).value = totalGrossSale.toFixed(2) || 0;
  worksheet.getCell(`C${lastRow + 3}`).font = { size: 12 };

  worksheet.getCell(`B${lastRow + 4}`).value = 'Descuento';
  worksheet.getCell(`B${lastRow + 4}`).font = { bold: true, size: 12 };
  worksheet.getCell(`C${lastRow + 4}`).value = totalDiscount.toFixed(2) || 0;
  worksheet.getCell(`C${lastRow + 4}`).font = { size: 12 };

  worksheet.getCell(`B${lastRow + 5}`).value = 'Ventas netas';
  worksheet.getCell(`B${lastRow + 5}`).font = { bold: true, size: 12 };
  worksheet.getCell(`C${lastRow + 5}`).value = totalSales.toFixed(2) || 0;
  worksheet.getCell(`C${lastRow + 5}`).font = { size: 12 };

  const buf = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buf]), `reporte_${moment(new Date()).format('YY-MM-DD')}.xlsx`);
};
