import React from 'react';
import {
  Box, Paper, Typography, styled, useTheme,
} from '@mui/material';

const Label = styled(Typography)(() => ({
  alignItems: 'center',
  borderRadius: 2,
  display: 'inline-flex',
  flexGrow: 0,
  whiteSpace: 'nowrap',
  cursor: 'default',
  flexShrink: 0,
  fontSize: 12,
  fontWeight: 'bold',
  height: 20,
  justifyContent: 'center',
  letterSpacing: 0.5,
  minWidth: 20,
  padding: 15,
  textTransform: 'uppercase',
}));

type TotalCustomersProps = {
  value: number;
  difference: number;
}

const TotalCustomers = ({
  value,
  difference,
}: TotalCustomersProps) => {
  const theme = useTheme();

  return (
    <Paper elevation={3}>
      <Box flexGrow={1} p={2}>
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          color="textSecondary"
        >
          CLIENTES REPETIDOS
        </Typography>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Typography variant="h3" color="textPrimary">
            {value}
          </Typography>
          <Label
            style={difference > 0 ? {
              color: theme.palette.success.main,
              backgroundColor: 'white',
            } : {
              color: theme.palette.error.main,
              backgroundColor: 'white',
            }}
          >
            {difference > 0 ? '+' : ''}
            {difference}
            %
          </Label>
        </Box>
      </Box>
    </Paper>
  );
};

export default TotalCustomers;
