import React, { memo, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommandPanel } from '../../screens';
import { TypeOfDelivery } from '../../screens/commandPanel/delivery';
import { OptionsButton } from '../../screens/commandPanel/optionsButton';
import { useAppDispatch, useAppSelector } from '../../appHooks/redux';
import { ROUTES } from '../../constants';
import { HeadTitle } from '../../components';
import { setCurrentOrder } from '../../redux/reducers/shoppingCart';
import { Order, ORDER_TYPE } from '../../core';
import { createOrderSubsidiaryCode } from '../../core/order/order.service';

const ContainerMain = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
`;

const CustomContainerOrder = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '70%',
  [theme.breakpoints.down('md')]: {
    width: '60%',
  },
}));

interface SelectServiceProps {
  select: string;
  handleSelect: (value: string) => void;
}
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SelectService = memo(
  ({ select, handleSelect }: SelectServiceProps) => {
    const router = useNavigate();
    const currentOrder = useAppSelector((state) => state.shoppingCart.currentOrder);
    const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
    const restaurant = useAppSelector((state) => state.restaurant.restaurant);
    const query = useQuery();
    const dispatch = useAppDispatch();

    const onSetOrderType = async (state: string) => {
      const action = query.get('action');
      if (state === 'delivery' || state === 'main') {
        handleSelect(state);
      } else {
        if (action && action === 'change') {
          dispatch(setCurrentOrder(
            new Order({
              ...currentOrder,
              order_type: state as ORDER_TYPE,
              xpress_motos: !!state.includes('DELIVERY'),
            }),
          ));
        } else {
          const code = await createOrderSubsidiaryCode({
            subsidiaryId: subsidiary?.id || '',
            restaurantId: restaurant?.id || '',
          });
          dispatch(setCurrentOrder(
            new Order({
              ...currentOrder,
              order_code: code,
              order_type: state as ORDER_TYPE,
              xpress_motos: !!state.includes('DELIVERY'),
              restaurant_name: restaurant?.name,
              subsidiary_name: subsidiary?.name,
              restaurant_id: restaurant?.id,
              subsidiary_id: subsidiary?.id,
              city: subsidiary?.city,
            }),
          ));
        }
        router(ROUTES.MENU);
      }
    };

    return (
      <CustomContainerOrder>
        {select === 'main' && <OptionsButton handleSelect={onSetOrderType} />}
        {select === 'delivery' && (
          <TypeOfDelivery handleSelect={onSetOrderType} />
        )}
      </CustomContainerOrder>
    );
  },
  (prevProps, nextProps) => prevProps.select === nextProps.select,
);

const Orders = () => {
  const [select, setSelect] = useState<string>('main');

  const handleSelect = (value: string) => {
    setSelect(value);
  };

  return (
    <>
      <HeadTitle title="Panel de Control" />
      <ContainerMain>
        <CommandPanel />
        <SelectService select={select} handleSelect={handleSelect} />
      </ContainerMain>
    </>
  );
};

export default Orders;
