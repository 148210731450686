import React from 'react';
import {
  Page, Text, View, Document, StyleSheet,
} from '@react-pdf/renderer';
import moment from 'moment-timezone';
import { COLORS } from '../../constants';
import { ORDER_TYPE } from '../../core';

// Create styles
const styles = StyleSheet.create({
  page: {
    width: '302.36220472',
    height: '377.95275591px',
  },
  headerContent: {
    paddingLeft: 10,
    backgroundColor: '#EFF2FB',
    paddingBottom: 6,
  },
  subHeaderContent: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  headerText: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  title: {
    fontSize: '13px',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  listText: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  listContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginHorizontal: '30px',
  },
  line: {
    marginTop: '5px',
    height: '3px',
    width: '30%',
    backgroundColor: '#1E346E',
  },
  parentListItem: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '5px',
  },
});
const ORDER_TYPE_CONST = {
  DELIVERY_ENTREGAS: 'Delivery',
  DELIVERY_OTHER: 'Delivery',
  PICK_UP: 'Pick Up',
  LOCAL: 'Local',
};

export type Addition = {
  amount: number;
  name: string;
};
export type Order = {
  amount: number;
  productName: string;
  additions: Addition[];
};

type Ticket = {
  orderNumber: string;

  orderType: ORDER_TYPE;

  clientName: string;

  orders: Order[];

  createAt: Date;
};

type TicketProps = {
  ticket: Ticket;
};
// Create Document Component
export const TicketGenerator = ({ ticket }: TicketProps) => {
  let headerHeight = 74.8661418;
  if (ticket.orders.length <= 2) {
    headerHeight += 9.8661418;
  }
  let height = 0;
  if (ticket.orders.length < 6) {
    height += 120;
  }
  ticket.orders.forEach(() => {
    height += 30.1811024;
  });
  const childs = ticket.orders.map((x) => x.additions).flat();
  let heightChild = 0;
  childs.forEach((element) => {
    if (element.name.length <= 30) {
      heightChild += 14.3385827;
    } else {
      heightChild += 20.1811024;
    }
  });
  return (
    <Document>
      <Page
        orientation="portrait"
        size={{
          width: '226.7716536',
          height: `${height + heightChild + headerHeight}`,
        }}
        style={{ ...styles.page }}
      >
        <View style={styles.headerContent}>
          <View style={{ marginTop: '4px' }}>
            <Text
              style={{
                ...styles.headerText,
                top: 8,
              }}
            >
              {`#Orden: ${ticket.orderNumber}`}
            </Text>
            <Text
              style={{
                ...styles.headerText,
                position: 'absolute',
                right: 7,
                top: 10,
              }}
            >
              {moment(ticket.createAt).format('HH:mm')}
            </Text>
          </View>
          <View style={{ marginTop: '10px', paddingBottom: '10px' }}>
            <Text
              style={{
                ...styles.headerText,
                top: 8,
              }}
            >
              {ORDER_TYPE_CONST[ticket.orderType]}
            </Text>
            <Text
              style={{
                ...styles.headerText,
                position: 'absolute',
                right: 7,
                top: '8px',
              }}
            >
              {ticket.clientName}
            </Text>
          </View>
        </View>
        <View
          style={{
            padding: '10px 10px 0 10px',
          }}
        >
          <View style={styles.subHeaderContent}>
            <View style={styles.line} />
            <View
              style={{
                paddingLeft: '10%',
                paddingTop: '0px',
                paddingRight: '10%',
              }}
            >
              <Text style={{ ...styles.title }}>Pedido</Text>
            </View>
            <View style={styles.line} />
          </View>
        </View>
        <View style={styles.listContainer}>
          <View
            style={{
              textAlign: 'center',
              alignContent: 'center',
            }}
          >
            <div>
              {ticket.orders.map((order: Order, index: number) => (
                <div key={order.productName + index.toString()}>
                  <View style={styles.parentListItem}>
                    <Text style={{ ...styles.title, width: '20px' }}>
                      {order.amount}
                    </Text>

                    <Text
                      style={{
                        ...styles.title,
                      }}
                    >
                      {order.productName}
                    </Text>
                  </View>
                  <View style={{ padding: '5px 0 0 0' }}>
                    {order.additions.map(
                      (extra: Addition, indexAdd: number) => (
                        <View
                          key={extra.name + indexAdd.toString()}
                          style={{ paddingLeft: '20px', paddingTop: 0 }}
                        >
                          <Text
                            style={{
                              ...styles.listText,
                              textAlign: 'left',
                              lineHeight: '1.3px',
                              color: COLORS.ROYAL_BLUE_DARK,
                            }}
                          >
                            { `${extra.amount}  Extra: ${extra.name}`}
                          </Text>
                        </View>
                      ),
                    )}
                  </View>
                </div>
              ))}
            </div>
          </View>
        </View>
      </Page>
    </Document>
  );
};
