import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { COLLECTIONS } from '../../constants';
import { Nullable } from '../../types';
import { City } from './city.dto';

export const useCities = (): Nullable<City[]> => {
  useFirestoreConnect(
    [{
      collection: COLLECTIONS.CITY,
      storeAs: 'all_cities',
    }],
  );

  const cities = useSelector(
    (state:any) => state.firestore.ordered.all_cities,
  );

  if (cities) {
    return cities.map((subsidiary:City) => new City({ ...subsidiary }));
  }

  return null;
};
