/* eslint-disable no-nested-ternary,no-unneeded-ternary */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { FieldArray, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  TextField,
  FormHelperText,
  Typography,
  CircularProgress,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { admin } from '../../../../redux/store';
import { Addition, AdditionOptionItem } from '../../../../core';
import AdditionItemForm from './AdditionItemForm';
import { COLORS } from '../../../../constants';
import { BoxLoadingWrapper, BoxWrapper } from '../../../../components/box';

export type AdditionOptionFormValues = {
  id:string;
  enable:boolean;
  name :string;
  price : number;
  availability :boolean;
}

export type AdditionFormValues = {
  name: string,
  mandatory: boolean,
  multi_selection: boolean,
  max_selection: number,
  options: AdditionOptionFormValues[],
  is_quantifiable: boolean,
  mandatory_amount: boolean,
  has_max_amount_addition: boolean,
  single_addition: boolean,
};

type AdditionEditFormProps = {
  addition: Addition | undefined,
  onClose: () => void,
  onCreateMenuAdditionsRestaurant?: (
    values: AdditionFormValues, availability: boolean
  ) => void | Promise<void>
  availability: boolean,
  prodId: string,
  onEditAdditionRestaurantAndProduct?: (
    values: AdditionFormValues,
    availability:boolean,
    menuAdditionId: string,
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
};

const AdditionEditForm = ({
  addition,
  onClose,
  onCreateMenuAdditionsRestaurant,
  availability = false,
  prodId,
  onEditAdditionRestaurantAndProduct,
}: AdditionEditFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const initialValuesAddition: AdditionFormValues = {
    name: addition?.name || '',
    mandatory: addition?.mandatory || false,
    multi_selection: addition?.multi_selection || false,
    max_selection: addition?.max_selection || 0,
    options: addition?.options || [],
    is_quantifiable: addition?.is_quantifiable || false,
    mandatory_amount: addition?.mandatory_amount || false,
    has_max_amount_addition: addition?.has_max_amount_addition || false,
    single_addition: addition?.single_addition || false,
  };

  return (
    <BoxWrapper>
      {loading && (
        <BoxLoadingWrapper>
          <CircularProgress />
        </BoxLoadingWrapper>
      )}
      <Formik
        initialValues={{ ...initialValuesAddition }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255)
            .required('Nombre de tipos de adición es requerido'),
          max_selection: Yup.number()
            .min(1, 'No. Máximo de adiciones requerido')
            .required('No. Máximo de adiciones requerido'),
          options: Yup.array()
            .min(1, 'Al menos una adición es requerida, las adiciones deben ser validas')
            .of(
              Yup.object().shape({
                price: Yup.number().min(0),
                name: Yup.string().required(),
              }),
            )
            .required('Al menos una adición es requerida, las adiciones deben ser válidas'),
        })}
        onSubmit={async (values, { setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            const newValues: AdditionFormValues = {
              ...values,
              // to delete initialyEditing from options
              options: values.options.map((currentOption) => ({
                availability: currentOption.availability,
                enable: currentOption.enable,
                id: currentOption.id,
                name: currentOption.name,
                price: currentOption.price,
              })),
            };

            if (!addition?.id && onCreateMenuAdditionsRestaurant) {
              await onCreateMenuAdditionsRestaurant(newValues, availability);
            } else if (onEditAdditionRestaurantAndProduct && addition) {
              // eslint-disable-next-line max-len
              onEditAdditionRestaurantAndProduct(newValues, availability, addition.menu_additions_id, addition.id, prodId);
            }

            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Se guardó correctamente', { variant: 'success' });
            setLoading(false);
            if (onClose) {
              onClose();
            }
          } catch (e) {
            setStatus({ success: false });
            setSubmitting(false);
            enqueueSnackbar('Ocurrio un Error al guardar', { variant: 'error' });
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
          >
            <Box ml={3} mr={3} mt={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    id="name"
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre de tipos de adición"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    required
                    variant="outlined"
                    color="secondary"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography>
                    <strong>
                      Tipo de elección:
                    </strong>
                  </Typography>
                  <Box>
                    <FormControlLabel
                      label="Obligatorio"
                      control={(
                        <Radio
                          id="mandatory"
                          checked={values.mandatory}
                          onChange={(e) => {
                            if (e.target.value === 'true') {
                              setFieldValue('mandatory', true);
                            }
                          }}
                          value="true"
                          color="secondary"
                          name="mandatory"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      label="Opcional"
                      control={(
                        <Radio
                          id="optional"
                          checked={!values.mandatory}
                          onChange={(e) => {
                            if (e.target.value === 'false') {
                              setFieldValue('mandatory', false);
                              setFieldValue('mandatory_amount', false);
                              setFieldValue('single_addition', false);
                              setFieldValue('has_max_amount_addition', false);
                            }
                          }}
                          value="false"
                          color="secondary"
                          name="mandatory"
                        />
                      )}
                    />
                  </Box>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography>
                    <strong>
                      Tipo de selección:
                    </strong>
                  </Typography>
                  <Box>
                    <FormControlLabel
                      label="Selección Múltiple"
                      control={(
                        <Checkbox
                          id="multiSelection"
                          onChange={(e, checked) => {
                            setFieldValue('multi_selection', checked);
                            if (!checked) setFieldValue('is_quantifiable', checked);
                          }}
                          name="multi_selection"
                          color="secondary"
                          checked={values.multi_selection}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <TextField
                      id="maxSelection"
                      error={Boolean(
                        touched.max_selection && errors.max_selection,
                      )}
                      fullWidth
                      type="number"
                      inputProps={{
                        min: 0,
                        step: 1,
                      }}
                      helperText={touched.max_selection && errors.max_selection}
                      label="No. Máximo de adiciones"
                      name="max_selection"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.max_selection}
                      required
                      variant="outlined"
                      color="secondary"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mt={3} mb={3}>
              <Typography>
                <strong>
                  Lista de adiciones
                </strong>
              </Typography>
            </Box>
            <Box ml={3} mr={3} mb={3}>
              <FieldArray
                name="options"
                render={(arrayHelpers) => (
                  <Box>
                    {values.options
                      && values.options.length > 0
                      && values.options.map((option, index) => (
                        <AdditionItemForm
                          showMax={values.has_max_amount_addition}
                          key={option.id}
                          index={index}
                          additionOption={option}
                          errors={
                            errors.options
                            && typeof errors.options !== 'string'
                              ? errors.options[index]
                              : {}
                          }
                          onSave={(i, additionToSave) => {
                            arrayHelpers.replace(i, additionToSave);
                          }}
                          onDelete={(i) => arrayHelpers.remove(i)}
                          initialyEditing={_.has(option, 'initialyEditing') ? true : false}
                        />
                      ))}
                    <Grid container spacing={3}>
                      <Grid item md={9} xs={12} />
                      <Grid item md={3} xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                          <IconButton
                            id="addNewItem"
                            type="button"
                            onClick={() => {
                              arrayHelpers.push({
                                ...new AdditionOptionItem({
                                  id: admin.firestore().collection('option').doc().id,
                                }),
                                initialyEditing: true,
                              });
                            }}
                          >
                            <AddCircleOutlineIcon sx={{ color: COLORS.BLACK }} />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              />
            </Box>
            {errors.options && typeof errors.options === 'string' && (
              <Box ml={3} mr={3} mb={3}>
                <FormHelperText error>{errors.options}</FormHelperText>
              </Box>
            )}
            <Box ml={3} mr={3} display="flex" justifyContent="center">
              <Button
                id="saveAddition"
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isSubmitting}
              >
                {addition?.id ? 'EDITAR ADICIÓN' : 'CREAR ADICIÓN'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </BoxWrapper>
  );
};

export default AdditionEditForm;
