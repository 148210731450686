import { useMemo } from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { FirestoreQueryResult, Nullable } from '../../types';
import { COLLECTIONS } from '../../constants';
import { JourneyProduction } from './journeyProduction.dto';
import { JourneyProductionProduct } from '../journeyProductionProduct';

export const useGetLastOpenJourneyProduction = (
  restaurant_id: string = '',
  subsidiary_id: string = '',
): FirestoreQueryResult<JourneyProduction> => {
  const query = useMemo(() => {
    if (restaurant_id && subsidiary_id) {
      return [{
        collection: COLLECTIONS.RESTAURANT,
        doc: restaurant_id,
        subcollections: [{
          collection: COLLECTIONS.SUBSIDIARY,
          doc: subsidiary_id,
        },
        {
          collection: COLLECTIONS.JOURNEY_PRODUCTION,
          orderBy: ['date_start', 'desc'] as any,
        }],
        storeAs: 'last_journey_production',
        limit: 1,
      }];
    }
    return [];
  }, [restaurant_id, subsidiary_id]);

  // @ts-nocheck
  useFirestoreConnect(query);

  const journeyProduction = useSelector(
    (state:any) => state.firestore.ordered.last_journey_production,
  );
  const loaded = isLoaded(journeyProduction);
  if (loaded) {
    const [firstJourney] = journeyProduction.map((journeyProd:JourneyProduction) => new JourneyProduction({ ...journeyProd }));
    return {
      isLoading: false,
      value: firstJourney,
    };
  }
  return {
    isLoading: true,
    value: null,
  };
};

export const useGetProductsOfJourneyProduction = (
  restaurant_id: string = '',
  subsidiary_id: string = '',
  journey_production_id: string = '',
): Nullable<JourneyProductionProduct[]> => {
  const query = useMemo(() => {
    if (restaurant_id
      && subsidiary_id
      && journey_production_id) {
      return [{
        collection: COLLECTIONS.RESTAURANT,
        doc: restaurant_id,
        subcollections: [{
          collection: COLLECTIONS.SUBSIDIARY,
          doc: subsidiary_id,
        },
        {
          collection: COLLECTIONS.JOURNEY_PRODUCTION,
          doc: journey_production_id,
        },
        {
          collection: COLLECTIONS.JOURNEY_PRODUCTION_PRODUCTS,
        }],
        storeAs: 'actual_products_production',
      }];
    }
    return [];
  }, [restaurant_id, subsidiary_id, journey_production_id]);
  useFirestoreConnect(query);

  const productsProduction = useSelector(
    (state:any) => state.firestore.ordered.actual_products_production,
  );
  if (productsProduction) {
    return productsProduction.map((productProd:JourneyProductionProduct) => new JourneyProductionProduct({ ...productProd }));
  }

  return null;
};

export const useAllJourneyProductionSubsidiary = (restaurantId: string, subsidiaryId: string): JourneyProduction [] => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANT,
      doc: restaurantId,
      subcollections: [{
        collection: COLLECTIONS.SUBSIDIARY,
        doc: subsidiaryId,
      },
      {
        collection: COLLECTIONS.JOURNEY_PRODUCTION,
        orderBy: ['created_at', 'desc'],
      }],
      storeAs: 'all_journey_subsidiary',
    },
  ]);
  const allJourneySubsidiary = useSelector((state:any) => state.firestore.ordered.all_journey_subsidiary);
  return allJourneySubsidiary || [];
};
