import React, { useState } from 'react';
import {
  Box, Button, CardHeader, CircularProgress, Grid, TextField, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { BaseModal } from '../../modal';
import { DragImage } from '../dragImage';
import { Gallery } from '../../../core';
import { UploadImgGalleryProps } from '../../../screens/galleryScreen/types';
import CropModal from '../../modal/cropModal';
import { getDocId } from '../../../config/firebaseService/firestore';
import { COLLECTIONS } from '../../../constants';

const CustomGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CustomMainBox = styled(Box)`
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
`;

type AddGalleryProps = {
  onUploadImg: ({ file, docId }: UploadImgGalleryProps) => void | Promise<boolean>,
  open: boolean,
  onClose: () => void,
  onAddGallery: (gallery: Gallery) => Promise<Gallery | null>,
}

type DataCompleteProps = {
  originalFile: Blob | File | null,
  file: Blob | File | null,
  docId: string,
  gallery: Gallery,
}

export const ModalAddGallery = ({
  open, onClose, onUploadImg, onAddGallery,
}: AddGalleryProps) => {
  const [currentFile, setCurrentFile] = useState<File>();
  const [showCropModal, setShowCropModal] = useState(false);
  const [requiredImg, setRequiredImg] = useState(false);
  const [required, setRequired] = useState(false);
  const [extensionImg, setExtensionImg] = useState('');
  const [typeImage, setTypeImage] = useState('');
  const [newTitle, setNewTitle] = useState('');
  const [previewImg, setPreviewImg] = useState('');
  const [loader, setLoader] = useState(false);
  const [dataComplete, setDataComplete] = useState<DataCompleteProps>({
    originalFile: null,
    file: null,
    docId: '',
    gallery: new Gallery({}),
  });

  const handleShowCrop = () => {
    setShowCropModal(!showCropModal);
  };

  const handleClose = () => {
    setCurrentFile(undefined);
    setShowCropModal(false);
  };

  const handleDropGallery = (files: FileList | File[] | null) => {
    const file = files;
    if (file) {
      const extension = file[0]?.name?.split('.').pop();
      if (extension) {
        setExtensionImg(extension);
      }
      setTypeImage(file[0].type);
      setCurrentFile(file[0]);
      handleShowCrop();
    }
  };

  const handleConfirmImage = async ({ file, originalFile }: UploadImgGalleryProps) => {
    if (originalFile && file) {
      const docId = getDocId(COLLECTIONS.GALLERY);
      const dataGallery = new Gallery({
        ...dataComplete.gallery,
        id: docId,
        title: newTitle,
        extension: extensionImg,
      });
      const img = URL.createObjectURL(file);
      setPreviewImg(img);
      handleShowCrop();
      setDataComplete({
        originalFile, file, docId, gallery: dataGallery,
      });
    }
  };

  const handleCloseMainModal = () => {
    setPreviewImg('');
    setNewTitle('');
    setTypeImage('');
    setCurrentFile(undefined);
    setDataComplete({
      originalFile: null, file: null, docId: '', gallery: new Gallery({}),
    });
    onClose();
  };

  const requiredForm = async () => {
    if (newTitle && dataComplete.file && dataComplete.originalFile) {
      setLoader(true);
      const gallery = await onAddGallery(dataComplete.gallery);
      if (gallery) {
        await onUploadImg({ ...dataComplete, gallery });
        setLoader(false);
        handleCloseMainModal();
      }
    }
    if (!newTitle) {
      setRequired(true);
    }
    if (!dataComplete.file && !dataComplete.originalFile) {
      setRequiredImg(true);
    }
  };

  return (
    <BaseModal open={open} size="sm" onClose={handleCloseMainModal} fullWidth>
      <CustomGrid container justifyContent="center">
        <CardHeader
          title={<Typography variant="h3">Agregar nueva imagen en la galeria</Typography>}
        />
        {!loader && (
          <>
            <Box mt={2} mb={3} width="40%">
              <TextField
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                name="title"
                variant="outlined"
                placeholder="Título de la imagen"
                fullWidth
              />
              {required && !newTitle && (
              <Typography color="red">
                Título Requerido
              </Typography>
              )}
            </Box>
            <Box mt={1} mb={4} width="24%">
              {previewImg && (
              <CustomMainBox
                style={{ backgroundImage: `url("${previewImg}")` }}
              />
              )}
              {!previewImg && (
              <DragImage
                handleDropGallery={handleDropGallery}
              />
              )}
              {requiredImg && !currentFile && (
              <Typography color="red">
                Imagen Requerida
              </Typography>
              )}
            </Box>
            <Box mt={3} width="100%">
              <Grid item container justifyContent="center" spacing={4}>
                <Box mr={2}>
                  <Button
                    variant="contained"
                    onClick={handleCloseMainModal}
                  >
                    Cancelar
                  </Button>
                </Box>
                <Box mr={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={requiredForm}
                  >
                    Guardar
                  </Button>
                </Box>
              </Grid>
            </Box>
          </>
        )}
        {loader && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              width: '100%',
              height: '300px',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </CustomGrid>
      <CropModal
        openDialog={showCropModal}
        onClose={handleClose}
        file={currentFile}
        onConfirm={
          (
            { file, originalFile }: UploadImgGalleryProps,
          ) => handleConfirmImage({ file, originalFile })
          }
        typeImage={typeImage}
      />
    </BaseModal>
  );
};
