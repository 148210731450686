/* eslint-disable max-len */
import React, { useMemo } from 'react';
import nProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useNavigate, useParams } from 'react-router-dom';
import { HeadTitle } from '../../../components';
import {
  Product,
  useLocalProductsBySectionId,
  editLocalProduct,
  saveLocalProductsOrder,
  MenuSection,
  useIsTemplateMenu,
  updateProductsProductionId,
  JourneyProductionProduct,
} from '../../../core';
import { useAppSelector } from '../../../appHooks/redux';
import { enableProductProps } from '../../../screens/productSectionScreen/types';
import { ProductSectionScreen } from '../../../screens';

const SubsidiarySectionId = () => {
  const router = useNavigate();
  const { sectionId } = useParams();
  const sectionIdParams = typeof sectionId === 'string' ? sectionId : '';
  const currentRestaurant = useAppSelector((state) => state.restaurant.restaurant);
  const isSharedMenu = useIsTemplateMenu();
  const currentSubsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  const { value: journeyProduction } = useAppSelector((state) => state.inventory.journeyProduction);
  const journeyProductionProduct = useAppSelector((state) => state.inventory.journeyProductionProducts);
  const productsListData = useLocalProductsBySectionId(currentRestaurant?.id || '', currentSubsidiary?.id || '', sectionIdParams);
  const menuListData = currentSubsidiary?.menu_section || [];

  nProgress.configure({ showSpinner: false });

  const menuSection = useMemo(() => {
    if (sectionId && menuListData) {
      return menuListData?.filter((item: MenuSection) => item.id === sectionId)[0];
    }
    return null;
  }, [sectionId, menuListData]);

  const onEnableProduct = async ({
    productId, checked,
  }: enableProductProps) => {
    const journeyProductionProductFinded = journeyProductionProduct?.find((prod:JourneyProductionProduct) => prod.product.id === productId);
    if (currentRestaurant && currentSubsidiary && journeyProduction && journeyProductionProductFinded) {
      const journeyProductionProductPlaned = journeyProductionProductFinded.toPlainObject();
      await editLocalProduct({ availability: checked }, currentRestaurant.id, currentSubsidiary.id, productId);
      if (journeyProductionProductPlaned && journeyProductionProductPlaned.id && journeyProductionProductPlaned.product) {
        await updateProductsProductionId(
          currentRestaurant?.id,
          currentSubsidiary?.id,
          journeyProduction?.id,
          journeyProductionProductPlaned?.id || '',
          {
            product: {
              ...journeyProductionProductPlaned?.product,
              availability: checked,
            },
          },
        );
      }
    }
  };

  const onDragEnd = async (products: Product[]) => {
    if (currentRestaurant && currentSubsidiary) {
      await saveLocalProductsOrder(products, currentRestaurant.id, currentSubsidiary.id);
    }
  };

  const onClickProduct = async (productId: string) => {
    router(`/subsidiaryMenu/${sectionId}/product/${productId}`);
  };

  return (
    <>
      <HeadTitle title="Productos de Sucursal" />
      <ProductSectionScreen
        productsDB={productsListData}
        isSharedMenu={isSharedMenu}
        onDragEnd={onDragEnd}
        onEnableProduct={onEnableProduct}
        onClickProduct={onClickProduct}
        menuSection={menuSection}
      />
    </>
  );
};

export default SubsidiarySectionId;
