import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';
import { User } from '../../../core';

type UserState = {user:User | null}

const initialState: UserState = { user: null };

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: Draft<UserState>, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    cleanUser: (state: Draft<UserState>) => {
      state.user = null;
    },
  },
});

export const { setUser, cleanUser } = userSlice.actions;
export default userSlice.reducer;
export const userReducerTransform = createTransform(
  (toSave:any) => ({
    ...toSave,
  }),
  (toRehydrated) => ({
    ...toRehydrated,
  }),
  { whitelist: ['users'] },
);
