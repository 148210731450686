import {
  addDays,
  Locale,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import moment from 'moment-timezone';
import { DefinedRange } from 'mui-daterange-picker';

export const getDefaultRanges = (date: Date, locale?: Locale): DefinedRange[] => [
  {
    label: 'Hoy',
    startDate: moment(date).startOf('day').toDate(),
    endDate: moment(date).endOf('day').toDate(),
  },
  {
    label: 'Ayer',
    startDate: moment(addDays(date, -1)).startOf('day').toDate(),
    endDate: moment(addDays(date, -1)).endOf('day').toDate(),
  },
  {
    label: 'Esta semana',
    startDate: startOfWeek(date, { locale }),
    endDate: date,
  },
  {
    label: 'Este mes',
    startDate: startOfMonth(date),
    endDate: date,
  },
  {
    label: 'Este año',
    startDate: startOfYear(date),
    endDate: date,
  },
];
