import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  MenuItem,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSnackbar } from 'notistack';
import { RoundedButton } from '../button';
import { COLOR, VARIANT } from '../button/enum';
import { BaseModal } from './baseModal';
import { COLORS } from '../../constants';
import { DiscountButtons } from '../discount';

export type Discount = {
  discount: number;
  reason?: string;
  afterDiscount: number;
};

type DialogProps = {
  open: boolean;
  onClose: () => void;
  totalPrice: number;
  onAcept: (total: Discount) => void;
};

const CustomTextField = styled(TextField)(() => ({
  background: COLORS.WHITE,
  boxShadow: '0px 4px 5px 2px rgba(0, 0, 0, 0.25)',
  borderRadius: '4px',
}));

const ArrowIcon = styled(KeyboardArrowDownIcon)(() => ({
  color: '#4E9788 !important',
}));

const CustomTitle = styled(Typography)(() => ({
  paddingBottom: '15px',
}));

const CustomContainer = styled(Grid)(() => ({
  border: '1px solid #A19991',
  borderRadius: '10px',
  paddingBottom: '20px',
  textAlign: 'center',
  padding: 15,
}));
const CustomDivider = styled(Divider)(() => ({
  backgroundColor: '#A19991',
  border: '1px solid #A19991',
  marginBottom: '15px',
}));

const CalculateButton = styled(Button)(() => ({
  color: 'black',
  borderRadius: '11px',
  border: '2px solid #1E346E',
  textTransform: 'none',
  fontWeight: 'bold',
}));
export const DiscountModal = ({
  open,
  onClose,
  totalPrice,
  onAcept,
}: DialogProps) => {
  const [amount, setAmount] = useState<string>('');
  const [totalAmount, setTotalAmount] = useState(totalPrice);
  const [discount, setDiscount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const reasonRef = useRef<HTMLInputElement | null>(null);
  const selectRef = useRef<HTMLInputElement | null>(null);
  const buttonConfirmRef = useRef<HTMLButtonElement>(null);
  const onCalculateClick = () => {
    const discountOption = selectRef.current?.value;
    if (discountOption === 'Bs') {
      if (Number(amount) < totalPrice) {
        setDiscount(Number(amount));
        setTotalAmount(Number(totalPrice) - Number(amount));
      } else {
        enqueueSnackbar('El descuento no puede ser menor al monto total', {
          variant: 'warning',
        });
      }
    }
    if (discountOption === '%') {
      if (Number(amount) <= 100) {
        const discountLocal = (Number(amount) * Number(totalPrice)) / 100;
        setDiscount(Number(discountLocal));
        setTotalAmount(Number(totalPrice) - Number(discountLocal));
      } else {
        enqueueSnackbar('El descuento no puede ser mayor al 100%', {
          variant: 'warning',
        });
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (buttonConfirmRef.current) {
        const { key } = event;
        if (key === 'Enter') {
          buttonConfirmRef.current.focus();
        }
      }
    });
  }, []);

  return (
    <BaseModal open={open} size="sm" onClose={onClose} fullWidth>
      <Grid container justifyContent="center">
        <CustomTitle variant="h6">
          <strong>Descuentos</strong>
        </CustomTitle>
        <CustomContainer item container flexDirection="column">
          <Typography fontWeight="bold" sx={{ padding: 1 }}>
            Total
          </Typography>
          <Typography
            variant="h5"
            sx={{ color: COLORS.ROYAL_BLUE_DARK, paddingBottom: '15px' }}
            fontWeight="bold"
          >
            {`Bs. ${totalPrice.toFixed(2)}`}
          </Typography>
          <CustomDivider />
          <Grid
            container
            justifyContent="space-around"
            flexDirection="row"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                flexDirection="row"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography textAlign="left">Descuento</Typography>
                </Grid>

                <Grid item xs={12}>
                  <CustomTextField
                    id="discountAmountInput"
                    fullWidth
                    placeholder="5"
                    sx={{ textAlign: 'left' }}
                    size="small"
                    color="secondary"
                    SelectProps={{
                      IconComponent: ArrowIcon,
                    }}
                    inputRef={selectRef}
                    defaultValue="Bs"
                    variant="outlined"
                    select
                  >
                    <MenuItem value="%">{`% ${amount}`}</MenuItem>
                    <MenuItem selected value="Bs">{`Bs ${amount}`}</MenuItem>
                  </CustomTextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography textAlign="left">Razon</Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    id="reasonDiscountInput"
                    fullWidth
                    type="text"
                    color="secondary"
                    placeholder="Ej. Descuento por la tercera compra"
                    size="small"
                    variant="outlined"
                    multiline
                    inputRef={reasonRef}
                    minRows={2}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  justifyContent="center"
                  my={4}
                >
                  <Grid item xs={3}>
                    <CalculateButton
                      id="calculateBtn"
                      size="small"
                      onClick={onCalculateClick}
                      variant="outlined"
                      fullWidth
                      color="secondary"
                      ref={buttonConfirmRef}
                    >
                      Calcular
                    </CalculateButton>
                  </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <Grid
                    item
                    xs={7}
                    sx={{
                      backgroundColor: '#EFF2FB',
                      borderRadius: '10px',
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ color: COLORS.ROYAL_BLUE_DARK, padding: 2 }}
                      fontWeight="bold"
                    >
                      {`Total: Bs. ${totalAmount.toFixed(2)}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} mt={2}>
              <DiscountButtons
                onChange={(number) => {
                  setAmount(number);
                }}
              />
            </Grid>
          </Grid>
        </CustomContainer>

        <RoundedButton
          id="acceptDiscountBtn"
          style={{ textTransform: 'none', marginTop: '20px' }}
          label="Aceptar"
          onClick={() => {
            onAcept({
              afterDiscount: Number(totalAmount.toFixed(2)),
              discount,
              reason: reasonRef?.current?.value,
            });
          }}
          color={COLOR.SECONDARY}
          variant={VARIANT.CONTAINED}
        />
      </Grid>
    </BaseModal>
  );
};
