import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';

type TableReportProps = {
  rows: readonly any[];
  columns: GridColDef[]
}

const noRowsTableReport = () => (
  <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
    <Typography>Sin registros</Typography>
  </Box>
);

const TableReport = ({ rows, columns }: TableReportProps) => (
  <DataGrid
    style={{ height: '100%' }}
    rows={rows}
    columns={columns}
    pageSize={10}
    rowsPerPageOptions={[10]}
    sortingOrder={['asc', 'desc']}
    disableColumnMenu
    components={{
      NoRowsOverlay: noRowsTableReport,
    }}
  />
);

export default TableReport;
