/* eslint-disable max-len */
import nProgress from 'nprogress';
import 'nprogress/nprogress.css';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ref, getStorage } from 'firebase/storage';
import { useAppSelector } from '../../../../appHooks/redux';
import { HeadTitle } from '../../../../components';
import {
  editLocalProduct,
  useLocalProduct,
  MenuSection,
  Addition,
  saveLocalProductsAdditions,
  sharedWithOtherProducts,
  editLocalProductAdditions,
  useIsTemplateMenu,
  Gallery,
  updateGalleryImage,
  uploadImgGallery, addGallery,
} from '../../../../core';
import { useLocalAdditionsByProduct } from '../../../../core/additions/addition.hook';
import { ProductDetailsScreen } from '../../../../screens';
import { AdditionOptionFormValues } from '../../../../screens/productDetailsScreen/addition/additionEditView/AdditionEditForm';
import { ProductFormValues } from '../../../../screens/productDetailsScreen/ProductForm';
import { UploadImgGalleryProps } from '../../../../screens/galleryScreen/types';
import { PictureModule } from '../../../../core/modules/picture.module';

const SubsidiaryProductId = () => {
  const { sectionId, productId } = useParams();
  const productIdParams = typeof productId === 'string' ? productId : '';
  const isSharedMenu = useIsTemplateMenu();
  const currentRestaurant = useAppSelector((state) => state.restaurant.restaurant);
  const currentSubsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  const currentProduct = useLocalProduct(currentRestaurant?.id || '', currentSubsidiary?.id || '', productIdParams);
  const menuListData = currentSubsidiary?.menu_section || [];
  const productSLocalAdditions = useLocalAdditionsByProduct(currentRestaurant?.id || '', currentSubsidiary?.id || '', productIdParams);
  nProgress.configure({ showSpinner: false });

  const menuSection = useMemo(() => {
    if (sectionId && menuListData) {
      return menuListData?.filter((item: MenuSection) => item.id === sectionId)[0];
    }
    return null;
  }, [sectionId, menuListData]);

  const onSubmitSave = async (values: ProductFormValues, currentProductId: string) => {
    if (currentRestaurant && currentSubsidiary) {
      const newValues = { ...values };
      if (!newValues.unit_price) {
        newValues.unit_price = 0;
      }
      await editLocalProduct(newValues, currentRestaurant.id, currentSubsidiary.id, currentProductId);
    }
  };

  const onDragEndProductsAdditions = async (prodId:string, additions: Addition[]) => {
    if (currentRestaurant && currentSubsidiary) {
      await saveLocalProductsAdditions(currentRestaurant.id, currentSubsidiary.id, prodId, additions);
    }
  };

  const onSharedWithOtherProducts = async (menuAdditionsId: string, prodId:string):Promise<number> => {
    if (currentRestaurant) {
      return sharedWithOtherProducts(currentRestaurant.id, menuAdditionsId, prodId);
    }
    return 0;
  };

  const onAddGallery = async (dataGallery: Gallery):Promise<Gallery | null> => {
    if (currentRestaurant?.id) {
      await addGallery(currentRestaurant.id, dataGallery);
      return dataGallery;
    }
    return null;
  };

  const onUpdateGallery = async (dataGallery: Gallery) => {
    if (currentRestaurant?.id) {
      await updateGalleryImage(currentRestaurant.id, dataGallery);
    }
  };

  const onUploadImg = async ({
    file, docId, gallery, originalFile,
  }: UploadImgGalleryProps):Promise<boolean> => {
    if (currentRestaurant?.id && originalFile && docId) {
      const restId = currentRestaurant.id;
      const galleryRef = ref(getStorage(), `restaurant_gallery/${restId}/${docId}/${docId}`);
      const galleryOriginalRef = ref(getStorage(), `restaurant_gallery/${restId}/${docId}`);
      const urlOriginal = await uploadImgGallery(originalFile, galleryOriginalRef);
      const url = await uploadImgGallery(file, galleryRef);
      const image = await PictureModule.getPhotoUrls(restId, docId, url);
      const dataGallery = new Gallery({
        ...gallery,
        original_image: urlOriginal,
        image,
      });
      await onUpdateGallery(dataGallery);
      return true;
    }
    return false;
  };

  const onEditAdditionOptionsProduct = async (
    swaped: AdditionOptionFormValues[],
    additionId:string,
    prodId:string,
  ) => {
    if (currentRestaurant && currentSubsidiary) {
      await editLocalProductAdditions(
        currentRestaurant.id,
        currentSubsidiary.id,
        prodId,
        additionId,
        {
          options: swaped,
        },
      );
    }
  };

  return (
    <>
      <HeadTitle title="Producto compartido" />
      <ProductDetailsScreen
        isSharedMenu={isSharedMenu}
        currentProduct={currentProduct}
        onSubmitSave={onSubmitSave}
        menuSection={menuSection}
        productAdditions={productSLocalAdditions}
        onDragEndProductsAdditions={onDragEndProductsAdditions}
        onSharedWithOtherProducts={onSharedWithOtherProducts}
        onEditAdditionOptionsProduct={onEditAdditionOptionsProduct}
        onUploadImg={onUploadImg}
        onAddGallery={onAddGallery}
      />
    </>
  );
};

export default SubsidiaryProductId;
