import React, { useEffect, useState } from 'react';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { swap } from 'react-grid-dnd';
import {
  Box, Grid, Typography, Checkbox, styled, CircularProgress,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Addition, AdditionOption } from '../../../core';
import { COLORS } from '../../../constants';
import { BoxLoadingWrapper, BoxWrapper } from '../../../components/box';
import { AdditionOptionFormValues } from './additionEditView/AdditionEditForm';

const GridContainer = styled(Grid)(() => ({
  marginBottom: '0',
  borderBottom: `1px solid ${COLORS.GRAY_LIGHT_3}`,
  '&:last-child': {
    marginBottom: 0,
    borderBottom: 'none',
  },
}));

const GridItem = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1),
  borderRight: `1px solid ${COLORS.GRAY_LIGHT_3}`,
  '&:last-child': {
    borderRight: 'none',
  },
}));

const BoxField = styled(Box)(() => ({
  minWidth: '60px',
  display: 'inline-block',
  textAlign: 'left',
}));

const BoxDrag = styled(Box)(({ theme }) => ({
  minWidth: '60px',
  display: 'flex',
  textAlign: 'left',
  alignItems: 'center',
  marginRight: theme.spacing(1),
}));

const BoxCustom = styled(Box)(() => ({
  '&:hover': {
    background: COLORS.BLUE_LIGHT_92,
    cursor: 'pointer',
  },
}));

type DragDropAdditionsItemProps = {
  additionOptions: AdditionOption[],
  addition: Addition,
  prodId: string,
  isSharedMenu: boolean,
  columnsSize: number,
  loading: boolean,
  onLoading: (value:boolean) => void,
  onEditAdditionOptionsRestaurantAndProduct?: (
    swaped: AdditionOption[],
    menuAdditionsId: string,
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
  onEditAdditionOptionsProduct?: (
    swaped: AdditionOptionFormValues[],
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
}
const DragDropAdditionsItem = ({
  additionOptions,
  addition,
  prodId,
  isSharedMenu,
  columnsSize,
  loading,
  onLoading,
  onEditAdditionOptionsRestaurantAndProduct,
  onEditAdditionOptionsProduct,
}: DragDropAdditionsItemProps) => {
  const [options, setOptions] = useState<AdditionOption[]>([]);

  useEffect(() => {
    if (additionOptions && additionOptions.length > 0) {
      setOptions(additionOptions);
    }
  }, [additionOptions]);

  const handleChangeStatus = async (optionId:string, status: boolean) => {
    if (onEditAdditionOptionsProduct) {
      onLoading(true);
      if (addition && addition.options && addition.options.length > 0) {
        const newOptions: AdditionOptionFormValues[] = addition.options.map((a) => {
          if (a.id === optionId) {
            return { ...a, availability: status };
          } return { ...a };
        });
        await onEditAdditionOptionsProduct(newOptions, addition.id, prodId);
      }
      onLoading(false);
    }
  };

  const dragEnd = async ({ destination, source }: DropResult) => {
    if (!destination) return;
    onLoading(true);
    const swaped = swap(
      additionOptions,
      source.index,
      destination.index,
    );
    setOptions(swaped);
    if (onEditAdditionOptionsRestaurantAndProduct) {
      await onEditAdditionOptionsRestaurantAndProduct(
        swaped,
        addition.menu_additions_id,
        addition.id,
        prodId,
      );
    }
    if (onEditAdditionOptionsProduct) {
      await onEditAdditionOptionsProduct(swaped, addition.id, prodId);
    }
    setTimeout(() => {
      onLoading(false);
    }, 1500);
  };

  return (
    <DragDropContext onDragEnd={dragEnd}>
      <Droppable droppableId="droppableItems">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {options.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(prov, snapshot) => (
                  <BoxCustom
                    ref={prov.innerRef}
                    {...prov.draggableProps}
                    {...prov.dragHandleProps}
                    sx={{
                      backgroundColor: snapshot.isDragging ? COLORS.BLUE_LIGHT_92 : '',
                      opacity: snapshot.isDragging ? 0.5 : 1,
                    }}
                  >
                    <BoxWrapper>
                      {loading && (
                        <BoxLoadingWrapper>
                          <CircularProgress />
                        </BoxLoadingWrapper>
                      )}
                      <GridContainer container key={item.id}>
                        <GridItem
                          item
                          xs={columnsSize}
                        >
                          <BoxDrag>
                            <DragIndicatorIcon
                              fontSize="medium"
                            />
                            <Typography>{item.name}</Typography>
                          </BoxDrag>
                        </GridItem>
                        <GridItem
                          item
                          xs={columnsSize}
                        >
                          <BoxField>
                            <Typography>
                              Bs.
                              {item.price}
                            </Typography>
                          </BoxField>
                        </GridItem>
                        {!isSharedMenu && (
                          <GridItem
                            item
                            xs={columnsSize}
                          >
                            <BoxField>
                              <Checkbox
                                checked={item.availability}
                                onChange={(event) => {
                                  handleChangeStatus(item.id, event.target.checked);
                                }}
                                color="secondary"
                              />
                            </BoxField>
                          </GridItem>
                        )}
                      </GridContainer>
                    </BoxWrapper>
                  </BoxCustom>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropAdditionsItem;
