import { useSelector } from 'react-redux';
import { ReduxFirestoreQuerySetting, mapper, useFirestoreConnect } from 'react-redux-firebase';
import { COLLECTIONS } from '../../constants';
import { Nullable } from '../../types';
import { Subsidiary } from './subsidiary.dto';

export const useSubsidiaries = (
  restaurant_id: string = '',
): Nullable<Subsidiary[]> => {
  // eslint-disable-next-line max-len
  let query: string | ReduxFirestoreQuerySetting | ReduxFirestoreQuerySetting[] | string[] | mapper<unknown, (string | ReduxFirestoreQuerySetting)[]> | mapper<unknown, string | ReduxFirestoreQuerySetting> | undefined = [];
  if (restaurant_id) {
    query = [{
      collection: COLLECTIONS.RESTAURANT,
      doc: restaurant_id,
      subcollections: [{
        collection: COLLECTIONS.SUBSIDIARY,
      },
      ],
      storeAs: 'all_subsidiaries',
    }];
  }
  useFirestoreConnect(query);

  const subsidiaries = useSelector(
    (state:any) => state.firestore.ordered.all_subsidiaries,
  );

  if (subsidiaries) {
    const subsidiariesList: Nullable<Subsidiary[]> = [];
    subsidiaries.forEach((subsidiary:Subsidiary, index: any) => {
      subsidiariesList.push(new Subsidiary({ ...subsidiary, index: `${index + 1}` }));
    });
    return subsidiariesList;
  }

  return null;
};

/* export const useSubsidiary = (
  restaurantId?:string,
  subsidiaryId?:string,
): Nullable<Subsidiary> => {
  useFirestoreConnect(
    [{
      collection: COLLECTIONS.RESTAURANT,
      doc: restaurantId,
      subcollections: [{
        collection: COLLECTIONS.SUBSIDIARY,
        doc: subsidiaryId,
      },
      ],
      storeAs: `subsidiary_selected_${subsidiaryId}`,
    }],
  );

  const subsidiary = useSelector(
    (state:any) => state.firestore.ordered[`subsidiary_selected_${subsidiaryId}`],
  );

  if (subsidiary) {
    return new Subsidiary({ ...subsidiary[0] });
  }

  return null;
}; */
