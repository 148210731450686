import React from 'react';
import {
  Dialog,
  Box,
  IconButton,
  Breakpoint,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CrossIcon } from '../imageIcons';

type SimpleModalProps = {
  show: boolean,
  children?: React.ReactNode,
  onClose: () => void,
  size?: Breakpoint,
  hideCloseIcon?: boolean,
}

const BoxModalCustom = styled(Box)(() => ({
  width: '100%',
}));

const CardHeaderCustom = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
}));

export const SimpleModal = ({
  show,
  children = <div />,
  onClose = () => {},
  size = 'md',
  hideCloseIcon = false,
}: SimpleModalProps) => (
  <Dialog
    fullWidth
    maxWidth={size}
    BackdropProps={{
      style: {
        background: '#1A1A1A',
        opacity: 0.44,
      },
    }}
    open={show}
  >
    <Stack p={2}>
      <CardHeaderCustom>
        {!hideCloseIcon && (
        <IconButton
          onClick={() => onClose()}
        >
          <CrossIcon />
        </IconButton>
        )}
      </CardHeaderCustom>
      <BoxModalCustom>{children}</BoxModalCustom>
    </Stack>
  </Dialog>
);
