import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import RestaurantPhotoForm from './RestaurantPhotoForm';
import { Restaurant, Subsidiary } from '../../core';
import { Nullable } from '../../types';

const Container = styled(Card)(() => ({
  width: '100%',
  boxShadow: 'unset',
}));
const CustomDividir = styled(Divider)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.12)', border: 'none', height: '1px',
}));
type Payload = {
    banner:File|undefined;
    logo:File|undefined;
    restaurantName:string;
}
export type RestaurantForm = {
  currentRestaurant: Restaurant | null;
  loading:boolean,
  onSubmit:(data:Payload)=>void;
  subsidiaries?:Nullable<Subsidiary[]>
};
const RestaurantFormOnConfig = ({
  currentRestaurant, loading, onSubmit, subsidiaries,
}: RestaurantForm) => {
  const [isValidImage, setValidImage] = useState(true);

  const [banner, setBanner] = useState<File>();
  const [logo, setLogo] = useState<File>();

  if (!currentRestaurant) {
    return (
      <Box m="auto" pt={2}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Grid>
      <Formik
        enableReinitialize
        initialValues={{
          name: currentRestaurant.name ? currentRestaurant.name : '',
          subsidiary_amount: subsidiaries?.length || 0,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Requerido'),
          subsidiary_amount: Yup.number(),
        })}
        onSubmit={async (values) => {
          onSubmit({ logo, banner, restaurantName: values.name });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off" noValidate>
            <Grid item container>
              <Grid item container>
                <Container>
                  <CardHeader title="Datos sobre comercio:" />
                  <CustomDividir />
                  <CardContent>
                    <Grid item container md={12} justifyContent="space-between" sm={6}>
                      <Grid
                        item
                        container
                        direction="column"
                        md={3}
                        justifyContent="flex-end"
                      >
                        <Box mb={3}>
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            label="Nombre del Restaurante"
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            variant="outlined"
                          />
                        </Box>
                        <Box mb={3}>
                          <TextField
                            disabled
                            type="number"
                            error={Boolean(
                              touched.subsidiary_amount
                                && errors.subsidiary_amount,
                            )}
                            fullWidth
                            helperText={
                              touched.subsidiary_amount
                              && errors.subsidiary_amount
                            }
                            label="No de Sucursales"
                            name="subsidiary_amount"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.subsidiary_amount}
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                      <Grid item container md={2}>
                        <RestaurantPhotoForm
                          key={`restaurant_logo_${currentRestaurant.id}`}
                          restaurant={currentRestaurant}
                          onSelectImage={(file) => setLogo(file)}
                          validImage={(value) => setValidImage(value)}
                          type="logo"
                        />
                      </Grid>
                      <Grid item container md={5}>
                        <RestaurantPhotoForm
                          key={`restaurant_banner_${currentRestaurant.id}`}
                          restaurant={currentRestaurant}
                          onSelectImage={(file) => setBanner(file)}
                          validImage={(value) => setValidImage(value)}
                          type="banner"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{ marginTop: 32 }}
                      justifyContent="center"
                    >
                      <Button
                        sx={{

                          height: 48,
                          borderRadius: 2,
                        }}
                        color="secondary"
                        type="submit"
                        variant="contained"
                        disabled={loading || !isValidImage}
                      >
                        {loading ? <CircularProgress color="secondary" /> : (
                          <Typography
                            variant="body2"
                            color="white"
                            style={{ fontWeight: 'bold', fontSize: 16 }}
                          >
                            Guardar Cambios
                          </Typography>
                        ) }

                      </Button>
                    </Grid>
                  </CardContent>
                </Container>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Grid>
  );
};

export default RestaurantFormOnConfig;
