/* eslint-disable prefer-promise-reject-errors */
import * as xlsx from 'xlsx';

export const readFileFromXlsx = (file:any, headers:string[], firstRow:number) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject({ isSuccesRead: false, error: new Error('error on read xlsx') });
    };

    try {
      reader.onload = (e:any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = xlsx.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, {
          range: firstRow,
          header: headers,
        });
        resolve({ isSuccesRead: true, dataMenu: json });
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      reject({ isSuccesRead: false, error: new Error('error on read xlsx') });
    }
  });
};
