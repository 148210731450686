import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import CropIcon from '@mui/icons-material/Crop';
import { COLORS } from '../../constants';

const CustomMainBox = styled(Box)`
  background-color: rgb(0, 23, 20, 0.7);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
`;

const CustomIconOption = styled(Box)`
  background-color: ${COLORS.GREEN};
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomEditIcon = styled(EditIcon)`
  width: 24px;
  height: 24px;
  color: white;
`;

const CustomVisibilityIcon = styled(VisibilityIcon)`
  width: 24px;
  height: 24px;
  color: white;
`;

const CustomDownloadIcon = styled(DownloadIcon)`
  width: 24px;
  height: 24px;
  color: white;
`;

const CustomCropIcon = styled(CropIcon)`
  width: 24px;
  height: 24px;
  color: white;
`;

type OptionImageProps = {
  handleEdit?: () => void;
  handleShowImage?: () => void;
  handleDownloadDocument?: () => void;
  handleCropImage?: () => void;
}

export const OptionImage = ({
  handleEdit,
  handleShowImage,
  handleDownloadDocument,
  handleCropImage,
}: OptionImageProps) => (
  <CustomMainBox>
    <CustomIconOption id="edit" onClick={handleEdit}>
      <CustomEditIcon id="edit-icon" />
    </CustomIconOption>
    <CustomIconOption id="view" onClick={handleShowImage}>
      <CustomVisibilityIcon id="view-icon" />
    </CustomIconOption>
    <CustomIconOption id="download" onClick={handleDownloadDocument}>
      <CustomDownloadIcon id="download-icon" />
    </CustomIconOption>
    <CustomIconOption id="crop" onClick={handleCropImage}>
      <CustomCropIcon id="crop-icon" />
    </CustomIconOption>
  </CustomMainBox>
);
