import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Outlet } from 'react-router';
import LoginGuard from '../../guards/loginGuard';

export const LoginLayout = () => (
  <LoginGuard>
    <Outlet />
  </LoginGuard>
);
