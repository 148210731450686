import React, { useEffect, useRef } from 'react';
import {
  Box, Grid, Stack, styled, Typography, Button,
} from '@mui/material';
import { COLORS } from '../../constants';
import { RoundedButton } from '../button';
import { COLOR, VARIANT } from '../button/enum';
import { BaseModal } from './baseModal';

 type DialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  okText?: string;
  cancelText?: string;
  title: string;
};

const CustomContainer = styled(Box)(() => ({
  paddingTop: '40px',
  paddingBottom: '90px',
}));

const CustomTitle = styled(Typography)(() => ({
  paddingTop: '40px',
  paddingBottom: '40px',
}));

const CustomizedButton = styled(Button)`
  padding-inline: 45px;
  padding-block: 10px;
  border-radius: 10px;
  font-weight: bold;
  @media (max-width: 450px) {
    padding-inline: 31px;
    padding-block: 6px;
  }
`;

export const ConfirmModal = ({
  open,
  onClose,
  onConfirm,
  okText = 'Si',
  cancelText = 'No',
  title,
}: DialogProps) => {
  const buttonConfirmRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      const { key } = event;
      if (key === 'Enter' && buttonConfirmRef.current) {
        buttonConfirmRef.current.click();
      }
    });
  }, []);

  return (
    <BaseModal open={open} size="xs" onClose={onClose} fullWidth>
      <CustomContainer>
        <Grid container sx={{ textAlign: 'center' }}>
          <Grid item xs={12}>
            <CustomTitle variant="h5">{title}</CustomTitle>
            <Stack
              spacing={10}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <CustomizedButton
                id="confirmModalBtn"
                onClick={onConfirm}
                color={COLOR.SECONDARY}
                variant={VARIANT.CONTAINED}
                ref={buttonConfirmRef}
                key="btnConfirm"
              >
                {okText}
              </CustomizedButton>
              <RoundedButton
                label={cancelText}
                onClick={onClose}
                style={{ backgroundColor: COLORS.LIGHT_BLUE }}
                variant={VARIANT.CONTAINED}
              />
            </Stack>
          </Grid>
        </Grid>
      </CustomContainer>
    </BaseModal>
  );
};
