import {
  Box, Grid, List, ListItem, ListItemText, Stack, styled, Typography,
} from '@mui/material';
import * as React from 'react';
import { Metric } from '../../core';

import { BaseModal } from './baseModal';

   type DialogProps = {
    open: boolean;
    onClose: () => void;

    metrics : Metric[]
  };

const CustomContainer = styled(Box)(() => ({

}));

export const MetricModal = ({
  open,
  onClose,

  metrics,
}: DialogProps) => (
  <BaseModal open={open} size="xs" onClose={onClose} fullWidth>
    <CustomContainer>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold">
            Lista de métricas
          </Typography>
          <Stack
            spacing={1}
            direction="row"
          >
            <div>
              <List>
                {metrics.map((metric) => (
                  <ListItem key={metric.id}>
                    <ListItemText
                      primary={metric.name}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </CustomContainer>
  </BaseModal>
);
