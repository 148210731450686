/* eslint-disable no-nested-ternary,no-unneeded-ternary,prefer-destructuring */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { CheckCircle, Edit2, XCircle } from 'react-feather';
import { AdditionOption } from '../../../../core';
import { COLORS } from '../../../../constants';
import { roundTwoDecimals } from '../../../../utils/commonUtils';

const BoxCustom = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  wordBreak: 'break-word',
}));

const IconButtonCustom = styled(IconButton)(() => ({
  color: COLORS.ROYAL_BLUE_DARK,
}));

type AdditionItemFormProps = {
  index: number,
  additionOption: AdditionOption,
  initialyEditing: boolean,
  errors: any,
  showMax: boolean,
  onSave:(index: number, option: AdditionOption) => void,
  onDelete: (index: number) => void,
};

const AdditionItemForm = ({
  index,
  additionOption,
  onDelete,
  onSave,
  initialyEditing = false,
  errors = { name: '', price: 0 },
  showMax = false,
}: AdditionItemFormProps) => {
  const [editingItem, setEditingItem] = useState(false);
  useEffect(() => {
    if (initialyEditing) {
      setEditingItem(true);
    }
  // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={3} mb={2} alignItems="center">
      <Grid item md={4} xs={12}>
        {editingItem ? (
          <TextField
            id="name"
            error={!!errors.name}
            fullWidth
            helperText={errors.name ? 'Nombre requerido' : ''}
            label="Nombre"
            name="name"
            onChange={(e:any) => {
              onSave(index, { ...additionOption, name: e.currentTarget.value });
            }}
            value={additionOption.name}
            required
            variant="outlined"
            color="secondary"
          />
        ) : (
          <BoxCustom>
            <Typography variant="body1">
              {additionOption.name}
            </Typography>
          </BoxCustom>
        )}
      </Grid>
      <Grid item md={showMax ? 2 : 5} xs={12}>
        {editingItem ? (
          <TextField
            id="price"
            error={errors.price}
            fullWidth
            type="number"
            inputProps={{
              min: 0,
              step: 0.01,
            }}
            helperText={
              errors.price
                ? 'Precio requerido'
                : ''
            }
            label="Precio"
            name="price"
            onChange={(e:any) => {
              onSave(index, {
                ...additionOption,
                price:
                  e.currentTarget.value === ''
                    ? e.currentTarget.value
                    : roundTwoDecimals(+e.currentTarget.value),
              });
            }}
            value={additionOption.price}
            required
            variant="outlined"
            color="secondary"
          />
        ) : (
          <BoxCustom>
            <Typography variant="body1">{additionOption.price}</Typography>
          </BoxCustom>
        )}
      </Grid>
      <Grid item md={3} xs={12}>
        <Box display="flex" justifyContent="flex-end">
          {editingItem ? (
            <IconButtonCustom
              id="saveItem"
              type="button"
              onClick={() => {
                if (additionOption.name) {
                  setEditingItem(false);
                  onSave(index, { ...additionOption });
                }
              }}
            >
              <CheckCircle />
            </IconButtonCustom>
          ) : (
            <>
              <IconButtonCustom
                id="editItem"
                type="button"
                onClick={() => setEditingItem(true)}
              >
                <Edit2 />
              </IconButtonCustom>
              <IconButtonCustom
                id="deleteItem"
                type="button"
                onClick={() => {
                  onDelete(index);
                }}
              >
                <XCircle />
              </IconButtonCustom>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default AdditionItemForm;
