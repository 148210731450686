import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTheme } from '@mui/material';

type ChartProps = {
  labels: string[];
  data: string[];
};

const Chart = ({
  data, labels,
}: ChartProps) => {
  const theme = useTheme();

  const dataSet = {
    datasets: [
      {
        backgroundColor: theme.palette.secondary.main,
        data,
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.9,
        categoryPercentage: 1,
      },
    ],
    labels,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cornerRadius: 20,
    legend: {
      display: false,
    },
    layout: {
      padding: 12,
    },

    scales: {
      xAxes: [
        {
          stacked: false,
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            display: true,
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 48,
      xPadding: 48,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.background.default,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        legend: () => {},
        title: () => {},
        label: (tooltipItem:any) => {
          const label = `Views: ${tooltipItem.yLabel}`;

          return label;
        },
      },
    },
  };

  return (
    <div style={{ position: 'relative' }}>
      <Line height={350} data={dataSet} options={options} />
    </div>
  );
};

export default Chart;
