import { FirebaseBase } from '../firebaseBase';
import {
  Constructor,
  PlainObject,
} from '../../types';
import { AdditionOption } from '../additions';

export class MenuAddition extends FirebaseBase {
  enable: boolean;

  availability?: boolean;

  has_max_amount_addition: boolean;

  is_quantifiable?:boolean;

  name: string;

  order: number;

  mandatory?: boolean;

  mandatory_amount:boolean;

  multi_selection?: boolean;

  max_selection: number;

  options: AdditionOption[];

  single_addition:boolean;

  restaurant_id: string;

  constructor({
    enable = true,
    availability = false,
    has_max_amount_addition = false,
    is_quantifiable = false,
    mandatory_amount = false,
    single_addition = false,
    name = '',
    order = 0,
    mandatory = false,
    multi_selection = false,
    max_selection = 1,
    options = [],
    restaurant_id = '',
    ...data
  }:Constructor<MenuAddition>) {
    super(data);
    this.enable = enable;
    this.availability = availability;
    this.name = name;
    this.order = order;
    this.mandatory = mandatory;
    this.multi_selection = multi_selection;
    this.max_selection = max_selection;
    this.options = options;
    this.single_addition = single_addition;
    this.has_max_amount_addition = has_max_amount_addition;
    this.is_quantifiable = is_quantifiable;
    this.mandatory_amount = mandatory_amount;
    this.restaurant_id = restaurant_id;
  }

  toPlainObject(): PlainObject<MenuAddition> {
    return {
      ...this,
    };
  }
}
