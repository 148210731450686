// eslint-disable-next-line max-classes-per-file
import { Constructor, PlainObject } from '../../types';
import { Metric } from '../metric';
import { MenuSection } from '../menuSection';

export class ProductSummary {
  id: string;

  name:string;

  menu_section_id: string;

  menu_section: MenuSection;

  availability: boolean;

  constructor({
    id = '',
    name = '',
    menu_section_id = '',
    menu_section = new MenuSection({}),
    availability = false,
  }: Constructor<ProductSummary>) {
    this.id = id;
    this.name = name;
    this.menu_section_id = menu_section_id;
    this.menu_section = new MenuSection(menu_section);
    this.availability = availability;
  }

  toPlainObject(): PlainObject<ProductSummary> {
    return { ...this, menu_section: this.menu_section.toPlainObject() };
  }
}

export class JourneyProductionProduct {
  id: string;

  metric: Metric;

  initial: number;

  income: number;

  discount: number;

  sales: number;

  product: ProductSummary;

  constructor({
    id = '',
    metric = new Metric({}),
    initial = 0,
    income = 0,
    discount = 0,
    sales = 0,
    product = new ProductSummary({}),
  }: Constructor<JourneyProductionProduct>) {
    this.id = id;
    this.metric = new Metric(metric);
    this.initial = initial;
    this.income = income;
    this.discount = discount;
    this.sales = sales;
    this.product = new ProductSummary(product);
  }

  toPlainObject(): PlainObject<JourneyProductionProduct> {
    return { ...this, product: this.product.toPlainObject(), metric: { ...this.metric } };
  }

  getStock() {
    return this.initial + this.income - this.sales - this.discount;
  }
}
