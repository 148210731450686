import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { MetricModal } from '../../components/modal/metricModal';
import { useMetrics } from '../../core/metric/metric.hook';
import { useRestaurantProfiles, useSubsidiaryProfiles } from '../../core/profile/profile.hook';
import { InventoryScreen } from '../../screens';
import { HeadTitle } from '../../components';
import { useAppSelector } from '../../appHooks/redux';
import {
  JourneyProductionProduct,
  useAllJourneySubsidiary,
  exportToXlsxJourneyProductionReport,
  updateProductsProductionId,
  Profile,
} from '../../core';
import { closeJourneyProductionWithProducts, createJourneyProductionWithProducts } from '../../core/journeyProduction/journeyProduction.service';
import { toTimestamp } from '../../utils/firestoreUtils';
import { useLoadingBackdrop } from '../../providers/loadingBackdropProvider/hooks';

const Inventory = () => {
  const [openModal, setOpenModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { showBackdrop, hideBackdrop } = useLoadingBackdrop();
  const metrics = useMetrics();
  const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  const restaurant = useAppSelector((state) => state.restaurant.restaurant);
  const { value: journeyProduction, isLoading } = useAppSelector((state) => state.inventory.journeyProduction);
  const products = useAppSelector((state) => state.inventory.journeyProductionProducts);
  const restaurantProfiles = useRestaurantProfiles(restaurant?.id || '');
  const subsidiaryProfiles = useSubsidiaryProfiles(restaurant?.id || '');
  const allJourneySubsidary = useAllJourneySubsidiary(restaurant?.id || '', subsidiary?.id || '');
  const allProfiles = useMemo(() => {
    if (restaurantProfiles && subsidiaryProfiles && subsidiary) {
      const profilesOfThisSubsidiary = subsidiaryProfiles.filter((profile:Profile) => profile.subsidiary_id === subsidiary.id);
      return [...restaurantProfiles || [], ...profilesOfThisSubsidiary || []].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
    }
    return [];
  }, [restaurantProfiles, subsidiaryProfiles]);
  const editable = useMemo(() => (journeyProduction?.date_end === null), [journeyProduction]);
  const openMetricModal = () => {
    setOpenModal(true);
  };

  const productsBySections = useMemo(() => {
    const result:any = {};
    if (!products) return null;
    products.forEach((productionProduct: JourneyProductionProduct) => {
      const { product, ...rest } = productionProduct;
      const { menu_section_id, menu_section } = product;
      const simpleProduct = {
        ...rest, menu_section, availability: product.availability, product_name: product.name, product_id: product.id, stock: productionProduct.getStock(),
      };
      if (!result[menu_section_id]) {
        result[menu_section_id] = {
          menu_section_id,
          menu_section_name: menu_section.name,
          menu_section_enable: menu_section.enable,
          products: [simpleProduct],
        };
      } else {
        result[menu_section_id].products.push(simpleProduct);
      }
    });
    return Object.keys(result).map((prod) => {
      const initial = _.sumBy(result[prod].products, (p:any) => p.initial);
      const income = _.sumBy(result[prod].products, (p:any) => p.income);
      const discount = _.sumBy(result[prod].products, (p:any) => p.discount);
      const sales = _.sumBy(result[prod].products, (p:any) => p.sales);
      const stock = _.sumBy(result[prod].products, (p:any) => p.stock);
      return {
        ...result[prod],
        initial,
        income,
        discount,
        sales,
        stock,
      };
    });
  }, [products]);

  const onExportToExcel = async () => {
    const dateStart = journeyProduction?.date_start ? moment(toTimestamp(journeyProduction.date_start).toDate()).format('LLL') : '-';
    const dateEnd = journeyProduction?.date_end ? moment(toTimestamp(journeyProduction.date_end).toDate()).format('LLL') : '-';
    exportToXlsxJourneyProductionReport(
      `${restaurant?.name || ''}${subsidiary?.name && `(${subsidiary.name})`}`,
      dateStart,
      dateEnd,
      products,
      journeyProduction,
    );
  };

  const openJourneyProduction = async (roleId: string, journeyId: string) => {
    if (roleId && journeyId && restaurant && subsidiary) {
      showBackdrop('Abriendo turno');
      const response = await createJourneyProductionWithProducts(restaurant.id, subsidiary.id, roleId, journeyId, journeyProduction ? journeyProduction.id : '');
      if (response && response?.id) {
        hideBackdrop();
        enqueueSnackbar('Se guardo correctamente', {
          variant: 'success',
        });
      } else {
        hideBackdrop();
        enqueueSnackbar('Ocurrio un error', {
          variant: 'error',
        });
      }
    }
  };

  const closeJourneyProduction = async () => {
    if (restaurant && subsidiary && journeyProduction) {
      showBackdrop('Cerrando turno');
      const response = await closeJourneyProductionWithProducts(restaurant.id, subsidiary.id, journeyProduction.id);
      if (response && response?.id) {
        hideBackdrop();
        enqueueSnackbar('Se guardo correctamente', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Ocurrio un error al cerrar turno', {
          variant: 'error',
        });
      }
    }
  };

  const saveMetric = async (metricId:string, productId:string) => {
    const metricSelected = metrics?.filter((metric) => metric.id === metricId)[0];
    if (restaurant && subsidiary && journeyProduction && metricSelected) {
      await updateProductsProductionId(restaurant.id, subsidiary.id, journeyProduction.id, productId, { metric: metricSelected.toPlainObject() });
    }
  };

  return (
    <>
      <HeadTitle title="Inventario" />
      <InventoryScreen
        isLoading={isLoading}
        metrics={metrics}
        onSaveMetric={saveMetric}
        metricClickHandler={openMetricModal}
        productsBySections={productsBySections}
        profiles={allProfiles}
        journeys={allJourneySubsidary}
        onOpenJourney={openJourneyProduction}
        onCloseJourney={closeJourneyProduction}
        journeyProduction={journeyProduction}
        onExportToExcel={onExportToExcel}
        editable={editable}
      />
      <MetricModal
        open={openModal}
        metrics={metrics || []}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default Inventory;
