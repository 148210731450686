import { FieldValue, serverTimestamp, Timestamp } from 'firebase/firestore';
import { FirebaseBase } from '../firebaseBase';
import { Constructor, PlainObject, Nullable } from '../../types';
import { Journey } from '../journey';
import { Profile } from '../profile';

export class JourneyProduction extends FirebaseBase {
  data_manager: Profile;

  date_start: Timestamp | FieldValue;

  date_end: Nullable<Timestamp | FieldValue>;

  journey: Journey;

  url: string;

  constructor({
    data_manager = new Profile({}),
    date_start = serverTimestamp(),
    date_end = null,
    journey = new Journey({}),
    url = '',
    ...data
  }:Constructor<JourneyProduction>) {
    super(data);
    this.data_manager = new Profile(data_manager);
    this.date_start = date_start;
    this.date_end = date_end;
    this.journey = new Journey(journey);
    this.url = url;
  }

  toPlainObject(): PlainObject<JourneyProduction> {
    return { ...this, data_manager: this.data_manager, journey: this.journey };
  }
}

export default JourneyProduction;
