import React from 'react';
import { ref, getStorage, getMetadata } from 'firebase/storage';
import { useAppSelector } from '../../appHooks/redux';
import {
  addGallery,
  deleteDocument,
  deleteGalleryFromProducts,
  deleteStorage,
  Gallery,
  updateGallery, uploadImgGallery,
  urlResizeOriginal,
  useGetGalleryByRestaurant,
} from '../../core';
import { HeadTitle } from '../../components';
import { UploadImgGalleryProps } from '../../screens/galleryScreen/types';
import { GalleryScreen } from '../../screens';
import { PictureModule } from '../../core/modules/picture.module';
import { COLLECTIONS } from '../../constants/index';

const GalleryPage = () => {
  const currentRestaurant = useAppSelector((state) => state.restaurant.restaurant);
  const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  const galleryList = useGetGalleryByRestaurant(currentRestaurant?.id);

  const onUpdateGallery = async (dataGallery: Gallery) => {
    if (currentRestaurant?.id) {
      await updateGallery(currentRestaurant.id, dataGallery);
    }
  };

  const onAddGallery = async (dataGallery: Gallery) => {
    if (currentRestaurant?.id) {
      await addGallery(currentRestaurant.id, dataGallery);
    }
  };

  const onDeleteGallery = async (urlImage: string[], docId: string) => {
    const restId = currentRestaurant?.id;
    const subId = subsidiary?.id;
    if (restId) {
      await deleteDocument(restId, docId);
      if (urlImage[0]) {
        const refGallery = ref(getStorage(), urlImage[0]);
        const metadata = getMetadata(refGallery);
        const { bucket } = await Promise.resolve(metadata);
        const pathSharedMenu = `${COLLECTIONS.RESTAURANT}/${restId}/${COLLECTIONS.SHARED_PRODUCTS}`;
        const pathLocalMenu = `${COLLECTIONS.RESTAURANT}/${restId}/${COLLECTIONS.SUBSIDIARY}/${subId}/${COLLECTIONS.LOCAL_PRODUCTS}`;
        const arrayUrl = [
          ...urlImage,
          urlResizeOriginal({
            bucket, restId, docId, resize: '400x400',
          }),
          urlResizeOriginal({
            bucket, restId, docId, resize: '40x40',
          }),
          urlResizeOriginal({
            bucket, restId, docId, resize: '80x80',
          }),
        ];
        await deleteStorage(arrayUrl);
        await deleteGalleryFromProducts({ galleryId: docId, pathProducts: pathSharedMenu });
        await deleteGalleryFromProducts({ galleryId: docId, pathProducts: pathLocalMenu });
      }
    }
  };

  const onUploadImg = async ({
    file, docId, gallery, originalFile,
  }: UploadImgGalleryProps):Promise<boolean> => {
    if (currentRestaurant?.id && originalFile && docId) {
      const restId = currentRestaurant.id;
      const galleryRef = ref(getStorage(), `restaurant_gallery/${restId}/${docId}/${docId}`);
      const galleryOriginalRef = ref(getStorage(), `restaurant_gallery/${restId}/${docId}`);
      const urlOriginal = await uploadImgGallery(originalFile, galleryOriginalRef);
      const url = await uploadImgGallery(file, galleryRef);
      const image = await PictureModule.getPhotoUrls(restId, docId, url);
      const dataGallery = new Gallery({
        ...gallery,
        original_image: urlOriginal,
        image,
      });
      await onUpdateGallery(dataGallery);
      return true;
    }
    return false;
  };

  return (
    <>
      <HeadTitle title="Galeria" />
      <GalleryScreen
        galleryList={galleryList}
        onUpdateGallery={onUpdateGallery}
        onAddGallery={onAddGallery}
        onUploadImg={onUploadImg}
        onDeleteGallery={onDeleteGallery}
      />
    </>
  );
};
export default GalleryPage;
