import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initFirebaseUser } from '../utils/firebaseUtils';
import { Loader } from '../components';
import { loadLoginData } from './loginProvider';

// eslint-disable-next-line no-undef
export const InitFirebase = ({ children }:{children:any}) => {
  const [showChildren, setShowChildren] = useState(false);
  useEffect(() => {
    initFirebaseUser().then(() => {
      setShowChildren(true);
    });
  }, []);
  if (showChildren) {
    return children;
  }
  return <Loader />;
};

export const InitApp = ({ children }:{children:any}) => {
  const [showChildren, setShowChildren] = useState(false);
  const dispatch = useDispatch();
  const run = useCallback(async () => {
    await loadLoginData(dispatch);
    setShowChildren(true);
  }, [dispatch]);
  useEffect(() => {
    run().then((r) => r);
  }, [run]);
  if (showChildren) {
    return children;
  }
  return <Loader />;
};
