import React from 'react';

import {
  Breadcrumbs,
  Button,
  Grid,
  Typography,
  Link,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../appHooks/redux';
import { COLORS } from '../../constants';

type HeaderProps = {
  isSharedMenu: boolean,
  sectionName: string,
};

const Header = ({
  isSharedMenu,
  sectionName,
}:HeaderProps) => {
  const { sectionId } = useParams();
  const currentRestaurant = useAppSelector((state) => state.restaurant.restaurant);
  const currentSubsidiary = useAppSelector((state) => state.subsidiary.subsidiary);

  return (
    <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" pt={3}>
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ color: COLORS.BLACK }}
        >
          <Link underline="hover" color="inherit" href={isSharedMenu ? '/sharedMenu' : '/subsidiaryMenu'}>
            <Typography
              sx={{
                fontWeight: 500,
                '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
              }}
            >
              Secciones de menú
            </Typography>
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href={
              isSharedMenu ? `/sharedMenu/${sectionId}` : `/subsidiaryMenu/${sectionId}`
            }
          >
            <Typography
              sx={{
                fontWeight: 500,
                '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
              }}
            >
              {sectionName}
            </Typography>
          </Link>
        </Breadcrumbs>
        <Typography color="textPrimary" sx={{ fontWeight: 300 }}>
          *Debes añadir mínimamente 1 producto por sección
        </Typography>
      </Grid>
      <Grid item>
        {isSharedMenu ? (
          <Typography variant="h3" color="textPrimary">
            {`${currentRestaurant?.name}`}
          </Typography>
        ) : (
          <Typography variant="h3" color="textPrimary">
            {`${currentRestaurant?.name} - ${currentSubsidiary?.name}`}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <a
          href={
            isSharedMenu ? `/sharedMenu/${sectionId}` : `/subsidiaryMenu/${sectionId}`
          }
          style={{ textDecoration: 'none' }}
        >
          <Button
            id="back"
            variant="contained"
            size="small"
            sx={{ backgroundColor: '#9F9D9F' }}
          >
            Atrás
          </Button>
        </a>
      </Grid>
    </Grid>
  );
};

export default Header;
