/* eslint-disable max-len */
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useMemo, useEffect, useRef } from 'react';
import { COLLECTIONS } from '../../constants';
import { Product } from './product.dto';
import { Nullable } from '../../types';

export const useMenuBySubsidiary = (
  restaurant_id: string = '',
  subsidiary_id: string = '',
): Nullable<Product[]> => {
  const query = useMemo(() => {
    if (restaurant_id && subsidiary_id) {
      return [{
        collection: COLLECTIONS.RESTAURANT,
        doc: restaurant_id,
        subcollections: [{
          collection: COLLECTIONS.SUBSIDIARY,
          doc: subsidiary_id,
        },
        {
          collection: COLLECTIONS.LOCAL_PRODUCTS,
          where: [
            ['availability', '==', true] as any,
          ],
        }],
        storeAs: 'subsidiary_products',
      }];
    }
    return [];
  }, [restaurant_id,
    subsidiary_id]);

  useFirestoreConnect(query);
  const products = useSelector(
    (state:any) => state.firestore.ordered.subsidiary_products,
  );

  if (products) {
    return products.map((product:Product) => new Product({ ...product }));
  }

  return null;
};

export const useSharedProductsBySectionId = (restaurantId: string, sectionId: string): Nullable<Product[]> => {
  useFirestoreConnect(
    [{
      collection: COLLECTIONS.RESTAURANT,
      doc: restaurantId,
      subcollections: [{
        collection: COLLECTIONS.SHARED_PRODUCTS,
      }],
      where: [
        ['menu_section_id', '==', sectionId],
        ['template', '==', true],
        ['enable', '==', true],
        ['subsidiary_id', '==', ''],
      ],
      storeAs: `shared_products_${sectionId}`,
    }],
  );

  const products = useSelector(
    (state:any) => state.firestore.ordered[`shared_products_${sectionId}`],
  );

  return useMemo(() => (products
    ? products
      .map((item: Product) => ({
        ...item,
        menu_order: item.menu_order || null,
      }))
      .sort((a:Product, b:Product) => (a.menu_order > b.menu_order ? 1 : -1))
    : null), [products]);
};

export const useLocalProductsBySectionId = (restaurantId: string, subsidiaryId: string, sectionId: string): Nullable<Product[]> => {
  useFirestoreConnect(
    [{
      collection: COLLECTIONS.RESTAURANT,
      doc: restaurantId,
      subcollections: [{
        collection: COLLECTIONS.SUBSIDIARY,
        doc: subsidiaryId,
      },
      {
        collection: COLLECTIONS.LOCAL_PRODUCTS,
      }],
      where: [
        ['subsidiary_id', '==', subsidiaryId],
        ['enable', '==', true],
        ['menu_section_id', '==', sectionId],
      ],
      storeAs: `local_products_${sectionId}`,
    }],
  );

  const products = useSelector(
    (state:any) => state.firestore.ordered[`local_products_${sectionId}`],
  );

  return useMemo(() => (products
    ? products
      .map((item: Product) => ({
        ...item,
        menu_order: item.menu_order || null,
      }))
      .sort((a:Product, b:Product) => (a.menu_order > b.menu_order ? 1 : -1))
    : null), [products]);
};

export const useSharedProduct = (restaurantId: string, productId: string): Nullable<Product> => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANT,
      doc: restaurantId,
      subcollections: [{
        collection: COLLECTIONS.SHARED_PRODUCTS,
        doc: productId,
      }],
      storeAs: `current_shared_product_${productId}`,
    },
  ]);
  const currentProduct = useSelector((state:any) => state.firestore.data[`current_shared_product_${productId}`]);
  return currentProduct || null;
};

export const useLocalProduct = (restaurantId: Nullable<string>, subsidiaryId: Nullable<string>, productId: Nullable<string>): Nullable<Product> => {
  let query: any = [];
  if (restaurantId && subsidiaryId && productId) {
    query = [
      {
        collection: COLLECTIONS.RESTAURANT,
        doc: restaurantId,
        subcollections: [{
          collection: COLLECTIONS.SUBSIDIARY,
          doc: subsidiaryId,
        },
        {
          collection: COLLECTIONS.LOCAL_PRODUCTS,
          doc: productId,
        }],
        storeAs: `current_local_product_${productId}`,
      },
    ];
  }
  useFirestoreConnect(query);
  const currentProduct = useSelector((state:any) => state.firestore.data[`current_local_product_${productId}`]);
  return currentProduct || null;
};

export const useClickOutsideSearchProduct = (handler: () => void) => {
  const domNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const listener = (e: any) => {
      if (domNode.current && !domNode.current.contains(e.target)) {
        handler();
      }
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  });
  return domNode;
};
