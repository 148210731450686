import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  styled,
  Link,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Subsidiary } from '../../core';
import { REGISTER_STATUS } from '../../core/subsidiary/subsidiary.enum';
import { Nullable } from '../../types';
import { ROUTES } from '../../constants';

type HeaderData = {
  id: string;
  label: string;
  filterName?: keyof Subsidiary;
}

type TableSubsidiariesProps = {
  rows?: Nullable<Subsidiary[]>;
  headerData:HeaderData[];

};

type Order = 'asc' | 'desc';

const OrderRow = styled(TableRow)`
  &:last-child td, &:last-child th {
    border: 0;
  }
`;
const getState = (item:REGISTER_STATUS) => {
  if (item === REGISTER_STATUS.PENDING) { return 'Pendiente'; }
  if (item === REGISTER_STATUS.ACTIVE) { return 'Activo'; }
  if (item === REGISTER_STATUS.ARCHIVED) { return 'Archivado'; }
  if (item === REGISTER_STATUS.SUBSCRIPTION_EXPIRED) { return 'Suscripción expirado'; }
  if (item === REGISTER_STATUS.SUSPENDED) { return 'Suspendido'; }
  if (item === REGISTER_STATUS.REJECTED) { return 'Rechazado'; }
  return 'Sin rol';
};

function descendingComparator(a: any, b: any, orderBy: any) {
  let A = '';
  let B = '';
  if (orderBy === 'name' || orderBy === 'address' || orderBy === 'index') {
    if (a[orderBy]) A = a[orderBy].toUpperCase();
    if (b[orderBy]) B = b[orderBy].toUpperCase();
  } else {
    A = a[orderBy];
    B = b[orderBy];
  }
  if (B < A) {
    return -1;
  }
  if (B > A) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array:any, comparator:any) => {
  const stabilizedThis = array.map((el:any, index:number) => [el, index]);
  stabilizedThis.sort((a:any, b:any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el:any) => el[0]);
};

export const TableSubsidiaries = ({
  rows, headerData,
}:TableSubsidiariesProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Subsidiary>('index');

  const onRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Subsidiary,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof Subsidiary) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function getData() {
    const rowsOrder = rows || [];
    return stableSort(rowsOrder, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }

  return (
    <>

      <TableContainer
        component={Paper}
      >
        <Table aria-label="oreder-table">
          <TableHead>
            <TableRow key="header-row">
              {headerData?.map((head:HeaderData) => (
                <TableCell align="center" key={head.id} sortDirection={orderBy === head.id ? order : false}>
                  {head.filterName ? (
                    <TableSortLabel
                      active={orderBy === head.id}
                      direction={orderBy === head.filterName ? order : 'asc'}
                      onClick={createSortHandler(head.filterName)}
                    >
                      <Typography variant="h4">{head.label}</Typography>
                    </TableSortLabel>
                  ) : (
                    <Typography variant="h4">{head.label}</Typography>

                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getData().map((row: any) => (
              <OrderRow
                key={`key-order-${row.id}`}
              >
                <TableCell align="center">{row.index || '-'}</TableCell>
                <TableCell align="center">{row.name || '-'}</TableCell>
                <TableCell align="center">{row.city.name || '-'}</TableCell>
                <TableCell align="center">{row.address || '-'}</TableCell>
                <TableCell align="center">{getState(row.register_status) || '-'}</TableCell>
                <TableCell align="center">
                  <Link
                    style={{ color: 'black', textDecorationLine: 'underline', textDecorationColor: 'black' }}
                    component={RouterLink}
                    to={`${ROUTES.SUBSIDIARY_INFO}/${row.id}`}

                  >
                    Ver Sucursal
                  </Link>
                </TableCell>

              </OrderRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={rows?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Sucursales por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} de ${
          count !== -1
            ? count
            : 'más'
        }`}
      />
    </>
  );
};
