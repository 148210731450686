import React, {
  useLayoutEffect, useRef, useEffect,
} from 'react';
import {
  Box, Stack, Divider, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../../constants';
import { updateSubTotal } from '../../../redux/reducers/shoppingCart';
import { useAppDispatch } from '../../../appHooks/redux';
import { TopSection, BottomSection } from './sectionButtons';
import {
  Order, SelectedProduct, OrderAdditionSelected,
} from '../../../core';
import { Discount } from '../../modal';

const ContainerMain = styled(Box)`
  width: 100%;
  background-color: ${COLORS.GREY};
`;

const MiddleSection = styled(Box)`
  height: 123px;
  width: 100%;
  display: flex;
  background-color: ${COLORS.LIGHT_GREY};
`;

export type OptionProps = {
  order: Order | null;
  onHoldHandler: () => void;
  onResetHandler: () => void;
  onSendHandler: () => void;
  onDiscount: (discount: Discount) => void;
  onPrint: () => void;
  onConvert: () => void;
  onNewItem: () => void;
  onPayment:()=>void;
  loading:boolean;
  onHeaderHeight: (site: string, value: number) => void;
  currentAdditions: OrderAdditionSelected[];
  currentProducts: SelectedProduct[];
  currentOrder: Order| null;
};

export const OptionSection = ({
  order,
  onSendHandler,
  onHoldHandler,
  onDiscount,
  onResetHandler,
  onPrint,
  onConvert,
  onPayment,
  onNewItem,
  loading,
  onHeaderHeight,
  currentAdditions,
  currentProducts,
  currentOrder,
}: OptionProps) => {
  const sectioRef = useRef();
  const dispatch = useAppDispatch();
  useEffect(() => {
    let total = 0;
    currentProducts.forEach((product:SelectedProduct) => {
      total += product.quantity * product.unit_price;
    });
    currentAdditions.forEach((addition:OrderAdditionSelected) => {
      total += addition.quantity * addition.price;
    });
    dispatch(updateSubTotal(total));
  }, [currentAdditions, currentProducts]);

  useLayoutEffect(() => {
    if (sectioRef.current) {
      // @ts-ignore
      onHeaderHeight('section', sectioRef.current.offsetHeight);
    }
  }, [onHeaderHeight]);
  return (
    <ContainerMain ref={sectioRef}>
      <TopSection
        order={order}
        onResetHandler={onResetHandler}
        onHoldHandler={onHoldHandler}
        onSendHandler={onSendHandler}
        onNewItem={onNewItem}
        loading={loading}
      />
      <MiddleSection>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          width="50%"
          spacing={1}
        >
          <Typography variant="h4">
            <strong>Sub Total: </strong>
            {`Bs.${currentOrder && currentOrder.sub_total.toFixed(2)}`}
          </Typography>
          <Typography variant="h4">
            <strong>Descuento: </strong>
            {`Bs. ${currentOrder && currentOrder.discount.toFixed(2)} `}
          </Typography>
          {/* <Typography variant="h4">
            <strong>Delivery: </strong>
            Bs. 15.00
          </Typography> */}
        </Stack>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          width="50%"
          spacing={1}
        >
          <Typography variant="h3">
            <strong>Total: </strong>
            {`Bs.${((currentOrder?.sub_total || 0) - (currentOrder?.discount || 0)).toFixed(2)}`}
          </Typography>
          <Typography variant="h4">
            <strong>Cambio: </strong>
            {`Bs. ${currentOrder && currentOrder.balance.toFixed(2)} `}
          </Typography>
        </Stack>
      </MiddleSection>
      <BottomSection
        order={order}
        onDiscount={onDiscount}
        onPrint={onPrint}
        onConvert={onConvert}
        onPayment={onPayment}
        subTotal={currentOrder?.sub_total || 0}
      />
    </ContainerMain>
  );
};
