import { FirebaseBase } from '../firebaseBase';
import { ROLES } from './user.enum';
import {
  Constructor, PlainObject,
} from '../../types';

type subsidiary_access = {
  restaurant_id: string;
  subsidiary_id: string;
}

export class User extends FirebaseBase {
  subsidiary_access: subsidiary_access;

  roles: ROLES;

  email:string;

  constructor({
    subsidiary_access = {
      restaurant_id: '',
      subsidiary_id: '',
    },
    email = '',
    roles = ROLES.POS,
    ...data
  }:Constructor<User>) {
    super(data);
    this.subsidiary_access = subsidiary_access;
    this.roles = roles;
    this.email = email;
  }

  toPlainObject(): PlainObject<User> {
    return { ...this };
  }
}
