/* eslint-disable no-underscore-dangle */
import { GeoPoint, Timestamp } from 'firebase/firestore';

export function toTimestamp(object:any):Timestamp {
  if (object) {
    const timestamp = object.seconds || object._seconds;
    const date = new Date(timestamp ? timestamp * 1000 : object);
    return Timestamp.fromDate(date);
  }
  throw new Error('invalid timestamp');
}

export function toGeoPoint(object:any):GeoPoint {
  try {
    if (object) {
      const latitude = object.latitude || object._latitude;
      const longitude = object.longitude || object._longitude;
      return new GeoPoint(latitude, longitude);
    }
    throw new Error('invalid GeoPoint');
  } catch (error) {
    console.error(error);
    // eslint-disable-next-line no-debugger
    debugger;
    throw new Error('invalid GeoPoint');
  }
}
