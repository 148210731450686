import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { FirestoreReducer, firestoreReducer as fr } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import userReducer from './user';
import authReducer from './auth';
import restaurantReducer from './restaurant';
import shopingCartReducer from './shoppingCart';
import subsidiaryReducer from './subsidiary';
import profileReducer from './profile';
import inventoryReducer from './inventory';
import stockReducer from './stock';
import productReducer from './product';

const firestoreReducer: Reducer<FirestoreReducer.Reducer<Record<any,
 any>>> = fr;

const rootReducers = combineReducers({
  user: userReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  session: authReducer,
  restaurant: restaurantReducer,
  shoppingCart: shopingCartReducer,
  subsidiary: subsidiaryReducer,
  profiles: profileReducer,
  product: productReducer,
  inventory: inventoryReducer,
  stock: stockReducer,
});

export type RootReducersType = typeof rootReducers;
export default rootReducers;
