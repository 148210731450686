import React from 'react';
import {
  Box, CircularProgress, Grid, Typography,
} from '@mui/material';
import { CarouselWithDots, MenuButton } from '../../components';
import {
  Addition,
  AdditionOption,
  OrderAdditionSelected,
} from '../../core/additions';

const ColorButton = {
  color: '#F9DCDF',
  hoverColor: '#e74626',
  textColor: '#000000',
  hoverTextColor: '#ffffff',
};

type AdditionSectionProps = {
  additions?: Addition[] | null;
  loading?: boolean;
  selectedAdditions: OrderAdditionSelected[];
  onSelectAddition: (addition: Addition, option: AdditionOption) => void;
};
export const AdditionSection = ({
  additions,
  loading,
  selectedAdditions,
  onSelectAddition,
}: AdditionSectionProps) => {
  if (loading) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }
  if (!additions) {
    return null;
  }

  return (
    <Box>
      {additions.map((addition: Addition) => (
        <Box key={addition.id}>
          <Typography fontWeight="bold" variant="h6" sx={{ margin: 1 }}>
            {`${addition.name} (Limite de selección ${addition.max_selection} - ${addition.mandatory ? 'Obligatoria' : 'Opcional'})`}
          </Typography>
          <CarouselWithDots sideBarWidth={550} slideWidth={165}>
            {addition.options?.map((option: AdditionOption) => (
              <MenuButton
                key={`key-${option.id}`}
                label={option.name}
                bgColor={
                  selectedAdditions?.find((item) => item.id === option.id)
                    ? ColorButton.hoverColor
                    : ColorButton.color
                }
                hoverColor={ColorButton.hoverColor}
                textColor={ColorButton.textColor}
                hoverTextColor={ColorButton.hoverTextColor}
                onClick={() => {
                  onSelectAddition(addition, option);
                }}
              />
            ))}
          </CarouselWithDots>
        </Box>
      ))}
    </Box>
  );
};
