import React, {
  SyntheticEvent, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import defaultImage from '../../assets/icons/ProductDish.png';

const Container = styled.div`
  display: flex;
  position: relative;
`;
const ChildrenContainer = styled.div`
  display: flex;
  flex:1;
  background-color: transparent;
  z-index: 1;
`;
const LoaderContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1;
  justify-content: center;
  align-items: center;
`;
interface ImageProps
  extends
    React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  children?:any
}
export const Image = ({ children, ref, ...props }:ImageProps) => {
  const [source, setSource] = useState(props.src);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setSource(props.src);
  }, [props.src]);
  const onError = (event:SyntheticEvent<HTMLImageElement>) => {
    setLoading(false);
    setSource(defaultImage);
    props.onError?.(event);
  };
  const onLoad = (event:SyntheticEvent<HTMLImageElement>) => {
    setLoading(false);
    props.onLoad?.(event);
  };
  return (
    <Container id="image-background" style={{ width: props.style?.width, height: props.style?.height }}>
      <ChildrenContainer {...props}>
        {children}
      </ChildrenContainer>
      <img
        alt=""
        ref={ref}
        {...props}
        onError={onError}
        onLoad={onLoad}
        src={source}
        style={{
          ...props.style,
          ...{
            display: loading ? 'none' : 'flex',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 0,
          },
        }}
      />
      {loading && (
      <LoaderContainer>
        <CircularProgress />
      </LoaderContainer>
      )}
    </Container>
  );
};
