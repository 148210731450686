export enum COLORS {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum EDGE {
  END = 'end',
  START = 'start',
}

export enum SIZE {
  MEDIUM = 'medium',
  SMALL = 'small',
}
