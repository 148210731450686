import { Environment } from './type';

const DevEnv:Environment = {
  firebaseConfig: {
    apiKey: 'AIzaSyCdHVlu54f6EJ7-JwwUaGCHETYRnY99ht8',
    authDomain: 'platform-of-sales-development.firebaseapp.com',
    projectId: 'platform-of-sales-development',
    storageBucket: 'platform-of-sales-development.appspot.com',
    messagingSenderId: '422205811877',
    appId: '1:422205811877:web:46ac870af7ffebb159714c',
  },
  emailDomain: 'conbrix.com',
  REACT_APP_CLOUD_FUNCTIONS_API: 'https://us-central1-platform-of-sales-development.cloudfunctions.net/api',
};

export default DevEnv;
