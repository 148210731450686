/* eslint-disable no-unreachable */
import Excel from 'exceljs';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import { DateRange } from 'mui-daterange-picker';
import moment from 'moment';
import 'moment/locale/es';
import { JourneyProductionProduct } from '../journeyProductionProduct';
import { JourneyProduction } from './journeyProduction.dto';
import { toTimestamp } from '../../utils/firestoreUtils';
import { InventoryBySection } from './journeyProduction.type';

moment.locale('es');

export const exportToXlsxJourneyProductionReport = async (
  restaurant_name:string,
  dateStart:string,
  dateEnd:string,
  products:JourneyProductionProduct[] | null,
  journeyProduction:JourneyProduction | null,
) => {
  const columnsTypes = [
    { key: 'counter', width: 5 },
    { key: 'menu_section_name', width: 30 },
    { key: 'product_name', width: 30 },
    { key: 'metric', width: 30 },
    { key: 'initial', width: 30 },
    { key: 'income', width: 30 },
    { key: 'sales', width: 10 },
    { key: 'discount', width: 10 },
    { key: 'stock', width: 20 },
  ];

  const rows:any = products?.map((prod:JourneyProductionProduct, index: number) => {
    const { product } = prod;
    return {
      counter: index + 1,
      product_name: product.name,
      metric: prod.metric.name,
      initial: prod.initial,
      income: prod.income,
      sales: prod.sales,
      discount: prod.discount,
      stock: prod.getStock(),
      menu_section_name: product.menu_section.name,
    };
  });
  const columnsValues = ['Nro', 'Sección', 'Producto', 'Metrica', 'Actual', 'Ingreso', 'Venta', 'Descuento', 'Stock'];
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Inventario');
  worksheet.getCell('D2').value = 'Inventario Actual CONBRIX';
  worksheet.getCell('D2').font = { bold: true, size: 14 };
  worksheet.getCell('D3').value = 'Nombre de Sucursal';
  worksheet.getCell('D3').font = { bold: false, size: 12 };
  worksheet.getCell('E3').value = restaurant_name;
  worksheet.getCell('E3').font = { bold: false, size: 12 };
  worksheet.getCell('D4').value = 'Turno:';
  worksheet.getCell('D4').font = { bold: true, size: 12 };
  worksheet.getCell('E4').value = journeyProduction?.journey.name;
  worksheet.getCell('E4').font = { bold: false, size: 12 };
  worksheet.getCell('D6').value = 'Encargado';
  worksheet.getCell('D6').font = { bold: true, size: 12 };
  worksheet.getCell('D7').value = journeyProduction?.data_manager.full_name;
  worksheet.getCell('D7').font = { bold: false, size: 12 };
  worksheet.getCell('E6').value = 'Fecha Apertura';
  worksheet.getCell('E6').font = { bold: true, size: 12 };
  worksheet.getCell('E7').value = dateStart;
  worksheet.getCell('E7').font = { bold: false, size: 12 };
  worksheet.getCell('F6').value = 'Fecha Cierre';
  worksheet.getCell('F6').font = { bold: true, size: 12 };
  worksheet.getCell('F7').value = dateEnd;
  worksheet.getCell('F7').font = { bold: false, size: 12 };

  worksheet.getCell('D9').value = 'Inventario';
  worksheet.getCell('D9').font = { bold: true, size: 12 };

  worksheet.getRow(10).values = columnsValues;
  worksheet.columns = columnsTypes;
  worksheet.getCell('A10').style = {
    font: { bold: true, size: 12 },
  };
  worksheet.getCell('B10').style = {
    font: { bold: true, size: 12 },
  };
  worksheet.getCell('C10').style = {
    font: { bold: true, size: 12 },
  };
  worksheet.getCell('D10').style = {
    font: { bold: true, size: 12 },
  };
  worksheet.getCell('E10').style = {
    font: { bold: true, size: 12 },
  };
  worksheet.getCell('F10').style = {
    font: { bold: true, size: 12 },
  };
  worksheet.getCell('G10').style = {
    font: { bold: true, size: 12 },
  };
  worksheet.getCell('H10').style = {
    font: { bold: true, size: 12 },
  };
  worksheet.getCell('I10').style = {
    font: { bold: true, size: 12 },
  };
  worksheet.getCell('J10').style = {
    font: { bold: true, size: 12 },
  };

  worksheet.addRows(rows);

  const buf = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buf]), `inventory_${moment(new Date()).format('YY-MM-DD')}.xlsx`);
};

export const exportToXlsxInventoryHistoryReport = async (subsidiaryName: string, dateRange:DateRange, productsBySections: InventoryBySection[], journeys: JourneyProduction[]) => {
  const dateStart = dateRange.startDate;
  const dateEnd = dateRange.endDate;

  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Historial de Inventario');
  worksheet.getCell('D2').value = 'Historial de Inventario CONBRIX';
  worksheet.getCell('D2').font = { bold: true, size: 14 };
  worksheet.getCell('D4').value = 'Nombre de Sucursal:';
  worksheet.getCell('D4').font = { bold: true };
  worksheet.getCell('E4').value = subsidiaryName;
  worksheet.getCell('D5').value = 'Fecha Incial:';
  worksheet.getCell('D5').font = { bold: true };
  worksheet.getCell('E5').value = moment(dateStart).format('LLL');
  worksheet.getCell('D6').value = 'Fecha Final:';
  worksheet.getCell('D6').font = { bold: true };
  worksheet.getCell('E6').value = moment(dateEnd).format('LLL');

  const rowCountjourneys = worksheet.rowCount;
  const columnsTypesJourney = [
    { key: 'blankSpace1' },
    { key: 'blankSpace2' },
    { key: 'blankSpace2' },
    { key: 'managerName' },
    { key: 'dateStart' },
    { key: 'dateEnd' },
  ];
  const columnsValuesJourney = ['', '', '', 'Encargado', 'Fecha de apertura', 'Fecha de cierre'];
  const journeysOrdered = _.sortBy(journeys, ['data_manager.full_name', 'data_manager.date_start'], ['asc', 'asc']);
  const rowsJourneyProduction = journeysOrdered.map((journeyProduction) => ({
    blankSpace1: '',
    blankSpace2: '',
    blankSpace3: '',
    managerName: journeyProduction.data_manager.full_name,
    dateStart: moment(toTimestamp(journeyProduction.date_start).toDate()).format('LLL'),
    dateEnd: moment(toTimestamp(journeyProduction.date_end).toDate()).format('LLL'),
  }));

  worksheet.getRow(rowCountjourneys + 3).values = columnsValuesJourney;
  worksheet.getRow(rowCountjourneys + 3).font = { bold: true };
  worksheet.columns = columnsTypesJourney;
  worksheet.addRows(rowsJourneyProduction);

  const rowCountProducts = worksheet.rowCount;
  const columnsTypesProducts = [
    { key: 'counter', width: 5 },
    { key: 'section', width: 30 },
    { key: 'product', width: 30 },
    { key: 'metric', width: 30 },
    { key: 'initial', width: 30 },
    { key: 'income', width: 30 },
    { key: 'sales', width: 10 },
    { key: 'discount', width: 10 },
    { key: 'stock', width: 20 },
  ];

  const columnsValuesProducts = [
    'Nro', 'Sección', 'Producto', 'Métrica', `Actual(${moment(dateStart).format('DD-MM-YY')})`, 'Ingreso', 'Venta', 'Descuento', `Stock(${moment(dateEnd).format('DD-MM-YY')})`,
  ];
  const rowsProducts:any = [];
  let counter = 0;
  productsBySections.forEach((productBySection) => {
    const productsReport = productBySection.products.map((product:any) => {
      counter += 1;
      return {
        counter,
        section: productBySection.menu_section_name,
        product: product.product_name,
        metric: product.metric.name,
        initial: product.initial,
        income: product.income,
        sales: product.sales,
        discount: product.discount,
        stock: product.initial + product.income - product.sales - product.discount,
      };
    });
    rowsProducts.push(...productsReport);
  });

  worksheet.getRow(rowCountProducts + 3).values = columnsValuesProducts;
  worksheet.getRow(rowCountProducts + 3).font = { bold: true };
  worksheet.getRow(rowCountProducts + 3).alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.columns = columnsTypesProducts;
  worksheet.addRows(rowsProducts);
  const buf = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buf]), `inventory_history${moment(new Date()).format('YY-MM-DD')}.xlsx`);
};
