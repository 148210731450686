import React, { useEffect, useState } from 'react';
import { swap } from 'react-grid-dnd';
import {
  DragDropContext, Draggable, Droppable, DropResult,
} from 'react-beautiful-dnd';
import { Paper, styled } from '@mui/material';
import { COLORS } from '../../constants';
import SectionCard from './SectionCard';
import { MenuSection } from '../../core/menuSection';
import { enableSectionProps, saveNameProps } from './types';

const PaperCustom = styled(Paper)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: 8,
  marginBottom: 16,
  padding: '8px 24px 8px 8px',
  boxShadow: '0px 4px 5px 2px rgba(0, 0, 0, 0.25)',
  '&:hover': {
    background: COLORS.BLUE_LIGHT_92,
    cursor: 'pointer',
  },
}));

type MenuSectionListProps = {
  menuSectionsDB: MenuSection[],
  isSharedMenu: boolean,
  onSaveName?: ({
    name, section, index, enable,
  }: saveNameProps) => void;
  onRemoveSection?: (index: number) => void;
  onEnableSection: ({
    section, index, enable,
  }: enableSectionProps) => void;
  onDragEnd: (menuSections: MenuSection[]) => void;
  onClickSection: (sectionId: string) => void;
}
const MenuSectionList = ({
  menuSectionsDB,
  isSharedMenu,
  onSaveName,
  onRemoveSection,
  onEnableSection,
  onDragEnd,
  onClickSection,
}:MenuSectionListProps) => {
  const [menuSections, setMenuSections] = useState<MenuSection[]>([]);

  useEffect(() => {
    setMenuSections(menuSectionsDB);
  }, [menuSectionsDB]);

  const dragEnd = async ({ destination, source }: DropResult) => {
    if (!destination) return;
    const swaped = swap(
      menuSections,
      source.index,
      destination.index,
    );
    setMenuSections(swaped);
    await onDragEnd(swaped);
  };
  return (
    <DragDropContext onDragEnd={dragEnd}>
      <Droppable droppableId="droppable-list-menu-section">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {menuSections.map((section, index) => (
              <Draggable key={section.id} draggableId={section.id} index={index}>
                {(prov, snapshot) => (
                  <PaperCustom
                    ref={prov.innerRef}
                    {...prov.draggableProps}
                    {...prov.dragHandleProps}
                    sx={{
                      backgroundColor: snapshot.isDragging ? COLORS.BLUE_LIGHT_92 : '',
                      opacity: snapshot.isDragging ? 0.5 : 1,
                    }}
                  >
                    <SectionCard
                      section={section}
                      index={index}
                      isSharedMenu={isSharedMenu}
                      onSaveName={onSaveName}
                      onRemoveSection={onRemoveSection}
                      onEnableSection={onEnableSection}
                      onClickSection={onClickSection}
                    />
                  </PaperCustom>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default React.memo(
  MenuSectionList,
  // eslint-disable-next-line max-len
  (prevProps, nextProps) => JSON.stringify(prevProps.menuSectionsDB) === JSON.stringify(nextProps.menuSectionsDB),
);
