import React, { useState } from 'react';
import {
  Box,
  Card,
  Typography,
  CardContent,
  Button,
  CircularProgress,
} from '@mui/material';
import {
  Addition, AdditionOption, MenuAddition, Product,
} from '../../../core';
import ModalPickAddition from './additionEditView/ModalPickAddition';
import { Nullable } from '../../../types';
import DragDropAdditions from './DragDropAdditions';
import { COLORS } from '../../../constants';
import { AdditionFormValues, AdditionOptionFormValues } from './additionEditView/AdditionEditForm';
import { BoxLoadingWrapper, BoxWrapper } from '../../../components/box';

type AdditionsPickerProps = {
  isSharedMenu: boolean,
  product: Product,
  restaurantAdditions: Nullable<MenuAddition[]> | undefined,
  productAdditions: Nullable<Addition[]>,
  onCreateMenuAdditionsRestaurant?: (
    values: AdditionFormValues, availability: boolean
  ) => void | Promise<void>,
  onDeleteMenuAdditionRestaurant?: (menuAdditionId: string) => void | Promise<void>,
  onAddAdditionProduct?: (
    productId: string, menuAddition: MenuAddition, order: number,
  ) => void | Promise<void>,
  onDeleteAdditionProduct?: (productId: string, additionId:string) => void | Promise<void>,
  onDragEndProductsAdditions: (prodId:string, additions: Addition[]) => void,
  onSharedWithOtherProducts: (menuAdditionsId: string, prodId:string) => Promise<number>,
  onEditAdditionRestaurantAndProduct?: (
    values: AdditionFormValues,
    availability:boolean,
    menuAdditionId: string,
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
  onEditAdditionOptionsRestaurantAndProduct?: (
    swaped: AdditionOption[],
    menuAdditionsId: string,
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
  onEditAdditionOptionsProduct?: (
    swaped: AdditionOptionFormValues[],
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
}
const AdditionsPicker = ({
  isSharedMenu,
  product,
  restaurantAdditions = [],
  productAdditions,
  onCreateMenuAdditionsRestaurant,
  onDeleteMenuAdditionRestaurant,
  onAddAdditionProduct,
  onDeleteAdditionProduct,
  onDragEndProductsAdditions,
  onSharedWithOtherProducts,
  onEditAdditionRestaurantAndProduct,
  onEditAdditionOptionsRestaurantAndProduct,
  onEditAdditionOptionsProduct,
}: AdditionsPickerProps) => {
  const [showList, setShowList] = useState(false);
  const [isLoading, setLoading] = useState(false);

  return (
    <>
      <Card>
        <CardContent>
          <Box mt={1} mb={1} display="flex" justifyContent="space-between">
            <Typography variant="h4">
              <strong>
                Adiciones
              </strong>
            </Typography>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => setShowList(true)}
              disabled={!isSharedMenu}
            >
              AÑADIR ADICIONES
            </Button>
          </Box>
        </CardContent>
      </Card>
      <BoxWrapper mt={3}>
        {isLoading && (
          <BoxLoadingWrapper>
            <CircularProgress />
          </BoxLoadingWrapper>
        )}
        {productAdditions?.length ? (
          <DragDropAdditions
            productAdditions={productAdditions}
            prodId={product.id}
            onLoading={(value) => setLoading(value)}
            isSharedMenu={isSharedMenu}
            onDragEndProductsAdditions={onDragEndProductsAdditions}
            onSharedWithOtherProducts={onSharedWithOtherProducts}
            onDeleteAdditionProduct={onDeleteAdditionProduct}
            onEditAdditionRestaurantAndProduct={onEditAdditionRestaurantAndProduct}
            onEditAdditionOptionsRestaurantAndProduct={onEditAdditionOptionsRestaurantAndProduct}
            onEditAdditionOptionsProduct={onEditAdditionOptionsProduct}
          />
        ) : (
          <Card>
            <Box m={3} display="flex" justifyContent="center">
              <Typography sx={{ color: COLORS.GRAY_LIGHT_2 }}>
                Todavia no tienes adiciones en este producto
              </Typography>
            </Box>
          </Card>
        )}
      </BoxWrapper>
      <ModalPickAddition
        show={showList}
        onClose={() => setShowList(false)}
        product={product}
        restaurantAdditions={restaurantAdditions}
        productAdditions={productAdditions}
        onCreateMenuAdditionsRestaurant={onCreateMenuAdditionsRestaurant}
        onDeleteMenuAdditionRestaurant={onDeleteMenuAdditionRestaurant}
        onAddAdditionProduct={onAddAdditionProduct}
        onDeleteAdditionProduct={onDeleteAdditionProduct}
      />
    </>
  );
};

export default AdditionsPicker;
