import React, { useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Typography,
  styled,
} from '@mui/material';
import { KeyboardArrowUp as KeyboardArrowUpIcon, KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { uniqueId } from 'lodash';
import { COLORS } from '../../../constants';
import { InventoryBySection, InventoryProduct } from '../../../core';

const TableBox = styled(Box)(() => ({
  border: `1px solid ${COLORS.BLUE_LIGHT_78}`,
  borderRadius: '10px',
}));
const DisableText = styled(Typography)(() => ({
  color: COLORS.SHIMMERING, paddingLeft: '20px', fontSize: '10px', paddingTop: '7px',
}));

type RowProps = {
  productBySection: InventoryBySection
};

// const rowCollapse = ['test1', 'test2', 'test3', 'test4'];

export const TableRowCollapse = ({ productBySection }: RowProps) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell width="21%">
          <Stack direction="row" spacing={0.5} alignItems="center">
            <IconButton
              aria-label="expand row"
              size="small"
              color="secondary"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
            <Typography>
              {productBySection.menu_section_name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell width="12.2%" component="th" scope="row" align="center" />
        <TableCell width="12.2%" align="center">{productBySection.initial}</TableCell>
        <TableCell width="12.2%" align="center">{productBySection.income}</TableCell>
        <TableCell width="12.2%" align="center">{productBySection.sales}</TableCell>
        <TableCell width="12.2%" align="center">{productBySection.discount}</TableCell>
        <TableCell width="12.2%" align="center">{productBySection.stock}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBlock: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableBox sx={{ margin: 1 }}>
              <Table aria-label="details-section">
                <TableBody>
                  {productBySection?.products.map((simpleProduct:InventoryProduct) => (
                    <TableRow key={`${uniqueId(simpleProduct.product_id)}_`}>

                      <TableCell width="21%" sx={simpleProduct.availability ? {} : { color: COLORS.SILVER_CHALICE }}>
                        <Box paddingLeft={5} display="flex" justifyContent="left">
                          <Typography textAlign="left">
                            {simpleProduct.product_name}
                          </Typography>
                          {!simpleProduct.availability && (
                          <DisableText>
                            Deshabilitado
                          </DisableText>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell width="12.2%" align="center" sx={simpleProduct.availability ? {} : { color: COLORS.SILVER_CHALICE }}>
                        <Box display="flex" justifyContent="center">
                          <Typography>
                            {simpleProduct.metric.name}
                          </Typography>

                          {simpleProduct.availability && (
                          <ArrowDropDownIcon color="secondary" />
                          )}
                          {!simpleProduct.availability && (
                          <ArrowDropDownIcon />
                          )}

                        </Box>

                      </TableCell>
                      <TableCell width="12.2%" align="center">{simpleProduct.initial}</TableCell>
                      <TableCell width="12.2%" align="center">{simpleProduct.income}</TableCell>
                      <TableCell width="12.2%" align="center" sx={simpleProduct.availability ? { paddingLeft: '1.6%' } : { color: COLORS.SILVER_CHALICE, paddingLeft: '1%' }}>
                        {simpleProduct.sales}
                      </TableCell>
                      <TableCell width="12.2%" align="center" sx={{ paddingLeft: '1.7%' }}>{simpleProduct.discount}</TableCell>
                      <TableCell width="12.2%" align="center" sx={{ paddingLeft: '2.3%' }}>{simpleProduct.stock}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableBox>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
