import React, { useEffect, useRef } from 'react';
import {
  Box, Button, Grid, styled, Typography,
} from '@mui/material';
import { COLOR, VARIANT } from '../button/enum';
import { BaseModal } from './baseModal';

type DialogProps = {
  open: boolean;
  onClose: (state?: boolean) => void;
  okText?: string;
  title: string;
  icon?: any;
  isCloseModal?: boolean;
  closeIcon?: boolean;
};

const CustomContainer = styled(Box)(() => ({
  paddingTop: '40px',
  paddingBottom: '40px',
}));

const CustomTitle = styled(Typography)(() => ({
  paddingTop: '30px',
  paddingBottom: '30px',
}));

const CustomizedButton = styled(Button)`
  padding-inline: 45px;
  padding-block: 10px;
  border-radius: 10px;
  font-weight: bold;
  @media (max-width: 450px) {
    padding-inline: 31px;
    padding-block: 6px;
  }
`;

export const AlertModal = ({
  open,
  onClose,
  okText = 'Si',
  title,
  icon,
  isCloseModal = false,
  closeIcon = true,
}: DialogProps) => {
  const buttonConfirmRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (buttonConfirmRef.current) {
        const { key } = event;
        if (key === 'Enter') {
          buttonConfirmRef.current.click();
          buttonConfirmRef.current.focus();
        }
      }
    });
  }, []);
  return (
    <BaseModal
      open={open}
      size="xs"
      closeIcon={closeIcon}
      onClose={() => {
        if (!isCloseModal) onClose(false);
      }}
      fullWidth
    >
      <CustomContainer>
        <Grid container sx={{ textAlign: 'center' }}>
          <Grid item xs={12}>
            {icon && (
              <img src={icon} alt="check icon" width="124" height="123" />
            )}
            <CustomTitle variant="h5">
              <strong>{title}</strong>
            </CustomTitle>
            <CustomizedButton
              id="acceptModalBtn"
              key="acceptModalBtn"
              style={{ textTransform: 'none' }}
              onClick={() => {
                onClose(true);
              }}
              color={COLOR.SECONDARY}
              variant={VARIANT.CONTAINED}
              ref={buttonConfirmRef}
            >
              {okText}
            </CustomizedButton>
          </Grid>
        </Grid>
      </CustomContainer>
    </BaseModal>
  );
};
