import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { COLOR, VARIANT, SIZE } from './enum';

const CustomizedButton = styled(Button)`
  padding-inline: 45px;
  padding-block: 10px;
  border-radius: 20px;
  font-weight: bold;
  @media (max-width: 450px) {
    padding-inline: 31px;
    padding-block: 6px;
  }
`;

export interface ButtonProps {
  color?:COLOR,
  label:string,
  variant?:VARIANT,
  disabled?:boolean,
  className?:string,
  fullWidth?:boolean,
  size?:SIZE,
  onClick: () => void,
  style?: React.CSSProperties,
}

export const RoundedWideButton = ({
  color, label, variant, disabled, className, fullWidth, size, onClick, style,
}: ButtonProps) => (
  <CustomizedButton
    className={className}
    color={color}
    size={size}
    onClick={onClick}
    variant={variant}
    disabled={disabled}
    fullWidth={fullWidth}
    style={style}
  >
    {label}
  </CustomizedButton>
);
