import React from 'react';
import {
  Container, Stack, Typography, Paper, Tabs, Tab, Box, Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../constants';
import { AllTab } from './tabs/allTab';
import { SupervisorTab } from './tabs/supervisorTab';
import { CashierTab } from './tabs/cashierTab';
import { TableUsers } from './tableUsers';
import { Profile, ROLES } from '../../core';
import { getDate } from '../../utils/date';

const ContainerMain = styled(Container)(() => ({
  width: '100%',
  minHeight: '93.8vh',
  backgroundColor: COLORS.BG_GRAY,
}));

const CustomContainerButton = styled(Box)(() => ({
  marginLeft: '16px',
  right: '20px',
  textAlign: 'right',
  paddingLeft: '60px',
}));

export type AccountProps = {
  restaurantProfiles:Profile[]|null,
  subsidiaryProfiles:Profile[]|null,
  openModalHandler:()=>void,
  deleteUserHandler:(user:Profile)=>void,
  editModalHandler : (user:Profile)=>void,
};
const columns = ['Index', 'Nombre', 'Telefono', 'Correo Electrónico', 'Rol', 'Fecha de creacion', 'Acciones'];

export const AccountScreen = ({
  restaurantProfiles, subsidiaryProfiles, openModalHandler, deleteUserHandler, editModalHandler,
}:AccountProps) => {
  const [value, setValue] = React.useState(0);
  const [allProfiles, setAllProfiles] = React.useState<Profile[]>([]);
  const [supervisorProfiles, setSupervisorProfiles] = React.useState<Profile[]>([]);
  const [cashierProfiles, setCashierProfiles] = React.useState<Profile[]>([]);
  const [ownerProfiles, setOwnerProfiles] = React.useState<Profile[]>([]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    if (restaurantProfiles) {
      const uniques = [...restaurantProfiles, ...subsidiaryProfiles || []].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
      setAllProfiles(uniques);
      const supervisors = uniques.filter((user) => user.roles[0] === ROLES.SUPERVISOR).sort(
        (objA, objB) => Number(new Date(getDate(objB.created_at))) - Number(new Date(getDate(objA.created_at))),
      );
      const cashiers = uniques.filter((user) => user.roles[0] === ROLES.CASHIER).sort(
        (objA, objB) => Number(new Date(getDate(objB.created_at))) - Number(new Date(getDate(objA.created_at))),
      );
      const owner = uniques.filter((user) => user.roles[0] === ROLES.OWNER).sort(
        (objA, objB) => Number(new Date(getDate(objB.created_at))) - Number(new Date(getDate(objA.created_at))),
      );
      setSupervisorProfiles(supervisors);
      setCashierProfiles(cashiers);
      setOwnerProfiles(owner);
    }
  }, [restaurantProfiles, subsidiaryProfiles]);

  return (
    <ContainerMain maxWidth="xl">
      <Stack>
        <Stack mt={4} mb={5} justifyContent="center" alignItems="center">
          <Typography sx={{ fontWeight: 'bold', fontSize: 23 }}>
            Administración de cuentas
          </Typography>
        </Stack>
        <Paper
          square
          sx={{ padding: '32px 16px 16px 16px', minHeight: '80vh', marginBottom: '24px' }}
        >
          <Stack>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="Todos" />
              <Tab label="Supervisor" />
              <Tab label="Cajero" />
              <Tab label="Dueño" />
            </Tabs>
            <CustomContainerButton>
              <Button
                variant="contained"
                style={{ backgroundColor: '#E25060' }}
                onClick={openModalHandler}
              >
                <Typography
                  textTransform="initial"
                  color="black"
                  fontWeight="bold"
                >
                  Nuevo perfil
                </Typography>
              </Button>
            </CustomContainerButton>
            <AllTab value={value} index={0}>
              <TableUsers rows={allProfiles || []} headerData={columns} key="all_User" deleteUserHandler={deleteUserHandler} editModalHandler={editModalHandler} />
            </AllTab>
            <SupervisorTab value={value} index={1}>
              <TableUsers rows={supervisorProfiles} headerData={columns} key="supervisor_User" deleteUserHandler={deleteUserHandler} editModalHandler={editModalHandler} />
            </SupervisorTab>
            <CashierTab value={value} index={2}>
              <TableUsers rows={cashierProfiles} headerData={columns} key="cashier_User" deleteUserHandler={deleteUserHandler} editModalHandler={editModalHandler} />
            </CashierTab>
            <CashierTab value={value} index={3}>
              <TableUsers rows={ownerProfiles} headerData={columns} key="owner_User" deleteUserHandler={deleteUserHandler} editModalHandler={editModalHandler} />
            </CashierTab>
          </Stack>
        </Paper>
      </Stack>
    </ContainerMain>
  );
};
