/* eslint-disable max-len */
import {
  collection, addDoc, doc, setDoc, getFirestore, deleteDoc, updateDoc, writeBatch, getDocs, query, where,
} from 'firebase/firestore';
import { COLLECTIONS } from '../../constants';
import { Product } from './product.dto';

const db = getFirestore();
export const subsidiaryProductsRef = (restaurantId:string, subsidiaryId:string) => collection(db, `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/${COLLECTIONS.LOCAL_PRODUCTS}`);

export const editProduct = async (
  productUpdated: Partial<Product>,
  productId: string,
  path: string,
): Promise<void> => {
  const productRef = doc(db, path, productId);
  await setDoc(productRef, productUpdated, { merge: true });
};

export const addProduct = async (product: Product, path: string): Promise<void> => {
  const productResult = await addDoc(collection(db, path), product.toPlainObject());
  await editProduct({ id: productResult.id }, productResult.id, path);
};

export const addSharedProduct = async (
  restaurantId: string,
  productId: string,
  product: Product,
): Promise<void> => {
  const productRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SHARED_PRODUCTS, productId);
  await setDoc(productRef, product.toPlainObject());
};

export const deleteSharedProduct = async (restaurantId: string, productId: string): Promise<void> => {
  const productRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SHARED_PRODUCTS, productId);
  await deleteDoc(productRef);
};

export const getSharedProductsBySectionId = async (restaurantId:string, sectionId:string):Promise<Product[]> => {
  const querySnapshot = await getDocs(query(collection(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SHARED_PRODUCTS), where('menu_section_id', '==', sectionId)));
  const products = querySnapshot.docs.map((snap:any) => {
    const product = new Product({ ...snap.data(), id: snap.id });
    return product;
  });
  return products;
};

export const getLocalProductsBySectionId = async (restaurantId:string, subsidiaryId:string, sectionId:string):Promise<Product[]> => {
  const querySnapshot = await getDocs(query(collection(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SUBSIDIARY, subsidiaryId, COLLECTIONS.LOCAL_PRODUCTS), where('menu_section_id', '==', sectionId)));
  const products = querySnapshot.docs.map((snap:any) => {
    const product = new Product({ ...snap.data(), id: snap.id });
    return product;
  });
  return products;
};

export const deleteSharedProductBySectionId = async (restaurantId: string, sectionId:string): Promise<void> => {
  const products = await getSharedProductsBySectionId(restaurantId, sectionId);
  const batch = writeBatch(db);
  products.forEach((product) => {
    const productRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SHARED_PRODUCTS, product.id);
    batch.delete(productRef);
  });
  await batch.commit();
};

export const editSharedProduct = async (
  productUpdated: Partial<Product>,
  restaurantId: string,
  productId: string,
): Promise<void> => {
  const productRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SHARED_PRODUCTS, productId);
  await updateDoc(productRef, { ...productUpdated });
};

export const editLocalProduct = async (
  productUpdated: Partial<Product>,
  restaurantId: string,
  subsidiaryId: string,
  productId: string,
): Promise<void> => {
  const productRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SUBSIDIARY, subsidiaryId, COLLECTIONS.LOCAL_PRODUCTS, productId);
  await updateDoc(productRef, { ...productUpdated });
};

export const saveSharedProductsOrder = async (
  products: Product[],
  restaurantId: string,
): Promise<void> => {
  const batch = writeBatch(db);
  products.forEach((product, index) => {
    const productRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SHARED_PRODUCTS, product.id);
    batch.update(productRef, { menu_order: index });
  });
  await batch.commit();
};

export const saveLocalProductsOrder = async (
  products: Product[],
  restaurantId: string,
  subsidiaryId: string,
): Promise<void> => {
  const batch = writeBatch(db);
  products.forEach((product, index) => {
    const productRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SUBSIDIARY, subsidiaryId, COLLECTIONS.LOCAL_PRODUCTS, product.id);
    batch.update(productRef, { menu_order: index });
  });
  await batch.commit();
};

export const getSharedProductsPerRestaurant = async (restaurantId:string) => {
  const querySnapshot = await getDocs(collection(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.SHARED_PRODUCTS));
  const products = querySnapshot.docs.map((snap:any) => {
    const product = new Product({ ...snap.data(), id: snap.id });
    return product;
  });
  return products;
};
