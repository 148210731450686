/* eslint-disable max-len */
import React from 'react';
import 'nprogress/nprogress.css';
import { useSnackbar } from 'notistack';
import { ConfirmModal, HeadTitle } from '../../components';
import { AccountScreen } from '../../screens/accountScreen';
import { useRestaurantProfiles, useSubsidiaryProfiles } from '../../core/profile/profile.hook';
import { useAppSelector } from '../../appHooks/redux';
import { Profile } from '../../core';
import { NewUserModal } from '../../components/modal/newUserModal';
import { useSubsidiaries } from '../../core/subsidiary/subsidiary.hook';
import { deleteProfile, editProfile, saveProfile } from '../../core/profile/profile.service';

const Account = () => {
  const restaurant = useAppSelector((state) => state.restaurant.restaurant);
  const restaurantsProfiles = useRestaurantProfiles(restaurant?.id || '');
  const { enqueueSnackbar } = useSnackbar();
  const subsidiaries = useSubsidiaries(restaurant?.id);
  const subsidiaryProfiles = useSubsidiaryProfiles(restaurant?.id || '');
  const [modal, setOpenModal] = React.useState(false);
  const [confirModal, setConfirModal] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState<Profile>(new Profile({}));
  const [isSubmitting, setIsSimiting] = React.useState(false);

  const openModalHandler = () => {
    setCurrentUser(new Profile({}));
    setOpenModal(true);
  };
  const saveUserHandler = async (user:Profile) => {
    setIsSimiting(true);
    try {
      const response = await saveProfile(user, restaurant?.id || '', [...restaurantsProfiles || [], ...subsidiaryProfiles || []]);
      if (typeof response === 'boolean') {
        setIsSimiting(false);
        enqueueSnackbar('Ya se encuentra un usuario registrado con este código/contraseña', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      } else {
        setOpenModal(false);
        setIsSimiting(false);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Ocurrió un error', {
        variant: 'error',
      });
      setIsSimiting(false);
    }
  };

  const editUserHandler = async (user:Profile) => {
    setIsSimiting(true);
    try {
      const response = await editProfile(user, currentUser, restaurant?.id || '', [...restaurantsProfiles || [], ...subsidiaryProfiles || []]);
      if (typeof response === 'boolean') {
        setIsSimiting(false);
        enqueueSnackbar('Ya se encuentra un usuario registrado con este código/contraseña', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      } else {
        setOpenModal(false);
        setIsSimiting(false);
        setCurrentUser(new Profile({}));
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Ocurrió un error', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setIsSimiting(false);
    }
  };

  const deleteUserHandler = (user:Profile) => {
    setCurrentUser(user);
    setConfirModal(true);
  };

  const openEditModal = (user:Profile) => {
    setCurrentUser(user);
    setOpenModal(true);
  };

  return (
    <>
      <HeadTitle title="Administración de cuentas" />
      <AccountScreen subsidiaryProfiles={subsidiaryProfiles} restaurantProfiles={restaurantsProfiles} openModalHandler={openModalHandler} deleteUserHandler={deleteUserHandler} editModalHandler={openEditModal} />
      {modal && <NewUserModal restaurantId={restaurant?.id || ''} open onClose={() => { setOpenModal(false); }} user={currentUser} subsidiaries={subsidiaries || []} onEdit={editUserHandler} onSave={saveUserHandler} submitting={isSubmitting} />}
      {confirModal && (
      <ConfirmModal
        open={confirModal}
        onClose={() => { setConfirModal(false); }}
        onConfirm={async () => {
          try {
            await deleteProfile(currentUser, restaurant?.id || '');
            setConfirModal(false);
          } catch (error) {
            enqueueSnackbar('Ocurrió un error', {
              variant: 'error',
            });
          }
        }}
        title="Seguro que desea borrar el usuario?"
      />
      )}
    </>
  );
};

export default Account;
