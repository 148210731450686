import {
  Box, Grid, List, ListItem, ListItemText, Stack, styled, Typography,
} from '@mui/material';
import * as React from 'react';
import { COLORS } from '../../constants';
import { RoundedButton } from '../button';
import { COLOR, VARIANT } from '../button/enum';
import { BaseModal } from './baseModal';

 type DialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  okText?: string;
  cancelText?: string;
  title: string;
  missingAdditions : string[]
};

const CustomContainer = styled(Box)(() => ({
  paddingTop: '20px',
  paddingBottom: '40px',
}));

const CustomTitle = styled(Typography)(() => ({
  paddingTop: '20px',
  paddingBottom: '20px',
}));

export const VerifyAdditionsModal = ({
  open,
  onClose,
  onConfirm,
  okText = 'Si',
  cancelText = 'No',
  title,
  missingAdditions,
}: DialogProps) => (
  <BaseModal open={open} size="xs" onClose={onClose} fullWidth>
    <CustomContainer>
      <Grid container sx={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <CustomTitle variant="h5">{title}</CustomTitle>
          <Stack
            spacing={1}
            direction="row"
            pb={5}
          >
            <div>
              <List>
                {missingAdditions.map((missingAddition, index) => (
                  <ListItem key={missingAddition}>
                    <ListItemText
                      primary={`${index + 1}. ${missingAddition}`}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </Stack>
          <Stack
            spacing={10}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <RoundedButton
              id="confirmModalBtn"
              label={okText}
              onClick={onConfirm}
              color={COLOR.SECONDARY}
              variant={VARIANT.CONTAINED}
            />
            <RoundedButton
              label={cancelText}
              onClick={onClose}
              style={{ backgroundColor: COLORS.LIGHT_BLUE }}
              variant={VARIANT.CONTAINED}
            />
          </Stack>
        </Grid>
      </Grid>
    </CustomContainer>
  </BaseModal>
);
