import {
  Grid, IconButton, Typography,
} from '@mui/material';
import React from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

interface HeaderProps {
  label:string;
  onClickNext: () => void;
  onClickPrevious: () => void;
  nextDisabled: boolean;
}

const Header: React.FunctionComponent<HeaderProps> = ({
  onClickNext,
  onClickPrevious,
  label,
  nextDisabled,
}: HeaderProps) => (
  <Grid container justifyContent="space-between" alignItems="center">
    <Grid item sx={{ padding: '5px' }}>
      <IconButton
        sx={{
          padding: '10px',
          '&:hover': {
            background: 'none',
          },
        }}
        onClick={onClickPrevious}
      >
        <ChevronLeft style={{ color: 'black' }} />
      </IconButton>
    </Grid>

    <Grid item sx={{ flex: 1, textAlign: 'center' }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
        {label}
      </Typography>
    </Grid>

    <Grid item sx={{ padding: '5px' }}>
      <IconButton
        sx={{
          padding: '10px',
          '&:hover': {
            background: 'none',
          },
        }}
        disabled={nextDisabled}
        onClick={onClickNext}
      >
        <ChevronRight style={nextDisabled ? { color: '#D3D3D3' } : { color: 'black' }} />
      </IconButton>
    </Grid>

  </Grid>
);

export default Header;
