import React from 'react';
import { Card, CardHeader } from '@mui/material';
import Chart from './Chart';
import { Order } from '../../../core';

type DateType = {
  start: Date;
  compare: Date;
  end: Date;
}

type OrdersRealTimeProps = {
  dates: DateType;
  labels: string[];
  data: string[];
  orders: Order[] | null;
};
const OrdersRealTime = ({
  dates,
  labels,
  data = [],
  orders = [],
}:OrdersRealTimeProps) => (
  <Card {...dates}>
    <CardHeader
      title="Ordenes"
      subheader={orders?.length}
      titleTypographyProps={{ color: 'textPrimary' }}
      subheaderTypographyProps={{ color: 'textPrimary' }}
    />
    <Chart data={data} labels={labels} />
  </Card>
);

export default OrdersRealTime;
