import { DateRange } from 'mui-daterange-picker';
import {
  deleteDoc,
  doc, getFirestore, setDoc,
} from 'firebase/firestore';
import { getDocId } from '../../config/firebaseService';
import { COLLECTIONS } from '../../constants';
import { axiosInstance } from '../../utils/axios';
import Journey from './journey.dto';

const db = getFirestore();
export const saveJourney = async (turn:Journey, resId:string, subsidiary_id:string):Promise<string | boolean> => {
  const path = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.SUBSIDIARY}/${subsidiary_id}/${COLLECTIONS.JOURNEY}`;
  const docId = getDocId(path);

  const ref = doc(
    db,
    `${path}/${docId}`,
  );
  await setDoc(ref, { ...turn.toPlainObject(), id: docId }, { merge: true });
  return docId;
};

export const editJourney = async (journey:Journey, resId:string, subsidiary_id:string):Promise<string | boolean> => {
  const path = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.SUBSIDIARY}/${subsidiary_id}/${COLLECTIONS.JOURNEY}/${journey.id}`;

  const ref = doc(
    db,
    `${path}`,
  );
  await setDoc(ref, { name: journey.name, id: journey.id }, { merge: true });
  return journey.id;
};

export const deleteJourney = async (journeyId:string, resId:string, subsidiary_id:string):Promise<string | boolean> => {
  const path = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.SUBSIDIARY}/${subsidiary_id}/${COLLECTIONS.JOURNEY}/${journeyId}`;

  const ref = doc(
    db,
    `${path}`,
  );
  await deleteDoc(ref);
  return journeyId;
};

export const getHistory = async (restaurant_id:string, subsidiary_id:string, date_range:DateRange) => {
  const path = '/journeyProductions/getInventoryHistory';
  const reports = await axiosInstance.post(path, { restaurant_id, subsidiary_id, date_range: { start_date: date_range.startDate, end_date: date_range.endDate } }).then(({ data }) => data);
  return reports;
};
