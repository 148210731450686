import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#C32032',
    },
    secondary: {
      main: '#1E346E',
    },
    text: {
      primary: '#232323',
    },
    divider: 'rgba(0, 0, 0, 1)',
  },
  typography: {
    h1: {
      fontSize: 38,
      fontWeight: 900,
      fontFamily: 'Nunito Sans',
    },
    h2: {
      fontFamily: 'Nunito Sans',
      fontSize: 32,
      fontWeight: 800,
    },
    h3: {
      fontFamily: 'Nunito Sans',
      fontSize: 23,
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'Nunito Sans',
      fontSize: 16,
      fontWeight: 700,
    },
    caption: {
      fontFamily: 'Nunito Sans',
      fontSize: 16,
      fontWeight: 600,
    },
    h5: {
      fontFamily: 'Nunito Sans',
      fontSize: 21,
      fontWeight: 300,
    },
    h6: {
      fontWeight: 400,
      fontFamily: 'Nunito Sans',
      fontSize: 21,
    },
    body1: {
      fontFamily: 'Nunito Sans',
      fontSize: 16,
      fontWeight: 300,
    },
    body2: {
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
    overline: {
      fontFamily: 'Nunito Sans',
      fontSize: 16,
      fontWeight: 900,
    },
  },
  breakpoints: {
    values: {
      xs: 464,
      sm: 800,
      md: 1025,
      lg: 1440,
      xl: 1920,
    },
  },
});

export default customTheme;
