import { Environment } from './type';

const ProductionEnv:Environment = {
  firebaseConfig: {
    apiKey: 'AIzaSyDRPj-3Im9YDwCFygX7BiqVn3CH90Niq7I',
    authDomain: 'conbrix-production.firebaseapp.com',
    projectId: 'conbrix-production',
    storageBucket: 'conbrix-production.appspot.com',
    messagingSenderId: '1060063411825',
    appId: '1:1060063411825:web:8ae3c9886fef1605b6e5d0',
    measurementId: 'G-GHKGHBHW7D',
  },
  REACT_APP_CLOUD_FUNCTIONS_API: 'https://us-central1-conbrix-production.cloudfunctions.net/api',
  emailDomain: 'conbrix.com',
};

export default ProductionEnv;
