import React, {
  createRef, useEffect, useMemo, useState,
} from 'react';
import {
  ButtonBase, CircularProgress, Grid, styled, Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { COLORS, IMAGE_SIZES } from '../../constants';
import { validateImageSizes } from '../../utils/images';
import { Restaurant } from '../../core';
// import moment from 'moment'

interface StyleSheet {
  [key: string]: React.CSSProperties;
}

// eslint-disable-next-line no-unused-vars
const logoStyles: StyleSheet = {
  imageContainer: {
    width: 160,
    height: 160,
    padding: 13,
    marginBottom: 20,
  },
};

const bannerStyles: StyleSheet = {
  imageContainer: {
    width: 368,
    height: 160,
    padding: 13,
  },
};

const CardContainer = styled(Grid)(() => ({
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 8,
  opacity: 1,

}));

const imageStyle = {
  width: '100%',
  height: '100%',
  borderRadius: 8,

};

const TitleContainer = styled(Grid)(() => ({
  marginBottom: 16,

}));

const ButtonIcon = styled(Grid)(() => ({
  width: 0,
  height: 0,
  position: 'relative',

}));

const Button = styled(ButtonBase)(() => ({
  width: 31,
  height: 31,
  backgroundColor: COLORS.GRAY_LIGHT_3,
  borderRadius: 6,

}));
export type RestaurantPhotoProps = {
  restaurant: Restaurant;
  type: string;
  validImage:(value:boolean)=>void,
  onSelectImage:(image:File)=>void
};

export const RestaurantPhotoForm = ({
  type,
  restaurant,
  validImage,
  onSelectImage,
}: RestaurantPhotoProps) => {
  const input = createRef<any>();
  const [file, setFile] = useState('');
  const [loadingImage] = useState(false);
  const [valid, setValid] = useState({
    maxWidth: true,
    minWidth: true,
    maxHeight: true,
    minHeight: true,
    errorMessage: false,
    error: false,
    message: '',
  });

  const [banner, setBanner] = useState<File>();

  useEffect(() => {
    if (banner) {
      if (valid.errorMessage) {
        validImage(false);
      } else {
        validImage(true);
        onSelectImage(banner);
      }
    }
  }, [valid, banner]);

  const setImage = (value: File) => {
    setBanner(value);
  };

  const setImageFile = (value: string) => {
    setFile(value);
  };

  const saveFile = (event: any) => {
    setImageFile(URL.createObjectURL(event.target.files[0]));
    setImage(event.target.files[0]);
  };

  const onButtonClick = () => {
    input.current.click();
  };

  const validations = useMemo(() => {
    if (type === 'logo') {
      return {
        minHeight: Number(IMAGE_SIZES.LOGO_MIN_HEIGHT),
        minWidth: Number(IMAGE_SIZES.LOGO_MIN_WIDTH),
      };
    }
    if (type === 'banner') {
      return {
        minHeight: Number(IMAGE_SIZES.BANNER_MIN_HEIGHT),
        minWidth: Number(IMAGE_SIZES.BANNER_MIN_WIDTH),
      };
    }
    throw new Error('Unknown Type');
  }, [type]);

  const target = useMemo(() => {
    if (type === 'logo') {
      return restaurant.logo_photo;
    }
    return restaurant.banner_photo;
  }, [restaurant]);

  const bannerStyle = useMemo(() => {
    if (type === 'banner') {
      return bannerStyles.imageContainer;
    }
    if (type === 'logo') {
      return logoStyles.imageContainer;
    }
    return {};
  }, [type]);

  return (
    <Grid>
      <TitleContainer>
        <Typography
          variant="body2"
          color="textPrimary"
          style={{ fontWeight: 'bold', fontSize: 16 }}
        >
          {type === 'banner' && 'banner'}
          {type === 'logo' && 'logo'}
        </Typography>
      </TitleContainer>
      <CardContainer style={{ ...bannerStyle }}>
        <ButtonIcon>
          <Button
            color="secondary"
            onChange={(event: any) => saveFile(event)}
            onClick={() => onButtonClick()}
          >
            <AddIcon style={{ marginRight: 3 }} />
          </Button>
        </ButtonIcon>
        <Grid container style={{ width: '100%', height: '100%' }}>
          {loadingImage && <CircularProgress />}
          {(file || (target && target.original)) && !loadingImage && (
            <img
              onLoad={() => {
                validateImageSizes(file, validations, setValid);
              }}
              style={{ ...imageStyle }}
              src={
                file
                || (target.x400 ? target.x400 : target.original)
              }
              alt={`${type}_photo`}
            />
          )}
        </Grid>
      </CardContainer>
      {valid.error && (
        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
          {valid.message}
        </Typography>
      )}
      <input
        type="file"
        accept="image/*"
        id={`${type}File`}
        ref={input}
        style={{ display: 'none' }}
        onChange={(event) => saveFile(event)}
      />
    </Grid>
  );
};
export default RestaurantPhotoForm;
