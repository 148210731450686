/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useState,
  useRef,
  useEffect,
  RefObject,
} from 'react';
import {
  Box, Typography, Button, ButtonGroup, Dialog,
} from '@mui/material';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop';
import { styled } from '@mui/material/styles';
import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview, getResizedCanvas, useDebounceEffect } from '../crop';
import { COLORS } from '../../constants';
import { UploadImgGalleryProps } from '../../screens/galleryScreen/types';

const CustomMainBox = styled(Box)(() => ({
  padding: 20,
  display: 'flex',
  paddingRight: 0,
}));

const CustomDetailsBox = styled(Box)(() => ({
  width: '30%',
  alignItems: 'center',
  display: 'flex',
}));

const CustomOptionBox = styled(Box)(() => ({
  width: '100%',
  alignItems: 'center',
  flexDirection: 'column',
  display: 'flex',
}));

const CustomDangerButton = styled(Button)(() => ({
  backgroundColor: COLORS.RED_LIGHT_78,
  color: 'white',
  '&:hover': {
    backgroundColor: COLORS.RED_DARK,
  },
}));

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 60,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

type CropModalProps = {
  openDialog: boolean,
  onClose: () => void,
  onConfirm: (file: UploadImgGalleryProps) => void,
  file?: File,
  typeImage: string,
}

const CropModal = ({
  openDialog, onClose, file, onConfirm, typeImage,
}: CropModalProps) => {
  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(4 / 4);

  useEffect(() => {
    if (file) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader?.result?.toString() || ''));
      reader.readAsDataURL(file);
    }
  }, [file]);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width
        && completedCrop?.height
        && imgRef.current
        && previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  const generateURL = (
    previewCanvas: RefObject<HTMLCanvasElement>,
    cropTmp?: PixelCrop | null,
  ) => {
    if (!cropTmp || !previewCanvas) {
      return;
    }

    const canvas = getResizedCanvas(
      previewCanvasRef,
      cropTmp.width,
      cropTmp.height,
    );

    canvas.toBlob(
      (blob) => {
        onConfirm({ file: blob, originalFile: file });
      },
      typeImage,
      1,
    );
  };

  const handleCancel = () => {
    setImgSrc('');
    setCrop(undefined);
    setCompletedCrop(undefined);
    onClose();
  };

  return (
    <Dialog open={openDialog} maxWidth="md" onClose={handleCancel} fullWidth>
      <CustomMainBox>
        <Box width="70%" display="flex" justifyContent="center">
          {Boolean(imgSrc) && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </Box>
        <CustomDetailsBox>
          <CustomOptionBox>
            <Box width="350px" display="flex" justifyContent="center">
              {Boolean(completedCrop) && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: '70%',
                  height: 'auto',
                }}
              />
              )}
            </Box>
            <ButtonGroup color="primary" style={{ marginTop: 20 }}>
              <CustomDangerButton
                onClick={handleCancel}
              >
                <Typography>Cancelar</Typography>
              </CustomDangerButton>
              <Button
                onClick={() => generateURL(previewCanvasRef, completedCrop)}
              >
                <Typography>Guardar</Typography>
              </Button>
            </ButtonGroup>
          </CustomOptionBox>
        </CustomDetailsBox>
      </CustomMainBox>
    </Dialog>
  );
};

export default CropModal;
