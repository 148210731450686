import React from 'react';
import {
  CircularProgress, Container, Stack, styled, Typography,
} from '@mui/material';
import { DateRange } from 'mui-daterange-picker';

import Header from './header';
import Results from './results/index';
import { COLORS } from '../../constants';
import { InventoryBySection } from '../../core';

const ContainerMain = styled(Container)`
  width: 100%;
  background-color: ${COLORS.GREY};
  min-height: 93.8vh;
`;

type InventoryProps = {
  metricClickHandler: () => void,
  productsBySections?: InventoryBySection[] ;
  onReset:()=>void;
  onFilter:(restaurantId:string, subsidiaryId:string, range:DateRange)=>void,
  onExportExcelInventoryHistory: (dateRange:DateRange) => void;
};

export const InventoryHistoryScreen = ({
  metricClickHandler, productsBySections, onReset, onFilter, onExportExcelInventoryHistory,
}:InventoryProps) => {
  const [dateRange, setRange] = React.useState<DateRange>({});
  return (
    <ContainerMain maxWidth="lg">
      <Stack direction="column" spacing={4}>
        <Header
          onReset={onReset}
          onFilter={onFilter}
          setDateRange={setRange}
          dateRange={dateRange}
          onExportExcelInventoryHistory={onExportExcelInventoryHistory}
          productsBySectionsLength={productsBySections?.length || 0}
        />
        {!productsBySections && (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
        )}
        {productsBySections && productsBySections.length === 0 && (
        <Typography>No hay turnos a mostrar</Typography>
        )}
        {productsBySections && productsBySections.length > 0 && (
        <Results metricClickHandler={metricClickHandler} productsBySections={productsBySections} dateRange={dateRange} />
        )}
      </Stack>
    </ContainerMain>
  );
};
