export const TIME_ZONES = {
  BOLIVIA: -240,
};

export const COLORS = {
  SKY_BLUE: '#D0D8EC',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  GREY: '#DBDBDB',
  RED: '#C23F25',
  LIGHT_GREY: '#E8E8E8',
  LIGHT_BLUE: '#2C4BA0',
  ROYAL_BLUE_DARK: '#1E346E',
  BLUE_LIGHT_78: '#A7B8E7',
  BLUE_LIGHT_92: '#DFE5F6',
  BLUE_LIGHT_52: '#3F65CB',
  RED_DARK: '#7B141F',
  RED_LIGHT_78: '#EF9FA8',
  RED_LIGHT_92: '#F9DCDF',
  RED_LIGHT_96: '#FCEDEF',
  RED_LIGHT_60: '#E25060',
  SILVER_CHALICE_DARK: '#EFF2FB',
  SILVER_CHALICE: '#B3B2B3',
  SHIMMERING: '#EB8490',
  BG_GRAY: '#ECECEC',
  SILVER_LIGHT: '#9F9D9F',
  CORNFLOWER_BLUE: '#6F8BD8',
  GREEN: '#56B19E',
  DISABLED_GRAY: '#F2F2F2',
  RUBY_RED: '#9E1A29',
  ROYAL_BLUE_DARK_52: '#3F65CB',
  GRAY_LIGHT: '#999999',
  GRAY_LIGHT_2: '#707070',
  GRAY_LIGHT_3: '#797979',
  DEFAULT_TRANSPARENT: 'rgba(255,255,255,0.9)',
};

export const ORDER_TYPE = {
  DELIVERY_ENTREGAS: 'DELIVERY_ENTREGAS',
  DELIVERY_OTHER: 'DELIVERY_OTHER',
  LOCAL: 'LOCAL',
  PICK_UP: 'PICK_UP',
};

export const ROUTES = {
  INDEX: '/',
  ORDERS: '/orders',
  DASHBOARD: '/dashboard',
  INVENTORY: '/inventory',
  INVENTORY_HISTORY: '/inventoryHistory',
  MENU: '/menu',
  SUBSIDIARY_MENU: '/subsidiaryMenu',
  SHARED_MENU: '/sharedMenu',
  GALLERY: '/gallery',
  COMMAND_PANEL: '/commandPanel',
  PAYMENT: '/payment',
  ORDER: '/order',
  TURN: '/turns',
  ORDERS_ON_HOLD: '/order/onHold',
  ORDERS_IN_PROCESS: '/order/inProcess',
  ORDERS_NEW: '/order/new',
  ORDERS_COMPLETED: '/order/completed',
  ORDERS_NO_SALES: '/order/noSales',
  ADMINISTRATIO_ACCOUNTS: '/administrationAccounts',
  RESTAURANT_PROFILE: '/restaurantProfile',
  SUBSIDIARY_INFO: '/restaurantProfile/subsidiary',
  SALES_REPORT: '/performance/salesReport',
};

export const ORDER_STATUS = {
  NEW: 'new',
  IN_PROCESS: 'inProcess',
  COMPLETED: 'completed',
  NO_SALES: 'noSales',
  ON_HOLD: 'onHold',
};

export const TITLES_NAVBAR = {
  DASHBOARD: 'Dashboard',
  ORDER_PANEL: 'Panel de ordenes',
  NEW: 'Nuevo',
  IN_PROCESS: 'En proceso',
  COMPLETED: 'Completadas',
  NO_SALES: 'Canceladas',
  ON_HOLD: 'En espera',
  MENU: 'Menu',
  SUBSIDIARY_MENU: 'Menú sucursal',
  SHARED_MENU: 'Menú compartido',
  GALLERY: 'Galeria',
  SALES_REPORT: 'Reporte de ventas',
  SCHEDULE: 'Horarios',
  INVENTORY: 'Inventario',
  INVENTORY_ACTUAL: 'Inventario Actual',
  INVENTORY_HISTORICAL: 'Historial de inventario',
  CONFIGURATIONS: 'Configuraciones',
  ADMINISTRATIO_ACCOUNTS: 'Cuentas de administración',
  RESTAURANT_PROFILE: 'Perfil del restaurante',
  TURN: 'Turnos',
  SETTINGS: 'Ajustes',
  LOG_OUT: 'Cerrar Sesión',
};
export const IMAGE_SIZES = {
  BANNER_MIN_WIDTH: '800',
  BANNER_MIN_HEIGHT: '400',
  LOGO_MIN_WIDTH: '400',
  LOGO_MIN_HEIGHT: '400',
  PRODUCT_MIN_WIDTH: '400',
  PRODUCT_MIN_HEIGHT: '400',
};

export const LINK_APPS = {
  android: 'https://play.google.com/store/apps/details?id=com.believesol.xpress',
  ios: 'https://apps.apple.com/bo/app/xpress/id1507805959',
};

export const COOK_AVERAGE_TYPE = [
  {
    type: 'VERY_FAST',
    min: 0,
    max: 10,
  },
  {
    type: 'FAST',
    min: 11,
    max: 20,
  },
  {
    type: 'NORMAL',
    min: 21,
    max: 30,
  },
  {
    type: 'SLOW',
    min: 31,
    max: 40,
  },
  {
    type: 'VERY_SLOW',
    min: 41,
    max: 50,
  },
];

export const DAYS = [
  { en_us: 'MONDAY', value: 'Lunes' },
  { en_us: 'TUESDAY', value: 'Martes' },
  { en_us: 'WEDNESDAY', value: 'Miercoles' },
  { en_us: 'THURSDAY', value: 'Jueves' },
  { en_us: 'FRIDAY', value: 'Viernes' },
  { en_us: 'SATURDAY', value: 'Sabado' },
  { en_us: 'SUNDAY', value: 'Domingo' },
];

export const COLLECTIONS = {
  USER: 'user',
  RESTAURANT: 'restaurant',
  SUBSIDIARY: 'subsidiary',
  PROFILE: 'profiles',
  PRODUCTS: 'products',
  SHARED_PRODUCTS: 'shared_products',
  LOCAL_PRODUCTS: 'local_products',
  MENU_ADDITIONS: 'menu_additions',
  GALLERY: 'gallery',
  ADDITION: 'additions',
  ORDER: 'orders',
  ORDER_PRODUCT: 'order_product',
  RESTAURANT_PROFILES: 'restaurant_profiles',
  PROFILES: 'profiles',
  CITY: 'city',
  METRIC: 'metric',
  JOURNEY: 'journey',
  JOURNEY_PRODUCTION: 'journey_production',
  JOURNEY_PRODUCTION_PRODUCTS: 'journey_production_product',
};

export const ERROR_CODE_AUTH = [
  'auth/invalid-credential',
  'auth/operation-not-allowed',
  'auth/user-disabled',
  'auth/user-not-found',
  'auth/wrong-password',
];

export const STATUS_ORDER = [{
  id: 'NEW',
  value: 'NUEVO',
}, {
  id: 'IN_PROCESS',
  value: 'EN PROCESO',
}, {
  id: 'COMPLETED',
  value: 'COMPLETADO',
}, {
  id: 'CANCELED',
  value: 'CANCELADO',
}, {
  id: 'NO_SALES',
  value: 'SIN VENTAS',
}];

export const TYPE_ORDERS_FILTERED = [{
  name: 'Pedido nuevo',
  id: 'NEW',
}, {
  name: 'Pedido en proceso',
  id: 'IN_PROCESS',
}, {
  name: 'Pedido completado',
  id: 'COMPLETED',
}, {
  name: 'Pedido cancelado',
  id: 'CANCELED',
}, {
  name: 'Sin ventas',
  id: 'NO_SALES',
}, {
  name: 'Ver todo',
  id: 'ALL',
}];

export const STATUS_PAYMENT = [{
  name: 'Todos los pagos',
  id: 'all',
}, {
  name: 'Pagado',
  id: 'PAID',
}, {
  name: 'Pendiente',
  id: 'PENDING',
}, {
  name: 'No pagado',
  id: 'NOT_PAYED',
}];

export const MONTHS_FILTERED = [{
  name: 'TODOS LOS MESES',
  id: 'all',
}, {
  name: 'ENERO',
  id: '01',
}, {
  name: 'FEBRERO',
  id: '02',
}, {
  name: 'MARZO',
  id: '03',
}, {
  name: 'ABRIL',
  id: '04',
}, {
  name: 'MAYO',
  id: '05',
}, {
  name: 'JUNIO',
  id: '06',
}, {
  name: 'JULIO',
  id: '07',
}, {
  name: 'AGOSTO',
  id: '08',
}, {
  name: 'SEPTIEMBRE',
  id: '09',
}, {
  name: 'OCTUBRE',
  id: '10',
}, {
  name: 'NOVIEMBRE',
  id: '11',
}, {
  name: 'DICIEMBRE',
  id: '12',
}];

// eslint-disable-next-line max-len
export const BORDER_DECORATION = 'data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'6\' ry=\'6\' stroke=\'%2356B19EFF\' stroke-width=\'1\' stroke-dasharray=\'7%2c 7\' stroke-dashoffset=\'3\' stroke-linecap=\'square\'/%3e%3c/svg%3e';
