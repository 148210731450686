export const getInitials = (full_name: string) => full_name
  .replace(/\s+/, ' ')
  .split(' ')
  .slice(0, 2)
  .map((v) => v && v[0].toUpperCase())
  .join('');

export const roundTwoDecimals = (number: number) => {
  if (number) {
    const num = Number(number);
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }
  return 0;
};
