import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconButton, Typography } from '@mui/material';
import { CrossIcon, PosSwitch, SimpleModal } from '../../../../components';
import { Addition } from '../../../../core';
import AdditionEditForm, { AdditionFormValues } from './AdditionEditForm';

const BoxCustom = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
}));

const CardHeaderCustom = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

type ModalCreateAdditionProps = {
  show: boolean,
  onClose: () => void,
  addition?: Addition,
  prodId?:string,
  onCreateMenuAdditionsRestaurant?: (
    values: AdditionFormValues, availability: boolean
  ) => void | Promise<void>,
  onEditAdditionRestaurantAndProduct?: (
    values: AdditionFormValues,
    availability:boolean,
    menuAdditionId: string,
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
};

const ModalCreateAddition = ({
  show,
  onClose,
  prodId,
  addition,
  onCreateMenuAdditionsRestaurant,
  onEditAdditionRestaurantAndProduct,
}: ModalCreateAdditionProps) => {
  const [availability, setAvailability] = useState(true);

  useEffect(() => {
    if (addition && addition?.availability) {
      setAvailability(addition.availability);
    }
  }, [addition]);

  return (
    <SimpleModal
      show={show}
      onClose={onClose}
      size="sm"
      hideCloseIcon
    >
      <CardHeaderCustom>
        <Typography>
          <strong>
            {addition?.id ? 'Editar Adición' : 'Nueva Adición'}
          </strong>
        </Typography>
        <BoxCustom>
          <Typography variant="body2">
            {availability ? 'Disponible' : 'No Disponible'}
          </Typography>
          <PosSwitch
            value={availability}
            onChange={(e:any) => {
              setAvailability(e.target.checked);
            }}
          />
        </BoxCustom>
        <IconButton
          onClick={() => onClose()}
        >
          <CrossIcon />
        </IconButton>
      </CardHeaderCustom>
      <AdditionEditForm
        addition={addition}
        onClose={onClose}
        availability={availability}
        prodId={prodId || ''}
        onCreateMenuAdditionsRestaurant={onCreateMenuAdditionsRestaurant}
        onEditAdditionRestaurantAndProduct={onEditAdditionRestaurantAndProduct}
      />
    </SimpleModal>
  );
};

export default ModalCreateAddition;
