import React from 'react';
import {
  Divider, ListItem, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

type ItemProps = {
  text: string;
  icon?: any;
  href?: any;
  isSubList?: boolean;
  onClick?: () => Promise<void>;
}

const ListItemIconCustom = styled(ListItemIcon)`
  min-Width: 57;
`;

const DividerCustom = styled(Divider)`
  border-color: #9F9D9F;
`;

export const SimpleListItem = ({
  icon: Icon, text, href, isSubList = false, onClick,
}:ItemProps) => {
  const router = useNavigate();

  return (
    <>
      <ListItem
        key={`key-${text}-${href}`}
        disablePadding
        onClick={() => {
          if (href) router(href);
          if (onClick) onClick();
        }}
      >
        <ListItemButton sx={{ paddingBlock: '23px', paddingInline: isSubList ? '46px' : '23px' }}>
          {Icon && (
            <ListItemIconCustom>
              <Icon />
            </ListItemIconCustom>
          )}
          {text && (<ListItemText primary={text} />)}
        </ListItemButton>
      </ListItem>
      <DividerCustom />
    </>
  );
};
