import { Addition, OrderAdditionSelected } from '../core';

export const verifyAdditions = (additions: Addition[], selectAdditions: OrderAdditionSelected[]) => {
  let missingAdditions:string[] = [];
  if (additions && additions?.length > 0) {
    const countObjects:any = {};
    selectAdditions?.forEach((addition) => {
      const additionKey:string = addition?.addition_id;
      if (countObjects[additionKey]) countObjects[additionKey] += 1;
      else countObjects[additionKey] = 1;
    });
    additions.forEach((addition) => {
      if (addition.mandatory) {
        if (addition.max_selection !== countObjects[addition.id]) {
          missingAdditions = [...missingAdditions, `Falta adiciones de ${addition.name}`];
        }
      }
    });
  }
  return missingAdditions;
};
