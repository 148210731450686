import React from 'react';
import {
  Box, Card, CircularProgress, Typography, Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CropIcon from '@mui/icons-material/Crop';
import { COLORS } from '../../../constants';
import { Gallery, Photo } from '../../../core';

const CustomIconBox = styled(Box)`
  background-color: ${COLORS.GREEN};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  float: right;
`;

const CustomIconLoader = styled(CircularProgress)`
  width: 25px !important;
  height: 25px !important;
  float: right;
  z-index: 1;
`;

const CustomCropIcon = styled(CropIcon)`
  width: 16px;
  height: 16px;
  color: white;
`;

const CustomImgBox = styled(Box)`
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
`;

type CardImageProps = {
  gallery: Gallery,
  imageSelect: string,
  handleSelect: (id: string, photo: Photo) => void,
  handleCropOriginal: (id: string) => void,
  loader: boolean,
  loaderCard: string,
  loaderCrop: boolean,
};

export const CardImageGallery = ({
  gallery, imageSelect, handleSelect, handleCropOriginal, loader, loaderCard, loaderCrop,
}: CardImageProps) => (
  <Card>
    <CustomImgBox
      style={imageSelect === gallery.id ? {
        border: '4px solid #B2E7D8',
        maxWidth: 216,
        maxHeight: 132,
        cursor: 'pointer',
        backgroundImage: `url("${gallery?.image?.original}")`,
        position: 'relative',
      } : {
        cursor: 'pointer',
        backgroundImage: `url("${gallery?.image?.original}")`,
        position: 'relative',
      }}
      height="140px"
      title={gallery.title}
      onClick={() => handleSelect(gallery.id, gallery.image)}
    >
      {loader && loaderCard === gallery.id && (
        <CustomImgBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            backgroundColor: 'rgb(0, 23, 20, 0.7)',
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress />
        </CustomImgBox>
      )}
    </CustomImgBox>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={1}
      mb={1}
    >
      <Tooltip title={gallery.title}>
        <Typography width="100%" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" variant="h5" component="h2">
          {gallery.title}
        </Typography>
      </Tooltip>
      {!loaderCrop && gallery?.original_image && (
        <CustomIconBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          mr={1}
          onClick={() => handleCropOriginal(gallery.id)}
        >
          <CustomCropIcon />
        </CustomIconBox>
      )}
      {loaderCrop && loaderCard === gallery.id && (
        <CustomIconLoader />
      )}
    </Box>
  </Card>
);
