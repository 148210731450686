import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';

export type ProductsStock = Record<string, number>

type StockStateType = {
  productsStock:ProductsStock
}

const initialState: StockStateType = {
  productsStock: {},
};

export const stockSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    setProductsStock: (state: Draft<StockStateType>, action:PayloadAction<ProductsStock>) => {
      state.productsStock = action.payload;
    },
  },
});

export const {
  setProductsStock,
} = stockSlice.actions;
export default stockSlice.reducer;
export const stockReducerTransform = createTransform(
  (toSave:StockStateType) => ({
    ...toSave,
  }),
  (toRehydrated) => ({
    ...initialState,
    ...toRehydrated,
  }),
  { whitelist: ['stock'] },
);
