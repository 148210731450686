import React from 'react';
import { Cross, Edit } from '../../assets';

export const CrossIcon = () => (
  <img
    src={Cross}
    alt="cross"
  />
);

export const EditIcon = () => (
  <img
    src={Edit}
    alt="edit"
  />
);
