import React from 'react';
import {
  Container, Stack, Typography, Paper, CircularProgress, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../constants';
import Header from './Header';
import MenuSectionList from './MenuSectionList';
import { MenuSection } from '../../core/menuSection';
import { enableSectionProps, saveNameProps } from './types';
import { useAppSelector } from '../../appHooks/redux';
import { Nullable } from '../../types';

const ContainerMain = styled(Container)(() => ({
  width: '100%',
  minHeight: '93.8vh',
  backgroundColor: COLORS.BG_GRAY,
}));

type MenuSectionScreenProps = {
  menuSectionsDB: Nullable<MenuSection[]>,
  onCreateSection?: () => void,
  isSharedMenu: boolean,
  onSaveName?: ({
    name, section, index, enable,
  }: saveNameProps) => void;
  onRemoveSection?: (index: number) => void;
  onEnableSection: ({
    section, index, enable,
  }: enableSectionProps) => void;
  onDragEnd: (menuSections: MenuSection[]) => void;
  onClickSection: (sectionId: string) => void;
  onExportToXlsx?: () => void;
  onSaveMenuImport?: (fileData:any) => void,
}
export const MenuSectionScreen = ({
  menuSectionsDB,
  onCreateSection,
  isSharedMenu,
  onSaveName,
  onRemoveSection,
  onEnableSection,
  onDragEnd,
  onClickSection,
  onExportToXlsx,
  onSaveMenuImport,
}: MenuSectionScreenProps) => {
  const currentRestaurant = useAppSelector((state) => state.restaurant.restaurant);
  const currentSubsidiary = useAppSelector((state) => state.subsidiary.subsidiary);

  return (
    <ContainerMain maxWidth="xl">
      <Stack>
        <Stack mt={4} mb={5} justifyContent="center" alignItems="center">
          <Typography sx={{ fontWeight: 'bold', fontSize: 23 }}>
            {isSharedMenu
              ? `${currentRestaurant?.name || ''}`
              : `${currentRestaurant?.name || ''} - ${currentSubsidiary?.name || ''}`}
          </Typography>
        </Stack>
        <Paper
          square
          sx={{ padding: '32px 16px 16px 16px', minHeight: '80vh', marginBottom: '24px' }}
        >
          <Stack>
            <Header onCreateSection={onCreateSection} isSharedMenu={isSharedMenu} onExportToXlsx={onExportToXlsx} onSaveMenuImport={onSaveMenuImport} />
            {!menuSectionsDB && (
              <Box display="flex" justifyContent="center" my={5}>
                <CircularProgress />
              </Box>
            )}
            {menuSectionsDB && (
              <Stack mt={3}>
                <MenuSectionList
                  menuSectionsDB={menuSectionsDB}
                  isSharedMenu={isSharedMenu}
                  onSaveName={onSaveName}
                  onRemoveSection={onRemoveSection}
                  onEnableSection={onEnableSection}
                  onDragEnd={onDragEnd}
                  onClickSection={onClickSection}
                />
              </Stack>
            )}
          </Stack>
        </Paper>
      </Stack>
    </ContainerMain>
  );
};
