import React, { useId } from 'react';
import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  AddImage,
  CardImage,
} from '../../components/gallery';
import { Gallery } from '../../core/gallery';
import { COLORS } from '../../constants';
import { UploadImgGalleryProps } from './types';

const CustomMainBox = styled(Box)`
  padding: 15px;
  height: 100%;
`;

const CustomCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CustomTitleBox = styled(Box)`
  margin: 25px 0px;
`;

const CustomDivider = styled(Divider)`
  margin: 15px;
  width: 97%;
`;

const CustomGalleryBox = styled(Grid)`
  width: 100%;
`;

const CustomMainImage = styled(Box)`
    border: 1px solid ${COLORS.SKY_BLUE};
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

type GalleryScreenProps = {
  galleryList: Gallery[],
  onUpdateGallery: (gallery: Gallery) => void | Promise<void>,
  onAddGallery: (gallery: Gallery) => void | Promise<void>,
  onUploadImg: ({ file, docId }: UploadImgGalleryProps) => void | Promise<boolean>,
  onDeleteGallery: (urlImage: string[], docId: string) => void | Promise<void>,
}

export const GalleryScreen = ({
  galleryList,
  onUpdateGallery,
  onAddGallery,
  onUploadImg,
  onDeleteGallery,
}: GalleryScreenProps) => {
  const newGallery = useId();

  return (
    <CustomMainBox>
      <CustomCard>
        <CustomTitleBox>
          <Typography variant="h1" textTransform="uppercase">Galeria</Typography>
        </CustomTitleBox>
        <CustomDivider />
        <CustomGalleryBox container direction="row" spacing={6} marginBottom={2}>
          {galleryList.map((gallery: Gallery) => (
            <Grid sm={3} item key={gallery.id} xs={12} height="300px">
              <CustomMainImage>
                <CardImage
                  docId={gallery.id}
                  title={gallery.title}
                  onUpdateGallery={onUpdateGallery}
                  onAddGallery={onAddGallery}
                  onUploadImg={onUploadImg}
                  onDeleteGallery={onDeleteGallery}
                  gallery={gallery}
                />
              </CustomMainImage>
            </Grid>
          ))}
          <Grid sm={3} item key={newGallery} height="300px">
            <CustomMainImage>
              <Box
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <AddImage onUpdateGallery={onUpdateGallery} onAddGallery={onAddGallery} />
              </Box>
            </CustomMainImage>
          </Grid>
        </CustomGalleryBox>
      </CustomCard>
    </CustomMainBox>
  );
};
