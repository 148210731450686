import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';
import { Profile } from '../../../core';

type ProfileState = {
  profiles: Profile[] | null,
  selectProfile: Profile | null,
}

const initialState: ProfileState = { profiles: null, selectProfile: null };

export const profileSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    setProfiles: (state: Draft<ProfileState>, action:PayloadAction<Profile[]>) => {
      state.profiles = action.payload;
    },
    cleanProfiles: (state: Draft<ProfileState>) => {
      state.profiles = null;
    },
    setSelectedProfile: (state: Draft<ProfileState>, action:PayloadAction<Profile>) => {
      state.selectProfile = action.payload;
    },
    cleanSelectedProfile: (state: Draft<ProfileState>) => {
      state.selectProfile = null;
    },
  },
});

export const {
  setProfiles, cleanProfiles, setSelectedProfile, cleanSelectedProfile,
} = profileSlice.actions;
export default profileSlice.reducer;
export const profileReducerTransform = createTransform(
  (toSave:any) => ({
    ...toSave,
  }),
  (toRehydrated) => ({
    ...toRehydrated,
  }),
  { whitelist: ['profiles'] },
);
