import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { onSnapshot, query, where } from '@firebase/firestore';
import { useAppDispatch, useAppSelector } from '../appHooks/redux';
import {
  Product,
  subsidiaryProductsRef,
  Subsidiary,
  subsidiaryRef,
  Restaurant,
  restaurantRef,
} from '../core';
import { setSubsidiary } from '../redux/reducers/subsidiary';
import { setRestaurant } from '../redux/reducers/restaurant';
import { setProducts } from '../redux/reducers/product';

export const SubsidiaryListenerProvider = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((store) => store.user.user);

  useEffect(() => {
    let unsubscribe = () => {
    };
    if (user?.subsidiary_access?.subsidiary_id && user?.subsidiary_access?.restaurant_id) {
      unsubscribe = onSnapshot(subsidiaryRef(user.subsidiary_access.restaurant_id, user.subsidiary_access.subsidiary_id), (snapshot) => {
        if (snapshot.exists()) {
          dispatch(setSubsidiary(new Subsidiary({ ...snapshot.data() })));
        } else {
          dispatch(setSubsidiary(null));
        }
      });
    }
    return unsubscribe;
  }, [user]);
  return null;
};
export const RestaurantListenerProvider = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((store) => store.user.user);

  useEffect(() => {
    let unsubscribe = () => {
    };
    if (user?.subsidiary_access?.restaurant_id) {
      unsubscribe = onSnapshot(restaurantRef(user.subsidiary_access.restaurant_id), (snapshot) => {
        if (snapshot.exists()) {
          dispatch(setRestaurant(new Restaurant({ ...snapshot.data() })));
        } else {
          dispatch(setRestaurant(null));
        }
      });
    }
    return unsubscribe;
  }, [user]);
  return null;
};

export const ProductListenerProvider = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((store) => store.user.user);

  useEffect(() => {
    let unsubscribe = () => {
    };
    if (user?.subsidiary_access?.subsidiary_id && user?.subsidiary_access?.restaurant_id) {
      const doc = subsidiaryProductsRef(user.subsidiary_access.restaurant_id, user.subsidiary_access.subsidiary_id);
      unsubscribe = onSnapshot(query(doc, where('availability', '==', true)), (snapshot) => {
        if (snapshot.size) {
          const products = snapshot.docs.map((snap) => new Product({ ...snap.data(), id: snap.id }));
          dispatch(setProducts(products));
        } else {
          dispatch(setProducts(null));
        }
      });
    }
    return unsubscribe;
  }, [user]);
  return null;
};

export const CommonFirestoreListenersProvider = () => (
  <>
    <RestaurantListenerProvider />
    <SubsidiaryListenerProvider />
    <ProductListenerProvider />
  </>
);
