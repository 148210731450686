import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { COLLECTIONS } from '../../constants';

import { MenuSection } from './menuSection.dto';

const db = getFirestore();

export const editSharedMenuSection = async (
  newSections: MenuSection[],
  restId: string,
): Promise<void> => {
  const restaurantRef = doc(db, COLLECTIONS.RESTAURANT, restId);
  await updateDoc(restaurantRef, {
    menu_section: newSections.map((menuSection) => menuSection.toPlainObject()),
  });
};

export const editSubsidiaryMenuSection = async (
  newSections: MenuSection[],
  restId: string,
  subId: string,
): Promise<void> => {
  const subsidiaryRef = doc(db, COLLECTIONS.RESTAURANT, restId, COLLECTIONS.SUBSIDIARY, subId);
  await updateDoc(subsidiaryRef, {
    menu_section: newSections.map((section) => section.toPlainObject()),
  });
};
