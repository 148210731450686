const FileUtils = {
  isXlsx(file:any) {
    const extension_xlsx = ['xls', 'xlsx'];
    let ext = file.name.split('.').pop();
    ext = ext.toLowerCase();
    return extension_xlsx.includes(ext);
  },
};

export default FileUtils;
