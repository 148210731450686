import React from 'react';
import moment from 'moment-timezone';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  styled,
  Stack,
  Button,
} from '@mui/material';
import { Profile, ROLES } from '../../core';
import { toTimestamp } from '../../utils/firestoreUtils';
import { COLORS } from '../../constants';

type TableOrdersProps = {
  rows?: Profile[];
  headerData:string[];
  deleteUserHandler:(user:Profile)=>void;
  editModalHandler:(user:Profile)=> void;
};

const OrderRow = styled(TableRow)`
  &:last-child td, &:last-child th {
    border: 0;
  }
`;
const getPosition = (item:ROLES) => {
  if (item === ROLES.CASHIER) { return 'Cajero'; }
  if (item === ROLES.SUPERVISOR) { return 'Supervisor'; }
  if (item === ROLES.OWNER) { return 'Dueño'; }
  return 'Sin rol';
};

export const TableUsers = ({
  rows, headerData, deleteUserHandler, editModalHandler,
}:TableOrdersProps) => (
  <TableContainer
    component={Paper}
  >
    <Table aria-label="oreder-table">
      <TableHead>
        <TableRow key="header-row">
          {headerData?.map((head:string) => (
            <TableCell align="center" key={head}>
              <Typography variant="h4">{head}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows?.map((row: Profile, index) => {
          const rowDate = row?.created_at ? toTimestamp(row?.created_at).toDate() : null;
          return (
            <OrderRow
              key={`key-order-${row.id}`}
            >
              <TableCell align="center">{index + 1 || '-'}</TableCell>
              <TableCell align="center">{row.full_name || '-'}</TableCell>
              <TableCell align="center">{row.phone_number || '-'}</TableCell>
              <TableCell align="center">{row.email || '-'}</TableCell>
              <TableCell align="center">{getPosition(row.roles[0]) || '-'}</TableCell>
              <TableCell align="center">{ rowDate ? moment(rowDate).format('DD/MM/YY HH:mm') : '-'}</TableCell>
              <TableCell align="center">
                <Stack spacing={2} direction="row">

                  <Button
                    variant="contained"
                    style={{ backgroundColor: COLORS.BLUE_LIGHT_52 }}
                    onClick={() => editModalHandler(row)}
                  >
                    <Typography
                      textTransform="initial"
                      color="black"
                      fontWeight="bold"
                    >
                      Editar
                    </Typography>
                  </Button>

                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#E25060' }}
                    onClick={() => deleteUserHandler(row)}
                    disabled={row.roles.includes(ROLES.OWNER)}
                  >
                    <Typography
                      textTransform="initial"
                      color="black"
                      fontWeight="bold"
                    >
                      Borrar
                    </Typography>
                  </Button>

                </Stack>

              </TableCell>
            </OrderRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
);
