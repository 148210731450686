import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomMainCard = styled(Card)`
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
`;

type TotalsFooterProps = {
  totalGrossSale: number,
  totalDiscount: number,
  totalSales: number,
};

const TotalsFooter = ({ totalGrossSale, totalDiscount, totalSales }:TotalsFooterProps) => (
  <CustomMainCard>
    <Box display="flex">
      <Box display="flex" mr={2}>
        <Typography marginRight={1}>Ventas Brutas Total:</Typography>
        <Typography fontWeight="bold">{`Bs. ${totalGrossSale.toFixed(2)}`}</Typography>
      </Box>
      <Box display="flex" mr={2}>
        <Typography marginRight={1}>Descuento Total:</Typography>
        <Typography fontWeight="bold">{`Bs. ${totalDiscount.toFixed(2)}`}</Typography>
      </Box>
      <Box display="flex" mr={2}>
        <Typography marginRight={1}>Ventas Total:</Typography>
        <Typography fontWeight="bold">{`Bs. ${totalSales.toFixed(2)}`}</Typography>
      </Box>
    </Box>
  </CustomMainCard>
);

export default TotalsFooter;
