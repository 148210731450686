import React from 'react';
import Switch from '@mui/material/Switch';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { COLORS } from '../../constants';

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: COLORS.RED_DARK,
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: COLORS.ROYAL_BLUE_DARK,
          },
        },
        track: {
          opacity: 0.2,
          backgroundColor: COLORS.RED_LIGHT_78,
          '.Mui-checked.Mui-checked + &': {
            opacity: 0.7,
            backgroundColor: COLORS.BLUE_LIGHT_78,
          },
          '.Mui-disabled.Mui-disabled + &': {
            opacity: 1,
            backgroundColor: COLORS.GREY,
          },
        },
      },
    },
  },
});

type PosSwitchProps = {
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
};

export const PosSwitch = ({
  disabled,
  onChange,
  value,
}: PosSwitchProps) => (
  <ThemeProvider theme={theme}>
    <Switch
      checked={value}
      disabled={disabled}
      onChange={onChange}
    />
  </ThemeProvider>
);
