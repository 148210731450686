import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../appHooks/redux';
import { JourneyProductionProduct, useGetLastOpenJourneyProduction } from '../core';
import { useAllJourneyProductionProductSubsidiary } from '../core/journeyProductionProduct/journeyProductionProduct.hook';
import {
  setAfterProduct,
  setBeforeProduct,
  setJourneyProduction, setJourneyProductionProducts, setJourneyProductionProductsSet,
} from '../redux/reducers/inventory';
import { ProductsStock, setProductsStock } from '../redux/reducers/stock';

const InventoryProviderDb = () => {
  const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  const restaurant = useAppSelector((state) => state.restaurant.restaurant);
  const journeyProductionResult = useGetLastOpenJourneyProduction(restaurant?.id, subsidiary?.id);
  const { value: journeyProduction } = journeyProductionResult;
  const journeyProductionProduct = useAllJourneyProductionProductSubsidiary(restaurant?.id, subsidiary?.id, journeyProduction?.id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setJourneyProduction(
      journeyProductionResult,
    ));
  }, [journeyProductionResult]);

  useEffect(() => {
    dispatch(setJourneyProductionProducts(
      journeyProductionProduct,
    ));
  }, [journeyProductionProduct]);

  useEffect(() => {
    const object:Record<string, JourneyProductionProduct> = {};
    journeyProductionProduct?.forEach((item) => { object[item.product.id] = item; });
    dispatch(setJourneyProductionProductsSet(object));
  }, [journeyProductionProduct]);
  return null;
};

const ProductsReservedProvider = () => {
  const dispatch = useAppDispatch();
  const selectProduct = useAppSelector((state) => state.shoppingCart.selectProduct);
  const orderProductDbResume = useAppSelector((state) => {
    const result:Record<string, number> = {};
    state.shoppingCart.orderProduct.forEach((orderProductItem) => {
      if (!result[orderProductItem.product_id]) {
        result[orderProductItem.product_id] = 0;
      }
      result[orderProductItem.product_id] += orderProductItem.quantity;
    });
    return result;
  }, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));

  const journeyProductionProducts = useAppSelector((state) => state.inventory.journeyProductionProducts);
  const rightSideProductsResume = useAppSelector((state) => {
    const result:Record<string, number> = {};
    state.shoppingCart.currentProducts?.forEach((currentProduct) => {
      const { id, quantity } = currentProduct;
      if (!result[id]) {
        result[id] = quantity;
      } else {
        result[id] += quantity;
      }
    });
    return result;
  }, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));

  useEffect(() => {
    dispatch(setBeforeProduct(Object.keys(orderProductDbResume).map((key) => [key, orderProductDbResume[key]])));
    dispatch(setAfterProduct(Object.keys(rightSideProductsResume).map((key) => [key, rightSideProductsResume[key]])));
  }, [rightSideProductsResume, orderProductDbResume]);

  useEffect(() => {
    if (journeyProductionProducts) {
      const stock:ProductsStock = {};
      journeyProductionProducts.forEach((journeyProductionProduct) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        stock[journeyProductionProduct.product.id] = (journeyProductionProduct.getStock() || 0)
            - (rightSideProductsResume[journeyProductionProduct.product.id] || 0)
        + (orderProductDbResume[journeyProductionProduct.product.id] || 0);
      });
      dispatch(setProductsStock(stock));
    } else {
      dispatch(() => {});
    }
  }, [journeyProductionProducts, selectProduct, rightSideProductsResume, orderProductDbResume]);
  return null;
};

export const AppInventoryProvider = () => (
  <>
    <InventoryProviderDb />
    <ProductsReservedProvider />
  </>
);
