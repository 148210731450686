import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../appHooks/redux';
import { HeadTitle } from '../../components';
import { Photo, Restaurant } from '../../core';
import { editRestaurant, uploadBannerLogo } from '../../core/restaurant/restaurant.service';
import { useSubsidiaries } from '../../core/subsidiary/subsidiary.hook';
import { setRestaurant } from '../../redux/reducers/restaurant';
import { RestaurantProfileScreen } from '../../screens/restaurantProfileScreen';
import { getPhotoUrls } from '../../utils/images';

type Payload = {
    banner:File|undefined;
    logo:File|undefined;
    restaurantName:string;
  }
  type LogoBanner = {
    banner_photo:Photo
    logo_photo:Photo
  }

const RestaurantProfile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const restaurant = useAppSelector((state) => state.restaurant.restaurant);
  const subsidiaries = useSubsidiaries(restaurant?.id);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (payload:Payload) => {
    setLoading(true);
    try {
      const urls:LogoBanner = { banner_photo: restaurant?.banner_photo || new Photo({}), logo_photo: restaurant?.logo_photo || new Photo({}) };
      if (payload.logo) {
        const url = await uploadBannerLogo(payload.logo, 'logo', restaurant?.id || '');
        urls.logo_photo = await getPhotoUrls(restaurant?.id || '', url, url, 'logo');
      }
      if (payload.banner) {
        const url = await uploadBannerLogo(payload.banner, 'banner', restaurant?.id || '');
        urls.banner_photo = await getPhotoUrls(restaurant?.id || '', url, url, 'banner');
      }
      await editRestaurant({
        restaurant: {
          name: payload.restaurantName,
          logo_photo: urls.logo_photo,
          banner_photo: urls.banner_photo,
        },
        restaurantId: restaurant?.id || '',
      });
      if (restaurant) {
        dispatch(setRestaurant(new Restaurant({
          ...restaurant,
          name: payload.restaurantName,
          logo_photo: urls.logo_photo,
          banner_photo: urls.banner_photo,
        })));
      }
      setLoading(false);
      enqueueSnackbar('Registro guardado correctamente', {
        variant: 'success',
      });
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Ocurrio un error desconocido', {
        variant: 'error',
      });
    }
  };
  return (
    <>
      <HeadTitle title="Perfil de restaurant" />
      <RestaurantProfileScreen loading={loading} restaurant={restaurant} onSubmit={handleSubmit} subsidiaries={subsidiaries} />
    </>
  );
};

export default RestaurantProfile;
