import React, { useEffect, useState } from 'react';
import {
  Box, Button, Card, CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridColDef, GridComparatorFn } from '@mui/x-data-grid';
import { City, Subsidiary } from '../../../core';
import { Nullable } from '../../../types';
import { FiltersGroup } from './filtersGroup';
import TableReport from './tableReport';
import { DataBodyReqProps } from '../../../pages/report';
import { STATUS_ORDER } from '../../../constants';
import TotalsFooter from './totalsFooter';
import { getDeliveryService } from '../../../core/report/report.utils';
import { createMomentOnBolivianTime } from '../../../utils/date';

const CustomMainBox = styled(Box)`
  padding: 15px;
  height: 100%;
`;

const CustomTableCard = styled(Card)`
  height: 100%;
  widht: 100%;
`;
const dayInMonthComparator: GridComparatorFn<Date> = (v1, v2) => Number(v1) - Number(v2);

type SalesReportScreenProps = {
  allCities: Nullable<City[]>,
  allSubsidiaries: Nullable<Subsidiary[]>,
  allReport: any[] | undefined,
  onExportToExcel:(totalGrossSale:number, totalDiscount:number, totalSales:number) => void,
  handleSetData: (value: DataBodyReqProps) => void,
  loader: boolean,
}

const columns: GridColDef[] = [
  { field: 'counter', headerName: 'No', width: 70 },
  { field: 'order_code', headerName: 'No. de orden', width: 110 },
  { field: 'client_name', headerName: 'Nombre', width: 230 },
  { field: 'client_phone_number', headerName: 'Número de teléfono', width: 230 },
  { field: 'subsidiary_name', headerName: 'Sucursal', width: 230 },
  {
    field: 'date',
    valueGetter: (params) => params.row.date,
    valueFormatter: (param) => createMomentOnBolivianTime(param.value).locale('es').format('LLL'),
    headerName: 'Fecha',

    type: 'date',
    width: 250,
    sortComparator: dayInMonthComparator,
  },
  { field: 'status', headerName: 'Estado', width: 130 },
  { field: 'city', headerName: 'Ciudad', width: 130 },
  { field: 'service', headerName: 'Servicio', width: 135 },
  { field: 'gross_sales', headerName: 'Ventas brutas', width: 130 },
  { field: 'discount', headerName: 'Descuento', width: 130 },
  { field: 'total', headerName: 'Ventas netas', width: 130 },
];

const SalesReportScreen = ({
  allCities, allSubsidiaries, allReport, handleSetData, onExportToExcel, loader,
}: SalesReportScreenProps) => {
  const [rows, setRows] = useState<readonly any[]>([]);
  const [totalGrossSale, setTotalGrossSale] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalSales, setTotalSales] = useState(0);

  const handleValue = async () => {
    const array:any[] = [];
    const data = allReport || [];
    let total_gross_sales = 0;
    let total_discount = 0;
    let total_sales = 0;
    data.forEach((value:any) => {
      total_gross_sales += value.gross_sales;
      total_discount += value.discount;
      total_sales += value.total;
      array.push({
        ...value,
        date: new Date(value.created_at),
        created_at: value.date,
        client_name: value.client_name || '-',
        client_phone_number: value.client_phone_number || '-',
        status: STATUS_ORDER.find((values) => values.id === value.status)?.value,
        gross_sales: value.gross_sales.toFixed(2),
        discount: value.discount.toFixed(2),
        total: value.total.toFixed(2),
        service: getDeliveryService(value.service),
      });
    });
    setTotalGrossSale(total_gross_sales);
    setTotalDiscount(total_discount);
    setTotalSales(total_sales);
    setRows(array as readonly any[]);
  };

  useEffect(() => {
    handleValue();
  }, [allReport]);

  return (
    <CustomMainBox>
      <FiltersGroup allCities={allCities} allSubsidiaries={allSubsidiaries} handleSetData={handleSetData} />
      <Button onClick={() => onExportToExcel(totalGrossSale, totalDiscount, totalSales)} variant="contained">
        Exportar a excel
      </Button>
      <Box height="450px" mt={4}>
        { !loader && (
          <CustomTableCard>
            <TableReport columns={columns} rows={rows} />
          </CustomTableCard>
        )}
        { loader && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Box mt={4}>
        <TotalsFooter
          totalGrossSale={totalGrossSale}
          totalDiscount={totalDiscount}
          totalSales={totalSales}
        />
      </Box>
    </CustomMainBox>
  );
};

export default SalesReportScreen;
