import {
  TrendingUp,
  InventoryOutlined,
  SettingsOutlined,
  AdminPanelSettingsRounded,
} from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { TITLES_NAVBAR, ROUTES } from '../../../constants';
import { ROLES } from '../../../core';
import {
  CompleteOrderIcon,
  GalleryIcon,
  InProcessOrderIcon,
  MenuIcon,
  NewOrderIcon,
  NoSaleOrderIcon,
  // OnHoldOrderIcon,
  OrderPanelIcon,
  RestaurantProfileIcon,
  SaleReportIcon,
  XpressMenuIcon,
  ActualInventoryIcon,
  HistorialInventoryIcon,
} from './imageIcons';

export const navItemsGeneral = [
  {
    title: TITLES_NAVBAR.DASHBOARD,
    icon: TrendingUp,
    href: ROUTES.DASHBOARD,
  },
  {
    title: TITLES_NAVBAR.ORDER_PANEL,
    icon: OrderPanelIcon,
    href: ROUTES.COMMAND_PANEL,
    items: [
      {
        title: TITLES_NAVBAR.NEW,
        icon: NewOrderIcon,
        href: ROUTES.COMMAND_PANEL,
      },
      {
        title: TITLES_NAVBAR.IN_PROCESS,
        icon: InProcessOrderIcon,
        href: ROUTES.ORDERS_IN_PROCESS,
      },
      {
        title: TITLES_NAVBAR.COMPLETED,
        icon: CompleteOrderIcon,
        href: ROUTES.ORDERS_COMPLETED,
      },
      {
        title: TITLES_NAVBAR.NO_SALES,
        icon: NoSaleOrderIcon,
        href: ROUTES.ORDERS_NO_SALES,
      },
      // {
      //   title: TITLES_NAVBAR.ON_HOLD,
      //   icon: OnHoldOrderIcon,
      //   href: ROUTES.ORDERS_ON_HOLD,
      // },
    ],
  },
  {
    title: TITLES_NAVBAR.MENU,
    icon: MenuIcon,
    href: ROUTES.SUBSIDIARY_MENU,
    items: [
      {
        title: TITLES_NAVBAR.SHARED_MENU,
        icon: XpressMenuIcon,
        href: ROUTES.SHARED_MENU,
      },
      {
        title: TITLES_NAVBAR.GALLERY,
        icon: GalleryIcon,
        href: ROUTES.GALLERY,
      },
    ],
  },
  {
    title: TITLES_NAVBAR.SALES_REPORT,
    icon: SaleReportIcon,
    href: ROUTES.SALES_REPORT,
  },
  {
    title: TITLES_NAVBAR.INVENTORY,
    icon: InventoryOutlined,
    href: ROUTES.INVENTORY,
    items: [
      {
        title: TITLES_NAVBAR.INVENTORY_ACTUAL,
        icon: ActualInventoryIcon,
        href: ROUTES.INVENTORY,
      },
      {
        title: TITLES_NAVBAR.INVENTORY_HISTORICAL,
        icon: HistorialInventoryIcon,
        href: ROUTES.INVENTORY_HISTORY,
      },
    ],
  },
  {
    title: TITLES_NAVBAR.CONFIGURATIONS,
    icon: SettingsOutlined,
    href: ROUTES.ADMINISTRATIO_ACCOUNTS,
    items: [
      {
        title: TITLES_NAVBAR.ADMINISTRATIO_ACCOUNTS,
        icon: AdminPanelSettingsRounded,
        href: ROUTES.ADMINISTRATIO_ACCOUNTS,
        roles: [ROLES.OWNER, ROLES.SUPERVISOR],
      },
      {
        title: TITLES_NAVBAR.RESTAURANT_PROFILE,
        icon: RestaurantProfileIcon,
        href: ROUTES.RESTAURANT_PROFILE,
      },
      {
        title: TITLES_NAVBAR.TURN,
        icon: AccessTimeIcon,
        href: ROUTES.TURN,
      },

    ],
  },
];
