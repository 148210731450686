/* eslint-disable max-len */
import {
  doc, setDoc, getFirestore, deleteDoc, updateDoc,
} from 'firebase/firestore';
import { COLLECTIONS } from '../../constants';
import { MenuAddition } from './menuAddition.dto';

const db = getFirestore();

export const saveMenuAdditionRestaurant = async (
  menuAddition: MenuAddition,
  restaurantId: string,
  menuAdditionId: string,
): Promise<void> => {
  const menuAdditionsRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.MENU_ADDITIONS, menuAdditionId);
  await setDoc(menuAdditionsRef, menuAddition.toPlainObject());
};

export const deleteMenuAdditionRestaurant = async (restaurantId: string, menuAdditionId: string): Promise<void> => {
  const menuAdditionsRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.MENU_ADDITIONS, menuAdditionId);
  await deleteDoc(menuAdditionsRef);
};

export const editMenuAdditionRestaurant = async (
  restaurantId: string,
  menuAdditionId: string,
  menuAdditionUpdated: Partial<MenuAddition>,
): Promise<void> => {
  const menuAdditionsRef = doc(db, COLLECTIONS.RESTAURANT, restaurantId, COLLECTIONS.MENU_ADDITIONS, menuAdditionId);
  await updateDoc(menuAdditionsRef, { ...menuAdditionUpdated });
};
