/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import {
  Box, Button, Stack, Typography, styled,
} from '@mui/material';
import { COLORS } from '../../constants';
import { SimpleBackdrop } from '../../components/backdrop';
import { ConfirmModal } from '../../components';

const InputCustom = styled('input')(() => ({
  display: 'none',
}));

export type HeaderProps = {
  onCreateSection?: () => void,
  isSharedMenu: boolean,
  onExportToXlsx?: () => void,
  onSaveMenuImport?: (fileData:any) => void,
};

const Header = ({
  onCreateSection, isSharedMenu, onExportToXlsx, onSaveMenuImport,
}: HeaderProps) => {
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [fileData, setfileData] = useState(null);

  const exportMenu = async () => {
    try {
      if (onExportToXlsx) {
        setOpenBackdrop(true);
        await onExportToXlsx();
        setOpenBackdrop(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setOpenBackdrop(false);
    }
  };

  const saveMenuImport = async () => {
    try {
      if (onSaveMenuImport) {
        await onSaveMenuImport(fileData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <SimpleBackdrop show={openBackdrop} text="Exportando Menú" />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          height: '70px',
          borderBottom: `1px solid ${COLORS.SILVER_LIGHT}`,
        }}
      >
        <Stack direction="row">
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 300,
            }}
          >
            *Debes añadir mínimamente 1 producto por sección
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} mr={2}>
          <Box display="inline-flex">
            <InputCustom
              accept=".xls,.xlsx"
              id="menu_import_file"
              type="file"
              onChange={(event: any) => {
                setfileData(event.target.files[0]);
                setShowModalConfirm(true);
                // eslint-disable-next-line no-param-reassign
                event.target.value = '';
              }}
            />
            <label htmlFor="menu_import_file">
              <Button
                component="span"
                color="secondary"
                variant="contained"
                sx={{ fontSize: 12 }}
                disabled={!isSharedMenu}
              >
                IMPORTAR MENÚ DE XLSX
              </Button>
            </label>
          </Box>
          <Button color="secondary" variant="contained" sx={{ fontSize: 12 }} onClick={exportMenu} disabled={!isSharedMenu}>
            EXPORTAR MENÚ A XLSX
          </Button>
          <Button
            id="addSection"
            color="secondary"
            variant="contained"
            disabled={!isSharedMenu}
            sx={{ fontSize: 12 }}
            onClick={onCreateSection}
          >
            AÑADIR NUEVA SECCIÓN
          </Button>
        </Stack>
      </Stack>
      <ConfirmModal
        open={showModalConfirm}
        onClose={() => {
          setShowModalConfirm(!showModalConfirm);
          setfileData(null);
        }}
        onConfirm={(e) => {
          e?.preventDefault();
          saveMenuImport();
          setShowModalConfirm(!showModalConfirm);
        }}
        title="Esta seguro de importar el menu desde un archivo Excel?"
      />
    </>
  );
};

export default Header;
