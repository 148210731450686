import React from 'react';
import { Box, styled } from '@mui/material';
import { CarouselWithArrows, MenuButtonCircular } from '../../components';
import { MenuSection } from '../../core';

type MenuScreenProps = {
  menuSections?: MenuSection[];
  selectSection?: string;
  handleChange: (value: string) => void;
};

const BoxContainer = styled(Box)`
  margin-top: 8px;
  border-bottom: 2px solid #3D3834;
`;

export const MenuSections = ({
  menuSections, selectSection, handleChange,
}: MenuScreenProps) => (
  <BoxContainer>
    <CarouselWithArrows
      sideBarWidth={550}
      slideWidth={165}
    >
      <MenuButtonCircular
        key="menu-all"
        label="Menú"
        bgColor="#6F8BD8"
        isSelected={selectSection === 'menu'}
        onClick={() => {
          handleChange('menu');
        }}
      />
      {menuSections?.map((menuSection: MenuSection) => (
        <MenuButtonCircular
          key={menuSection.id}
          label={menuSection.name}
          bgColor="#6F8BD8"
          isSelected={selectSection === menuSection.id}
          onClick={() => {
            handleChange(menuSection.id);
          }}
        />
      ))}
    </CarouselWithArrows>
  </BoxContainer>
);
