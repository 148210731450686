import React from 'react';
import moment from 'moment-timezone';
import {
  Grid, Stack, Select, TextField, MenuItem, SelectChangeEvent,
} from '@mui/material';

type TimerangeType = {
  value: string,
  text: string
}

type DateType = {
  start: Date;
  compare: Date;
  end: Date;
}

type HeaderProps = {
  timeRange: string;
  timeRanges: TimerangeType[];
  handleTimeRange: (event:SelectChangeEvent) => void;
  dates: DateType;
};

const Header = ({
  timeRange, timeRanges, handleTimeRange, dates,
}: HeaderProps) => (
  <Stack direction="column" spacing={2} mt={2}>
    <Grid container direction="row">
      <Grid item xs={3}>
        <Select
          value={timeRange}
          variant="outlined"
          onChange={handleTimeRange}
          fullWidth
        >
          {timeRanges.map((t) => (
            <MenuItem value={t.text} key={t.value}>
              {t.text}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
    <Grid container direction="row" style={{ gap: '15px' }}>
      <Grid item xs={3}>
        <TextField
          name="datestart"
          variant="outlined"
          value={moment(dates.start).format('DD/MM/YYYY')}
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          name="dateend"
          variant="outlined"
          value={moment(dates.end).format('DD/MM/YYYY')}
          disabled
          fullWidth
        />
      </Grid>
    </Grid>
  </Stack>
);

export default Header;
