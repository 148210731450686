import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint } from '@mui/material';

 type DialogProps = {
  open: boolean;
  children?: React.ReactNode;
  onClose: () => void;
  size?: Breakpoint;
  fullWidth?: boolean | undefined;
  closeIcon?: boolean;
};

export const BaseModal = ({
  open,
  onClose,
  children,
  size = 'sm',
  fullWidth,
  closeIcon = true,
}: DialogProps) => (
  <Dialog
    onClose={onClose}
    open={open}
    fullWidth={fullWidth}
    BackdropProps={{
      style: {
        backgroundColor: '#1A1A1A',
        opacity: '0.7',
      },
    }}
    maxWidth={size}
  >
    <DialogTitle sx={{ m: 0, p: 2 }}>
      {closeIcon && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ color: '#0B0A09' }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
  </Dialog>
);
