import React from 'react';
import {
  CircularProgress, Container, Stack, styled, Typography,
} from '@mui/material';
import Header from './header';
import Results from './results/index';
import { COLORS } from '../../constants';
import {
  Journey, Profile, Metric, JourneyProduction, MenuSection,
} from '../../core';
import { Nullable } from '../../types';

const ContainerMain = styled(Container)`
  width: 100%;
  background-color: ${COLORS.GREY};
  min-height: 93.8vh;
`;

type SimpleProduct = {
  id: string;
  availability: boolean;
  product_id:string;
  product_name:string;
  metric: Metric;
  initial: number;
  income: number;
  discount: number;
  sales: number;
  stock: number;
  menu_section: MenuSection;
};

type ProductBySection = {
  menu_section_id: string;
  menu_section_enable: boolean;
  menu_section_name: string;
  initial: number;
  income: number;
  discount: number;
  sales: number;
  stock: number;
  products: SimpleProduct[];
};

type InventoryProps = {
  metrics:Nullable<Metric[]>,
  onSaveMetric: ((metricId:string, productId:string) => void),
  metricClickHandler: () => void,
  productsBySections?: ProductBySection[] | null;
  profiles: Profile[];
  journeys: Journey[];
  journeyProduction: Nullable<JourneyProduction>;
  onOpenJourney: (roleId: string, journeyId: string) => void;
  onCloseJourney: () => void;
  onExportToExcel: () => void;
  editable: boolean;
  isLoading:boolean;
};

export const InventoryScreen = ({
  metrics, onSaveMetric, metricClickHandler, productsBySections, profiles, journeys, onOpenJourney, onCloseJourney, journeyProduction, onExportToExcel, editable,
  isLoading,
}:InventoryProps) => (
  <ContainerMain maxWidth="lg">
    <Stack direction="column" spacing={4}>
      <Header profiles={profiles} journeys={journeys} onOpenJourney={onOpenJourney} onCloseJourney={onCloseJourney} journeyProduction={journeyProduction} onExportToExcel={onExportToExcel} />
      {isLoading && (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
      {productsBySections && productsBySections.length === 0 && (
        <Typography>No hay turnos a mostrar</Typography>
      )}
      {productsBySections && productsBySections.length > 0 && (
        <Results metrics={metrics} onSaveMetric={onSaveMetric} metricClickHandler={metricClickHandler} productsBySections={productsBySections} editable={editable} />
      )}
    </Stack>
  </ContainerMain>
);
