import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

const auth = getAuth();

type LoginProps = {
  email:string;
  password:string;
}

// eslint-disable-next-line max-len
export const loginWithEmail = async ({ email, password }: LoginProps) => signInWithEmailAndPassword(auth, email, password);

export const logout = async () => {
  await signOut(auth);
};
