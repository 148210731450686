/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  Typography,
  IconButton,
  TextField,
  styled,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Gallery,
  Photo,
  Product,
} from '../../core';
import { COLORS } from '../../constants';
import { UploadImgGalleryProps } from '../galleryScreen/types';
import { ModalGallery } from '../../components/gallery/productGallery';
import { OptionImageProduct } from './OptionImageProduct';
import { Image, PosSwitch } from '../../components';

const GridContainer = styled(Grid)(() => ({
  height: '80px',
  paddingLeft: '32px',
  paddingRight: '32px',
  flexWrap: 'nowrap',
}));

const CookingCardContent = styled(CardContent)(() => ({
  display: 'flex',
  padding: 0,
  '&:last-child': {
    paddingBottom: 0,
  },
}));

const CookingGrid = styled(Grid)(() => ({
  height: '80px',
  borderBottom: `1px solid ${COLORS.DISABLED_GRAY}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export type ProductFormValues = {
  name: string,
  description: string,
  unit_price: number,
  cooking_time: number,
  pre_packed: boolean,
  availability: boolean,
  photo: Photo,
  gallery_id: string,
  barcode: string,
};

type ProductFormProps = {
  currentProduct: Product,
  isSharedMenu: boolean,
  onDeleteProduct?: (productId: string) => void,
  onSubmitSave:(values: ProductFormValues, productId: string) =>void | Promise<void>
  onUploadImg: ({ file, docId }: UploadImgGalleryProps) => void | Promise<boolean>,
  onAddGallery: (gallery: Gallery) => Promise<Gallery | null>,
};

const ProductForm = ({
  currentProduct,
  isSharedMenu,
  onDeleteProduct = () => {},
  onSubmitSave = () => {},
  onAddGallery,
  onUploadImg,
}: ProductFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  // const restaurant = useAppSelector((state) => state.restaurant.restaurant);
  // const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  // const { value: journeyProduction } = useAppSelector((state) => state.inventory.journeyProduction);
  // const journeyProductionProducts = useAppSelector((state) => state.inventory.journeyProductionProducts);
  const [loading, setLoading] = useState(false);
  const [hoverOptions, setHoverOptions] = useState(false);
  const [showModalGallery, setShowModalGallery] = useState(false);
  const initialPhoto = new Photo({});
  // const metrics = useMetrics();
  const initialValuesProduct: ProductFormValues = {
    name: currentProduct.name,
    description: currentProduct.description,
    unit_price: currentProduct?.unit_price ? currentProduct.unit_price : 0,
    cooking_time: currentProduct.cooking_time,
    pre_packed: currentProduct.pre_packed,
    availability: currentProduct.availability,
    photo: currentProduct.photo,
    gallery_id: currentProduct.gallery_id || '',
    barcode: currentProduct.barcode,
  };

  const handleDownloadDocument = (url: string, name: string) => {
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const imageData = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = imageData;
      link.setAttribute('download', `${name}.png`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleShowModal = (value: boolean) => {
    setShowModalGallery(value);
  };

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={{ ...initialValuesProduct }}
        validationSchema={Yup.object().shape({
          availability: Yup.boolean(),
          name: Yup.string().when('availability', {
            is: true,
            then: Yup.string().required('El nombre es requerido'),
            otherwise: Yup.string(),
          }),
          unit_price: Yup.number()
            .positive('Debe ser un numero mayor a cero')
            .nullable(true)
            .required('El precio es requerido')
            .typeError('El precio es requerido')
            .transform((_, val) => (val === Number(val) ? val : 0)),
        })}
        onSubmit={async (values, {
          resetForm, setStatus, setSubmitting,
        }) => {
          try {
            await onSubmitSave(values, currentProduct.id);
            // if (journeyProduction) {
            //   const isNewProduct = journeyProductionProducts?.find((prod: JourneyProductionProduct) => prod.product.id === currentProduct.id);
            //   if (!isNewProduct) {
            //     const defaultMetric = metrics?.find((metric) => metric.is_default);
            //     const product = new ProductSummary({
            //       id: currentProduct.id,
            //       name: values?.name || '',
            //       availability: values?.availability || false,
            //       menu_section: menuSection,
            //       menu_section_id: menuSection.id,
            //     });
            //     const journeyProductionProduct = new JourneyProductionProduct({
            //       product,
            //       metric: new Metric(defaultMetric || {}),
            //     });
            //     addProductsProduction(
            //       restaurant?.id || '',
            //       subsidiary?.id || '',
            //       journeyProduction?.id || '',
            //       journeyProductionProduct,
            //     );
            //   } else {
            //     const product = new ProductSummary({
            //       ...isNewProduct.product,
            //       name: values?.name || '',
            //       availability: values?.availability || false,
            //     });
            //     updateProductsProductionId(restaurant?.id || '', subsidiary?.id || '', journeyProduction?.id || '', isNewProduct?.id || '', {
            //       product: { ...product.toPlainObject() },
            //     });
            //   }
            // }
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Datos guardados correctamente.', { variant: 'success' });
          } catch (error:any) {
            setStatus({ success: false });
            setSubmitting(false);
            if (error.status === 409) {
              enqueueSnackbar('Algo salió mal.', { variant: 'error' });
            } else {
              enqueueSnackbar('Algo salió mal. Contáctese con el administrador', {
                variant: 'error',
              });
            }
          } finally {
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          dirty,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off" noValidate>
            <Card>
              <GridContainer
                container
                item
                justifyContent="space-between"
              >
                <Grid item container alignContent="center">
                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    Información Básica del Producto
                  </Typography>
                </Grid>
                <Grid
                  md={3}
                  xs={3}
                  item
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                  wrap="nowrap"
                >
                  <Typography variant="body2">
                    {values.availability ? 'Disponible' : 'No Disponible'}
                  </Typography>
                  <PosSwitch
                    disabled={!values.unit_price || !values.name}
                    value={values.availability}
                    onChange={(e:any) => {
                      setFieldValue('availability', e.target.checked);
                    }}
                  />
                </Grid>
              </GridContainer>
              <Divider sx={{ borderColor: COLORS.GRAY_LIGHT }} />
              <CardContent>
                <Grid item container spacing={1}>
                  <Grid
                    item
                    container
                    xs={8}
                    md={8}
                    flexDirection="column"
                  >
                    <Box mb={3} display="flex" justifyContent="space-between">
                      <Grid xs={5.9}>
                        <TextField
                          id="name"
                          required={values.availability}
                          disabled={!isSharedMenu}
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label="Nombre de producto"
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          variant="outlined"
                          color="secondary"
                        />
                      </Grid>
                      <Grid xs={5.9}>
                        <TextField
                          id="barcode"
                          disabled={!isSharedMenu}
                          error={Boolean(touched.barcode && errors.barcode)}
                          fullWidth
                          helperText={touched.barcode && errors.barcode}
                          label="Codigo de barras"
                          name="barcode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.barcode}
                          variant="outlined"
                          color="secondary"
                        />
                      </Grid>
                    </Box>
                    <Box mb={3}>
                      <TextField
                        id="description"
                        disabled={!isSharedMenu}
                        error={Boolean(
                          touched.description && errors.description,
                        )}
                        fullWidth
                        multiline
                        helperText={touched.description && errors.description}
                        label="Descripción"
                        name="description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        variant="outlined"
                        color="secondary"
                        inputProps={{
                          style: {
                            height: '165px',
                          },
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={4} md={4}>
                    <Box mb={3}>
                      <TextField
                        id="price"
                        required={values.availability}
                        error={Boolean(
                          touched.unit_price && errors.unit_price,
                        )}
                        fullWidth
                        helperText={touched.unit_price && errors.unit_price}
                        label="Precio unitario"
                        name="unit_price"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.unit_price}
                        variant="outlined"
                        color="secondary"
                        type="number"
                        inputProps={{
                          min: 0.0,
                          step: 0.01,
                        }}
                      />
                    </Box>
                    <Image
                      onMouseEnter={() => setHoverOptions(true)}
                      onMouseLeave={() => setHoverOptions(false)}
                      src={values.photo.original}
                      style={{ width: '100%', height: 200, objectFit: 'cover' }}
                      onError={() => {
                        setFieldValue('photo', { original: '' });
                      }}
                    >
                      {hoverOptions && (
                        <OptionImageProduct
                          isSharedMenu={isSharedMenu}
                          img={values.photo.original}
                          handleDownloadDocument={
                            () => handleDownloadDocument(values.photo.original, values.name)
                          }
                          handleSelectImage={() => handleShowModal(true)}
                          handleDeleteImage={() => setFieldValue('photo', { ...initialPhoto })}
                        />
                      )}
                    </Image>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card style={{ marginTop: '24px' }}>
              <CookingCardContent>
                <Grid
                  item
                  container
                  xs={6}
                  md={6}
                  style={{
                    borderRight: `1px solid ${COLORS.DISABLED_GRAY}`,
                  }}
                >
                  <CookingGrid item container>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: COLORS.BLACK }}>
                      Tiempo de preparación
                    </Typography>
                  </CookingGrid>
                  <CookingGrid item container>
                    {!values.pre_packed && (
                      <>
                        <IconButton
                          id="decrease"
                          style={{ color: COLORS.RUBY_RED }}
                          onClick={() => {
                            const cookingTime = values.cooking_time - 1 >= 0
                              ? values.cooking_time - 1
                              : 0;
                            setFieldValue('cooking_time', cookingTime);
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <Typography
                          variant="h3"
                          sx={{ color: COLORS.ROYAL_BLUE_DARK, fontWeight: 'bold' }}
                        >
                          {`${values.cooking_time} Min`}
                        </Typography>
                        <IconButton
                          id="increase"
                          style={{ color: COLORS.RUBY_RED }}
                          onClick={() => {
                            setFieldValue(
                              'cooking_time',
                              values.cooking_time + 1,
                            );
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </>
                    )}
                    {values.pre_packed && (
                      <Typography
                        variant="h3"
                        style={{ color: COLORS.ROYAL_BLUE_DARK, fontWeight: 'bold' }}
                      >
                        {`${values.cooking_time} Min`}
                      </Typography>
                    )}
                  </CookingGrid>
                </Grid>

                <Grid item container xs={6} md={6}>
                  <CookingGrid
                    item
                    container
                    direction="column"
                  >
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: COLORS.BLACK }}>
                      Ó si tu producto es preempacado:
                    </Typography>
                    <Typography variant="body2">
                      Ej Cerveza, gaseosa, etc.
                    </Typography>
                  </CookingGrid>
                  <CookingGrid item container>
                    <Box alignItems="center" display="flex">
                      <Checkbox
                        disabled={currentProduct.template && !isSharedMenu}
                        checked={values.pre_packed}
                        name="pre_packed"
                        onChange={(event) => {
                          handleChange(event);
                          if (event.target.checked) {
                            setFieldValue('cooking_time', 0);
                          } else {
                            setFieldValue('cooking_time', 10);
                          }
                        }}
                        style={{ color: COLORS.ROYAL_BLUE_DARK }}
                      />
                      <Typography variant="body2">
                        Ó si tu producto es preempacado:
                      </Typography>
                    </Box>
                  </CookingGrid>
                </Grid>
              </CookingCardContent>
            </Card>

            <Grid item container spacing={1} justifyContent="flex-end">
              <Box mt={2}>
                <Button
                  id="deleteProduct"
                  sx={{
                    marginRight: 2,
                    backgroundColor: COLORS.RED,
                    color: COLORS.WHITE,
                  }}
                  size="large"
                  type="button"
                  variant="contained"
                  disabled={!isSharedMenu}
                  onClick={(e) => {
                    e.preventDefault();
                    onDeleteProduct(currentProduct.id);
                  }}
                >
                  Eliminar Producto
                </Button>
              </Box>
              <Box mt={2}>
                <Button
                  id="saveProduct"
                  disabled={!dirty || loading}
                  color="secondary"
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Guardar cambios
                </Button>
              </Box>
            </Grid>
            <ModalGallery
              open={showModalGallery}
              onClose={() => setShowModalGallery(false)}
              onUploadImg={onUploadImg}
              onAddGallery={onAddGallery}
              onSelect={(photo, docId) => {
                setFieldValue('photo', { ...photo });
                setFieldValue('gallery_id', docId);
              }}
            />
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default React.memo(
  ProductForm,
  // eslint-disable-next-line max-len
  (prevProps, nextProps) => JSON.stringify(prevProps.currentProduct) === JSON.stringify(nextProps.currentProduct),
);
