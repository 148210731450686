import React from 'react';
import IconButton from '@mui/material/IconButton';
import { COLOR, SIZE } from './enum';

export interface OnlyButtonIconProps {
  color?:COLOR,
  disabled?:boolean,
  className?:string,
  size?:SIZE,
  onClick: (e: any) => void,
  children?: React.ReactNode
}

export const OnlyIconButton = ({
  color,
  disabled,
  className,
  size,
  onClick,
  children,
}: OnlyButtonIconProps) => (
  <IconButton
    color={color}
    disabled={disabled}
    className={className}
    size={size}
    onClick={onClick}
  >
    {children}
  </IconButton>
);
