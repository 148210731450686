import React, { useLayoutEffect, useRef } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { COLORS } from '../../../constants';
import { SearchInput } from '../../input';
import { getOrderType } from '../../../core';
import {
  useAppSelector,
} from '../../../appHooks/redux';

const ContainerTitles = styled(Box)(({ theme }) => ({
  backgroundColor: COLORS.GREY,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBlock: '8px',
  paddingInline: '13px',
  gap: '15px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}));

const OrderCodeContainer = styled(Box)`
  max-width: 150px;
  width: 100%;
`;

const TextContainer = styled(Box)`
  max-width: 280px;
  width: 100%;
`;

const HeaderContainer = styled(Box)`
  width: 100%,
  display: flex;
  flex-direction: column;
`;

const ContainerInputs = styled(Box)(({ theme }) => ({
  backgroundColor: COLORS.LIGHT_GREY,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBlock: '8px',
  paddingInline: '13px',
  gap: '15px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}));

const ContainerInputName = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    margin: '0px',
    padding: '5px 0px',
  },
}));

export type HeaderProps = {
  onChangeClientName:(event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>void;
  onChangePhoneNumber:(event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>void;
  onHeaderHeight: (site: string, value: number) => void;
  clientName:string|undefined;
  phoneNumber:string|undefined;
}

export const Header = ({
  onChangeClientName, onHeaderHeight, onChangePhoneNumber, phoneNumber, clientName,
}:HeaderProps) => {
  const headerRef = useRef();
  const currentOrder = useAppSelector((store) => store.shoppingCart.currentOrder);

  useLayoutEffect(() => {
    if (headerRef.current) {
      // @ts-ignore
      onHeaderHeight('header', headerRef.current.offsetHeight);
    }
  }, [onHeaderHeight]);

  return (
    <HeaderContainer ref={headerRef}>
      <ContainerTitles>
        <OrderCodeContainer>
          <Typography fontSize="18px">
            <strong># orden: </strong>
            {currentOrder && currentOrder?.order_code}
          </Typography>
        </OrderCodeContainer>
        <TextContainer>
          <Typography fontSize="18px">
            <strong>Tipo de orden: </strong>
            {currentOrder && currentOrder?.order_type && getOrderType(currentOrder.order_type)}
          </Typography>
        </TextContainer>
      </ContainerTitles>
      <ContainerInputs>
        <ContainerInputName width="193px">
          <TextField
            id="clientNameInput"
            onChange={onChangeClientName}
            placeholder="Nombre del cliente"
            value={clientName}
            inputProps={{
              style: {
                height: '30px',
                backgroundColor: COLORS.WHITE,
                padding: '0px 10px',
              },
            }}
          />
        </ContainerInputName>
        <ContainerInputName width="193px">
          <SearchInput
            placeHolder="Teléfono"
            icon={<WhatsAppIcon style={{ zIndex: 1, color: COLORS.GREY }} />}
            onChange={onChangePhoneNumber}
            value={phoneNumber}
            inputProps={{
              id: 'clientPhoneInput',
              style: {
                height: '28px',
                padding: '0px 8px 0px 0px',
                backgroundColor: COLORS.WHITE,
                paddingLeft: 'calc(1em + 24px)',
                width: '100%',
                border: '1px solid',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                borderRadius: '3px',
              },
            }}
            inputShadow="none"
          />
        </ContainerInputName>
      </ContainerInputs>
    </HeaderContainer>
  );
};
