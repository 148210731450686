import React from 'react';
import {
  IconButton,
  Paper,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { TableRowCollapse } from './tableRowCollapse';
import { Metric, MenuSection } from '../../../core';
import { Nullable } from '../../../types';

const TABLE_HEADERS = [
  { title: 'Producto', width: '400px' },
  { title: 'Metrica', width: '111px' },
  { title: 'Actual', width: '111px' },
  { title: 'Ingreso', width: '111px' },
  { title: 'Venta', width: '111px' },
  { title: 'Descuento', width: '111px' },
  { title: 'Stock', width: '111px' },
];

type TableHeader = {
  title: string;
  width: string;
};

type SimpleProduct = {
  id: string;
  availability: boolean;
  product_id:string;
  product_name:string;
  metric: Metric;
  initial: number;
  income: number;
  discount: number;
  sales: number;
  stock: number;
  menu_section: MenuSection;
};

type ProductBySection = {
  menu_section_id: string;
  menu_section_enable: boolean;
  menu_section_name: string;
  initial: number;
  income: number;
  discount: number;
  sales: number;
  stock: number;
  products: SimpleProduct[];
};

type ResultProps = {
  metrics:Nullable<Metric[]>,
  onSaveMetric: ((metricId:string, productId:string) => void),
  metricClickHandler: () => void;
  productsBySections: ProductBySection[];
  editable: boolean;
};

// const rows:string[] = ['test1', 'test2'];

const Results = ({
  metrics, onSaveMetric, metricClickHandler, productsBySections, editable,
}:ResultProps) => (
  <TableContainer component={Paper}>
    <Table aria-label="inventory-collapsable-table">
      <TableHead>
        <TableRow>
          {TABLE_HEADERS.map((header:TableHeader, index:number) => (
            <TableCell width={header.width}>
              <Stack width={header.width} direction="row" spacing={0.5} alignItems="center" justifyContent={index === 0 ? 'flex-start' : 'center'}>
                <Typography>
                  <strong>{header.title}</strong>
                </Typography>
                {index === 1 && (
                  <IconButton onClick={metricClickHandler}>
                    <MoreVertIcon />
                  </IconButton>
                )}
              </Stack>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {productsBySections.map((productBySection:ProductBySection) => (
          <TableRowCollapse metrics={metrics} onSaveMetric={onSaveMetric} productBySection={productBySection} key={productBySection.menu_section_id} editable={editable} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default Results;
