import React, { useState } from 'react';
import {
  Grid, Button, Box, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { COLORS, ORDER_TYPE } from '../../constants';
import { useAppSelector } from '../../appHooks/redux';

const CustomButton = styled(Button)(() => ({
  backgroundColor: COLORS.WHITE,
  borderColor: COLORS.LIGHT_BLUE,
  color: COLORS.BLACK,
  textTransform: 'none',
  minHeight: '65px',
  fontWeight: 'bold',
  fontSize: '18px',
  '&:hover': {
    backgroundColor: COLORS.LIGHT_BLUE,
    borderColor: COLORS.CORNFLOWER_BLUE,
    color: COLORS.WHITE,
  },
}));
const buttonSelectedStyle = {
  backgroundColor: COLORS.LIGHT_BLUE,
  borderColor: COLORS.CORNFLOWER_BLUE,
  color: COLORS.WHITE,
};
type OptionsProps = {
  handleSelect: (state: string) => void;
};
export const OptionsButton = ({ handleSelect }: OptionsProps) => {
  const [page, setPage] = useState('main');
  const currentOrder = useAppSelector((state) => state.shoppingCart.currentOrder);
  return (
    <Box sx={{ padding: 4 }}>
      <Box textAlign="right" sx={{ padding: 3 }}>
        <Button onClick={() => setPage('main')}>
          <KeyboardBackspaceIcon fontSize="large" color="action" />
        </Button>
      </Box>

      <Typography
        fontWeight="bold"
        variant="h3"
        textAlign={{ xs: 'justify', sm: 'center' }}
      >
        {page === 'main'
          ? 'Escoja que tipo de servicio requiere'
          : 'Escoja que tipo de delivery requiere'}
      </Typography>

      {page === 'main' && (
        <Box sx={{ textAlign: 'center', paddingTop: 6 }}>
          <Grid container spacing={12} justifyContent="center">
            <Grid
              item
              xs={12}
              container
              spacing={3}
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={4} md={3}>
                <CustomButton
                  id="deliveryBtn"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  style={
                    currentOrder?.order_type?.includes('DELIVERY') ? buttonSelectedStyle : {}
                  }
                  onClick={() => handleSelect('delivery')}
                >
                  Delivery
                </CustomButton>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <CustomButton
                  fullWidth
                  variant="outlined"
                  style={
                    currentOrder?.order_type === ORDER_TYPE.PICK_UP ? buttonSelectedStyle : {}
                  }
                  onClick={() => {
                    handleSelect(ORDER_TYPE.PICK_UP);
                  }}
                >
                  Pick up
                </CustomButton>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              <CustomButton
                fullWidth
                variant="outlined"
                style={
                  currentOrder?.order_type === ORDER_TYPE.LOCAL ? buttonSelectedStyle : {}
                }
                onClick={() => {
                  handleSelect(ORDER_TYPE.LOCAL);
                }}
              >
                Para el local
              </CustomButton>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
