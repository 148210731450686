import React from 'react';
import { IconButton, Typography, Box } from '@mui/material';

interface DayProps {
  filled?: boolean;
  outlined?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  startOfRange?: boolean;
  endOfRange?: boolean;
  onClick?: () => void;
  onHover?: () => void;
  value: number | string;
}

const Day: React.FunctionComponent<DayProps> = ({
  startOfRange,
  endOfRange,
  disabled,
  highlighted,
  outlined,
  filled,
  onClick,
  onHover,
  value,
}: DayProps) => (
  <Box
    sx={{
      display: 'flex',
      // eslint-disable-next-line no-nested-ternary
      borderRadius: startOfRange
        ? '50% 0 0 50%'
        : endOfRange
          ? '0 50% 50% 0'
          : undefined,
      backgroundColor: () => (!disabled && highlighted ? '#DFE5F6' : undefined),
    }}
  >
    <IconButton
      sx={{
        height: '36px',
        width: '36px',
        padding: 0,
        border: () => (!disabled && outlined
          ? '1px solid #6F8BD8'
          : undefined),
        ...(!disabled && filled
          ? {
            '&:hover': {
              backgroundColor: () => '#6F8BD8',
            },
            backgroundColor: () => '#6F8BD8',
          }
          : {}),
      }}
      disabled={disabled}
      onClick={onClick}
      onMouseOver={onHover}
    >
      <Typography
        sx={{
          lineHeight: 1.6,
          // eslint-disable-next-line no-nested-ternary
          color: (theme) => (!disabled
            ? filled
              ? theme.palette.primary.contrastText
              : theme.palette.text.primary
            : '#DBDCE0'),
          // eslint-disable-next-line no-nested-ternary
          fontWeight: () => (!disabled
            ? filled
              ? 'bold'
              : 'bold'
            : ''),
        }}
        variant="body2"
      >
        {value}
      </Typography>
    </IconButton>
  </Box>
);

export default Day;
