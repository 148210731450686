import {
  FieldValue,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import { FirebaseBase } from '../firebaseBase';
import { Profile } from '../profile';
import {
  Constructor,
  PlainObject,
  Nullable,
} from '../../types';
import {
  ORDER_TYPE,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  STATUS,
} from './order.enum';
import { City } from '../city';

export class Order extends FirebaseBase {
  additional_description: string;

  canceled_by: Nullable<PlainObject<Profile>>;

  canceled_reason: string;

  discount: number;

  discount_reason: string;

  client_name: string;

  client_phone_number:string;

  invoice_name: string;

  invoice_nit: string;

  order_code: string;

  order_paid: boolean;

  order_type: ORDER_TYPE | null;

  payment_method: PAYMENT_METHOD;

  payment_status:PAYMENT_STATUS;

  status: STATUS;

  count_of_products: number;

  total: number;

  sub_total: number;

  balance: number;

  verified: boolean;

  xpress_motos: boolean;

  sent_to_kitchen_date:Timestamp | FieldValue;

  amount_paid:number;

  restaurant_id: string;

  subsidiary_id: string;

  city: PlainObject<City>;

  restaurant_name: string;

  subsidiary_name: string;

  constructor({
    discount_reason = '',
    additional_description = '',
    canceled_by = null,
    canceled_reason = '',
    discount = 0,
    client_name = '',
    client_phone_number = '',
    invoice_name = '',
    invoice_nit = '',
    order_code = '',
    order_paid = false,
    order_type = null,
    payment_method = PAYMENT_METHOD.CASH,
    payment_status = PAYMENT_STATUS.PENDING,
    sent_to_kitchen_date = serverTimestamp(),
    status = STATUS.IN_PROCESS,
    count_of_products = 0,
    total = 0,
    sub_total = 0,
    balance = 0,
    verified = false,
    xpress_motos = false,
    amount_paid = 0,
    restaurant_id = '',
    subsidiary_id = '',
    city = new City({}),
    restaurant_name = '',
    subsidiary_name = '',
    ...data
  }:Constructor<Order>) {
    super(data);
    this.additional_description = additional_description;
    this.canceled_by = canceled_by ? new Profile(canceled_by).toPlainObject() : null;
    this.canceled_reason = canceled_reason;
    this.discount = discount;
    this.client_name = client_name;
    this.invoice_name = invoice_name;
    this.invoice_nit = invoice_nit;
    this.order_code = order_code;
    this.order_paid = order_paid;
    this.order_type = order_type;
    this.payment_method = payment_method;
    this.status = status;
    this.count_of_products = count_of_products;
    this.total = total;
    this.sub_total = sub_total;
    this.balance = balance;
    this.verified = verified;
    this.xpress_motos = xpress_motos;
    this.discount_reason = discount_reason;
    this.payment_status = payment_status;
    this.sent_to_kitchen_date = sent_to_kitchen_date;
    this.client_phone_number = client_phone_number;
    this.amount_paid = amount_paid;
    this.restaurant_id = restaurant_id;
    this.subsidiary_id = subsidiary_id;
    this.city = new City(city).toPlainObject();
    this.restaurant_name = restaurant_name;
    this.subsidiary_name = subsidiary_name;
  }

  toPlainObject(): PlainObject<Order> {
    return { ...this };
  }
}
