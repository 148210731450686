import { FirebaseBase } from '../firebaseBase';
import { Constructor, PlainObject } from '../../types';

export class Metric extends FirebaseBase {
  name: string;

  enable:boolean;

  is_default: boolean;

  constructor({
    name = '',
    enable = true,
    is_default = false,
    ...data
  }:Constructor<Metric>) {
    super(data);
    this.name = name;
    this.enable = enable;
    this.is_default = is_default;
  }

  toPlainObject(): PlainObject<Metric> {
    return { ...this };
  }
}

export default Metric;
