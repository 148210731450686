import React from 'react';
import {
  InputBase,
  alpha,
  Box,
  InputBaseComponentProps,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';

interface SearchInputProps {
  className?: string,
  placeHolder: string,
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  icon?: React.ReactNode,
  inputProps?: InputBaseComponentProps | undefined,
  inputShadow?: string,
  value?: string,
}

const Search = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: 0,
  marginLeft: 0,
  width: 'auto',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
  boxShadow:
    `-6px 6px 8px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%)`,
}));

const SearchIconWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export const SearchInput = ({
  placeHolder,
  className,
  onChange,
  icon = <SearchIcon />,
  inputProps,
  inputShadow,
  value,
}: SearchInputProps) => (
  <Search style={{ boxShadow: inputShadow }}>
    <SearchIconWrapper>
      {icon}
    </SearchIconWrapper>
    <StyledInputBase
      inputProps={inputProps}
      className={className}
      onChange={onChange}
      placeholder={placeHolder}
      value={value}
    />
  </Search>
);
