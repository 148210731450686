import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { CheckCircleOutlinedIcon, Ghost } from '../../assets';
import {
  HeadTitle,
  AlertModal,
} from '../../components';
import {
  Order,
  ORDER_TYPE,
  STATUS,
  useValidateEnableInventory,
  setOrder,
} from '../../core';
import { useAppDispatch, useAppSelector } from '../../appHooks/redux';
import {
  cleanAllOrder, cleanTransactionFailedProducts, setTransactionFailedProducts,
} from '../../redux/reducers/shoppingCart';
import { ROUTES } from '../../constants';
import {
  OrderProduct,
  parseOrderProduct,
  addOrderProducts,
  updateOrderProducts,
  orderTransactions,
} from '../../core/orderProduct';
import { CashPaymentScreen } from '../../screens';
import {
  TicketGenerator, Order
  as OrderTicket,
} from '../../components/pdf';

const ContainerMain = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
`;

const CashPayment = () => {
  const {
    currentOrder,
    currentProducts,
    currentAdditions,
    orderProduct,
  } = useAppSelector((state) => state.shoppingCart);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  const restaurant = useAppSelector((state) => state.restaurant.restaurant);
  const { beforeProduct, afterProduct } = useAppSelector((state) => state.inventory);
  const journeyProductionProductsSet = useAppSelector((state) => state.inventory.journeyProductionProductsSet);
  const { value: lastOpenjourneyProduction } = useAppSelector((state) => state.inventory.journeyProduction);
  const enableInventory = useValidateEnableInventory();
  const [modal, setModal] = useState(false);
  const { value: journeyProduction } = useAppSelector((state) => state.inventory.journeyProduction);
  const products = useAppSelector((state) => state.inventory.journeyProductionProducts);
  const [loading, setLoading] = useState<boolean>(false);
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const onPrintClick = async () => {
    if (currentProducts.length && restaurant?.enable_comanda) {
      const ticketPdf = await pdf(
        TicketGenerator({
          ticket: {
            clientName: currentOrder?.client_name || '',
            orderNumber: currentOrder?.order_code || '',
            orderType: currentOrder?.order_type || ORDER_TYPE.LOCAL,
            createAt: new Date(),
            orders: currentProducts.map((product) => {
              const additions = currentAdditions.filter(
                (addition) => addition.product_id === product.id,
              );
              const order:OrderTicket = {
                amount: product.quantity,
                productName: product.name,
                additions: additions.map((add) => ({ amount: add.quantity, name: add.name })),
              };
              return order;
            }),
          },
        }),
      ).toBlob();

      window.open(
        URL.createObjectURL(ticketPdf),
        '_blank',
        'width=600,height=700',
      );
    }
  };

  const onBack = () => {
    router(-1);
  };

  const handleOrderPayment = async (newOrderProducts: OrderProduct[], restaurantId: string, subsidiaryId: string, orderId: string) => {
    if (orderProduct.length > 0) {
      await updateOrderProducts({
        newOrderProducts,
        orderProduct,
        restaurantId,
        subsidiaryId,
        orderId,
        journeyProductionId: journeyProduction?.id,
        products,
      });
    } else {
      addOrderProducts({
        newOrderProducts,
        restaurantId,
        subsidiaryId,
        orderId,
      });
    }
    dispatch(cleanTransactionFailedProducts());
    await onPrintClick();
    enqueueSnackbar('Orden Actualizada', {
      variant: 'success',
    });
    setPaymentSuccess(true);
  };

  const onConfirm = async (paid_value:string) => {
    if (!restaurant?.id || !subsidiary?.id || !currentOrder) return;
    if (Number(paid_value) < currentOrder.total) {
      enqueueSnackbar('El monto a pagar debe ser mayor o igual al total', {
        variant: 'error',
      });
      return;
    }
    setLoading(true);
    try {
      const balance = Number(paid_value) - (currentOrder?.total || 0);
      const order = new Order({
        ...currentOrder,
        count_of_products: currentProducts?.length || 0,
        amount_paid: Number(paid_value),
        balance,
        status: STATUS.COMPLETED,
      });
      const orderId = await setOrder({
        restaurantId: restaurant?.id,
        order,
        subsidiaryId: subsidiary?.id,
      });
      const newOrderProducts = parseOrderProduct({
        products: currentProducts,
        additions: currentAdditions,
        orderId,
      });
      if (!enableInventory && restaurant.enable_inventory && journeyProductionProductsSet && lastOpenjourneyProduction) {
        const result = await orderTransactions(restaurant.id, subsidiary.id, beforeProduct, afterProduct, lastOpenjourneyProduction.id, journeyProductionProductsSet);
        setModal(!result.isSuccess);
        if (result.isSuccess) {
          await handleOrderPayment(newOrderProducts, restaurant?.id, subsidiary?.id, orderId);
        } else if (result?.failedProduct) {
          dispatch(setTransactionFailedProducts(result?.failedProduct));
        }
      } else {
        await handleOrderPayment(newOrderProducts, restaurant?.id, subsidiary?.id, orderId);
      }
    } finally {
      setLoading(true);
    }
  };
  const onCloseAlert = () => {
    dispatch(cleanAllOrder());
    router(ROUTES.COMMAND_PANEL);
  };
  return (
    <>
      <HeadTitle title="Pago en efectivo" />
      <ContainerMain>
        <CashPaymentScreen loading={loading} onBack={onBack} onConfirm={onConfirm} onCancel={onBack} />
        <AlertModal
          icon={CheckCircleOutlinedIcon}
          open={paymentSuccess}
          title="Se realizó el pago correctamente"
          okText="Aceptar"
          onClose={onCloseAlert}
        />
        <AlertModal
          icon={Ghost}
          open={modal}
          title="Ups! El stock disminuyo y no se puede agregar los productos requeridos"
          okText="Aceptar"
          onClose={() => setModal(false)}
        />
      </ContainerMain>
    </>
  );
};

export default CashPayment;
