import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { COLLECTIONS } from '../../constants';
import { Nullable } from '../../types';
import { JourneyProductionProduct } from './journeyProductionProduct.dto';

export const useAllJourneyProductionProductSubsidiary = (
  restaurantId: string = '',
  subsidiaryId: string = '',
  journeyProdId: string = '',
): Nullable<JourneyProductionProduct[]> => {
  const query = useMemo(() => {
    if (restaurantId && subsidiaryId && journeyProdId) {
      return [
        {
          collection: COLLECTIONS.RESTAURANT,
          doc: restaurantId,
          subcollections: [{
            collection: COLLECTIONS.SUBSIDIARY,
            doc: subsidiaryId,
          },
          {
            collection: COLLECTIONS.JOURNEY_PRODUCTION,
            doc: journeyProdId,
          },
          {
            collection: COLLECTIONS.JOURNEY_PRODUCTION_PRODUCTS,
          }],
          storeAs: 'all_journey_production_product',
        },
      ];
    }
    return [];
  }, [restaurantId,
    subsidiaryId,
    journeyProdId]);

  useFirestoreConnect(query);
  const allJourneyProductionProductSubsidiary = useSelector((state:any) => state.firestore.ordered.all_journey_production_product);

  if (allJourneyProductionProductSubsidiary) {
    return allJourneyProductionProductSubsidiary.map((journeyProduct:JourneyProductionProduct) => new JourneyProductionProduct({ ...journeyProduct }));
  }

  return null;
};
