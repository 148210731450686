import React, { useState } from 'react';
import { DateRange } from 'mui-daterange-picker';
import { MetricModal } from '../../components/modal/metricModal';
import { useMetrics } from '../../core/metric/metric.hook';
import { HeadTitle } from '../../components';
import { InventoryHistoryScreen } from '../../screens/inventoryHistoryScreen';
import {
  exportToXlsxInventoryHistoryReport, getHistory, InventoryBySection, JourneyProduction,
} from '../../core';
import { useAppSelector } from '../../appHooks/redux';

const InventoryHistory = () => {
  const [openModal, setOpenModal] = useState(false);
  const metrics = useMetrics();
  const [productsBySections, setProductsBySection] = useState<InventoryBySection[]|undefined>([]);
  const [journeyProductions, setJourneyProductions] = useState<JourneyProduction[]>();

  const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);

  const openMetricModal = () => {
    setOpenModal(true);
  };

  const getInventoryHistories = async (restaurantId:string, subsidiaryId:string, range:DateRange) => {
    setProductsBySection(undefined);
    const response = await getHistory(restaurantId, subsidiaryId, range);
    setProductsBySection(response.productsBySections);
    setJourneyProductions(response.journeys);
  };

  const onReset = () => {
    setProductsBySection([]);
  };

  const exportExcelInventoryHistory = async (dateRange:DateRange) => {
    if (subsidiary && productsBySections && journeyProductions) {
      await exportToXlsxInventoryHistoryReport(subsidiary.name, dateRange, productsBySections, journeyProductions);
    }
  };

  return (
    <>
      <HeadTitle title="Historial Inventario" />
      <InventoryHistoryScreen
        metricClickHandler={openMetricModal}
        productsBySections={productsBySections}
        onReset={onReset}
        onFilter={getInventoryHistories}
        onExportExcelInventoryHistory={exportExcelInventoryHistory}
      />
      <MetricModal
        open={openModal}
        metrics={metrics || []}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default InventoryHistory;
