import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Divider,
  IconButton,
  AccordionDetails,
  Accordion,
  styled,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ConfirmModal, CrossIcon, EditIcon } from '../../../components';
import { COLORS } from '../../../constants';
import { Addition, AdditionOption } from '../../../core';
import ModalCreateAddition from './additionEditView/ModalCreateAddition';
import DragDropAdditionsItem from './DragDropAdditionItem';
import { AdditionFormValues, AdditionOptionFormValues } from './additionEditView/AdditionEditForm';

const GridContainer = styled(Grid)(() => ({
  marginBottom: '0',
  borderBottom: `1px solid ${COLORS.GRAY_LIGHT_3}`,
  '&:last-child': {
    marginBottom: 0,
    borderBottom: 'none',
  },
}));

const GridItem = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1),
  borderRight: `1px solid ${COLORS.GRAY_LIGHT_3}`,
  '&:last-child': {
    borderRight: 'none',
  },
}));

const BoxField = styled(Box)(() => ({
  minWidth: '60px',
  display: 'inline-block',
  textAlign: 'left',
}));

const BoxIcon = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  marginLeft: 'auto',
  marginRight: theme.spacing(1),
}));

const TypographyCustom = styled(Box)(({ theme }) => ({
  backgroundColor: COLORS.BLUE_LIGHT_52,
  color: COLORS.WHITE,
  fontFamily: 'Nunito Sans',
  padding: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.spacing(1),
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 10,
  display: 'inline-block',
}));

type AdditionPickerItemProps = {
  addition: Addition,
  prodId: string,
  isSharedMenu: boolean,
  onSharedWithOtherProducts: (menuAdditionsId: string, prodId:string) => Promise<number>,
  onDeleteAdditionProduct?: (productId: string, additionId:string) => void | Promise<void>,
  onEditAdditionRestaurantAndProduct?: (
    values: AdditionFormValues,
    availability:boolean,
    menuAdditionId: string,
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
  onEditAdditionOptionsRestaurantAndProduct?: (
    swaped: AdditionOption[],
    menuAdditionsId: string,
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
  onEditAdditionOptionsProduct?: (
    swaped: AdditionOptionFormValues[],
    additionId:string,
    prodId:string,
  ) => void | Promise<void>,
}
const AdditionPickerItem = ({
  addition,
  prodId,
  isSharedMenu,
  onSharedWithOtherProducts,
  onDeleteAdditionProduct,
  onEditAdditionRestaurantAndProduct,
  onEditAdditionOptionsRestaurantAndProduct,
  onEditAdditionOptionsProduct,
}: AdditionPickerItemProps) => {
  const [sharedWith, setSharedWith] = useState(0);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const getSharedWith = async () => {
    const sharedWithNumber = await onSharedWithOtherProducts(addition.menu_additions_id, prodId);
    if (typeof sharedWithNumber === 'number') {
      setSharedWith(sharedWithNumber);
    }
  };

  useEffect(() => {
    getSharedWith();
    // eslint-disable-next-line
  }, []);

  const columnsSize = isSharedMenu ? 6 : 4;

  return (
    <>
      <Accordion expanded={expanded}>
        <Box p={2} display="flex" alignItems="center">
          <Box>
            <Typography>
              <strong>{addition.name}</strong>
              {' '}
            </Typography>
            {sharedWith > 0 && (
              <TypographyCustom>
                {`Compartido con ${sharedWith} ${
                  sharedWith === 1 ? 'producto' : 'productos'
                }`}
              </TypographyCustom>
            )}
          </Box>
          {expanded ? (
            <BoxIcon>
              <ArrowDropUpIcon
                onClick={() => setExpanded(false)}
              />
            </BoxIcon>
          ) : (
            <BoxIcon>
              <ArrowDropDownIcon
                onClick={() => setExpanded(true)}
              />
            </BoxIcon>
          )}
          {isSharedMenu && (
            <>
              <IconButton id="editAddition" size="small" onClick={() => setShowEdit(true)}>
                <EditIcon />
              </IconButton>
              <IconButton
                id="deleteAddition"
                size="small"
                onClick={() => setShowModalDelete(true)}
              >
                <CrossIcon />
              </IconButton>
            </>
          )}
          <IconButton>
            <DragIndicatorIcon sx={{ fontSize: 45, color: COLORS.BLACK }} />
          </IconButton>
        </Box>
        <Divider sx={{ borderColor: COLORS.GRAY_LIGHT }} />
        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box mt={1}>
            <GridContainer container>
              <GridItem item xs={columnsSize}>
                <BoxField>
                  <Typography>
                    <strong>Nombre</strong>
                  </Typography>
                </BoxField>
              </GridItem>
              <GridItem item xs={columnsSize}>
                <BoxField>
                  <Typography>
                    <strong>Precio</strong>
                  </Typography>
                </BoxField>
              </GridItem>
              {!isSharedMenu && (
                <GridItem item xs={columnsSize}>
                  <BoxField>
                    <Typography>
                      <strong>
                        Habilitado
                      </strong>
                    </Typography>
                  </BoxField>
                </GridItem>
              )}
            </GridContainer>
            <DragDropAdditionsItem
              additionOptions={addition?.options || []}
              addition={addition}
              prodId={prodId}
              isSharedMenu={isSharedMenu}
              columnsSize={columnsSize}
              loading={loading}
              onLoading={setLoading}
              onEditAdditionOptionsRestaurantAndProduct={onEditAdditionOptionsRestaurantAndProduct}
              onEditAdditionOptionsProduct={onEditAdditionOptionsProduct}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      {addition && (
        <ModalCreateAddition
          show={showEdit}
          onClose={() => setShowEdit(false)}
          addition={addition}
          prodId={prodId}
          onEditAdditionRestaurantAndProduct={onEditAdditionRestaurantAndProduct}
        />
      )}
      <ConfirmModal
        open={showModalDelete}
        okText="BORRAR"
        cancelText="CANCELAR"
        onClose={() => {
          setShowModalDelete(!showModalDelete);
        }}
        onConfirm={(e:any) => {
          e?.preventDefault();
          if (onDeleteAdditionProduct) {
            onDeleteAdditionProduct(prodId, addition.id);
          }
          setShowModalDelete(!showModalDelete);
        }}
        title="Seguro que deseas borrar este ítem?"
      />
    </>
  );
};

export default AdditionPickerItem;
