import { getAuth } from 'firebase/auth';
import { admin, AppDispatch } from '../redux/store';
import { setSession } from '../redux/reducers/auth';
import { getCollection, getDocument } from '../config/firebaseService';
import {
  Profile, Restaurant, Subsidiary, User,
} from '../core';
import { COLLECTIONS } from '../constants';
import { setUser } from '../redux/reducers/user';
import { setSubsidiary } from '../redux/reducers/subsidiary';
import { setRestaurant } from '../redux/reducers/restaurant';
import { setProfiles } from '../redux/reducers/profile';

const userRef = admin.firestore().collection(COLLECTIONS.USER);
const restaurantRef = admin.firestore().collection(COLLECTIONS.RESTAURANT);

export const loadLoginData = async (dispatch:AppDispatch) => {
  const auth = getAuth();
  if (auth.currentUser) {
    dispatch(setSession(auth.currentUser));
    const user = await getDocument<User>(userRef.doc(auth.currentUser.uid), User);
    if (user) {
      const { subsidiary_access } = user;
      if (subsidiary_access) {
        const restaurant = await getDocument<Restaurant>(
          restaurantRef.doc(subsidiary_access.restaurant_id),
          Restaurant,
        );
        const subsidiaryRef = admin
          .firestore()
          .collection(COLLECTIONS.RESTAURANT)
          .doc(subsidiary_access?.restaurant_id)
          .collection(COLLECTIONS.SUBSIDIARY);
        const subsidiary = await getDocument<Subsidiary>(
          subsidiaryRef.doc(subsidiary_access.subsidiary_id),
          Subsidiary,
        );
        dispatch(setUser(user));
        if (subsidiary) {
          dispatch(setSubsidiary(subsidiary));
        }
        if (restaurant) {
          dispatch(setRestaurant(restaurant));
        }
        const profileRef = admin
          .firestore()
          .collection(COLLECTIONS.RESTAURANT)
          .doc(subsidiary_access?.restaurant_id)
          .collection(COLLECTIONS.SUBSIDIARY)
          .doc(subsidiary_access?.subsidiary_id)
          .collection(COLLECTIONS.PROFILE);
        const restaurantProfilesRef = admin
          .firestore()
          .collection(COLLECTIONS.RESTAURANT)
          .doc(subsidiary_access?.restaurant_id)
          .collection(COLLECTIONS.RESTAURANT_PROFILES);
        const [subsidiaryProfiles, restaurantProfiles] = await Promise.all([
          getCollection(profileRef, Profile),
          getCollection(restaurantProfilesRef, Profile),
        ]);
        dispatch(setProfiles([...subsidiaryProfiles, ...restaurantProfiles]));
      }
    }
  }
};
