import React, { memo, useState } from 'react';
import {
  Box,
  Button,
  Card, CircularProgress,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LogoWithText } from '../../assets';

const ContainerMain = styled(Container)`
  height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const CustomCard = styled(Card)`
  padding: 44px 32px 44px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContainerImage = styled(Box)`
  width: 200px;
  height: 100px; 
  background-position: center;
`;

const CustomInputAdornment = styled(InputAdornment)`
  &:hover {
    cursor: pointer;
  }
`;
export interface LoginForm {
  email: string,
  password:string,
}
export interface LoginScreenProps {
  onSubmit:(values:LoginForm)=>void | Promise<void>
    loading?:boolean
}
const initialValues:LoginForm = {
  email: '',
  password: '',
};

const FormComponent = memo(
  ({ onSubmit, loading }:{onSubmit:(values:LoginForm)=>void | Promise<void>, loading?:boolean}) => {
    const [showPassword, setShowPassword] = useState(false);

    const formik = useFormik({
      initialValues,
      validationSchema: Yup.object({
        email: Yup.string()
          .email('Correo electrónico invalido')
          .max(255)
          .required('Requiere correo electrónico'),
        password: Yup.string()
          .max(255)
          .required('Requiere contraseña'),
      }),
      onSubmit,
    });
    return (
      <>
        <ContainerImage>
          <img src={LogoWithText} alt="logo" height="50" width="150" />
        </ContainerImage>
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h1">Ingresa tu correo electrónico y contraseña </Typography>
          <Box mt={5}>
            <TextField
              id="email"
              name="email"
              type="email"
              fullWidth
              variant="outlined"
              placeholder="Correo electrónico"
              label="Correo electrónico"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <Typography color="red">{formik.errors.email}</Typography>
            ) : null}
          </Box>
          <Box mt={3} mb={5}>
            <TextField
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              onChange={formik.handleChange}
              value={formik.values.password}
              fullWidth
              variant="outlined"
              placeholder="Contraseña"
              label="Contraseña"
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment
                    onClick={() => setShowPassword(!showPassword)}
                    position="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </CustomInputAdornment>
                ),
              }}
            />
            {formik.touched.password && formik.errors.password ? (
              <Typography color="red">{formik.errors.password}</Typography>
            ) : null}
          </Box>
          {!loading && (
          <Button
            variant="contained"
            type="submit"
            fullWidth
          >
            Ingresar
          </Button>
          )}
          {loading && (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
          )}
        </form>
      </>
    );
  },
  // eslint-disable-next-line max-len
  (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps),
);

export const LoginScreen = ({ onSubmit, loading }:LoginScreenProps) => (
  <ContainerMain>
    <CustomCard>
      <FormComponent onSubmit={onSubmit} loading={loading} />
    </CustomCard>
  </ContainerMain>
);
