import React, {
  useState, useMemo, useRef, useEffect,
} from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../../../constants';
import { Order, PAYMENT_STATUS, STATUS } from '../../../../core';
import { ConfirmModal } from '../../../modal';
import { useAppSelector } from '../../../../appHooks/redux';

const ContainerTop = styled(Grid)`
  padding-inline: 13px;
  padding-block: 6px;
`;

const CustomTypography = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  text-transform: initial;
  color: black;
`;

const CustomButton = styled(Button)`
  background-color: ${COLORS.WHITE};
  border-color: ${COLORS.BLACK};
  height: 45px;
  &:hover {
    background-color: ${COLORS.WHITE};
    border-color: ${COLORS.BLACK};
  }
`;

export type TopSectionProps = {
  order: Order | null;
  onHoldHandler: () => void;
  onResetHandler: () => void;
  onSendHandler: () => void;
  onNewItem: () => void;
  loading: boolean;
};

export const TopSection = ({
  order,
  onHoldHandler,
  onSendHandler,
  loading,
  onResetHandler,
  onNewItem,
}: TopSectionProps) => {
  const currentOrder = useAppSelector((state) => state.shoppingCart.currentOrder);
  const [confirmModal, setConfirmModal] = useState(false);
  const buttonNewItemRef = useRef<HTMLButtonElement>(null);
  const buttonDeleteRef = useRef<HTMLButtonElement>(null);
  const buttonSendRef = useRef<HTMLButtonElement>(null);
  const disableNewItem = useMemo(() => {
    if (
      order?.status === STATUS.IN_PROCESS
      || order?.status === STATUS.ACCEPTED
      || order?.status === STATUS.ON_CART
      || order?.status === STATUS.ON_HOLD
    ) {
      return false;
    }
    return true;
  }, [order]);

  const isDisabled = useMemo(() => (order?.status === STATUS.COMPLETED), [order]);

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      const { key } = event;
      if (buttonNewItemRef.current && key === 'F6') {
        event.preventDefault();
        buttonNewItemRef.current.click();
      }
      if (buttonDeleteRef.current && key === 'F7') {
        event.preventDefault();
        buttonDeleteRef.current.click();
      }
      if (buttonSendRef.current && key === 'F8') {
        event.preventDefault();
        buttonSendRef.current.click();
      }
    });
  }, []);

  return (
    <ContainerTop container direction="row" spacing={2} justifyContent="center">
      <Grid item sm={6} md={3} xs={12}>
        <CustomButton
          id="newItemBtn"
          variant="outlined"
          fullWidth
          disabled={disableNewItem || isDisabled}
          onClick={onNewItem}
          ref={buttonNewItemRef}
        >
          <CustomTypography>Nuevo item</CustomTypography>
        </CustomButton>
      </Grid>
      {false && (
        <Grid item sm={6} md={3} xs={12}>
          <CustomButton
            id="onHoldBtn"
            onClick={() => onHoldHandler()}
            variant="outlined"
            disabled={isDisabled}
            fullWidth

          >
            <CustomTypography>En espera</CustomTypography>
          </CustomButton>
        </Grid>
      )}
      <Grid item sm={6} md={3} xs={12}>
        <CustomButton
          ref={buttonDeleteRef}
          id="resetBtn"
          onClick={() => { setConfirmModal(true); }}
          variant="outlined"
          fullWidth
          disabled={currentOrder?.payment_status !== PAYMENT_STATUS.PENDING || isDisabled || loading}
        >
          <CustomTypography>Borrar/Resetear</CustomTypography>
        </CustomButton>
      </Grid>
      <Grid item sm={6} md={3} xs={12}>
        <CustomButton
          ref={buttonSendRef}
          id="sendBtn"
          disabled={currentOrder?.payment_status !== PAYMENT_STATUS.PENDING || isDisabled || loading}
          onClick={onSendHandler}
          variant="outlined"
          fullWidth
        >
          <CustomTypography>Enviar</CustomTypography>
        </CustomButton>
      </Grid>
      <ConfirmModal
        open={confirmModal}
        title="¿Estás seguro que deseas borrar la orden?"
        okText="Si"
        onConfirm={() => {
          onResetHandler();
          setConfirmModal(false);
        }}
        cancelText="No"
        onClose={() => { setConfirmModal(false); }}
      />
    </ContainerTop>
  );
};
