import { Photo } from '../photo';
import { FirebaseBase } from '../firebaseBase';
import {
  Constructor,
} from '../../types';
import { MenuSection } from '../menuSection';

export class Restaurant extends FirebaseBase {
  name: string;

  logo_photo: Photo;

  menu_section: MenuSection[];

  banner_photo: Photo;

  enable_inventory: boolean;

  enable_comanda: boolean;

  constructor({
    name = '',
    menu_section = [],
    logo_photo = new Photo({}),
    banner_photo = new Photo({}),
    enable_inventory = false,
    enable_comanda = false,
    ...data
  }:Constructor<Restaurant>) {
    super(data);
    this.name = name;
    this.menu_section = menu_section.map((menuSection) => new MenuSection(menuSection));
    this.logo_photo = logo_photo;
    this.banner_photo = banner_photo;
    this.enable_inventory = enable_inventory;
    this.enable_comanda = enable_comanda;
  }
}
