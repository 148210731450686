import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SideBar } from '../../components/layouts';
import { PAYMENT_METHOD, PAYMENT_STATUS } from '../../core';
import { PaymentOptions } from './paymentOptions';

const ContainerMain = styled(Box)`
width: 100%;
height: 89vh;
display: flex;
`;

const ContainerSideBar = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '30%',
  [theme.breakpoints.down('md')]: {
    width: '40%',
  },
  marginRight: '6px',
}));

const ContainerPage = styled(Box)(({ theme }) => ({
  height: 'auto',
  display: 'flex',
  width: '70%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '60%',
  },
}));

type PaymentScreenProps = {
  onSelectPaymentType: (product: PAYMENT_METHOD | PAYMENT_STATUS) => void;
  onBack:()=>void;
  loading: string;
};

export const PaymentScreen = ({
  onSelectPaymentType,
  onBack,
  loading,
}: PaymentScreenProps) => (
  <ContainerMain>
    <ContainerSideBar>
      <SideBar />
    </ContainerSideBar>
    <ContainerPage>
      <PaymentOptions loading={loading} handleSelect={onSelectPaymentType} onBack={onBack} />
    </ContainerPage>
  </ContainerMain>
);
