import React, { useState } from 'react';
import {
  Box, Grid, Stack, styled, TextField, Typography,
} from '@mui/material';
import { COLLECTIONS, COLORS } from '../../constants';
import { BaseModal } from './baseModal';
import { COLOR, VARIANT } from '../button/enum';
import { RoundedButton } from '../button';
import { Gallery } from '../../core';
import { getDocId } from '../../config/firebaseService';

const CustomContainer = styled(Box)(() => ({
  paddingTop: '40px',
  paddingBottom: '90px',
}));

const CustomTitle = styled(Typography)(() => ({
  paddingTop: '40px',
  paddingBottom: '40px',
}));

type DialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  okText?: string;
  cancelText?: string;
  titleModal: string;
  onUpdateGallery: (gallery: Gallery) => void | Promise<void>;
  onAddGallery: (gallery: Gallery) => void | Promise<void>;
  title?: string;
  documentId?: string;
  dataGallery?: Gallery;
};

export const UpdateGalleryModal = ({
  open,
  onClose,
  onConfirm,
  okText = 'Si',
  cancelText = 'No',
  titleModal,
  onUpdateGallery,
  onAddGallery,
  title = '',
  documentId = '',
  dataGallery,
}: DialogProps) => {
  const [titleImage, setTitleImage] = useState(title);
  const [validation, setValidation] = useState(false);

  const validationTitle = () => {
    if (titleImage) {
      let gallery = new Gallery({});
      if (documentId) {
        gallery = new Gallery({
          ...dataGallery,
          title: titleImage,
          id: documentId,
        });
        setTitleImage(titleImage);
        onUpdateGallery(gallery);
      } else {
        const docId = getDocId(COLLECTIONS.GALLERY);
        gallery = new Gallery({
          id: docId,
          title: titleImage,
        });
        setTitleImage('');
        onAddGallery(gallery);
      }
      onConfirm();
    } else {
      setValidation(true);
    }
  };

  const onCloseModal = () => {
    setValidation(false);
    setTitleImage(title);
    onClose();
  };

  return (
    <BaseModal open={open} size="xs" onClose={onCloseModal} fullWidth>
      <CustomContainer>
        <Grid container sx={{ textAlign: 'center' }}>
          <Grid item xs={12}>
            <CustomTitle variant="h5">{titleModal}</CustomTitle>
            <Box mb={6}>
              <TextField
                value={titleImage}
                onChange={(e) => setTitleImage(e.target.value)}
                placeholder="Título de la imagen"
              />
              {validation && (
                <Typography variant="body2" color="red">Título de la imagen requerido</Typography>
              )}
            </Box>
            <Stack
              spacing={10}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <RoundedButton
                label={okText}
                onClick={validationTitle}
                color={COLOR.SECONDARY}
                variant={VARIANT.CONTAINED}
                disabled={title === titleImage}
              />
              <RoundedButton
                label={cancelText}
                onClick={onCloseModal}
                style={{ backgroundColor: COLORS.LIGHT_BLUE }}
                variant={VARIANT.CONTAINED}
              />
            </Stack>
          </Grid>
        </Grid>
      </CustomContainer>
    </BaseModal>
  );
};
