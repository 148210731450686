import React, { useMemo } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { DateRange } from 'mui-daterange-picker';
import moment from 'moment-timezone';
import { TableRowCollapse } from './tableRowCollapse';
import { InventoryBySection } from '../../../core';

type ResultProps = {
  metricClickHandler: () => void;
  productsBySections: InventoryBySection[];
  dateRange: DateRange;
};

// const rows:string[] = ['test1', 'test2'];

const Results = ({
  metricClickHandler,
  productsBySections,
  dateRange,
}: ResultProps) => {
  const headersTable = useMemo(() => {
    if (dateRange.endDate) {
      return [
        'Producto',
        'Metrica',
        `Actual (${
          dateRange.startDate
            ? moment(dateRange.startDate).format('DD/MM/YYYY')
            : 'Fecha inicial'
        })`,
        'Ingreso',
        'Venta',
        'Descuento',
        `Stock (${
          dateRange.endDate
            ? moment(dateRange.endDate).format('DD/MM/YYYY')
            : 'Fecha final'
        })`,
      ];
    }
    return [
      'Producto',
      'Metrica',
      'Actual (Fecha inicial)',
      'Ingreso',
      'Venta',
      'Descuento',
      'Stock (fecha final)',
    ];
  }, [dateRange]);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="inventory-collapsable-table">
        <TableHead>
          <TableRow>
            {headersTable.map((title: string, index: number) => (
              <TableCell align="center">
                <Stack
                  direction="row"
                  spacing={0.5}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography>
                    <strong>{title}</strong>
                  </Typography>
                  {index === 1 && (
                    <IconButton onClick={metricClickHandler}>
                      <MoreVertIcon />
                    </IconButton>
                  )}
                </Stack>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {productsBySections.map((productBySection: InventoryBySection) => (
            <TableRowCollapse
              productBySection={productBySection}
              key={productBySection.menu_section_id}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Results;
