import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FirebaseError } from '@firebase/app';
import { useNavigate } from 'react-router-dom';
import { LoginForm, LoginScreen } from '../../screens';
import { loginWithEmail } from '../../config/firebaseService';
import { ERROR_CODE_AUTH } from '../../constants';
import { loadLoginData } from '../../providers/loginProvider';
import { useAppDispatch } from '../../appHooks/redux';
import { HeadTitle } from '../../components';

const Login = () => {
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const errorAuth = (code: string) => {
    switch (code) {
      case ERROR_CODE_AUTH[0]:
        return 'Correo o contraseña tiene un formato incorrecto o ha caducado.';
      case ERROR_CODE_AUTH[1]:
        return 'La cuenta no está habilitado.';
      case ERROR_CODE_AUTH[2]:
        return 'La cuenta ha sido deshabilitada.';
      case ERROR_CODE_AUTH[3]:
        return 'Correo o contraseña incorrecto.';
      case ERROR_CODE_AUTH[4]:
        return 'Correo o contraseña incorrecto.';
      default:
        return null;
    }
  };
  const onSubmit = async (values:LoginForm) => {
    const { email, password } = values;
    setLoading(true);
    try {
      await loginWithEmail({ email, password });
      await loadLoginData(dispatch);
      await router('/commandPanel');
    } catch (error) {
      if (error instanceof FirebaseError) {
        const messageError = errorAuth(error.code);
        if (messageError) {
          enqueueSnackbar(errorAuth(error.code), { variant: 'error' });
          console.error('error', error.code);
        }
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <HeadTitle title="Login" />
      <LoginScreen onSubmit={onSubmit} loading={loading} />
    </>
  );
};

export default Login;
