import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';
import { JourneyProductionProduct } from '../../../core';
import JourneyProduction from '../../../core/journeyProduction/journeyProduction.dto';
import { FirestoreQueryResult, Nullable } from '../../../types';

type InventoryState = {
  journeyProduction: FirestoreQueryResult<JourneyProduction>,
  journeyProductionProducts: Nullable<JourneyProductionProduct[]>,
  journeyProductionProductsSet: Record<string, JourneyProductionProduct>,
  afterProduct: Nullable<(string | number)[][]>,
  beforeProduct: Nullable<(string | number)[][]>,
}

const initialState: InventoryState = {
  journeyProduction: { isLoading: false, value: null },
  journeyProductionProducts: null,
  journeyProductionProductsSet: {},
  afterProduct: null,
  beforeProduct: null,
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setJourneyProduction: (state: Draft<InventoryState>, action:PayloadAction<FirestoreQueryResult<JourneyProduction>>) => {
      state.journeyProduction = action.payload;
    },
    setJourneyProductionProducts: (state: Draft<InventoryState>, action:PayloadAction<Nullable<JourneyProductionProduct[]>>) => {
      state.journeyProductionProducts = action.payload;
    },
    setJourneyProductionProductsSet: (state: Draft<InventoryState>, action: PayloadAction<Record<string, JourneyProductionProduct>>) => {
      state.journeyProductionProductsSet = action.payload;
    },
    setAfterProduct: (state: Draft<InventoryState>, action: PayloadAction<Nullable<(string | number)[][]>>) => {
      state.afterProduct = action.payload;
    },
    setBeforeProduct: (state: Draft<InventoryState>, action: PayloadAction<Nullable<(string | number)[][]>>) => {
      state.beforeProduct = action.payload;
    },
  },
});

export const {
  setJourneyProduction,
  setJourneyProductionProducts,
  setJourneyProductionProductsSet,
  setAfterProduct,
  setBeforeProduct,
} = inventorySlice.actions;
export default inventorySlice.reducer;
export const InventoryReducerTransform = createTransform(
  (toSave:InventoryState) => ({
    ...toSave,
  }),
  (toRehydrated) => ({
    ...initialState,
    journeyProduction: toRehydrated.journeyProduction.value ? { isLoading: false, value: new JourneyProduction(toRehydrated.journeyProduction.value) } : { isLoading: false, value: null },
    journeyProductionProducts: toRehydrated.journeyProductionProducts ? toRehydrated.journeyProductionProducts.map((item) => new JourneyProductionProduct(item)) : null,
    journeyProductionProductsSet: (() => {
      const result:Record<string, JourneyProductionProduct> = {};
      Object.entries(toRehydrated.journeyProductionProductsSet).forEach(([key, value]) => {
        result[key] = new JourneyProductionProduct(value);
      });
      return result;
    })(),
  }),
  { whitelist: ['inventory'] },
);
