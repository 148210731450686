import React, { useState } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../constants';
import { OptionImage } from './optionImage';
import { UpdateGalleryModal } from '../modal/updateGalleryModal';
import { Gallery } from '../../core';
import { DragImage } from './dragImage';
import CropModal from '../modal/cropModal';
import { UploadImgGalleryProps } from '../../screens/galleryScreen/types';
import { ConfirmModal } from '../modal';

const CustomMainBox = styled(Box)`
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
`;

const CustomEditIcon = styled(EditIcon)`
  color: ${COLORS.GREEN};
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const CustomDeleteIcon = styled(DeleteIcon)`
  color: ${COLORS.GREEN};
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const CustomContainerMain = styled(Box)`
  height: 100%;
  border-radius: 6px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-position: center;
  max-width: 100%;
`;

type CardImageProps = {
  onUpdateGallery: (gallery: Gallery) => void | Promise<void>,
  onAddGallery: (gallery: Gallery) => void | Promise<void>,
  onUploadImg: ({ file, docId }: UploadImgGalleryProps) => void | Promise<boolean>,
  onDeleteGallery: (urlImage: string[], docId: string) => void | Promise<void>,
  title: string,
  docId: string,
  gallery: Gallery,
};

export const CardImage = ({
  onUpdateGallery,
  onAddGallery,
  docId,
  title,
  gallery,
  onUploadImg,
  onDeleteGallery,
}: CardImageProps) => {
  const [currentFile, setCurrentFile] = useState<File>();
  const [extensionImg, setExtensionImg] = useState('');
  const [typeImage, setTypeImage] = useState('');
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showCropModal, setShowCropModal] = useState(false);
  const [hoverOptions, setHoverOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleShowCrop = () => {
    setShowCropModal(!showCropModal);
  };

  const handleDropGallery = (files: FileList | File[] | null) => {
    const file = files;
    if (file) {
      const extension = file[0]?.name?.split('.').pop();
      if (extension) {
        setExtensionImg(extension);
      }
      setTypeImage(file[0].type);
      setCurrentFile(file[0]);
      handleShowCrop();
    }
  };

  const handleCropOriginal = async () => {
    await fetch(gallery.original_image).then(async (response) => {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        const blob = await response.blob();
        const file = new File([blob], `${docId}.${gallery.extension}`, { type: contentType });
        handleDropGallery([file]);
      }
    }).catch((error) => console.error(error));
  };

  const handleEdit = () => {
    const inputFile = document.getElementById(docId);
    if (inputFile) {
      inputFile.click();
    }
  };

  const handleShowImage = () => {
    const docImage = document.createElement('a');
    if (docImage) {
      docImage.href = gallery.image.original;
      docImage.setAttribute('target', '_blank');
      document.body.appendChild(docImage);
      docImage.click();
      document.body.removeChild(docImage);
    }
  };

  const handleDownloadDocument = () => {
    const url = gallery.image.original;
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const imageData = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = imageData;
      link.setAttribute('download', `${title}.${gallery.extension}`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleConfirmImage = async ({ file, originalFile }: UploadImgGalleryProps) => {
    setLoader(true);
    const dataGallery = new Gallery({
      ...gallery,
      extension: extensionImg,
    });
    handleShowCrop();
    await onUploadImg({
      originalFile, file, docId, gallery: dataGallery,
    });
    setLoader(false);
  };

  const handleClose = () => {
    setCurrentFile(undefined);
    setShowCropModal(false);
  };

  const handleDeleteGallery = async () => {
    const urlImages = [
      gallery.original_image,
      gallery.image.original,
      gallery.image.x40,
      gallery.image.x400,
      gallery.image.x80,
    ];
    await onDeleteGallery(urlImages, docId);
    setShowModalDelete(false);
  };

  return (
    <CustomContainerMain width="100%" height="100%">
      <input
        type="file"
        id={docId}
        hidden
        accept="image/png, image/jpeg"
        onChange={(e) => handleDropGallery(e.target.files)}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        m={2}
      >
        <Typography>{title}</Typography>
        <Box display="flex">
          <Box width="20px" height="20px" id="edit" onClick={() => setShowModal(!showModal)} mr={1}>
            <CustomEditIcon id="edit-icon" />
          </Box>
          <Box width="20px" height="20px" id="delete" onClick={() => setShowModalDelete(true)}>
            <CustomDeleteIcon id="delete-icon" />
          </Box>
        </Box>
      </Box>
      {!loader && gallery.image.original && (
        <CustomMainBox
          mb={2}
          mr={2}
          ml={2}
          onMouseEnter={() => setHoverOptions(true)}
          onMouseLeave={() => setHoverOptions(false)}
          style={{ backgroundImage: `url("${gallery.image.original}")` }}
        >
          {hoverOptions && (
            <OptionImage
              handleEdit={handleEdit}
              handleShowImage={handleShowImage}
              handleDownloadDocument={handleDownloadDocument}
              handleCropImage={handleCropOriginal}
            />
          )}
        </CustomMainBox>
      )}
      {!loader && !gallery.image.original && (
        <CustomMainBox
          mb={2}
          mr={2}
          ml={2}
        >
          <DragImage handleDropGallery={handleDropGallery} />
        </CustomMainBox>
      )}
      {loader && (
        <CustomMainBox display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </CustomMainBox>
      )}
      <CropModal
        openDialog={showCropModal}
        onClose={handleClose}
        file={currentFile}
        onConfirm={
          (
            { file, originalFile }: UploadImgGalleryProps,
          ) => handleConfirmImage({ file, originalFile })
          }
        typeImage={typeImage}
      />
      <UpdateGalleryModal
        open={showModal}
        onClose={() => setShowModal(!showModal)}
        onConfirm={() => setShowModal(!showModal)}
        titleModal="Actualizar título de la imagen"
        onUpdateGallery={onUpdateGallery}
        onAddGallery={onAddGallery}
        title={title}
        documentId={docId}
        dataGallery={gallery}
      />
      <ConfirmModal
        open={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        onConfirm={handleDeleteGallery}
        title="¿Seguro que deseas borrar este ítem?"
      />
    </CustomContainerMain>
  );
};
