import { Constructor, PlainObject } from '../../types';

export class MenuSection {
  enable: boolean;

  id: string;

  name: string;

  constructor({
    enable = false,
    id = '',
    name = 'New Section',
  }: Constructor<MenuSection>) {
    this.enable = enable;
    this.id = id;
    this.name = name;
  }

  toPlainObject(): PlainObject<MenuSection> {
    return { ...this };
  }
}
