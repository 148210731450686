import React from 'react';
import {
  Box, styled, Typography,
} from '@mui/material';
import { Restaurant } from '../../core';

const ButtonContainer = styled(Box)`
  position: relative;
  height: 70px;
  border-radius: 8px;
  border: 1px solid #2C2926;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  text-align: center;
`;

const PriceText = styled(Typography)`
  font-size: 9px;
  color: white;
  font-weight: 700;
`;

export interface MenuButtonProps {
  label:string,
  bgColor: string,
  hoverColor: string,
  textColor: string,
  hoverTextColor: string,
  onClick: () => void,
  stock?:number,
  isSelected?:boolean,
  restaurant?: Restaurant | null,
}

export const MenuButton = ({
  label,
  bgColor,
  hoverColor,
  textColor,
  hoverTextColor,
  stock,
  onClick,
  isSelected,
  restaurant,
}: MenuButtonProps) => (
  <ButtonContainer
    sx={{
      backgroundColor: isSelected ? hoverColor : bgColor,
      '&:hover': {
        backgroundColor: hoverColor,
      },

    }}
    style={{ marginBottom: 30 }}
    onClick={onClick}
    width={150}
    height={90}
  >
    <Typography
      variant="body1"
      sx={{
        color: isSelected ? hoverTextColor : textColor,
        '&:hover': {
          color: hoverTextColor,
        },

      }}
      fontSize={{
        lg: 16,
        md: 14,
        sm: 12,
        xs: 10,
      }}
    >
      <strong>{label}</strong>
    </Typography>
    {restaurant?.enable_inventory && (
      <Box
        sx={{
          position: 'absolute',
          right: '8px',
          bottom: '6px',
          bgcolor: '#575657',
          width: '20px',
          height: '20px',
          boxShadow: '-1px 2px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PriceText>{stock}</PriceText>
      </Box>
    )}
  </ButtonContainer>
);
