import { GeoPoint } from 'firebase/firestore';
import { FirebaseBase } from '../firebaseBase';
import {
  PlainObject,
  Nullable,
} from '../../types';
import { toGeoPoint } from '../../utils/firestoreUtils';

export class City extends FirebaseBase {
  name: string;

  price_area_id: string;

  location: Nullable<GeoPoint>;

  coverage_polygon: GeoPoint[];

  is_default: boolean;

  name_abbreviation: string;

  enable: boolean;

  constructor({
    name = '',
    price_area_id = '',
    location = null,
    coverage_polygon = [],
    is_default = false,
    name_abbreviation = '',
    enable = false,
    ...data
  }:Partial<City>) {
    super(data);
    this.name = name;
    this.price_area_id = price_area_id;
    this.location = location ? toGeoPoint(location) : null;
    this.coverage_polygon = coverage_polygon?.map(
      (position) => toGeoPoint(position),
    );
    this.is_default = is_default;
    this.name_abbreviation = name_abbreviation;
    this.enable = enable;
  }

  toPlainObject(): PlainObject<City> {
    return { ...this };
  }
}

export default City;
