import {
  doc, getDoc, getFirestore, setDoc,
} from 'firebase/firestore';
import { COLLECTIONS } from '../../constants';
import { Subsidiary } from './subsidiary.dto';

const db = getFirestore();

export type EditOrderParams = {
    subsidiary: Partial<Subsidiary>,
    restaurantId:string;
    subsidiaryId:string;
}

export const subsidiaryRef = (restaurantId:string, subsidiaryId:string) => doc(db, `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}`);

export const editSubsidiary = async (
  {
    restaurantId, subsidiaryId, subsidiary,
  }:EditOrderParams,
): Promise<void> => {
  const orderRef = doc(
    db,
    // eslint-disable-next-line max-len
    `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}`,
  );
  await setDoc(orderRef, subsidiary, { merge: true });
};

export type GetSubsidiaryParams = {
    restaurantId:string;
    subsidiaryId:string;
}

export const getSubsidiary = async (
  {
    restaurantId,
    subsidiaryId,
  }:GetSubsidiaryParams,
):Promise<Subsidiary> => {
  const docRef = doc(
    db,
    // eslint-disable-next-line max-len
    `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.SUBSIDIARY}/${subsidiaryId}/`,
  );
  const querySnapshot = await getDoc(docRef);
  return new Subsidiary({
    ...querySnapshot.data(),
    id: querySnapshot.id,
  });
};
