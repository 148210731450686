import React from 'react';
import { Stack, Typography } from '@mui/material';
import { SearchInput } from '../../components';

type SearchSectionProps = {
  onChange?: (event:React.ChangeEvent<HTMLInputElement>) => void ;
  search?: string;
}

export const Header = ({ onChange, search }: SearchSectionProps) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    pl={2}
    pr={3}
    pt={3}
    pb={3}
  >
    <Typography variant="body1">
      <strong>Ordenes Completadas</strong>
    </Typography>
    <SearchInput
      onChange={onChange}
      value={search}
      placeHolder="Buscar"
    />
  </Stack>
);
