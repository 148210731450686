import { getAuth, onAuthStateChanged } from 'firebase/auth';

const auth = getAuth();
export async function initFirebaseUser() {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      resolve(user);
    });
  });
}
