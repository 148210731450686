/* eslint-disable max-len */
import React from 'react';
import 'nprogress/nprogress.css';
import { useSnackbar } from 'notistack';
import { ConfirmModal, HeadTitle } from '../../components';
import { useAppSelector } from '../../appHooks/redux';
import { Journey } from '../../core';
import { TurnScreen } from '../../screens/journeyScreen';
import { saveJourney, editJourney, deleteJourney } from '../../core/journey/journey.service';
import { NewJourneyModal } from '../../components/modal/newJourneyModal';
import { useAllJourneySubsidiary } from '../../core/journey/journey.hook';

const JourneyPage = () => {
  const restaurant = useAppSelector((state) => state.restaurant.restaurant);
  const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);

  const { enqueueSnackbar } = useSnackbar();
  const allJourney = useAllJourneySubsidiary(restaurant?.id || '', subsidiary?.id || '');
  const [modal, setOpenModal] = React.useState(false);
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [currentJourney, setCurrentJourney] = React.useState<Journey>(new Journey({}));
  const [isSubmitting, setSubmitting] = React.useState(false);

  const openModalHandler = () => {
    setCurrentJourney(new Journey({}));
    setOpenModal(true);
  };
  const saveJourneyHandler = async (journey:Journey) => {
    setSubmitting(true);
    try {
      await saveJourney(journey, restaurant?.id || '', subsidiary?.id || '');
      setSubmitting(false);
      setOpenModal(false);
      enqueueSnackbar('Registro realizado con éxito', {
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Ocurrió un error', {
        variant: 'error',
      });
      setSubmitting(false);
    }
  };

  const editJourneyHandler = async (journey:Journey) => {
    setSubmitting(true);
    try {
      await editJourney(journey, restaurant?.id || '', subsidiary?.id || '');
      setSubmitting(false);
      setOpenModal(false);
      enqueueSnackbar('Registro actualizado con éxito', {
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Ocurrió un error', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setSubmitting(false);
    }
  };

  const deleteUserHandler = (journey:Journey) => {
    setCurrentJourney(journey);
    setConfirmModal(true);
  };

  const openEditModal = (journey:Journey) => {
    setCurrentJourney(journey);
    setOpenModal(true);
  };

  return (
    <>
      <HeadTitle title="Administración de turnos" />
      <TurnScreen journeys={allJourney} openModalHandler={openModalHandler} deleteUserHandler={deleteUserHandler} editModalHandler={openEditModal} />
      {modal && <NewJourneyModal journey={currentJourney} open onClose={() => { setOpenModal(false); }} onEdit={editJourneyHandler} onSave={saveJourneyHandler} submitting={isSubmitting} />}
      {confirmModal && (
      <ConfirmModal
        open={confirmModal}
        onClose={() => { setConfirmModal(false); }}
        onConfirm={async () => {
          try {
            await deleteJourney(currentJourney.id, restaurant?.id || '', subsidiary?.id || '');
            setConfirmModal(false);
            enqueueSnackbar('Registro eliminado con éxito', {
              variant: 'success',
            });
          } catch (error) {
            enqueueSnackbar('Ocurrió un error', {
              variant: 'error',
            });
          }
        }}
        title="¿Seguro que desea borrar el registro?"
      />
      )}
    </>
  );
};

export default JourneyPage;
