import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

type AverageOrderProps = {
  value: number;
  currency: string;
}

const AverageOrder = ({ value, currency }: AverageOrderProps) => {
  const valueFixed = (value * 1).toFixed(2);

  return (
    <Paper elevation={3}>
      <Box flexGrow={1} p={2}>
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          color="textSecondary"
        >
          PROMEDIO POR ORDEN
        </Typography>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Typography variant="h3" color="textPrimary">
            {currency}
            <span>&nbsp;&nbsp;</span>
            {valueFixed}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default AverageOrder;
