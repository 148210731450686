import React from 'react';
import {
  Grid, Button, Box, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { COLORS } from '../../constants';
import { PAYMENT_METHOD, PAYMENT_STATUS } from '../../core';

const CustomButton = styled(Button)(() => ({
  backgroundColor: COLORS.ROYAL_BLUE_DARK_52,
  border: `1px solid ${COLORS.ROYAL_BLUE_DARK_52}`,
  borderRadius: '15px',
  color: COLORS.WHITE,
  textTransform: 'none',
  minHeight: '65px',
  fontWeight: 'bold',
  fontSize: '18px',
  '&:hover': {
    backgroundColor: COLORS.LIGHT_BLUE,
    borderColor: COLORS.CORNFLOWER_BLUE,
  },
}));
type PaymentOptionsProps = {
  handleSelect: (state: PAYMENT_METHOD | PAYMENT_STATUS) => void;
  onBack: () => void;
  loading: string;
};
export const PaymentOptions = ({
  handleSelect,
  onBack,
  loading,
}: PaymentOptionsProps) => (
  <Box sx={{ padding: 4 }}>
    <Box textAlign="left" sx={{ padding: 3 }}>
      <Button onClick={onBack}>
        <KeyboardBackspaceIcon fontSize="large" color="action" />
      </Button>
    </Box>

    <Typography
      fontWeight="bold"
      variant="h3"
      textAlign={{ xs: 'justify', sm: 'center' }}
    >
      Escoja que tipo de servicio requiere
    </Typography>

    <Box sx={{ textAlign: 'center', paddingTop: 6 }}>
      <Grid container spacing={12} justifyContent="center">
        <Grid item xs={12} container spacing={3} justifyContent="space-evenly">
          <Grid item xs={12} sm={4} md={3}>
            <CustomButton
              id="cashBtn"
              color="secondary"
              variant="outlined"
              fullWidth
              disabled={loading === PAYMENT_METHOD.CASH}
              onClick={() => {
                handleSelect(PAYMENT_METHOD.CASH);
              }}
            >
              Efectivo
            </CustomButton>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <CustomButton
              id="cardBtn"
              fullWidth
              variant="outlined"
              disabled={loading === PAYMENT_METHOD.CARD}
              onClick={() => {
                handleSelect(PAYMENT_METHOD.CARD);
              }}
            >
              Tarjeta
            </CustomButton>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <CustomButton
              fullWidth
              id="qrBtn"
              variant="outlined"
              disabled={loading === PAYMENT_METHOD.QR}
              onClick={() => {
                handleSelect(PAYMENT_METHOD.QR);
              }}
            >
              QR
            </CustomButton>
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={3} justifyContent="space-evenly">
          <Grid item xs={12} sm={4} md={3}>
            <CustomButton
              color="secondary"
              variant="outlined"
              id="transferBtn"
              fullWidth
              disabled={loading === PAYMENT_METHOD.TRANSFER}
              onClick={() => {
                handleSelect(PAYMENT_METHOD.TRANSFER);
              }}
            >
              Transferencia
            </CustomButton>
          </Grid>
          {/* <Grid item xs={12} sm={4} md={3}>
            <CustomButton
              fullWidth
              variant="outlined"
              id="pendingBtn"
              disabled={loading === PAYMENT_STATUS.PENDING}
              onClick={() => {
                handleSelect(PAYMENT_STATUS.PENDING);
              }}
            >
              Pago pendiente
            </CustomButton>
          </Grid> */}
          <Grid item xs={12} sm={4} md={3}>
            <CustomButton
              fullWidth
              id="notPaymentBtn"
              variant="outlined"
              disabled={loading === PAYMENT_STATUS.NOT_PAYED}
              onClick={() => {
                handleSelect(PAYMENT_STATUS.NOT_PAYED);
              }}
            >
              Pedido cancelado
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </Box>
);
