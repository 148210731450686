import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { BORDER_DECORATION, COLORS } from '../../constants';
import { Gallery } from '../../core';
import { UpdateGalleryModal } from '../modal/updateGalleryModal';

export const CustomMainBox = styled(Box)`
  background-image: url("${BORDER_DECORATION}");
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const CustomDetailBox = styled(Box)`
  margin: 24px 0px;
  text-align: center;
  cursor: pointer;
`;

export const CustomIconBox = styled(Box)`
  height: 60px;
  margin-bottom: 8px;
`;

const CustomAddCircleIcon = styled(AddCircleIcon)`
  color: ${COLORS.GREEN};
  width: 100%;
  height: 100%;
`;

type AddImageProps = {
  onUpdateGallery: (gallery: Gallery) => void | Promise<void>,
  onAddGallery: (gallery: Gallery) => void | Promise<void>,
}

export const AddImage = ({ onUpdateGallery, onAddGallery }: AddImageProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <CustomMainBox m={2}>
      <CustomDetailBox onClick={() => setShowModal(!showModal)}>
        <CustomIconBox>
          <CustomAddCircleIcon />
        </CustomIconBox>
        <Typography>Agregar espacio</Typography>
        <Typography>para nuevo documento</Typography>
      </CustomDetailBox>
      <UpdateGalleryModal
        open={showModal}
        onClose={() => setShowModal(!showModal)}
        onConfirm={() => setShowModal(!showModal)}
        titleModal="Agregar imagen"
        onUpdateGallery={onUpdateGallery}
        onAddGallery={onAddGallery}
      />
    </CustomMainBox>
  );
};
