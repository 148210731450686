import { FirebaseBase } from '../firebaseBase';
import { Constructor, PlainObject } from '../../types';

export class Journey extends FirebaseBase {
  name: string;

  constructor({
    name = '',
    ...data
  }:Constructor<Journey>) {
    super(data);
    this.name = name;
  }

  toPlainObject(): PlainObject<Journey> {
    return { ...this };
  }
}

export default Journey;
