/* eslint-disable jsx-a11y/no-distracting-elements */
import React from 'react';
import {
  Box, styled, Typography,
} from '@mui/material';

const CustomizedBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  width: 154,
  height: 88,
  borderRadius: '86px 86px 0px 0px',
  cursor: 'pointer',
}));

export interface MenuButtonCircularProps {
    label:string,
    bgColor?: string,
    isSelected?: boolean,
    onClick: () => void,
  }

export const MenuButtonCircular = ({
  label, bgColor, isSelected, onClick,
} : MenuButtonCircularProps) => (
  <CustomizedBox
    sx={{
      backgroundColor: isSelected ? bgColor : '#FCFCFC',
      border: !isSelected ? '1.5px solid #2C2926' : '',
      paddingLeft: '0px',
      padding: 2,
    }}
    onClick={onClick}
  >

    <Typography
      sx={{
        fontSize: 13.5,
        fontWeight: 'bold',
        textAlign: 'center',

        color: '#000000',

      }}
    >
      {label[0].toUpperCase() + label.substring(1).toLowerCase()}
    </Typography>
  </CustomizedBox>
);
