import {
  collection, collectionGroup, deleteDoc, doc, getDocs, getFirestore, query, serverTimestamp, setDoc, where,
} from 'firebase/firestore';
import { getDocId } from '../../config/firebaseService';
import { COLLECTIONS } from '../../constants';
import { Subsidiary } from '../subsidiary';
import { Profile } from './profile.dto';
import { ROLES } from './profile.enum';

const db = getFirestore();

export const ifExistProfile = (users:Profile[], code:string, subsidiary:string, before:Profile|null) => {
  if (before) {
    const tempUsers = users.filter((user) => user.id !== before.id);
    if (subsidiary === '') {
      return tempUsers.find(
        (user) => user.subsidiary_id === '' && user.code === code,
      );
    }
    return tempUsers.find(
      (user) => user.subsidiary_id === subsidiary && user.code === code,
    );
  }
  if (subsidiary === '') {
    return users.find((user) => user.subsidiary_id === '' && user.code === code);
  }
  return users.find(
    (user) => user.subsidiary_id === subsidiary && user.code === code,
  );
};

const getPhoneNumber = (number:string) => {
  if (number) {
    const phone = number.replaceAll('+', '');
    if (phone === '591') {
      return '';
    }
    return `+${phone}`;
  }
  return '';
};

export const getSubsidiaryProfiles = async (
  restId:string,
):Promise<Profile[]> => {
  const docRef = collection(
    db,
    // eslint-disable-next-line max-len
    `${COLLECTIONS.RESTAURANT}/${restId}/${COLLECTIONS.SUBSIDIARY}`,
  );
  const querySnapshot = await getDocs(docRef);

  if (querySnapshot.size) {
    const subsidiaries = querySnapshot.docs.map((data) => new Subsidiary({ ...data.data(), id: data.id }));
    const ids:string[] = [];
    subsidiaries.forEach((subsidiary) => {
      ids.push(subsidiary.id);
    });

    const docsRef = collectionGroup(
      db,
      `${COLLECTIONS.PROFILES}`,
    );
    const q = query(docsRef, where('subsidiary_id', 'in', ids));
    const profileSnap = await getDocs(q);

    const profiles = profileSnap.docs.map((data) => new Profile({ ...data.data(), id: data.id }));

    return profiles;
  }
  return [];
};

export const saveProfile = async (user:Profile, resId:string, users:Profile[]):Promise<string | boolean> => {
  const userData = user;
  userData.phone_number = getPhoneNumber(userData.phone_number);
  if (user.roles[0] === ROLES.CASHIER) {
    const path = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.SUBSIDIARY}/${user.subsidiary_id}/${COLLECTIONS.PROFILES}`;
    const docId = getDocId(path);
    const exist = ifExistProfile(users, user.code, userData.subsidiary_id, null);
    if (!exist) {
      const ref = doc(
        db,
        `${path}/${docId}`,
      );
      await setDoc(ref, { ...userData, id: docId }, { merge: true });
      return docId;
    }
    return true;
  }
  userData.subsidiary_id = '';
  const path = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.RESTAURANT_PROFILES}`;
  const docId = getDocId(path);
  const exist = ifExistProfile(users, user.code, '', null);
  if (!exist) {
    const ref = doc(
      db,
      `${path}/${docId}`,
    );
    await setDoc(ref, { ...userData, id: docId }, { merge: true });
    return docId;
  }
  return true;
};

export const deleteProfile = async (user:Profile, resId:string):Promise<string> => {
  if (user.roles[0] === ROLES.CASHIER) {
    const path = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.SUBSIDIARY}/${user.subsidiary_id}/${COLLECTIONS.PROFILES}`;
    const ref = doc(
      db,
      `${path}/${user.id}`,
    );
    await deleteDoc(ref);
    return user.id;
  }
  const path = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.RESTAURANT_PROFILES}`;

  const ref = doc(
    db,
    `${path}/${user.id}`,
  );
  await deleteDoc(ref);
  return user.id;
};

export const editProfile = async (user:Profile, before :Profile, resId:string, users:Profile[]):Promise<string | boolean> => {
  const exist = ifExistProfile(users, user.code, user.roles[0] === ROLES.CASHIER ? user.subsidiary_id : '', before);
  if (!exist) {
    if (before.roles[0] === ROLES.OWNER) {
      const path = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.RESTAURANT_PROFILES}`;
      const ref = doc(
        db,
        `${path}/${user.id}`,
      );
      const userData = user as Omit<Profile, 'created_at'>;
      userData.subsidiary_id = '';
      userData.roles = before.roles;
      await setDoc(ref, {
        ...userData,
        update_at: serverTimestamp(),
      }, { merge: true });

      return user.id;
    }
    let userData = user as Omit<Profile, 'created_at'>;
    userData.phone_number = getPhoneNumber(userData.phone_number);
    if (before.roles[0] === user.roles[0]) {
      if (user.subsidiary_id === before.subsidiary_id) {
        userData = user as Omit<Profile, 'created_at'>;
      }
      if (user.roles[0] === ROLES.CASHIER) {
        const path = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.SUBSIDIARY}/${user.subsidiary_id}/${COLLECTIONS.PROFILES}`;
        const ref = doc(
          db,
          `${path}/${before.id}`,
        );
        await setDoc(ref, {
          ...userData,
          created_at: before.created_at,
        }, { merge: true });
        if (before.subsidiary_id !== user.subsidiary_id) {
          const path2 = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.SUBSIDIARY}/${before.subsidiary_id}/${COLLECTIONS.PROFILES}`;
          const ref2 = doc(
            db,
            `${path2}/${before.id}`,
          );
          await deleteDoc(ref2);
        }
        return before.id;
      }
      userData.subsidiary_id = '';
      const path = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.RESTAURANT_PROFILES}`;
      const ref = doc(
        db,
        `${path}/${before.id}`,
      );
      await setDoc(ref, {
        ...userData,
      }, { merge: true });
      return before.id;
    }
    if (user.roles[0] === ROLES.CASHIER) {
      const path = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.SUBSIDIARY}/${user.subsidiary_id}/${COLLECTIONS.PROFILES}`;
      const ref = doc(
        db,
        `${path}/${before.id}`,
      );
      await setDoc(ref, {
        ...userData,
        created_at: before.created_at,
      }, { merge: true });

      const path2 = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.RESTAURANT_PROFILES}`;

      const ref2 = doc(
        db,
        `${path2}/${before.id}`,
      );
      await deleteDoc(ref2);
      return before.id;
    }
    userData.subsidiary_id = '';

    const path = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.RESTAURANT_PROFILES}`;
    const ref = doc(
      db,
      `${path}/${before.id}`,
    );
    await setDoc(ref, {
      ...userData,
      created_at: before.created_at,
    }, { merge: true });

    const path2 = `${COLLECTIONS.RESTAURANT}/${resId}/${COLLECTIONS.SUBSIDIARY}/${before.subsidiary_id}/${COLLECTIONS.PROFILES}`;

    const ref2 = doc(
      db,
      `${path2}/${before.id}`,
    );
    await deleteDoc(ref2);
    return before.id;
  }
  return true;
};
