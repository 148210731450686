import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';
import { Restaurant } from '../../../core';
import { Nullable } from '../../../types';

type RestaurantState = {
  restaurant:Nullable<Restaurant>
}

const initialState: RestaurantState = {
  restaurant: null,
};

export const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    setRestaurant: (state: Draft<RestaurantState>, action: PayloadAction<Nullable<Restaurant>>) => {
      state.restaurant = action.payload;
    },
    cleanRestaurant: (state: Draft<RestaurantState>) => {
      state.restaurant = null;
    },
  },
});

export const { setRestaurant, cleanRestaurant } = restaurantSlice.actions;
export default restaurantSlice.reducer;
export const restaurantReducerTransform = createTransform(
  (toSave:any) => ({
    ...toSave,
  }),
  (toRehydrated) => ({
    ...toRehydrated,
  }),
  { whitelist: ['restaurant'] },
);
