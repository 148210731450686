import React from 'react';
import {
  Container, Stack, Typography, Paper, Tabs, Tab,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../constants';
import { Restaurant, Subsidiary } from '../../core';
import { InformationTab } from './tabs/informationTab';
import { SubsidiariesTab } from './tabs/subsidiariesTab';
import RestaurantFormOnConfig from './restaurantForms';
import { Nullable } from '../../types';

const ContainerMain = styled(Container)(() => ({
  width: '100%',
  minHeight: '93.8vh',
  backgroundColor: COLORS.BG_GRAY,
}));

type Payload = {
  banner:File|undefined;
  logo:File|undefined;
  restaurantName:string;
}
export type RestaurantProfileProps = {
  restaurant:Restaurant|null,
  loading:boolean,
  subsidiaries:Nullable<Subsidiary[]>,
  onSubmit:(data:Payload)=>void;
};

export const RestaurantProfileScreen = ({
  restaurant, loading, onSubmit, subsidiaries,
}:RestaurantProfileProps) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <ContainerMain maxWidth="xl">
      <Stack>
        <Stack mt={4} mb={5} justifyContent="center" alignItems="center">
          <Typography sx={{ fontWeight: 'bold', fontSize: 23 }}>
            Perfil de restaurante
          </Typography>
        </Stack>
        <Paper
          square
          sx={{ padding: '32px 16px 16px 16px', marginBottom: '24px' }}
        >
          <Stack>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Información" />
              <Tab label="Sucursales" />
            </Tabs>

          </Stack>
        </Paper>

        <Paper
          square
          sx={{ padding: '32px 16px 16px 16px', marginBottom: '24px' }}
        >
          <Stack>

            <InformationTab value={value} index={0}>
              <RestaurantFormOnConfig loading={loading} currentRestaurant={restaurant} onSubmit={(data) => onSubmit(data)} subsidiaries={subsidiaries} />
            </InformationTab>
            <SubsidiariesTab value={value} index={1} subsidiaries={subsidiaries} />

          </Stack>
        </Paper>
      </Stack>
    </ContainerMain>
  );
};
