import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExpandMore } from '@mui/icons-material';
import { SimpleListItem } from './simpleListItem';
import { useAppSelector } from '../../../appHooks/redux';

type AccordeonListItemProps = {
  icon?: any;
  text:string;
  items?:any;
}

const AccordionCustom = styled(Accordion)`
  width: 100%;
  background-color: #D0D8EC;
  box-shadow: none;
`;

const AccordionSummaryCustom = styled(AccordionSummary)`
  padding-inline: 22px;
  padding-block: 10px;
  margin: 0px;
`;

const ListItemIconCustom = styled(ListItemIcon)`
  min-Width: 57px;
`;

const ListItemButtonCustom = styled(ListItemButton)`
  padding: 0px;
`;

const DividerCustom = styled(Divider)`
  border-color: #9F9D9F;
`;

const AccordionDetailsCustom = styled(AccordionDetails)`
  padding: 0px;
`;

const ListCustom = styled(List)`
  padding: 0px;
`;

export const AccordeonListItem = ({
  icon: Icon, text, items,
} : AccordeonListItemProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const handleExpanded = () => setIsExpanded(!isExpanded);
  const user = useAppSelector((state) => state.profiles.selectProfile);
  return (
    <>
      <ListItem
        key={`key-${text}`}
        disablePadding

      >
        <AccordionCustom expanded={isExpanded}>
          <AccordionSummaryCustom
            onClick={(ev) => {
              handleExpanded();
              ev.stopPropagation();
            }}
            expandIcon={(
              <ExpandMore onClick={(ev) => {
                handleExpanded();
                ev.stopPropagation();
              }}
              />
)}
          >
            <ListItemButtonCustom>
              {Icon && (
              <ListItemIconCustom>
                <Icon />
              </ListItemIconCustom>
              )}
              {text && (<ListItemText primary={text} />)}
            </ListItemButtonCustom>
          </AccordionSummaryCustom>
          <DividerCustom />
          <AccordionDetailsCustom>
            <ListCustom>
              {items?.map((item:any) => {
                if (!item.roles) {
                  return (
                    <SimpleListItem
                      key={`key-${item.title}`}
                      icon={item.icon}
                      text={item.title}
                      href={item.href}
                      isSubList
                    />
                  );
                }
                if (item.roles.includes(user?.roles[0])) {
                  return (
                    <SimpleListItem
                      key={`key-${item.title}`}
                      icon={item.icon}
                      text={item.title}
                      href={item.href}
                      isSubList
                    />
                  );
                }

                return null;
              })}
            </ListCustom>
          </AccordionDetailsCustom>
        </AccordionCustom>
      </ListItem>
      {!isExpanded && <DividerCustom />}
    </>
  );
};
