import { OrderProduct } from './orderProduct.dto';
import { SelectedProduct, OrderAdditionSelected } from '../index';

const getTotal = (product:SelectedProduct, additions: OrderAdditionSelected[]):number => {
  const totalOnlyProduct = (product?.quantity || 0) * (product?.unit_price || 0);
  let totalAdditions = 0;
  if (additions) {
    additions?.forEach((addition:OrderAdditionSelected) => {
      const totalAddition = (addition?.quantity || 0) * (addition?.price || 0);
      totalAdditions += totalAddition;
    });
  }
  return totalOnlyProduct + totalAdditions;
};

type OrderAdditionParams = {
  additions: OrderAdditionSelected[]
  products: SelectedProduct[]
};
export const orderProductDestructuring = (orderProducts: OrderProduct[]):OrderAdditionParams => {
  let additions: OrderAdditionSelected[] = [];
  const products: SelectedProduct[] = [];
  if (orderProducts) {
    orderProducts.forEach((orderProduct:OrderProduct) => {
      const additionsToPush = orderProduct?.selected_options.map((addition) => new OrderAdditionSelected({ ...addition }));
      additions = [...additions, ...additionsToPush];
      products.push(new SelectedProduct({
        ...orderProduct,
        id: orderProduct?.product_id,
        order_product_id: orderProduct.id,
      }));
    });
  }
  return { additions, products };
};

type OrderProductParams = {
  additions: OrderAdditionSelected[]
  products: SelectedProduct[]
  orderId: string
  enableInventory?: boolean
};

export const parseOrderProduct = ({
  additions, products, orderId, enableInventory,
}: OrderProductParams): OrderProduct[] => {
  const additionsById: any = {};
  additions.forEach((addition:OrderAdditionSelected) => {
    const { product_id } = addition;
    if (additionsById[product_id]) {
      additionsById[product_id].push(addition);
    } else {
      additionsById[product_id] = [addition];
    }
  });
  return products.map((product:SelectedProduct) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { created_at, updated_at, ...updatedProduct } = product;
    return new OrderProduct({
      ...updatedProduct,
      id: updatedProduct.order_product_id,
      selected_options: additionsById[updatedProduct.id],
      product_id: updatedProduct.id,
      order_id: orderId,
      total: getTotal(product, additionsById[updatedProduct.id]),
      is_discounted_inventory: !enableInventory || false,
    });
  });
};

type GetDifferenceParams = {
  oldOrderProduct: Partial<OrderProduct>;
  newOrderProduct: OrderProduct;
}

export const getDifference = ({ oldOrderProduct, newOrderProduct }:GetDifferenceParams) => {
  const difference:any = {};
  const params = Object.keys(newOrderProduct);
  params.forEach((key:any) => {
    // @ts-ignore
    if (typeof oldOrderProduct[key] === 'object') {
      // @ts-ignore
      if (JSON.stringify(oldOrderProduct[key]) !== JSON.stringify(newOrderProduct[key])) {
        // @ts-ignore
        difference[key] = newOrderProduct[key];
      }
    // @ts-ignore
    } else if (oldOrderProduct[key] !== newOrderProduct[key]) {
      // @ts-ignore
      difference[key] = newOrderProduct[key];
    }
  });
  return Object.keys(difference).length > 0 ? difference : null;
};

type GetDeletesParams = {
  oldOrderProducts: OrderProduct[];
  newOrderProducts: OrderProduct[];
}

export const getDeletes = ({ oldOrderProducts, newOrderProducts }:GetDeletesParams) => {
  const newOrderIds = newOrderProducts.map((newOrderProduct: OrderProduct) => newOrderProduct.id);
  return oldOrderProducts.filter((oldOrderProduct: OrderProduct) => !newOrderIds.includes(oldOrderProduct.id));
};
