import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { COLLECTIONS } from '../../constants';
import { Nullable } from '../../types';
import { MenuAddition } from './menuAddition.dto';

export const useMenuAdditionsRestaurant = (restaurantId: string): Nullable<MenuAddition[]> => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANT,
      doc: restaurantId,
      subcollections: [{
        collection: COLLECTIONS.MENU_ADDITIONS,
      }],
      storeAs: 'menu_additions',
    },
  ]);
  const menuAdditions = useSelector((state:any) => state.firestore.ordered.menu_additions);
  return menuAdditions || null;
};
