/* eslint-disable max-len */
import React, {
  useMemo, useState, useRef, useLayoutEffect,
} from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PosSwitch, SideBar } from '../../components';
import {
  Product, MenuSection, Restaurant,
  Addition,
  AdditionOption,
  OrderAdditionSelected,
} from '../../core';
import { ProductsSection } from './productsSection';
import { MenuSections } from './menuSections';
import { AdditionSection } from './additionSection';
import { useAppSelector } from '../../appHooks/redux';
import { SearchProduct } from './searchProduct';
import { COLORS } from '../../constants';
import { BarcodeSection } from './barcodeSection';

const ContainerMain = styled(Box)`
  width: 100%;
  height: 89vh;
  display: flex;
`;

const ContainerMenu = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: scroll;
  max-height: -webkit-fill-available;
`;

const ContainerSideBar = styled(Box)(({ theme }) => ({
  height: '89vh',
  width: '30%',
  [theme.breakpoints.down('md')]: {
    width: '40%',
  },
  marginRight: '6px',
}));

const CotainerOrders = styled(Box)(({ theme }) => ({
  height: 'auto',
  width: '70%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '60%',
  },
}));

type MenuScreenProps = {
  products?: Product[] | null;
  menuSections?: MenuSection[] | null;
  onSelectProduct: (product: Product) => void;
  onSelectBarcodeProduct: (product: Product, quantity: number) => void;
  selectProduct: Product | null;
  additions?: Addition[] | null;
  loadingAdditions?: boolean;
  selectedAdditions: OrderAdditionSelected[];
  onSelectAddition: (addition: Addition, option: AdditionOption) => void;
  onSelectSection:(id:string, isSearch?: boolean, product?: Product)=>void;
  restaurant?: Restaurant | null;
  selectSection: string;
};
type ArrayProduct = Product[];

export const MenuScreen = ({
  restaurant,
  products,
  menuSections,
  onSelectProduct,
  selectProduct,
  additions,
  loadingAdditions,
  selectedAdditions,
  onSelectAddition,
  onSelectSection,
  selectSection,
  onSelectBarcodeProduct,
}: MenuScreenProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useState<string>('');
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [sideBarWidth, setSideBarWidth] = useState<number>(0);
  const [enableBarcode, setEnableBarcode] = useState<boolean>(false);
  const containerRef = useRef();
  const sideBarRef = useRef();
  const productsStock = useAppSelector((store) => store.stock.productsStock);

  useLayoutEffect(() => {
    if (containerRef.current) {
      // @ts-ignore
      setContainerHeight(containerRef.current.offsetHeight);
    }
    if (sideBarRef.current) {
      // @ts-ignore
      setSideBarWidth(sideBarRef.current.offsetWidth);
    }
  }, []);

  const filterMenuSections = useMemo(() => {
    if (selectSection === 'menu') return menuSections;
    return menuSections?.filter((section) => section.id === selectSection);
  }, [menuSections, selectSection]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleSectionBarcode = () => {
    setEnableBarcode((value) => !value);
    onSelectSection('menu');
  };

  const filterProductSearch = useMemo(() => {
    if (!search) {
      return products;
    }
    return products?.filter((product) => product.name && product.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
  }, [search]);

  const productsSection = useMemo(() => {
    const productsArray: ArrayProduct[] = [];
    filterMenuSections?.forEach((section) => {
      const productsOfSection = filterProductSearch?.filter(
        (product: Product) => product.menu_section_id === section.id,
      ).sort((a:Product, b:Product) => (a.menu_order > b.menu_order ? 1 : -1));
      if (productsOfSection) productsArray.push(productsOfSection);
    });
    return productsArray;
  }, [filterProductSearch, filterMenuSections]);

  const handleChange = (sectionId:string, isSearch?: boolean, product?: Product) => {
    onSelectSection(sectionId, isSearch, product);
  };

  return (
    <ContainerMain ref={containerRef}>
      <ContainerSideBar ref={sideBarRef}>
        <SideBar />
      </ContainerSideBar>
      <CotainerOrders>
        <Box display="flex" alignItems="center" marginX={2}>
          <Box display="flex" alignItems="center" width={130} flexDirection="column">
            <Typography
              variant="body2"
              sx={{ color: COLORS.ROYAL_BLUE_DARK, fontWeight: 'bold' }}
            >
              {enableBarcode ? 'Codigo de barras' : 'Manual'}
            </Typography>
            <PosSwitch
              value={enableBarcode}
              onChange={handleSectionBarcode}
            />
          </Box>
          <SearchProduct
            enableBarcode={enableBarcode}
            search={search}
            handleSearch={handleSearch}
            filterProductSearch={filterProductSearch}
            handleChangeMenu={handleChange}
          />
        </Box>
        <Divider />
        {!enableBarcode && (
          <ContainerMenu height={containerHeight}>
            {menuSections && (
            <MenuSections
              menuSections={menuSections}
              selectSection={selectSection}
              handleChange={handleChange}
            />
            )}
            <Box>
              {productsSection.map(
                (productsOfSection: Product[], index:number) => (
                  <ProductsSection
                  // eslint-disable-next-line react/no-array-index-key
                    key={`product-section-${index}`}
                    products={productsOfSection}
                    onSelectProduct={onSelectProduct}
                    selectProduct={selectProduct}
                    sideBarWidth={sideBarWidth}
                    index={index}
                    restaurant={restaurant}
                    productsStock={productsStock}
                  />
                ),
              )}
            </Box>
            <AdditionSection
              additions={additions}
              loading={loadingAdditions}
              selectedAdditions={selectedAdditions}
              onSelectAddition={onSelectAddition}
            />
          </ContainerMenu>
        )}
        {enableBarcode && (
          <ContainerMenu height={containerHeight}>
            <BarcodeSection
              arrayProducts={filterProductSearch}
              onSelectBarcodeProduct={onSelectBarcodeProduct}
              productsStock={productsStock}
            />
          </ContainerMenu>
        )}
      </CotainerOrders>
    </ContainerMain>
  );
};
