import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { COLLECTIONS } from '../../constants';
import { Gallery } from './gallery.dto';

export const useGetGalleryByRestaurant = (restId: string = 'gallery'): Gallery[] => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANT,
      doc: restId,
      subcollections: [{ collection: COLLECTIONS.GALLERY }],
      storeAs: `gallery_${restId}`,
      orderBy: ['created_at', 'asc'],
    },
  ]);
  const restaurant = useSelector(
    (state: any) => state.firestore.ordered[`gallery_${restId}`],
  );
  if (restaurant) {
    const gallery: Gallery[] = restaurant;
    return gallery;
  }
  return [];
};
