/* eslint-disable max-len */
import nProgress from 'nprogress';
import 'nprogress/nprogress.css';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ref, getStorage } from 'firebase/storage';
import { useAppSelector } from '../../../../appHooks/redux';
import {
  deleteSharedProduct,
  editSharedProduct,
  useSharedProduct,
  MenuSection,
  useMenuAdditionsRestaurant,
  MenuAddition,
  Addition,
  deleteAdditionSharedProduct,
  addAdditionSharedProduct,
  saveSharedProductsAdditions,
  sharedWithOtherProducts,
  editSharedProductAdditions,
  AdditionOption,
  useIsTemplateMenu,
  Gallery,
  addGallery,
  updateGalleryImage,
  uploadImgGallery,
} from '../../../../core';
import { useSharedAdditionsByProduct } from '../../../../core/additions/addition.hook';
import { deleteMenuAdditionRestaurant, editMenuAdditionRestaurant, saveMenuAdditionRestaurant } from '../../../../core/menuAdditions/menuAddition.service';
import { admin } from '../../../../redux/store';
import { ProductDetailsScreen } from '../../../../screens';
import { AdditionFormValues } from '../../../../screens/productDetailsScreen/addition/additionEditView/AdditionEditForm';
import { ProductFormValues } from '../../../../screens/productDetailsScreen/ProductForm';
import { UploadImgGalleryProps } from '../../../../screens/galleryScreen/types';
import { PictureModule } from '../../../../core/modules/picture.module';

const SharedProductId = () => {
  const router = useNavigate();
  const { sectionId, productId } = useParams();
  const productIdParams = typeof productId === 'string' ? productId : '';
  const isSharedMenu = useIsTemplateMenu();
  const currentRestaurant = useAppSelector((state) => state.restaurant.restaurant);
  const currentProduct = useSharedProduct(currentRestaurant?.id || '', productIdParams);
  const menuListData = currentRestaurant?.menu_section || [];
  const restaurantMenuAdditions = useMenuAdditionsRestaurant(currentRestaurant?.id || '');
  const productSharedAdditions = useSharedAdditionsByProduct(currentRestaurant?.id || '', productIdParams);
  /*
  Router.events.on('routeChangeStart', nProgress.start);
  Router.events.on('routeChangeError', nProgress.done);
  Router.events.on('routeChangeComplete', nProgress.done); */
  nProgress.configure({ showSpinner: false });

  const menuSection = useMemo(() => {
    if (sectionId && menuListData) {
      return menuListData?.filter((item: MenuSection) => item.id === sectionId)[0];
    }
    return null;
  }, [sectionId, menuListData]);

  const onDeleteProduct = async (currentProductId: string) => {
    if (currentRestaurant) {
      await deleteSharedProduct(currentRestaurant.id, currentProductId);
      router(`/sharedMenu/${sectionId}`);
    }
  };

  const onSubmitSave = async (values: ProductFormValues, currentProductId: string) => {
    if (currentRestaurant) {
      const newValues = { ...values };
      if (!newValues.unit_price) {
        newValues.unit_price = 0;
      }
      await editSharedProduct(newValues, currentRestaurant.id, currentProductId);
    }
  };

  const onCreateMenuAdditionsRestaurant = async (values: AdditionFormValues, availability: boolean) => {
    if (currentRestaurant) {
      const menuAdditionId = admin.firestore().collection('menu_additions').doc().id;
      const newMenuAddition = new MenuAddition({
        ...values,
        availability,
        id: menuAdditionId,
        restaurant_id: currentRestaurant.id,
        order: values.options.length,
      });
      await saveMenuAdditionRestaurant(newMenuAddition, currentRestaurant.id, menuAdditionId);
    }
  };

  const onDeleteMenuAdditionRestaurant = async (menuAdditionId: string) => {
    if (currentRestaurant) {
      await deleteMenuAdditionRestaurant(currentRestaurant.id, menuAdditionId);
    }
  };

  const onAddAdditionProduct = async (prodId: string, menuAddition: MenuAddition, order: number) => {
    if (currentRestaurant) {
      const additionId = admin.firestore().collection('additions').doc().id;
      const newAddition: Addition = new Addition({
        ...menuAddition,
        id: additionId,
        menu_additions_id: menuAddition.id,
        product_id: prodId,
        order,
      });
      await addAdditionSharedProduct(currentRestaurant.id, prodId, newAddition, additionId);
    }
  };

  const onDeleteAdditionProduct = async (prodId: string, additionId:string) => {
    if (currentRestaurant) {
      await deleteAdditionSharedProduct(currentRestaurant.id, prodId, additionId);
    }
  };

  const onEditAdditionRestaurantAndProduct = async (
    values: AdditionFormValues,
    availability:boolean,
    menuAdditionId: string,
    additionId:string,
    prodId:string,
  ) => {
    if (currentRestaurant) {
      await editMenuAdditionRestaurant(
        currentRestaurant.id,
        menuAdditionId,
        {
          ...values,
          id: menuAdditionId,
          availability,
        },
      );
      await editSharedProductAdditions(
        currentRestaurant.id,
        prodId,
        additionId,
        {
          ...values,
          availability,
        },
      );
    }
  };

  const onDragEndProductsAdditions = async (prodId:string, additions: Addition[]) => {
    if (currentRestaurant) {
      await saveSharedProductsAdditions(currentRestaurant.id, prodId, additions);
    }
  };

  const onSharedWithOtherProducts = async (menuAdditionsId: string, prodId:string):Promise<number> => {
    if (currentRestaurant) {
      return sharedWithOtherProducts(currentRestaurant.id, menuAdditionsId, prodId);
    }
    return 0;
  };

  const onEditAdditionOptionsRestaurantAndProduct = async (
    swaped: AdditionOption[],
    menuAdditionsId: string,
    additionId:string,
    prodId:string,
  ) => {
    if (currentRestaurant) {
      await editMenuAdditionRestaurant(
        currentRestaurant.id,
        menuAdditionsId,
        {
          options: swaped,
        },
      );
      await editSharedProductAdditions(
        currentRestaurant.id,
        prodId,
        additionId,
        {
          options: swaped,
        },
      );
    }
  };
  const onAddGallery = async (dataGallery: Gallery):Promise<Gallery | null> => {
    if (currentRestaurant?.id) {
      await addGallery(currentRestaurant.id, dataGallery);
      return dataGallery;
    }
    return null;
  };

  const onUpdateGallery = async (dataGallery: Gallery) => {
    if (currentRestaurant?.id) {
      await updateGalleryImage(currentRestaurant.id, dataGallery);
    }
  };

  const onUploadImg = async ({
    file, docId, gallery, originalFile,
  }: UploadImgGalleryProps):Promise<boolean> => {
    if (currentRestaurant?.id && originalFile && docId) {
      const restId = currentRestaurant.id;
      const galleryRef = ref(getStorage(), `restaurant_gallery/${restId}/${docId}/${docId}`);
      const galleryOriginalRef = ref(getStorage(), `restaurant_gallery/${restId}/${docId}`);
      const urlOriginal = await uploadImgGallery(originalFile, galleryOriginalRef);
      const url = await uploadImgGallery(file, galleryRef);
      const image = await PictureModule.getPhotoUrls(restId, docId, url);
      const dataGallery = new Gallery({
        ...gallery,
        original_image: urlOriginal,
        image,
      });
      await onUpdateGallery(dataGallery);
      return true;
    }
    return false;
  };

  return (
    <>
      <ProductDetailsScreen
        isSharedMenu={isSharedMenu}
        menuSection={menuSection}
        currentProduct={currentProduct}
        onDeleteProduct={onDeleteProduct}
        onSubmitSave={onSubmitSave}
        restaurantAdditions={restaurantMenuAdditions}
        productAdditions={productSharedAdditions}
        onCreateMenuAdditionsRestaurant={onCreateMenuAdditionsRestaurant}
        onDeleteMenuAdditionRestaurant={onDeleteMenuAdditionRestaurant}
        onAddAdditionProduct={onAddAdditionProduct}
        onDeleteAdditionProduct={onDeleteAdditionProduct}
        onDragEndProductsAdditions={onDragEndProductsAdditions}
        onSharedWithOtherProducts={onSharedWithOtherProducts}
        onEditAdditionRestaurantAndProduct={onEditAdditionRestaurantAndProduct}
        onEditAdditionOptionsRestaurantAndProduct={onEditAdditionOptionsRestaurantAndProduct}
        onUploadImg={onUploadImg}
        onAddGallery={onAddGallery}
      />
    </>
  );
};

export default SharedProductId;
