import React from 'react';
import {
  Box, Grid, Typography, Button, Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { COLORS, ORDER_TYPE } from '../../../constants';
import { useAppSelector } from '../../../appHooks/redux';

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: COLORS.WHITE,
  borderColor: COLORS.LIGHT_BLUE,
  color: COLORS.BLACK,
  textTransform: 'none',
  minWidth: '100px',
  height: '65px',
  fontWeight: 'bold',
  fontSize: '18px',
  '&:hover': {
    backgroundColor: COLORS.LIGHT_BLUE,
    borderColor: COLORS.CORNFLOWER_BLUE,
    color: COLORS.WHITE,
  },
  [theme.breakpoints.down('xs')]: {
    width: '40%',
    marginRight: '3px',
    marginLeft: '3px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '40%',
    marginRight: '3px',
    marginLeft: '3px',
  },
}));

const CustomLink = styled(Link)(() => ({
  color: COLORS.BLACK,
  textDecoration: 'none',
  '&:hover': {
    color: COLORS.WHITE,
  },
}));

const buttonSelectedStyle = {
  backgroundColor: COLORS.LIGHT_BLUE,
  borderColor: COLORS.CORNFLOWER_BLUE,
  color: COLORS.WHITE,
};
interface TypeOfDeliveryProps {
  handleSelect: (value: string) => void,
}

export const TypeOfDelivery = ({ handleSelect }: TypeOfDeliveryProps) => {
  const currentOrder = useAppSelector(
    (state) => state.shoppingCart.currentOrder,
  );
  return (
    <Box sx={{ padding: 4 }}>
      <Box textAlign="right" sx={{ padding: 3 }}>
        <Button id="optionBackBtn" onClick={() => handleSelect('main')}>
          <KeyboardBackspaceIcon fontSize="large" color="action" />
        </Button>
      </Box>
      <Box textAlign="center" sx={{ padding: 4 }}>
        <Typography fontWeight="bold" variant="h3" sx={{ padding: 3 }}>
          Escoja el tipo de delivery que requiere
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center', paddingTop: 6 }}>
        <Grid container spacing={12} justifyContent="center">
          <Grid item xs={12} container spacing={3} justifyContent="space-evenly">
            <Grid item xs={8} sm={5}>
              <CustomLink
                href="https://xpress-motos-dev.web.app/login"
                target="_blank"
              >
                <CustomButton
                  id="merchantBtn"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  style={
                  currentOrder?.order_type === ORDER_TYPE.DELIVERY_ENTREGAS
                    ? buttonSelectedStyle
                    : {}
                  }
                  onClick={() => {
                    handleSelect(ORDER_TYPE.DELIVERY_ENTREGAS);
                  }}
                >
                  Entregas.com (Pedir una Moto)
                </CustomButton>
              </CustomLink>
            </Grid>
            <Grid item xs={8} sm={5}>
              <CustomButton
                id="otherDeliveryBtn"
                fullWidth
                style={
                currentOrder?.order_type === ORDER_TYPE.DELIVERY_OTHER
                  ? buttonSelectedStyle
                  : {}
              }
                variant="outlined"
                onClick={() => {
                  handleSelect(ORDER_TYPE.DELIVERY_OTHER);
                }}
              >
                Otro Delivery
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
