/* eslint-disable no-unsafe-optional-chaining */
import React, { useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from '@mui/material/styles';
import { OnlyIconButton } from '../../../button';
import {
  SelectedProduct,
  OrderAdditionSelected,
  Restaurant,
  deleteOrder,
  useValidateEnableInventory, setOrder, Order, Addition,
} from '../../../../core';

import { ConfirmModal } from '../../../modal';
import {
  incrementQuantityForProduct,
  decrementQuantityForProduct,
  deleteProduct,
  cleanAllOrder,
  setSelectProduct,
  setSelectAdditions,
  cleanSelect,
  cleanTransactionFailedProducts, setOrderProduct,
} from '../../../../redux/reducers/shoppingCart';
import { useAppDispatch, useAppSelector } from '../../../../appHooks/redux';

import { deleteOrderProduct, deleteProductTransaction } from '../../../../core/orderProduct';
import { ROUTES } from '../../../../constants';
import { ProductsStock } from '../../../../redux/reducers/stock';
import store, { RootState } from '../../../../redux/store';
import { VerifyAdditionsModal } from '../../../modal/verifyAdditionsModal';
import { verifyAdditions } from '../../../../utils/orderUtils';

const CustomIconButton = styled(OnlyIconButton)`
  background-color: #f3b9bf;
  &:hover {
    background-color: #ef9fa8;
  }
`;

const CustomTypographyHead = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
`;

const CustomTableCellBody = styled(TableCell)`
  max-width: 120px;
  min-width: 100px;
`;

const CustomCellAddition = styled(TableCell)`
  padding-block: 0px;
`;

type OrderListProps = {
  additionsForProduct: OrderAdditionSelected[] | null;
  allAdditionsByProduct: Addition[];
  products: SelectedProduct[] | null;
  realtimeRestaurant?: Restaurant | null;
  restaurantId: string;
  productsStock: ProductsStock;
  subsidiaryId: string;
  selectedProduct: SelectedProduct | null;
};

export const OrderList = ({
  additionsForProduct,
  allAdditionsByProduct,
  products,
  realtimeRestaurant,
  restaurantId,
  productsStock,
  selectedProduct,
  subsidiaryId,
}: OrderListProps) => {
  const dispatch = useAppDispatch();
  const failedProducts = useAppSelector(
    (state) => state.shoppingCart.transactionFailedProducts,
  );
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const restaurant = useAppSelector((state) => state.restaurant.restaurant);
  const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  const router = useNavigate();
  const { value: lastOpenjourneyProduction } = useAppSelector((state) => state.inventory.journeyProduction);
  const journeyProductionProductsSet = useAppSelector((state) => state.inventory.journeyProductionProductsSet);
  const order = useAppSelector((state) => state.shoppingCart.currentOrder);
  const selectAdditions = useAppSelector((state) => state.shoppingCart.selectAdditions);
  const [verifyAdditionsModal, setVerifyAdditionsModal] = useState(false);
  const [missingAdditionsList, setMissingAdditionsList] = useState<string[]>([]);
  const [productSelectToEdit, setProductSelectToEdit] = useState<{product:SelectedProduct | null, additions: OrderAdditionSelected[] }>({
    additions: [],
    product: null,
  });
  const enableInventory = useValidateEnableInventory();
  const parseProducts = useMemo(() => {
    const additionsByProduct: any = {};
    if (additionsForProduct && additionsForProduct.length > 0) {
      additionsForProduct.forEach((addition: OrderAdditionSelected) => {
        const { product_index } = addition;
        if (additionsByProduct[product_index]) {
          additionsByProduct[product_index].push(addition);
        } else {
          additionsByProduct[product_index] = [];
          additionsByProduct[product_index].push(addition);
        }
      });
    }
    if (products && products.length > 0) {
      return products.map((product: SelectedProduct) => ({
        product: {
          ...product,
          failed: failedProducts[product.id],
          restaurant_id: restaurantId,
          subsidiary_id: subsidiaryId,
        } as SelectedProduct,
        additions: additionsByProduct[product.index],
      }));
    }
    return null;
  }, [additionsForProduct, products, failedProducts]);

  const handleIncrementProduct = (product: SelectedProduct) => {
    //     dispatch(setSelectProduct(new SelectedProduct({ ...selectProduct, quantity: newQuantity })));
    dispatch(incrementQuantityForProduct(product));
  };
  const handleDecrementProduct = (product: SelectedProduct) => {
    dispatch(decrementQuantityForProduct(product));
  };
  const disableButtons = (product: SelectedProduct) => {
    if (product && realtimeRestaurant?.enable_inventory) {
      if (!enableInventory && !realtimeRestaurant?.enable_inventory) {
        return false;
      }
      if (product.index === selectedProduct?.index) {
        return true;
      }
      if (productsStock[product.id] < 1) {
        return true;
      }
      return false;
    }
    if (product.index === selectedProduct?.index) {
      return true;
    }
    return false;
  };

  const handleDeleteProduct = async (product: SelectedProduct) => {
    if (order && parseProducts && parseProducts?.length > 1 && restaurant && subsidiary && lastOpenjourneyProduction && product) {
      dispatch(deleteProduct(product));
      dispatch(cleanSelect());
      const isExecuted = await deleteProductTransaction(restaurant?.id, subsidiary?.id, lastOpenjourneyProduction?.id, journeyProductionProductsSet, [[product.id, product.quantity]]);
      if (isExecuted) {
        await Promise.all([
          deleteOrderProduct({
            restaurantId: restaurant.id,
            subsidiaryId: subsidiary.id,
            orderId: order.id,
            orderProductId: product.order_product_id,
          }),
          setOrder({
            restaurantId: restaurant.id,
            subsidiaryId: subsidiary.id,
            order: new Order({ ...order, count_of_products: order.count_of_products - 1 }),
          }),
        ]);
      }
      const orderProductUpdated = (store.getState() as RootState).shoppingCart.orderProduct?.filter((item) => item.id !== product.order_product_id);
      dispatch(setOrderProduct(orderProductUpdated));
    } else {
      setConfirmModal(true);
    }
  };
  // color: '#A7B8E7'

  const handleCloseModal = () => setConfirmModal(false);
  const handleConfirmModal = async () => {
    const [lastProduct = null] = parseProducts || [];

    if (
      !enableInventory
      && realtimeRestaurant?.enable_inventory
      && lastProduct
      && restaurant
      && subsidiary
      && lastOpenjourneyProduction
    ) {
      await deleteProductTransaction(restaurant?.id, subsidiary?.id, lastOpenjourneyProduction?.id, journeyProductionProductsSet, [[lastProduct.product.id, lastProduct.product.quantity]]);
    }
    if (restaurant && subsidiary && order) {
      dispatch(cleanTransactionFailedProducts());
      await deleteOrder(order.id || '', restaurant.id, subsidiary.id);
      dispatch(cleanAllOrder());
      setConfirmModal(false);
      dispatch(cleanSelect());
    }
    router(ROUTES.COMMAND_PANEL);
  };

  const handleSelectItem = (product: any, additions: any = []) => {
    const listMissingAdditions = verifyAdditions(allAdditionsByProduct, selectAdditions);
    if (listMissingAdditions.length > 0) {
      setProductSelectToEdit({ product, additions });
      setMissingAdditionsList(listMissingAdditions);
      setVerifyAdditionsModal(true);
    } else {
      const selectProduct = new SelectedProduct({ ...product, isEditing: true });
      dispatch(setSelectProduct(selectProduct));
      dispatch(setSelectAdditions(additions));
      router('/menu');
    }
  };

  const deleteCurrentProduct = () => {
    if (selectedProduct) dispatch(deleteProduct(selectedProduct));
    dispatch(cleanSelect());
    const { product, additions } = productSelectToEdit;
    if (product) {
      const selectProduct = new SelectedProduct({ ...product, isEditing: true });
      dispatch(setSelectProduct(selectProduct));
      dispatch(setSelectAdditions(additions));
      router('/menu');
    }
    setVerifyAdditionsModal(false);
  };

  return (
    <>
      <TableContainer>
        <ConfirmModal
          open={confirmModal}
          onClose={handleCloseModal}
          onConfirm={handleConfirmModal}
          title="Esta seguro que desea eliminar este producto? La orden sera eliminada"
        />
        <Table>
          <TableHead>
            <TableRow
              style={{ padding: '0px !important', height: '0px !important' }}
              key="header"
            >
              <TableCell>
                <CustomTypographyHead>Nombre</CustomTypographyHead>
              </TableCell>
              <TableCell align="center">
                <CustomTypographyHead>Cantidad</CustomTypographyHead>
              </TableCell>
              <TableCell align="center">
                <CustomTypographyHead>Precio</CustomTypographyHead>
              </TableCell>
              <TableCell align="center">
                <CustomTypographyHead>Total</CustomTypographyHead>
              </TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {parseProducts
            && parseProducts.map((productRow) => {
              const { product, additions } = productRow;
              const total = product.unit_price * product.quantity;
              return (
                <>
                  <TableRow
                    key={product.id}
                    sx={
                      selectedProduct
                        ? {
                          backgroundColor:
                              product.index === selectedProduct.index
                                ? '#A7B8E7'
                                : '',
                        }
                        : {
                          backgroundColor: product.failed ? '#EF9FA8' : '',
                        }
                    }
                  >
                    <CustomTableCellBody
                      onClick={(e) => {
                        e.preventDefault();
                        handleSelectItem(product, additions);
                      }}
                      style={
                        additions?.length > 0
                          ? {
                            borderTop: '1px solid rgba(224, 224, 224, 1)',
                            borderBottom: '0px',
                            cursor: 'pointer',
                          }
                          : {
                            cursor: 'pointer',
                          }
                      }
                    >
                      <Typography fontWeight="bold">{product.name}</Typography>
                    </CustomTableCellBody>
                    <TableCell
                      align="center"
                      style={
                        additions?.length > 0
                          ? {
                            borderTop: '1px solid rgba(224, 224, 224, 1)',
                            borderBottom: '0px',
                          }
                          : {}
                      }
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <CustomIconButton
                          disabled={product.quantity === 1 || product.index === selectedProduct?.index}
                          onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDecrementProduct(product);
                          }}
                        >
                          <RemoveIcon />
                        </CustomIconButton>
                        <Typography fontWeight="bold">
                          {product.quantity}
                        </Typography>
                        <CustomIconButton
                          onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleIncrementProduct(product);
                          }}
                          disabled={disableButtons(product)}
                        >
                          <AddIcon />
                        </CustomIconButton>
                      </Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={
                        additions?.length > 0
                          ? {
                            borderTop: '1px solid rgba(224, 224, 224, 1)',
                            borderBottom: '0px',
                          }
                          : {}
                      }
                    >
                      <Typography fontWeight="bold">{`Bs. ${product.unit_price}`}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={
                        additions?.length > 0
                          ? {
                            borderTop: '1px solid rgba(224, 224, 224, 1)',
                            borderBottom: '0px',
                          }
                          : {}
                      }
                    >
                      <Typography fontWeight="bold">{`Bs. ${total}`}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={
                        additions?.length > 0
                          ? {
                            borderTop: '1px solid rgba(224, 224, 224, 1)',
                            borderBottom: '0px',
                          }
                          : {}
                      }
                    >
                      <IconButton
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteProduct(product);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {additions?.length > 0
                    && additions.map((addition: OrderAdditionSelected) => {
                      const additionTotal = addition.quantity * addition.price;
                      return (
                        <>
                          <TableRow
                            key={addition.id}
                            sx={
                      selectedProduct
                        ? {
                          backgroundColor:
                              product.index === selectedProduct.index
                                ? '#A7B8E7'
                                : '',
                        }
                        : {
                          backgroundColor: product.failed ? '#EF9FA8' : '',
                        }
                    }
                          >
                            <CustomCellAddition>
                              <Typography fontSize="14px">
                                {`+ Extras: ${addition.name}`}
                              </Typography>
                            </CustomCellAddition>
                            <CustomCellAddition align="center">
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Typography fontWeight="bold">
                                  {addition.quantity}
                                </Typography>
                              </Box>
                            </CustomCellAddition>
                            <CustomCellAddition align="center">
                              <Typography fontSize="14px">{`Bs. ${addition.price}`}</Typography>
                            </CustomCellAddition>
                            <CustomCellAddition align="center">
                              <Typography fontSize="14px">{`Bs. ${additionTotal}`}</Typography>
                            </CustomCellAddition>
                            <CustomCellAddition align="center" />
                          </TableRow>
                        </>
                      );
                    })}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <VerifyAdditionsModal
        open={verifyAdditionsModal}
        title={`Si continúa perderá el producto ${selectedProduct ? selectedProduct.name : ''}`}
        okText="Continuar"
        onConfirm={() => {
          deleteCurrentProduct();
        }}
        cancelText="Cancelar"
        onClose={() => { setVerifyAdditionsModal(false); }}
        missingAdditions={missingAdditionsList}
      />
    </>
  );
};
