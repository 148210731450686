import React, { useState, useMemo, useEffect } from 'react';
import {
  Box, Button, MenuItem, TextField,
} from '@mui/material';
import moment from 'moment-timezone';
import { City, Subsidiary } from '../../../core';
import { Nullable } from '../../../types';
import { TYPE_ORDERS_FILTERED, MONTHS_FILTERED, STATUS_PAYMENT } from '../../../constants/index';
import { DataBodyReqProps } from '../../../pages/report';
import { useAppSelector } from '../../../appHooks/redux';

type FiltersGroupProps = {
  allCities: Nullable<City[]>,
  allSubsidiaries: Nullable<Subsidiary[]>,
  handleSetData: (value: DataBodyReqProps) => void,
}

export const FiltersGroup = ({
  allCities, allSubsidiaries, handleSetData,
}: FiltersGroupProps) => {
  const currentRestaurant = useAppSelector((state) => state.restaurant.restaurant);
  const [city, setCity] = useState('all');
  const [subsidiary, setSubsidiary] = useState('all');
  const [typeOrders, setTypeOrders] = useState('ALL');
  const [statusPayment, setStatusPayment] = useState('all');
  const [month, setMonth] = useState(`${new Date().getMonth() + 1 <= 9 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}`);
  const [year, setYear] = useState(`${new Date().getFullYear()}`);

  const cities = useMemo(() => {
    if (allCities) {
      const filterCities = allCities.filter((value) => value.enable === true);
      filterCities.unshift(new City({
        enable: true, id: 'all', name: 'Todas las ciudades', is_default: true,
      }));
      return filterCities;
    }
    return null;
  }, [allCities]);

  const subsidiaries = useMemo(() => {
    if (allSubsidiaries) {
      allSubsidiaries.unshift(new Subsidiary({ name: 'Todas las sucursales', id: 'all' }));
      return allSubsidiaries;
    }
    return null;
  }, [allSubsidiaries]);

  const getYears = () => {
    const arr = [];
    const currentYear = moment().format('YYYY');
    for (let i = 2020; i <= Number(currentYear) * 1; i += 1) {
      arr.push({ name: `${i}`, id: `${i}` });
    }
    return arr;
  };

  const years = getYears();

  useEffect(() => {
    if (currentRestaurant?.id) {
      const value = {
        city,
        restaurant: currentRestaurant?.id,
        subsidiary,
        year,
        month,
        promo: 'all',
        statusOrder: typeOrders,
        statusPayment,
      };
      handleSetData(value);
    }
  }, [city,
    subsidiary,
    typeOrders,
    month,
    year,
    statusPayment]);

  const handleResetFilters = () => {
    setCity('all');
    setSubsidiary('all');
    setTypeOrders('COMPLETED');
    setStatusPayment('all');
    setMonth(`${new Date().getMonth() + 1 <= 9 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}`);
    setYear(`${new Date().getFullYear()}`);
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        onClick={handleResetFilters}
        variant="contained"
      >
        Reset
      </Button>
      <Box width="10%" ml={2}>
        <TextField
          label="Ciudad"
          select
          name="city"
          onChange={(e) => setCity(e.target.value)}
          value={city}
          variant="standard"
          fullWidth
          placeholder="Seleccione una ciudad"
        >
          {cities?.map((option) => (
            <MenuItem id={option.id} key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box width="10%" ml={2}>
        <TextField
          label="Sucursal"
          select
          name="subsidiary"
          onChange={(e) => setSubsidiary(e.target.value)}
          value={subsidiary}
          variant="standard"
          fullWidth
          placeholder="Seleccione una sucursal"
        >
          {subsidiaries?.map((option) => (
            <MenuItem id={option.id} key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box width="10%" ml={2}>
        <TextField
          label="Ordenes"
          select
          name="Orders"
          onChange={(e) => setTypeOrders(e.target.value)}
          value={typeOrders}
          variant="standard"
          fullWidth
          placeholder="Seleccione un tipo de pedido"
        >
          {TYPE_ORDERS_FILTERED?.map((option) => (
            <MenuItem id={option.id} key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box width="10%" ml={2}>
        <TextField
          label="Pago"
          select
          name="statusPayment"
          onChange={(e) => setStatusPayment(e.target.value)}
          value={statusPayment}
          variant="standard"
          fullWidth
          placeholder="Seleccione un tipo de pedido"
        >
          {STATUS_PAYMENT?.map((option) => (
            <MenuItem id={option.id} key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box width="10%" ml={2}>
        <TextField
          label="Año"
          select
          name="year"
          onChange={(e) => setYear(e.target.value)}
          value={year}
          variant="standard"
          fullWidth
          placeholder="Seleccione un tipo de pedido"
        >
          {years?.map((option) => (
            <MenuItem id={option.id} key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box width="10%" ml={2}>
        <TextField
          label="Meses"
          select
          name="Month"
          onChange={(e) => setMonth(e.target.value)}
          value={month}
          variant="standard"
          fullWidth
          placeholder="Seleccione un mes"
        >
          {MONTHS_FILTERED?.map((option) => (
            <MenuItem id={option.id} key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Box>
  );
};
