import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import theme from './theme';
import store, { persistor, rrfProps } from './redux/store';
import { InitApp, InitFirebase } from './providers/appProviders';
import AppRoutes from './routes';
import { AppInventoryProvider } from './providers/appInventoryProvider';
import { CommonFirestoreListenersProvider } from './providers/commonFirestoreListenersProvider';
import { LoadingBackdropProvider } from './providers/loadingBackdropProvider';

const App = () => (
  <InitFirebase>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider
          maxSnack={3}
          hideIconVariant
          autoHideDuration={1000}
        >
          <ThemeProvider theme={theme}>
            <InitApp>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <ReactReduxFirebaseProvider {...rrfProps}>
                <CommonFirestoreListenersProvider />
                <AppInventoryProvider />
                <LoadingBackdropProvider>
                  <AppRoutes />
                </LoadingBackdropProvider>
              </ReactReduxFirebaseProvider>
            </InitApp>
          </ThemeProvider>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  </InitFirebase>
);

export default App;
