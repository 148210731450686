import React, { useState } from 'react';
import {
  Box, TextField, Typography, styled, Icon,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Product, useClickOutsideSearchProduct } from '../../core';

const ContainerSearchBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SearchBox = styled(Box)`
  width: 96%;
`;

const ContainerIcon = styled(Box)`
  display: flex;
  justify-content: center;
  width: 4%;
`;

const BoxContainerCustom = styled(Box)`
  border-radius: 50%;
  width: auto;
  margin: 20px 0px 20px 20px;
  position: relative;
  z-index: 1;
`;

const BoxContainerItems = styled(Box)`
  display: flex;
  flex-direction: column;
  align-self: center;
  position: absolute;
  background-color: white;
  width: 100%;
`;

const BoxCustom = styled(Box)`
  border-left: solid 1px #c4c4c4;
  border-right: solid 1px #c4c4c4;
  border-bottom: solid 1px #c4c4c4;
  cursor: pointer;
  padding: 5px 12px;
  margin-top: -1px;
  &:hover {
    background-color: #EAEAEA;
  }
`;

type SearchProductProps = {
handleSearch: (value: string) => void;
filterProductSearch?: Product[] | null;
search?: string | null;
handleChangeMenu: (value: string, isSearch?: boolean, product?: Product) => void;
enableBarcode?: boolean;
}

export const SearchProduct = ({
  handleSearch, filterProductSearch, search, handleChangeMenu, enableBarcode = false,
}: SearchProductProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const domNode = useClickOutsideSearchProduct(() => setOpen(false));

  const handleClick = (value: string, product: Product) => {
    handleChangeMenu(product.menu_section_id, true, product);
    const name = value.split('-');
    handleSearch(name[1]);
    setOpen(false);
  };

  return (
    <ContainerSearchBox ref={domNode}>
      <SearchBox width="100%">
        <BoxContainerCustom>
          <TextField
            id="search-bar"
            className="text"
            onChange={(event) => handleSearch(event.target.value)}
            onClick={() => setOpen(true)}
            label="Buscar Producto"
            variant="outlined"
            placeholder="Buscar Producto"
            size="small"
            autoComplete="off"
            value={search}
            disabled={enableBarcode}
            fullWidth
          />
          {open && (
          <BoxContainerItems>
            {search && filterProductSearch && filterProductSearch.length > 0 && filterProductSearch.map((product) => (
              <BoxCustom id={`${product.id}-${product.name}`} onClick={(e) => handleClick(e.currentTarget.id, product)}>
                <Typography variant="body2">
                  {product.name}
                </Typography>
              </BoxCustom>
            ))}
          </BoxContainerItems>
          )}
        </BoxContainerCustom>
      </SearchBox>
      <ContainerIcon display="flex" justifyContent="center" width="4%">
        <Icon>
          <SearchIcon sx={{ height: '30px', width: '30px' }} />
        </Icon>
      </ContainerIcon>
    </ContainerSearchBox>
  );
};
