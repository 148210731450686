import React, { useState, useEffect, useRef } from 'react';
import {
  Button, Box, TextField, Grid, Divider, CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { COLORS } from '../../constants';
import { NumberKeyboardButtons } from '../index';
import { useAppSelector, useAppDispatch } from '../../appHooks/redux';
import { setCurrentOrder } from '../../redux/reducers/shoppingCart';
import { Order } from '../../core';

const CustomTextField = styled(TextField)(() => ({
  background: COLORS.WHITE,
  borderRadius: '4px',
  marginBottom: 10,
  '& .MuiOutlinedInput-root.Mui-disabled ': {
    color: COLORS.BLACK,
    border: '0.386901px solid #575657',
    WebkitTextFillColor: '#000',
  },
  '& input.Mui-disabled': {
    WebkitTextFillColor: '#000',
  },
}));
const OkButton = styled(Button)(() => ({
  backgroundColor: COLORS.BLUE_LIGHT_92,
  border: `1px solid ${COLORS.ROYAL_BLUE_DARK_52}`,
  borderRadius: '15px',
  color: COLORS.BLACK,
  textTransform: 'none',
  minHeight: '65px',
  fontWeight: 'bold',
  fontSize: '18px',
  width: '100%',
  '&:hover': {
    backgroundColor: COLORS.BLUE_LIGHT_92,
    borderColor: COLORS.CORNFLOWER_BLUE,
  },
}));
const CancelButton = styled(Button)(() => ({
  backgroundColor: COLORS.RED_LIGHT_96,
  border: `1px solid ${COLORS.RED_LIGHT_60}`,
  borderRadius: '15px',
  color: COLORS.BLACK,
  textTransform: 'none',
  minHeight: '65px',
  fontWeight: 'bold',
  fontSize: '18px',
  width: '100%',
  '&:hover': {
    backgroundColor: COLORS.RED_LIGHT_96,
    borderColor: COLORS.RED_LIGHT_60,
  },
}));
const LineDivider = styled(Divider)`
  margin-top:15px;
  margin-bottom:20px;
`;
type PaymentOptionsProps = {
  onBack: () => void;
  loading?:boolean
  onCancel:()=>void,
  onConfirm:(value:string)=>void
};
export const CashKeyboard = ({
  onBack,
  loading,
  onCancel,
  onConfirm,
}: PaymentOptionsProps) => {
  const dispatch = useAppDispatch();
  const currentOrder = useAppSelector((state) => state.shoppingCart.currentOrder);
  const buttonConfirmRef = useRef<HTMLButtonElement>(null);
  const buttonCanceleRef = useRef<HTMLButtonElement>(null);
  const [value, setValue] = useState('');
  useEffect(() => {
    if (currentOrder) {
      const amount_paid = Number(value);
      const balance = Number(value) - currentOrder.total;
      dispatch(setCurrentOrder(new Order({ ...currentOrder, balance, amount_paid })));
    }
  }, [value]);
  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (buttonConfirmRef.current) {
        const { key } = event;
        if (key === 'Enter') {
          buttonConfirmRef.current.click();
        } else if (key === 'Escape' && buttonCanceleRef.current) {
          buttonCanceleRef.current.click();
        }
      }
    });
  }, []);

  return (
    <Box p={2}>
      <Box textAlign="left" p={2}>
        <Button onClick={onBack}>
          <KeyboardBackspaceIcon fontSize="large" color="action" />
        </Button>
      </Box>
      <Box textAlign="center" p={2}>
        <CustomTextField
          disabled
          id="reasonDiscountInput"
          variant="outlined"
          fullWidth
          type="text"
          size="small"
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
        <NumberKeyboardButtons onChange={setValue} />
        <LineDivider />
        <Grid container xs={12} spacing={1} style={{ marginTop: 10 }}>
          {!loading && (
          <>
            <Grid item xs={4}><CancelButton onClick={() => onCancel()} ref={buttonCanceleRef}>Cancelar</CancelButton></Grid>
            <Grid item xs={4} />
            <Grid item xs={4}><OkButton onClick={() => onConfirm(value)} ref={buttonConfirmRef}>Ok</OkButton></Grid>
          </>
          )}
          {loading && <Grid item xs={12} justifyContent="center"><CircularProgress /></Grid>}
        </Grid>
      </Box>
    </Box>
  );
};
