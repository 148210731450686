// eslint-disable-next-line max-classes-per-file
import { FirebaseBase } from '../firebaseBase';
import {
  Constructor,
  PlainObject,
} from '../../types';
import { OrderAdditionSelected } from '../additions';

export class OrderProduct extends FirebaseBase {
  product_id : string;

  quantity : number;

  unit_price : number;

  total : number;

  additional_requirement : string;

  name : string;

  cooking_time : number;

  selected_options : PlainObject<OrderAdditionSelected> [];

  availability : boolean;

  order_id:string;

  is_discounted_inventory : boolean;

  index:string;

  constructor({
    order_id = '',
    product_id = '',
    quantity = 0,
    unit_price = 0,
    total = 0,
    additional_requirement = '',
    name = '',
    cooking_time = 10,
    selected_options = [],
    index = '',
    availability = true,
    is_discounted_inventory = false,
    ...data
  }:Constructor<OrderProduct>) {
    super(data);
    this.product_id = product_id;
    this.quantity = quantity;
    this.unit_price = (unit_price);
    this.selected_options = selected_options.map((option) => new OrderAdditionSelected(option).toPlainObject());
    this.additional_requirement = additional_requirement;
    this.availability = availability;
    this.name = name;
    this.cooking_time = cooking_time;
    this.total = total;
    this.index = index;
    this.order_id = order_id;
    this.is_discounted_inventory = is_discounted_inventory;
  }

  toPlainObject(): PlainObject<OrderProduct> {
    return {
      ...this,
    };
  }
}

// export type SelectedProduct= Product & {quantity:number};
