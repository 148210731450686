import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { COLLECTIONS } from '../../constants';
import { Nullable } from '../../types';
import { User } from '../user';
import { Profile } from './profile.dto';

export const useRestaurantProfiles = (
  restaurant_id: string,
): Nullable<Profile[]> => {
  useFirestoreConnect(
    [{
      collection: COLLECTIONS.RESTAURANT,
      doc: restaurant_id,
      subcollections: [{
        collection: COLLECTIONS.RESTAURANT_PROFILES,
      },
      ],
      storeAs: 'restaurants_profiles',
    }],
  );

  const products = useSelector(
    (state:any) => state.firestore.ordered.restaurants_profiles,
  );

  if (products) {
    return products.map((product:Profile) => new Profile({ ...product }));
  }

  return null;
};

export const useSubsidiaryProfiles = (restaurantId:string) => {
  useFirestoreConnect(
    [{
      collectionGroup: COLLECTIONS.PROFILES,
      where: ['restaurant_id', '==', restaurantId],
      storeAs: 'subsidiaries_profiles',
    }],
  );

  const products = useSelector(
    (state:any) => state.firestore.ordered.subsidiaries_profiles,
  );

  if (products) {
    return products.map((product:Profile) => new Profile({ ...product }));
  }

  return null;
};

export const useSubsidiaryUser = (subsidiaryId:string) => {
  useFirestoreConnect(
    [{
      collection: COLLECTIONS.USER,
      where: ['subsidiary_access.subsidiary_id', '==', subsidiaryId],
      storeAs: 'subsidiaries_users',
      limit: 1,
    }],
  );

  const users = useSelector(
    (state:any) => state.firestore.ordered.subsidiaries_users,
  );
  if (users) {
    return new User({ ...users[0] });
  }

  return null;
};
