import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'firebase/auth';
import { createTransform } from 'redux-persist';

type SessionState = {currentUser:User | null}

const initialState: SessionState = { currentUser: null };

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state:Draft<SessionState>, action:PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    closeSession: (state:Draft<SessionState>) => {
      state.currentUser = null;
    },
  },
});

export const { setSession, closeSession } = sessionSlice.actions;
export default sessionSlice.reducer;
export const authReducerTransform = createTransform(
  (toSave:any) => ({
    ...toSave,
  }),
  (toRehydrated) => ({
    ...toRehydrated,
  }),
  { whitelist: ['session'] },
);
