// @ts-ignore
import React from 'react';
import {
  Avatar, Divider, Drawer, Box, Stack, Typography, List,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import {
  AccountCircleOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AccordeonListItem } from './accordeonListItem';
import { SimpleListItem } from './simpleListItem';
import { navItemsGeneral } from './roleNavBar';
import { useAppSelector, useAppDispatch } from '../../../appHooks/redux';
import { TITLES_NAVBAR } from '../../../constants';
import { logout } from '../../../config/firebaseService';
import { closeSession } from '../../../redux/reducers/auth';
import { cleanProfiles, cleanSelectedProfile } from '../../../redux/reducers/profile';
import { cleanRestaurant } from '../../../redux/reducers/restaurant';
import { cleanSubsidiary } from '../../../redux/reducers/subsidiary';
import { cleanAllOrder } from '../../../redux/reducers/shoppingCart';
import { cleanUser } from '../../../redux/reducers/user';

type NavbarProps = {
  isDrawerOpen: boolean;
  onDrawerClose: () => void;
}

const DrawerBox = styled(Box)`
  width: 400px;
  background-color: #D0D8EC;
  height: inherit;
`;

const DividerCustom = styled(Divider)`
  border-color: #9F9D9F;
`;

export const NavBar = ({ isDrawerOpen, onDrawerClose }:NavbarProps) => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const restaurant = useAppSelector((store) => store.restaurant.restaurant);
  const subsidiary = useAppSelector((store) => store.subsidiary.subsidiary);
  const handleLogOut = async () => {
    try {
      await logout();
      await router('/login');
      dispatch(cleanUser());
      dispatch(closeSession());
      dispatch(cleanProfiles());
      dispatch(cleanSelectedProfile());
      dispatch(cleanRestaurant());
      dispatch(cleanSubsidiary());
      dispatch(cleanAllOrder());
      enqueueSnackbar('Se cerro la sesión', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error al cerrar sesión', { variant: 'error' });
    }
  };
  return (
    <Drawer
      anchor="left"
      open={isDrawerOpen}
      onClose={onDrawerClose}
    >
      <DrawerBox
        onClick={onDrawerClose}
        onKeyDown={onDrawerClose}
      >
        <Stack direction="column" spacing={1} sx={{ backgroundColor: '#D0D8EC' }}>
          <Stack direction="row" spacing={2} p={3} pb={2}>
            <Avatar
              alt="subsidiary-logo"
              sx={{ width: 62, height: 62 }}
              src={restaurant?.logo_photo?.original}
            />
            <Stack direction="column" spacing={1} justifyContent="center">
              <Typography variant="h3">
                <strong>{restaurant?.name}</strong>
              </Typography>
              <Typography variant="body2">{subsidiary?.name}</Typography>
            </Stack>
          </Stack>
          <DividerCustom />
          <List>
            {navItemsGeneral?.map((navItem) => {
              if (navItem.title === TITLES_NAVBAR.INVENTORY && !restaurant?.enable_inventory) return (<></>);
              if (navItem?.items && navItem?.items.length > 0) {
                return (
                  <AccordeonListItem
                    key={`key-${navItem.title}`}
                    icon={navItem.icon}
                    text={navItem.title}
                    items={navItem.items}
                  />
                );
              }
              return (
                <SimpleListItem
                  key={`key-${navItem.title}`}
                  icon={navItem.icon}
                  text={navItem.title}
                  href={navItem.href}
                />
              );
            })}
            <SimpleListItem
              key="logout"
              icon={AccountCircleOutlined}
              text={TITLES_NAVBAR.LOG_OUT}
              onClick={handleLogOut}
            />
          </List>
        </Stack>
      </DrawerBox>
    </Drawer>
  );
};
