import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Typography,
  Avatar,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { OnlyIconButton } from '../../button';
import { NavBar } from '../navBar';
import { useAppDispatch, useAppSelector } from '../../../appHooks/redux';
import { cleanSelectedProfile } from '../../../redux/reducers/profile';
import { getInitials } from '../../../utils/commonUtils';
import { ConfirmModal } from '../../modal';
import {
  cleanAdditions,
  cleanCurrentOrder,
  cleanProduct,
  cleanAllOrder,
  cleanSelect,
  cleanTransactionFailedProducts,
  setOrderProduct,
} from '../../../redux/reducers/shoppingCart';
import { Logo } from '../../../assets';

const CustomizedAppBar = styled(AppBar)`
  background-color: #ffffff;
  color: black;
  padding-left: 15px;
`;

const CustomizedToolBar = styled(Toolbar)`
  padding: 0px !important;
  justify-content: space-between;
`;

const CustomizeDragHandleIcon = styled(DragHandleIcon)`
  width: 100%;
  height: 100%;
  color: black;
`;

const CustomContainerButton = styled(Box)(({ theme }) => ({
  marginLeft: '16px',
  [theme.breakpoints.down('md')]: {
    marginLeft: '10px',
  },
}));

const CustomContainerLogo = styled(Box)(({ theme }) => ({
  width: '55px',
  marginLeft: '16px',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0px',
  },
}));

const CustomContainerNewOrder = styled(Box)(({ theme }) => ({
  marginLeft: '24px',
  [theme.breakpoints.down('md')]: {
    marginLeft: '16px',
  },
}));

export const TopNavBar = () => {
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const restaurant = useAppSelector((store) => store.restaurant.restaurant);
  const selectProfile = useAppSelector((store) => store.profiles.selectProfile);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleClose = () => setIsDrawerOpen(false);
  const handleOpen = () => setIsDrawerOpen(true);
  return (
    <CustomizedAppBar position="static" elevation={1}>
      <NavBar isDrawerOpen={isDrawerOpen} onDrawerClose={handleClose} />
      <CustomizedToolBar>
        <Grid display="flex" alignItems="center">
          {selectProfile && (
            <Box width="70px" height="100%">
              <OnlyIconButton onClick={handleOpen}>
                <CustomizeDragHandleIcon />
              </OnlyIconButton>
            </Box>
          )}
          <CustomContainerLogo>
            <img src={Logo} alt="logo" width="50px" height="40px" />
          </CustomContainerLogo>
          {selectProfile && (
            <CustomContainerNewOrder>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(cleanProduct());
                  dispatch(cleanAdditions());
                  dispatch(cleanCurrentOrder());
                  dispatch(cleanCurrentOrder());
                  dispatch(cleanSelect());
                  dispatch(cleanTransactionFailedProducts());
                  dispatch(setOrderProduct([]));
                  router('/commandPanel');
                }}
              >
                <Typography
                  textTransform="initial"
                  color="black"
                  fontWeight="bold"
                  variant="body1"
                  fontSize={{
                    lg: 16,
                    md: 16,
                    sm: 12,
                    xs: 10,
                  }}
                >
                  Nueva orden
                </Typography>
              </Button>
            </CustomContainerNewOrder>
          )}
        </Grid>
        <Box>
          <Typography
            fontWeight="bold"
            variant="body1"
            fontSize={{
              lg: 16,
              md: 16,
              sm: 12,
              xs: 10,
            }}
          >
            {moment().format('DD/MM/YY')}
          </Typography>
        </Box>
        <Grid display="flex" alignItems="center" mr={2}>
          {selectProfile && (
            <>
              <CustomContainerButton>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#E25060' }}
                  onClick={() => {
                    dispatch(cleanAllOrder());
                    dispatch(cleanTransactionFailedProducts());
                    router('/order/inProcess');
                  }}
                >
                  <Typography
                    textTransform="initial"
                    color="black"
                    fontWeight="bold"
                    variant="body1"
                    fontSize={{
                      lg: 16,
                      md: 16,
                      sm: 12,
                      xs: 10,
                    }}
                  >
                    Ordenes en proceso
                  </Typography>
                </Button>
              </CustomContainerButton>
              {/* <CustomContainerButton>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#E87380' }}
                  onClick={() => {
                    dispatch(cleanAllOrder());
                    dispatch(cleanTransactionFailedProducts());
                    router('/order/onHold');
                  }}
                >
                  <Typography
                    textTransform="initial"
                    color="black"
                    fontWeight="bold"
                    variant="body1"
                    fontSize={{
                      lg: 16,
                      md: 16,
                      sm: 12,
                      xs: 10,
                    }}
                  >
                    Ordenes en espera
                  </Typography>
                </Button>
              </CustomContainerButton> */}
              <CustomContainerButton>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#EF9FA8' }}
                  onClick={() => {
                    setShowModal(true);
                    dispatch(cleanTransactionFailedProducts());
                  }}
                >
                  <Typography
                    textTransform="initial"
                    color="black"
                    fontWeight="bold"
                    variant="body1"
                    fontSize={{
                      lg: 16,
                      md: 16,
                      sm: 12,
                      xs: 10,
                    }}
                  >
                    Cambiar usuario
                  </Typography>
                </Button>
              </CustomContainerButton>
            </>
          )}
          {!selectProfile && (
          <Box display="flex" alignItems="center" ml={3}>
            <Avatar alt="restaurant-photo" src={restaurant?.logo_photo?.original} />
            <Typography>{restaurant?.name}</Typography>
          </Box>
          )}
          {selectProfile && (
          <Box display="flex" alignItems="center" ml={3}>
            <Avatar alt="profile-avatar">
              {getInitials(selectProfile?.full_name)}
            </Avatar>
            <Typography>{selectProfile?.full_name}</Typography>
          </Box>
          )}
        </Grid>
      </CustomizedToolBar>
      <ConfirmModal
        open={showModal}
        onClose={() => setShowModal(!showModal)}
        onConfirm={(e) => {
          setShowModal(!showModal);
          e?.preventDefault();
          dispatch(cleanSelectedProfile());
          dispatch(cleanAllOrder());
        }}
        title="¿Estás seguro que deseas cambiar de usuario?"
      />
    </CustomizedAppBar>
  );
};
