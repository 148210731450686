import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { COLORS } from '../../constants';

const CustomMainBox = styled(Box)`
  background-color: rgb(0, 23, 20, 0.7);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  flex:1
`;

const CustomIconOption = styled(Box)`
  background-color: ${COLORS.GREEN};
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomEditIcon = styled(EditIcon)`
  width: 24px;
  height: 24px;
  color: white;
`;

const CustomDeleteIcon = styled(DeleteIcon)`
  width: 24px;
  height: 24px;
  color: white;
`;

const CustomDownloadIcon = styled(DownloadIcon)`
  width: 24px;
  height: 24px;
  color: white;
`;

type OptionImageProps = {
  handleDownloadDocument?: () => void;
  handleDeleteImage?: () => void;
  handleSelectImage?: () => void;
  img: string;
  isSharedMenu: boolean;
}

export const OptionImageProduct = ({
  handleDownloadDocument,
  handleDeleteImage,
  handleSelectImage,
  img,
  isSharedMenu,
}: OptionImageProps) => (
  <CustomMainBox>
    {isSharedMenu && (
      <CustomIconOption id="edit" onClick={handleSelectImage}>
        <CustomEditIcon id="edit-icon" />
      </CustomIconOption>
    )}
    {img && (
      <CustomIconOption id="load" onClick={handleDownloadDocument}>
        <CustomDownloadIcon id="load-icon" />
      </CustomIconOption>
    )}
    {img && (
      <CustomIconOption id="option" onClick={handleDeleteImage}>
        <CustomDeleteIcon id="option-icon" />
      </CustomIconOption>
    )}
  </CustomMainBox>
);
