import { ref, getStorage, getMetadata } from 'firebase/storage';
import { createUrlResize } from '../gallery';
import { Photo } from '../photo';

export const PictureModule = {
  async getPhotoUrls(restId: string, docId: string, url: string) {
    const refGallery = ref(getStorage(), url);
    const metadata = getMetadata(refGallery);
    const { bucket } = await Promise.resolve(metadata);
    if (restId) {
      const image = new Photo({
        original: url,
        x400: createUrlResize({
          bucket, restId, docId, resize: '400x400',
        }),
        x40: createUrlResize({
          bucket, restId, docId, resize: '40x40',
        }),
        x80: createUrlResize({
          bucket, restId, docId, resize: '80x80',
        }),
      });
      return image;
    }
    return new Photo({});
  },
};
