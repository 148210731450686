import React, {
  useMemo, useState, useEffect,
} from 'react';
import { useSnackbar } from 'notistack';
import { HeadTitle } from '../../components';
import {
  MenuSection, Product, Addition,
  AdditionOption,
  getAdditionByProduct,
  OrderAdditionSelected,
  SelectedProduct,

} from '../../core';
import {
  useAppSelector,
  useAppDispatch,
} from '../../appHooks/redux';
import {
  addProduct, addAdditions, setSelectProduct, cleanSelect, setSelectAdditions, setAdditions, deleteProduct,
} from '../../redux/reducers/shoppingCart';
import { MenuScreen } from '../../screens';
import { guid } from '../../utils/randomUtils';
import { VerifyAdditionsModal } from '../../components/modal/verifyAdditionsModal';
import { verifyAdditions } from '../../utils/orderUtils';

type AdditionState = {
  loading: boolean;
  additions: Addition[]| null;
};

const additionFilter = (
  selected: OrderAdditionSelected[],
  currents: AdditionOption[],
) => {
  const match: AdditionOption[] = [];
  selected?.forEach((select) => {
    const result = currents?.find((current) => current.id === select.id);
    if (result) {
      match.push(result);
    }
  });
  return match;
};

const INITIAL_ADDITION_STATE = {
  loading: false,
  additions: null,
};

const Menu = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const restaurant = useAppSelector((store) => store.restaurant.restaurant);
  const subsidiary = useAppSelector((store) => store.subsidiary.subsidiary);
  const selectProduct = useAppSelector((store) => store.shoppingCart.selectProduct);
  const selectAdditions = useAppSelector((store) => store.shoppingCart.selectAdditions);
  const currentAdditions = useAppSelector((store) => store.shoppingCart.currentAdditions);
  const products = useAppSelector((state) => state.product.products);
  const productsStock = useAppSelector((store) => store.stock.productsStock);

  const menuSections = useMemo(() => {
    const sections = subsidiary?.menu_section;
    if (sections) {
      return sections.filter((section: MenuSection) => section.enable === true);
    }
    return [];
  }, [subsidiary]);
  const [additionState, setAdditionState] = useState<AdditionState>(INITIAL_ADDITION_STATE);
  const [selectSection, setSelectSection] = useState<string>('menu');
  const [nextSelectSection, setNextSelectSection] = useState<string>('menu');
  const [verifyAdditionsModal, setVerifyAdditionsModal] = useState(false);
  const [missingAdditionsList, setMissingAdditionsList] = useState<string[]>([]);
  const [productSearch, setProductSearch] = useState<null|Product>(null);

  useEffect(() => {
    if (selectProduct) {
      if (selectProduct.menu_section_id === '') {
        const productFinded = products?.find((product: Product) => product.id === selectProduct.id);
        selectProduct.menu_section_id = productFinded?.menu_section_id || 'menu';
      }
      setSelectSection(selectProduct.menu_section_id);
      const fetchAdditions = async () => {
        const additions = await getAdditionByProduct({
          subsidiaryId: selectProduct.subsidiary_id,
          productId: selectProduct.id,
          restaurantId: selectProduct.restaurant_id,
        });
        setAdditionState({ loading: false, additions });
      };
      fetchAdditions();
    } else {
      setAdditionState({ loading: false, additions: null });
    }
  }, [selectProduct]);

  const onSelectProduct = async (product: Product, isSearch?: boolean) => {
    if ((restaurant?.enable_inventory && productsStock[product.id] > 0) || !restaurant?.enable_inventory) {
      if (!selectProduct || isSearch) {
        const newProduct = new SelectedProduct({ ...product, quantity: 1, index: guid() });
        dispatch(setSelectProduct(newProduct));
        dispatch(addProduct(newProduct));
      } else if (selectProduct.quantity > 0) {
        const newQuantity = selectProduct.quantity + 1;
        const updateProduct = new SelectedProduct({ ...selectProduct, quantity: newQuantity });
        const updateCurrentAdditions = currentAdditions.map((selectAddition) => {
          if (updateProduct.index === selectAddition.product_index) {
            return new OrderAdditionSelected({
              ...selectAddition,
              quantity: newQuantity,
            });
          }
          return new OrderAdditionSelected(selectAddition);
        });
        dispatch(setSelectProduct(updateProduct));
        dispatch(addProduct(updateProduct));
        dispatch(setAdditions(updateCurrentAdditions));
      }
    }
  };

  const onSelectSection = (sectionId:string, isSearch?: boolean, product?: Product) => {
    const { additions } = additionState;
    const listMissingAdditions = verifyAdditions(additions || [], selectAdditions);
    if (isSearch && product) {
      setProductSearch(product);
    } else {
      setProductSearch(null);
    }
    if (listMissingAdditions.length > 0) {
      setMissingAdditionsList(listMissingAdditions);
      setNextSelectSection(sectionId);
      setVerifyAdditionsModal(true);
    } else {
      dispatch(cleanSelect());
      setSelectSection(sectionId);
      setAdditionState({ loading: false, additions: null });
      if (isSearch && product) {
        onSelectProduct(product, true);
        setProductSearch(null);
      }
    }
  };

  const onSelectBarcodeProduct = async (product: Product, quantity: number) => {
    if ((restaurant?.enable_inventory && productsStock[product.id] > 0) || !restaurant?.enable_inventory) {
      if (!selectProduct) {
        const newProduct = new SelectedProduct({ ...product, quantity, index: guid() });
        dispatch(setSelectProduct(newProduct));
        dispatch(addProduct(newProduct));
      } else if (selectProduct.quantity > 0) {
        const newQuantity = selectProduct.quantity + quantity;
        const updateProduct = new SelectedProduct({ ...selectProduct, quantity: newQuantity });
        const updateCurrentAdditions = currentAdditions.map((selectAddition) => {
          if (updateProduct.index === selectAddition.product_index) {
            return new OrderAdditionSelected({
              ...selectAddition,
              quantity: newQuantity,
            });
          }
          return new OrderAdditionSelected(selectAddition);
        });
        dispatch(setSelectProduct(updateProduct));
        dispatch(addProduct(updateProduct));
        dispatch(setAdditions(updateCurrentAdditions));
      }
      onSelectSection('menu');
    }
  };

  const onSelectAddition = async (addition: Addition, option: AdditionOption) => {
    const exist = selectAdditions?.find((selected) => selected.id === option.id);
    if (selectProduct) {
      if (exist) {
        const additions = selectAdditions.filter((selected) => selected.id !== option.id);
        const newCurrentAdditions = currentAdditions.filter((selected) => selected.id !== option.id);
        dispatch(setSelectAdditions(additions));
        dispatch(setAdditions(newCurrentAdditions));
        if (additions.length > 0) {
          selectProduct.additionFlag = true;
        } else {
          selectProduct.additionFlag = false;
        }
      } else {
        const matches = additionFilter(selectAdditions, addition.options || []);
        if (matches.length < addition.max_selection) {
          const selectedOption: OrderAdditionSelected = new OrderAdditionSelected({
            ...option,
            product_id: addition.product_id,
            addition_id: addition.id,
            addition_name: addition.name,
            quantity: selectProduct.quantity,
            product_index: selectProduct.index,
          });
          dispatch(setSelectAdditions([...selectAdditions, selectedOption]));
          dispatch(addAdditions([selectedOption]));
          if (selectProduct) selectProduct.additionFlag = true;
        } else {
          enqueueSnackbar('A alcanzado el limite de selección de esta sección XXXXX', {
            variant: 'warning',
          });
        }
      }
    }
  };

  const deleteCurrentProduct = () => {
    if (selectProduct) dispatch(deleteProduct(selectProduct));
    dispatch(cleanSelect());
    setSelectSection(nextSelectSection);
    setAdditionState({ loading: false, additions: null });
    setVerifyAdditionsModal(false);
    if (productSearch) {
      onSelectProduct(productSearch, true);
    }
  };

  return (
    <>
      <HeadTitle title="Menu" />
      <MenuScreen
        restaurant={restaurant}
        products={products}
        menuSections={menuSections}
        onSelectProduct={onSelectProduct}
        onSelectBarcodeProduct={onSelectBarcodeProduct}
        selectProduct={selectProduct}
        additions={additionState.additions}
        loadingAdditions={additionState.loading}
        selectedAdditions={selectAdditions}
        onSelectAddition={onSelectAddition}
        onSelectSection={onSelectSection}
        selectSection={selectSection}
      />
      <VerifyAdditionsModal
        open={verifyAdditionsModal}
        title={`Si continúa perderá el producto ${selectProduct ? selectProduct.name : ''}`}
        okText="Continuar"
        onConfirm={() => {
          deleteCurrentProduct();
          setVerifyAdditionsModal(false);
        }}
        cancelText="Cancelar"
        onClose={() => { setVerifyAdditionsModal(false); }}
        missingAdditions={missingAdditionsList}
      />
    </>
  );
};

export default Menu;
