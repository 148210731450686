import {
  doc, getFirestore, Transaction, updateDoc, setDoc,
} from 'firebase/firestore';
import { COLLECTIONS } from '../../constants';
import { JourneyProductionProduct } from './journeyProductionProduct.dto';
import { getDocId } from '../../config/firebaseService/firestore';

const db = getFirestore();

export const updateProductsProductionId = (
  restaurantId:string,
  subsidiaryId:string,
  journeyProductId: string,
  productsProductionId: string,
  data: any,
) => {
  const journeyProductionRef = doc(
    db,
    COLLECTIONS.RESTAURANT,
    restaurantId,
    COLLECTIONS.SUBSIDIARY,
    subsidiaryId,
    COLLECTIONS.JOURNEY_PRODUCTION,
    journeyProductId,
    COLLECTIONS.JOURNEY_PRODUCTION_PRODUCTS,
    productsProductionId,
  );
  updateDoc(journeyProductionRef, { ...data });
};

export const addProductsProduction = (
  restaurantId:string,
  subsidiaryId:string,
  journeyProductId: string,
  data: JourneyProductionProduct,
) => {
  const id = getDocId(COLLECTIONS.JOURNEY_PRODUCTION_PRODUCTS);
  const journeyProductionRef = doc(
    db,
    COLLECTIONS.RESTAURANT,
    restaurantId,
    COLLECTIONS.SUBSIDIARY,
    subsidiaryId,
    COLLECTIONS.JOURNEY_PRODUCTION,
    journeyProductId,
    COLLECTIONS.JOURNEY_PRODUCTION_PRODUCTS,
    id,
  );
  setDoc(journeyProductionRef, { ...data.toPlainObject(), id });
};

export const verifyProductTransaction = async (
  restId:string,
  subId:string,
  journeyProductId: string,
  productsProductionId: string,
  transaction: Transaction,
  currentStock: number,
) => {
  const journeyProductionRef = doc(
    db,
    COLLECTIONS.RESTAURANT,
    restId,
    COLLECTIONS.SUBSIDIARY,
    subId,
    COLLECTIONS.JOURNEY_PRODUCTION,
    journeyProductId,
    COLLECTIONS.JOURNEY_PRODUCTION_PRODUCTS,
    productsProductionId,
  );
  const product = await transaction.get(journeyProductionRef);
  const productItem = new JourneyProductionProduct({ ...product.data() });
  const dbStock = productItem.getStock();
  const result = dbStock - currentStock;
  if (result >= 0) {
    return { isSuccess: true, data: { journeyProductionRef, newSales: currentStock + productItem.sales } };
  }
  return {
    isSuccess: false,
    data: {
      journeyProductionRef, newSales: currentStock, productId: productItem.product.id, status: false,
    },
  };
};
