import React, { useRef, useEffect, useState } from 'react';
import {
  Box, Button, Card, TextField, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Product } from '../../core';
import { ProductsStock } from '../../redux/reducers/stock';
import { AlertModal } from '../../components';
import { Warning } from '../../assets';

type BarcodeSectionProps = {
  arrayProducts: Product[] | null | undefined;
  onSelectBarcodeProduct: (product: Product, quantity: number) => void;
  productsStock: ProductsStock
}

const CustomCard = styled(Card)`
  display: flex;
  padding: 30px;
`;

export const BarcodeSection = ({ arrayProducts, onSelectBarcodeProduct, productsStock }: BarcodeSectionProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleShowModalLimitStock = (value: boolean) => {
    setShowModal(value);
  };

  const handleFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    handleFocusInput();
  }, []);

  const formik = useFormik({
    initialValues: {
      quantity: 1,
      barcode: '',
    },
    validationSchema: Yup.object({
      quantity: Yup.number()
        .positive('Cantidad mínima 1')
        .required('Requiere cantidad'),
      barcode: Yup.string()
        .max(255)
        .required('Requiere codigo de barras'),
    }),
    onSubmit: (values) => {
      if (arrayProducts) {
        const barcodeExists = arrayProducts.find((product) => product.barcode === values.barcode);
        if (barcodeExists) {
          const stock = productsStock[barcodeExists.id];
          if (stock >= values.quantity) {
            onSelectBarcodeProduct(barcodeExists, values.quantity);
          } else {
            handleShowModalLimitStock(true);
          }
          // eslint-disable-next-line no-use-before-define
          setFieldValue('barcode', '');
          // eslint-disable-next-line no-use-before-define
          setFieldValue('quantity', 1);
          handleFocusInput();
        }
      }
    },
  });

  const {
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
    handleSubmit,
    setFieldValue,
  } = formik;

  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
      <CustomCard>
        <Box width="100%" height="100%" display="flex">
          <form onSubmit={handleSubmit} style={{ width: '100%', height: '100%' }}>
            <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
              <Typography variant="h1">Codigo de barras</Typography>
            </Box>
            <Box mt={5}>
              <TextField
                id="quantity"
                name="quantity"
                type="number"
                fullWidth
                variant="outlined"
                placeholder="Cantidad"
                label="Cantidad"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.quantity}
              />
              {touched.quantity && errors.quantity ? (
                <Typography color="red">{errors.quantity}</Typography>
              ) : null}
            </Box>
            <Box mt={5}>
              <TextField
                inputRef={inputRef}
                id="barcode"
                key="barcode"
                name="barcode"
                type="string"
                fullWidth
                variant="outlined"
                placeholder="Cantidad"
                label="Codigo de barras"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.barcode}
                autoComplete="off"
              />
              {touched.barcode && errors.barcode ? (
                <Typography color="red">{errors.barcode}</Typography>
              ) : null}
            </Box>
            <Box mt={5}>
              <Button
                variant="contained"
                type="submit"
                fullWidth
              >
                Agregar producto
              </Button>
            </Box>
          </form>
        </Box>
        <AlertModal
          icon={Warning}
          open={showModal}
          title="Sobrepasa limite de stock"
          okText="Aceptar"
          onClose={() => handleShowModalLimitStock(false)}
        />
      </CustomCard>
    </Box>
  );
};
