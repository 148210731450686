import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { COLLECTIONS } from '../../constants';
import { Nullable } from '../../types';
import Metric from './metric.dto';

export const useMetrics = (): Nullable<Metric[]> => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.METRIC,
      where: ['enable', '==', true],
      storeAs: 'metrics',
    },
  ]);
  const metrics = useSelector(
    (state: any) => state.firestore.ordered.metrics,
  );

  if (metrics) {
    return metrics.map((metric:Metric) => new Metric({ ...metric }));
  }
  return null;
};
