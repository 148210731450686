import React, { useState } from 'react';
import {
  Box,
  Button, CardContent, CardHeader, Grid,
} from '@mui/material';
import { BaseModal } from '../../modal';
import { Gallery, useGetGalleryByRestaurant } from '../../../core';
import CropModal from '../../modal/cropModal';
import { UploadImgGalleryProps } from '../../../screens/galleryScreen/types';
import { CardImageGallery } from './cardImageGallery';
import { useAppSelector } from '../../../appHooks/redux';
import { ModalAddGallery } from './modalAddGallery';
import { Photo } from '../../../core/photo/photo.dto';

type ModalGalleryProps = {
  open: boolean;
  onClose: () => void;
  onUploadImg: ({ file, docId }: UploadImgGalleryProps) => void | Promise<boolean>,
  onAddGallery: (gallery: Gallery) => Promise<Gallery | null>,
  onSelect: (photo: Photo, docId: string) => void,
}

type FileStateProps = {
  extension: string,
  id: string,
  type: string,
}

export const ModalGallery = ({
  open, onClose, onUploadImg, onAddGallery, onSelect,
}: ModalGalleryProps) => {
  const currentRestaurant = useAppSelector((state) => state.restaurant.restaurant);
  const galleryList = useGetGalleryByRestaurant(currentRestaurant?.id);
  const [showAddGallery, setShowAddGallery] = useState(false);
  const [showCropModal, setShowCropModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [upImg, setUpImg] = useState<File>();
  const [loaderCard, setLoaderCard] = useState('');
  const [currentGallery, setCurrentGallery] = useState<Gallery>();
  const [loaderCrop, setLoaderCrop] = useState(false);
  const [fileState, setFileState] = useState<FileStateProps>({
    extension: '',
    id: '',
    type: '',
  });
  const [imageSelect, setImageSelect] = useState({
    docId: '',
    photo: new Photo({}),
  });

  const handleSelect = (docId: string, photo: Photo) => {
    setImageSelect({
      docId,
      photo,
    });
  };

  const handleCancel = () => {
    setImageSelect({ docId: '', photo: new Photo({}) });
    onClose();
  };

  const handleCloseDrop = () => {
    setUpImg(undefined);
    setShowCropModal(false);
  };

  const imageToUrl = (file: File, extension: string, id: string) => {
    setUpImg(file);
    setFileState({ extension, id, type: file.type });
    setShowCropModal(true);
  };

  const handleCropOriginal = async (docId: string) => {
    const gallery = galleryList.find((value) => value.id === docId);
    setLoaderCard(docId);
    setLoaderCrop(true);
    setCurrentGallery(gallery);
    if (gallery && gallery.original_image) {
      await fetch(gallery.original_image).then(async (response) => {
        const contentType = response.headers.get('content-type');
        if (contentType) {
          const blob = await response.blob();
          const file = new File([blob], `${docId}.${gallery.extension}`, {
            type: contentType,
          });
          imageToUrl(file, gallery.extension, docId);
        }
      });
    }
    setLoaderCrop(false);
  };

  const handleConfirmImage = async ({ file, originalFile }: UploadImgGalleryProps) => {
    setLoader(true);
    const dataGallery = new Gallery({
      ...currentGallery,
      extension: fileState.extension,
    });
    handleCloseDrop();
    if (currentGallery) {
      await onUploadImg({
        originalFile, file, docId: currentGallery.id, gallery: dataGallery,
      });
    }
    setLoader(false);
  };

  const handleSelectImage = () => {
    onSelect(imageSelect.photo, imageSelect.docId);
    handleCancel();
  };

  return (
    <BaseModal open={open} size="sm" onClose={handleCancel} fullWidth>
      <Grid container direction="row">
        <Grid
          sm={5}
          item
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '16px',
          }}
        >
          <Button
            style={{ paddingLeft: '16px' }}
            color="secondary"
            variant="contained"
            onClick={() => setShowAddGallery(true)}
          >
            Añadir nueva imagen
          </Button>
        </Grid>
        <Grid>
          <CardHeader
            style={{ paddingLeft: 0 }}
            title="Seleccione una imagen"
          />
        </Grid>
      </Grid>
      <CardContent>
        <Grid
          container
          direction="row"
          spacing={2}
        >
          {galleryList?.map((gallery: Gallery) => (gallery.image.original !== '' ? (
            <Grid sm={3} item key={gallery.id}>
              <CardImageGallery
                gallery={gallery}
                imageSelect={imageSelect.docId}
                handleSelect={(id, photo) => handleSelect(id, photo)}
                handleCropOriginal={(id) => handleCropOriginal(id)}
                loader={loader}
                loaderCrop={loaderCrop}
                loaderCard={loaderCard}
              />
            </Grid>
          ) : null))}
        </Grid>
        <Box mt={3} width="100%">
          <Grid item container justifyContent="center">
            <Box mr={2}>
              <Button
                variant="contained"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
            </Box>
            <Box ml={2}>
              <Button
                color="secondary"
                variant="contained"
                disabled={!imageSelect}
                onClick={handleSelectImage}
              >
                Seleccionar
              </Button>
            </Box>
          </Grid>
        </Box>
      </CardContent>
      <CropModal
        openDialog={showCropModal}
        onClose={handleCloseDrop}
        file={upImg}
        onConfirm={
          (
            { file, originalFile }: UploadImgGalleryProps,
          ) => handleConfirmImage({ file, originalFile })
          }
        typeImage={fileState.type}
      />
      <ModalAddGallery
        open={showAddGallery}
        onClose={() => setShowAddGallery(false)}
        onUploadImg={onUploadImg}
        onAddGallery={onAddGallery}
      />
    </BaseModal>
  );
};
