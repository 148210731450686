import { FirebaseBase } from '../firebaseBase';
import { ROLES } from './profile.enum';
import {
  Constructor, PlainObject,
} from '../../types';

export class Profile extends FirebaseBase {
  subsidiary_id:string;

  full_name:string;

  enabled:boolean;

  code:string;

  roles: ROLES[];

  email: string;

  phone_number : string;

  ci :string;

  restaurant_id : string;

  constructor({
    subsidiary_id = '',
    full_name = '',
    enabled = true,
    code = '',
    roles = [ROLES.CASHIER],
    email = '',
    phone_number = '',
    ci = '',
    restaurant_id = '',
    ...data
  }:Constructor<Profile>) {
    super(data);
    this.subsidiary_id = subsidiary_id;
    this.full_name = full_name;
    this.code = code.toString();
    this.roles = roles;
    this.ci = ci;
    this.enabled = enabled;
    this.phone_number = phone_number;
    this.email = email;
    this.restaurant_id = restaurant_id;
  }

  toPlainObject(): PlainObject<Profile> {
    return { ...this };
  }
}
