import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';
import {
  Order, SelectedProduct, OrderAdditionSelected,
} from '../../../core';
import { OrderProduct } from '../../../core/orderProduct';

type ShoppingCartState = {
  currentOrder:Order | null,
  currentProducts:SelectedProduct[],
  currentAdditions:OrderAdditionSelected[],
  orderProduct: OrderProduct[],
  isOrderEdit: boolean,
  transactionFailedProducts: Record<string, string>
  selectProduct:SelectedProduct | null,
  selectAdditions:OrderAdditionSelected[],
}

const initialState: ShoppingCartState = {
  // order on firestore and ui
  currentOrder: null,
  // order on UI
  currentProducts: [],
  currentAdditions: [],
  // order saved on firestore
  orderProduct: [],
  isOrderEdit: false,
  transactionFailedProducts: {},
  // items selected on menu UI
  selectProduct: null,
  selectAdditions: [],
};

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {

    loadCurrentOrder: (state: Draft<ShoppingCartState>, action: PayloadAction<Order>) => {
      state.currentOrder = action.payload;
      state.isOrderEdit = false;
    },
    setCurrentOrder: (state: Draft<ShoppingCartState>, action: PayloadAction<Order>) => {
      state.currentOrder = action.payload;
      state.isOrderEdit = true;
    },
    cleanCurrentOrder: (state: Draft<ShoppingCartState>) => {
      state.currentOrder = null;
      state.isOrderEdit = false;
    },
    updateSubTotal: (state: Draft<ShoppingCartState>, action: PayloadAction<number>) => {
      const sub_total = action.payload;
      const currentOrder = new Order({ ...state.currentOrder, sub_total });
      state.currentOrder = currentOrder;
      state.isOrderEdit = true;
    },
    addProduct: (state: Draft<ShoppingCartState>, action: PayloadAction<SelectedProduct>) => {
      const lastProducts = [...state.currentProducts];
      const indexProduct = lastProducts.findIndex((item) => item.index === action.payload.index);
      if (indexProduct >= 0 && action.payload.additionFlag) {
        lastProducts[indexProduct].quantity = action.payload.quantity;
      } else {
        const indexOnlyProduct = state.currentProducts.findIndex((item) => item.id === action.payload.id && !action.payload.additionFlag && item.index === action.payload.index);
        const productFilterByIndex = state.currentProducts.findIndex((item) => item.index === action.payload.index && !action.payload.additionFlag);
        if (indexOnlyProduct >= 0 && !action.payload.additionFlag) {
          if (productFilterByIndex >= 0) {
            lastProducts[productFilterByIndex].quantity = action.payload.quantity;
          } else {
            lastProducts[indexOnlyProduct].quantity += action.payload.quantity;
          }
        } else {
          lastProducts.push(action.payload);
        }
      }
      state.currentProducts = lastProducts;
    },
    setProducts: (state: Draft<ShoppingCartState>, action: PayloadAction<SelectedProduct[]>) => {
      state.currentProducts = action.payload;
    },
    setSelectProduct: (state: Draft<ShoppingCartState>, action: PayloadAction<SelectedProduct>) => {
      state.selectProduct = action.payload;
    },
    setSelectAdditions: (state: Draft<ShoppingCartState>, action: PayloadAction<OrderAdditionSelected[]>) => {
      state.selectAdditions = action.payload;
    },
    setOrderProduct: (state: Draft<ShoppingCartState>, action: PayloadAction<OrderProduct[]>) => {
      state.orderProduct = action.payload;
    },
    /* updateProduct: (state: Draft<ShoppingCartState>, action: PayloadAction<SelectedProduct>) => {
      const updateProduct = action.payload;
      const lastProducts = [...state.currentProducts]
        .filter((product:Product) => product.id !== updateProduct.id);
      lastProducts.push(updateProduct);
      state.currentProducts = lastProducts;
    }, */
    deleteProduct: (state: Draft<ShoppingCartState>, action: PayloadAction<SelectedProduct>) => {
      const deleteProduct = action.payload;
      const lastProducts = [...state.currentProducts]
        .filter((product:SelectedProduct) => product.index !== deleteProduct.index);
      const lastAdditions = [...state.currentAdditions]
        .filter((addition:OrderAdditionSelected) => addition.product_index !== deleteProduct.index);
      state.currentProducts = lastProducts;
      state.currentAdditions = lastAdditions;
    },
    deleteAdditionByProduct: (state: Draft<ShoppingCartState>, action: PayloadAction<SelectedProduct>) => {
      const deleteProduct = action.payload;
      const lastAdditions = [...state.currentAdditions]
        .filter((addition:OrderAdditionSelected) => addition.product_index !== deleteProduct.index);
      state.currentAdditions = lastAdditions;
    },
    cleanProduct: (state: Draft<ShoppingCartState>) => {
      state.currentProducts = [];
    },
    cleanAdditions: (state: Draft<ShoppingCartState>) => {
      state.currentAdditions = [];
    },
    incrementQuantityForProduct: (
      state: Draft<ShoppingCartState>,
      action: PayloadAction<SelectedProduct>,
    ) => {
      const updateProduct = { ...action.payload };
      const lastProducts = [...state.currentProducts];
      const indexToUpdate = lastProducts.findIndex(
        (product: SelectedProduct) => product.index === updateProduct.index,
      );
      lastProducts[indexToUpdate].quantity += 1;
      const lastAdditions = [...state.currentAdditions];

      state.currentProducts = lastProducts;
      state.currentAdditions = lastAdditions?.map((addition) => {
        if (addition.product_index === updateProduct.index) {
          return { ...addition, quantity: lastProducts[indexToUpdate].quantity };
        }
        return addition;
      });
    },
    decrementQuantityForProduct: (
      state: Draft<ShoppingCartState>,
      action: PayloadAction<SelectedProduct>,
    ) => {
      const updateProduct = { ...action.payload };
      const lastProducts = [...state.currentProducts];
      const indexToUpdate = lastProducts.findIndex(
        (product: SelectedProduct) => product.index === updateProduct.index,
      );
      lastProducts[indexToUpdate].quantity -= 1;
      const lastAdditions = [...state.currentAdditions];

      state.currentProducts = lastProducts;
      state.currentAdditions = lastAdditions?.map((addition) => {
        if (addition.product_index === updateProduct.index) {
          return { ...addition, quantity: lastProducts[indexToUpdate].quantity };
        }
        return addition;
      });
    },
    addAdditions: (
      state: Draft<ShoppingCartState>,
      action: PayloadAction<OrderAdditionSelected[]>,
    ) => {
      const lastAdditions = [...state.currentAdditions];

      lastAdditions.push(...action.payload);

      state.currentAdditions = lastAdditions;
    },
    setAdditions: (
      state: Draft<ShoppingCartState>,
      action: PayloadAction<OrderAdditionSelected[]>,
    ) => {
      state.currentAdditions = [];
      let lastAdditions = [...state.currentAdditions];
      if (lastAdditions.length > 0) {
        lastAdditions.forEach((currentAddition) => {
          const value = action.payload.find((addition) => addition.id === currentAddition.id);
          if (value) {
            currentAddition.quantity += value.quantity;
            lastAdditions = [...lastAdditions];
          }
        });
      } else {
        lastAdditions.push(...action.payload);
      }
      state.currentAdditions = lastAdditions;
    },
    incrementQuantityForAddition: (
      state: Draft<ShoppingCartState>,
      action: PayloadAction <OrderAdditionSelected>,
    ) => {
      const updateAddition = { ...action.payload };
      const lastAdditions = [...state.currentAdditions];
      const indexToUpdate = lastAdditions.findIndex(
        (addition:OrderAdditionSelected) => addition.id === updateAddition.id,
      );
      lastAdditions[indexToUpdate].quantity += 1;
      state.currentAdditions = lastAdditions;
    },
    decrementQuantityForAddition: (
      state: Draft<ShoppingCartState>,
      action: PayloadAction<OrderAdditionSelected>,
    ) => {
      const updateAddition = { ...action.payload };
      const lastAdditions = [...state.currentAdditions];
      const indexToUpdate = lastAdditions.findIndex(
        (addition:OrderAdditionSelected) => addition.id === updateAddition.id,
      );
      lastAdditions[indexToUpdate].quantity -= 1;
      state.currentAdditions = lastAdditions;
    },
    cleanSelect: (state: Draft<ShoppingCartState>) => {
      state.selectProduct = null;
      state.selectAdditions = [];
    },
    cleanAllOrder: (state: Draft<ShoppingCartState>) => {
      state.currentOrder = null;
      state.currentAdditions = [];
      state.currentProducts = [];
      state.orderProduct = [];
      state.isOrderEdit = false;
      state.selectProduct = null;
      state.selectAdditions = [];
    },
    setTransactionFailedProducts: (state: Draft<ShoppingCartState>, action: PayloadAction<Record<string, string>>) => {
      state.transactionFailedProducts = action.payload;
    },
    cleanTransactionFailedProducts: (state:Draft<ShoppingCartState>) => {
      state.transactionFailedProducts = {};
    },
  },
});

export const {
  loadCurrentOrder,
  setCurrentOrder,
  cleanCurrentOrder,
  updateSubTotal,
  addProduct,
  setProducts,
  setSelectProduct,
  setSelectAdditions,
  deleteProduct,
  deleteAdditionByProduct,
  cleanProduct,
  incrementQuantityForProduct,
  decrementQuantityForProduct,
  addAdditions,
  setAdditions,
  cleanAdditions,
  setOrderProduct,
  cleanSelect,
  cleanAllOrder,
  setTransactionFailedProducts,
  cleanTransactionFailedProducts,
} = shoppingCartSlice.actions;
export default shoppingCartSlice.reducer;
export const shoppingCartReducerTransform = createTransform(
  (toSave:any) => ({
    ...toSave,
  }),
  (toRehydrated) => ({
    ...toRehydrated,
  }),
  { whitelist: ['shoppingCart'] },
);
