import { GeoPoint } from 'firebase/firestore';
import { FirebaseBase } from '../firebaseBase';
import {
  Constructor, PlainObject,
} from '../../types';
import { MenuSection } from '../menuSection';
import { REGISTER_STATUS } from './subsidiary.enum';
import { City } from '../city';

export class Subsidiary extends FirebaseBase {
  name: string;

  enabled:boolean;

  pos_user_id: string;

  register_status:REGISTER_STATUS;

  menu_section: MenuSection[];

  restaurant_id:string;

  city: City;

  address:string;

  orders_number: number;

  location:GeoPoint;

  index: string;

  constructor({
    name = '',
    pos_user_id = '',
    register_status = REGISTER_STATUS.ACTIVE,
    menu_section = [],
    enabled = true,
    restaurant_id = '',
    orders_number = 0,
    city = new City({}),
    location = new GeoPoint(0, 0),
    address = '',
    index = '1',
    ...data
  }:Constructor<Subsidiary>) {
    super(data);
    this.name = name;
    this.city = city;
    this.pos_user_id = pos_user_id;
    this.menu_section = menu_section.map((menuSection) => new MenuSection(menuSection));
    this.orders_number = orders_number;
    this.register_status = register_status;
    this.enabled = enabled;
    this.register_status = register_status;
    this.address = address;
    this.restaurant_id = restaurant_id;
    this.address = address;
    this.location = location;
    this.index = index;
    this.city = new City(city);
  }

  toPlainObject(): PlainObject<Subsidiary> {
    return { ...this, menu_section: this.menu_section.map((item) => item.toPlainObject()) };
  }
}
