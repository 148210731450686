import React from 'react';
import {
  Container, Stack, Paper, CircularProgress, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../constants';
import { Product } from '../../core/product';
import ProductSectionList from './ProductSectionList';
import { useAppSelector } from '../../appHooks/redux';
import Header from './Header';
import { enableProductProps } from './types';
import { MenuSection } from '../../core/menuSection';
import { Nullable } from '../../types';

const ContainerMain = styled(Container)(() => ({
  width: '100%',
  minHeight: '93.8vh',
  backgroundColor: COLORS.BG_GRAY,
}));

type ProductSectionScreenProps = {
    menuSection: Nullable<MenuSection>;
    productsDB: Nullable<Product[]>;
    onCreateProduct?: (sectionId:string) => void,
    isSharedMenu: boolean,
    onRemoveProduct?: (productId: string) => void;
    onEnableProduct: ({
      productId, checked,
    }: enableProductProps) => void;
    onDragEnd: (products: Product[]) => void;
    onClickProduct: (productId: string) => void;
  };

export const ProductSectionScreen = ({
  menuSection,
  productsDB,
  onDragEnd,
  onCreateProduct,
  isSharedMenu,
  onRemoveProduct,
  onEnableProduct,
  onClickProduct,
}: ProductSectionScreenProps) => {
  const currentRestaurant = useAppSelector((state) => state.restaurant.restaurant);
  const currentSubsidiary = useAppSelector((state) => state.subsidiary.subsidiary);

  return (
    <ContainerMain maxWidth="xl">
      <Header
        onCreateProduct={onCreateProduct}
        isSharedMenu={isSharedMenu}
        section={menuSection}
        restaurantName={currentRestaurant?.name || ''}
        subsidiaryName={currentSubsidiary?.name || ''}
      />
      {!productsDB && (
        <Box display="flex" justifyContent="center" my={5}>
          <CircularProgress />
        </Box>
      )}
      {productsDB && (
        <Stack>
          <Paper
            square
            sx={{ padding: '32px 16px 16px 16px', minHeight: '80vh', marginBottom: '24px' }}
          >
            <Stack>
              <Stack mt={3}>
                <ProductSectionList
                  productsDB={productsDB}
                  isSharedMenu={isSharedMenu}
                  onRemoveProduct={onRemoveProduct}
                  onEnableProduct={onEnableProduct}
                  onDragEnd={onDragEnd}
                  onClickProduct={onClickProduct}
                />
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      )}
    </ContainerMain>
  );
};
