import React from 'react';
import moment from 'moment-timezone';
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  styled,
} from '@mui/material';
import { Order, getOrderType, getOrderStatus } from '../../core';
import { toTimestamp } from '../../utils/firestoreUtils';

type TableOrdersProps = {
  rows?: Order[];
  headerData:string[];
  onRowClick: (order: Order) => void;
};

const OrderRow = styled(TableRow)`
  cursor: pointer;
  &:last-child td, &:last-child th {
    border: 0;
  }
`;

export const TableOrders = ({ rows, headerData, onRowClick }:TableOrdersProps) => (
  <TableContainer
    component={Paper}
    sx={{
      overflow: 'scroll',
      height: '75vh',
    }}
  >
    <Table aria-label="oreder-table">
      <TableHead>
        <TableRow key="header-row">
          {headerData?.map((head:string) => (
            <TableCell align="center">
              <Typography variant="h4">{head}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows?.map((row: Order) => {
          const rowDate = toTimestamp(row?.created_at);
          return (
            <OrderRow
              key={`key-order-${row.id}`}
              onClick={(e) => {
                e.preventDefault();
                onRowClick(row);
              }}
            >
              <TableCell align="center">{row.order_code || '-'}</TableCell>
              <TableCell align="center">{row.count_of_products || '-'}</TableCell>
              <TableCell align="center">{row?.order_type ? (getOrderType(row.order_type)) : ('-')}</TableCell>
              <TableCell align="center">{row.client_name || '-'}</TableCell>
              <TableCell align="center">{moment(rowDate.toDate()).format('DD/MM/YY HH:mm')}</TableCell>
              <TableCell align="center">
                <Chip label={getOrderStatus(row.status)} color="secondary" sx={{ backgroundColor: '#7F98DC', color: 'black' }} />
              </TableCell>
            </OrderRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
);
