import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../appHooks/redux';
import { HeadTitle } from '../../../components';
import { useCities } from '../../../core';
import { useSubsidiaryUser } from '../../../core/profile/profile.hook';

import { SubsidiaryProfileScreen } from '../../../screens/subsidiaryInfoScreen';

const SubsidiaryInfo = () => {
  const subsidiary = useAppSelector((state) => state.subsidiary.subsidiary);
  const { subsidiaryId } = useParams();
  const cities = useCities();
  const user = useSubsidiaryUser(subsidiaryId || '');

  return (
    <>
      <HeadTitle title="Información de subsidiaria" />
      <SubsidiaryProfileScreen subsidiary={subsidiary} cities={cities} user={user} />
    </>
  );
};

export default SubsidiaryInfo;
