import React from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Stack,
  Typography,
  Link,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MenuSection } from '../../core/menuSection';
import { COLORS } from '../../constants';
import { Nullable } from '../../types';

export type HeaderProps = {
  onCreateProduct?: (sectionId:string) => void,
  isSharedMenu: boolean,
  section: Nullable<MenuSection>,
  restaurantName:string,
  subsidiaryName:string,
};

const Header = ({
  onCreateProduct = () => {},
  isSharedMenu,
  section,
  restaurantName,
  subsidiaryName,
}: HeaderProps) => (
  <>
    {!section && (
      <Box display="flex" justifyContent="center" alignItems="center" my={5}>
        <CircularProgress />
      </Box>
    )}
    {section && (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pt={4}
        pb={2}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ color: COLORS.BLACK, fontSize: '18px' }}
        >
          <Typography sx={{ fontWeight: 600 }}>
            { isSharedMenu ? restaurantName : subsidiaryName}
          </Typography>
          <Link underline="hover" color="inherit" href={isSharedMenu ? '/sharedMenu' : '/subsidiaryMenu'}>
            <Typography
              sx={{
                fontWeight: 600,
                '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
              }}
            >
              { isSharedMenu ? 'Menú compartido' : 'Menu Sucursal'}
            </Typography>
          </Link>
          <Typography sx={{ fontWeight: 600 }}>
            { section.name}
          </Typography>
        </Breadcrumbs>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Button
            id="addProduct"
            color="secondary"
            variant="contained"
            disabled={!isSharedMenu}
            sx={{ fontSize: 12 }}
            onClick={(e:any) => {
              e?.preventDefault();
              onCreateProduct(section.id);
            }}
          >
            AÑADIR NUEVO PRODUCTO
          </Button>
        </Stack>
      </Stack>
    )}
  </>
);

export default Header;
