import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Outlet } from 'react-router';
import AuthGuard from '../../guards/authGuard';
import { MainLayout } from '../mainLayout';
import ProfileGuard from '../../guards/profileGuard';

export const LoggedLayout = () => (
  <AuthGuard>
    <MainLayout>
      <ProfileGuard>
        <Outlet />
      </ProfileGuard>
    </MainLayout>
  </AuthGuard>
);
